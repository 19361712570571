import { useLocation } from 'react-router-dom'
import { useUpdateEffect } from 'usehooks-ts'

export const useCustomNavigate = (cb: () => void) => {
  const location = useLocation()

  return useUpdateEffect(() => {
    cb()
  }, [location.pathname, location.search, location.hash, cb])
}
