import { eSeverity } from 'components/types'
import { iLooseObject } from 'pages/DataHub/filters/types'

export interface iToastErrorData {
  messages: iLooseObject[]
  isMessageActive: boolean
  severity?: eSeverity
  messageType?: eSnackbarLocation
}
export interface iMessage {
  title: string
  message: string
}
export interface iToastData {
  message: iMessage
  severity: eSeverity
  isMessageActive?: boolean
  messageType?: eSnackbarLocation
}
export interface iFieldsErrorData {
  messages: iLooseObject[]
}

export enum eSnackbarLocation {
  default = 'floatingTopCenter',
  embededSearchParams = 'embededSearchParams',
}

export interface iUserProfileUrls {
  changePasswordUrl: string
  updateProfileUrl: string
}
export interface iGeneralSettings {
  userProfileUrls: iUserProfileUrls
  isDrawerOpen: boolean
  toastData: iToastData
}

export interface iGeneralSettings {
  userProfileUrls: iUserProfileUrls
  isDrawerOpen: boolean
  toastErrorData: iToastErrorData
  fieldsErrorData: iFieldsErrorData
  organization: {
    isOrganizationCreationSuccessModalOpen: boolean
    isOrganizationCreateModalOpen: boolean
    isAddMemberModalOpen: boolean
  }
}

export interface iErrorViolation {
  code: string
  message: string
  propertyPath: string
}

export interface iErrorData {
  detail: string
  title: string
  type: string
  violations: iErrorViolation[]
}

export interface iErrorResponse {
  config: iLooseObject
  data: iErrorData
  headers: iLooseObject
  request: iLooseObject
  status: number
}
