import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { t } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FeaturedTableHeadProps, FeaturedTableProps, Row } from './types'

const FeaturedTable = ({ rows, onPaper }: FeaturedTableProps) => {
  return (
    <div data-testid="featured-table-testid">
      <Box sx={{ width: `100%` }}>
        <Paper sx={{ padding: `15px` }}>
          <TableContainer>
            <Table
              sx={{
                minWidth: 600,
              }}
              size={`small`}
            >
              <FeaturedTableHead columnHeaders={rows[0]} />
              <TableBody>
                {rows.map((row: Row[], i: number, arr: Row[][]) => {
                  if (i === 0) return null
                  return (
                    <TableRow hover key={'tableRow' + i}>
                      <TableCell
                        aria-checked={true}
                        role={`checkbox`}
                        scope="columnheader"
                        key={`checkbox-${i}`}
                        padding={`checkbox`}
                      >
                        <div
                          id={`table-checkbox-cell-id${i}`}
                          className="absolute h-[0.0625rem] w-[0.0625rem] overflow-hidden"
                        >
                          {t('geaturedTable.checkboxRowLabel') + ' ' + i}
                        </div>
                        <Checkbox
                          inputProps={{
                            'aria-label': `table-checkbox-cell-id${i}`,
                          }}
                          color={`primary`}
                        />
                      </TableCell>
                      {row.map((col: Row, i: number, arr: Row[]) => {
                        return (
                          <TableCell scope="columnheader" key={i}>
                            {col.value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  )
}

export default FeaturedTable

const FeaturedTableHead = ({ columnHeaders }: FeaturedTableHeadProps) => {
  const { t } = useTranslation()

  return (
    <TableHead>
      <TableRow>
        <TableCell
          scope="cell"
          aria-checked={true}
          role={`checkbox`}
          key={`check`}
          padding={`checkbox`}
        >
          <div id="table-heading-checkbox-cell" className="absolute h-[0.0625rem] w-[0.0625rem] overflow-hidden">
            {t('geaturedTable.checkboxHeadingLabel')}
          </div>
          <Checkbox
            inputProps={{
              'aria-label': 'table-heading-checkbox-cell',
            }}
            color={`primary`}
          />
        </TableCell>
        {columnHeaders
          ? columnHeaders.length > 0
            ? columnHeaders.map((col: Row, i: number, arr: Row[]) => {
                const colValue: String | number | any = col?.value
                return (
                  <TableCell scope="cell" component={`th`} key={i.toString()}>
                    <TableSortLabel>
                      <Typography variant={`subtitle2`} fontWeight={600}>
                        {t(colValue)}
                      </Typography>
                      <Box
                        component={`span`}
                        sx={{
                          visibility: `hidden`,
                        }}
                      >
                        {t('general.sorted')}
                      </Box>
                    </TableSortLabel>
                  </TableCell>
                )
              })
            : []
          : []}
      </TableRow>
    </TableHead>
  )
}
