import { Checkbox } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { eProductKey, iSubscriptionOrder } from "store/types/subscriptionOrder";
import { susIcons } from "config/services/requests";
import ModalWrapper from "components/custom/ModalWrapper";
import TitleWrapper from "components/custom/TitleWrapper";
import { ePackageSelection, eTypographyVariant } from "components/types";
import { useDispatch, useSelector } from "react-redux";
import { setSubscriptionOrder, subscriptionOrderReducerState } from "store/slices/subscriptionOrderSlice";
import { toScaledPrice } from "../helpers";
import { userReducerState } from "store/slices/user";

interface DatabasesAndToolsProps {
  packageSelection: ePackageSelection,
  productKey: eProductKey,
  selected: boolean,
  disabled: boolean,
  upgradeOnly?: boolean,
  price?: number,
  className?: string
}

export const DatabasesAndTools: FC<DatabasesAndToolsProps> = ({
  packageSelection,
  productKey,
  selected,
  disabled,
  upgradeOnly,
  price,
  className
}) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    subscriptionOrder
  } = useSelector(subscriptionOrderReducerState)

  const selectedClass = `bg-paletteLightestGreen`

  const [isChecked, setIsChecked] = useState<boolean>(selected)
  const [isWantingToUpgrade, setIsWantingToUpgrade] = useState<boolean>(false)

  const handleProductConstraints = (
    products: {productKey: eProductKey}[], 
    key: eProductKey
  ): {productKey: eProductKey}[] => {

    let _products = [...products]

    const isKey = (_key: eProductKey) => key === _key
    const pContains = (_key: eProductKey) => 
      products.filter(p => p.productKey === _key)
      .length > 0

    const isKeyDatabase = key.endsWith('_DB')

    const doesProductsContainDatabase = 
      products
        .filter(p => p.productKey.endsWith('_DB'))
        .length > 0

    /** Add Employee on each DB */
    if (
      isKeyDatabase 
        && !isKey(eProductKey.SUST_PRODUCTION_EMPLOYEE_DB)
        && !doesProductsContainDatabase
    ) {
      _products.push({productKey: eProductKey.SUST_PRODUCTION_EMPLOYEE_DB})
      return _products
    
    /** Add Mass Upload Feature */
    } else if (
      isKey(eProductKey.SUST_MASS_UPLOAD_FEATURE)
        && !pContains(eProductKey.SUST_MASS_UPLOAD_FEATURE)
        && !pContains(eProductKey.SUST_MATERIALS_DB)
    ) {
      _products.push(
        {productKey: eProductKey.SUST_MATERIALS_DB},
        {productKey: eProductKey.SUST_PRODUCTION_EMPLOYEE_DB} 
      )

      /** First DB added, adds employee, too */
      if (!doesProductsContainDatabase) {
        _products.push({productKey: eProductKey.SUST_PRODUCTION_EMPLOYEE_DB})
      }

      return _products
    
    /** Remove Mass Upload Feature */
    } 
    else if (
      isKey(eProductKey.SUST_MATERIALS_DB)
        && pContains(eProductKey.SUST_MATERIALS_DB)
        && pContains(eProductKey.SUST_MASS_UPLOAD_FEATURE)
    ) {
      _products = _products
        .filter(p => p.productKey !== eProductKey.SUST_MASS_UPLOAD_FEATURE)

      return _products
    }

    return products
  }

  const handleOnSelect = (key: eProductKey) => {
    if (!disabled) setIsChecked(!isChecked)

    if (!subscriptionOrder) return

    let order: iSubscriptionOrder = {...subscriptionOrder}
    let products = order.products

    products = handleProductConstraints(products, key)

    let newArray = [...products, {productKey: key}]
    if (!products.map(p => p.productKey).includes(key)) {
      order.products = newArray
    } else {
      const filteredArray = products.filter(p => p.productKey !== key)
      order.products = [...filteredArray]
    }

    dispatch(setSubscriptionOrder(order))
  }

  useEffect(() => {
    if (packageSelection !== ePackageSelection.FLEX) return

    if (!subscriptionOrder?.products
        .map(p => p.productKey)
        .includes(productKey)) {
          isChecked && setIsChecked(false)
    } else {
      !isChecked && setIsChecked(true)
    }
  }, [subscriptionOrder, setIsChecked, productKey, isChecked, packageSelection])

  const handleUpgrade = useCallback(() => {
    /**
    console.log(`Wishing to Upgrade?`)
    setIsWantingToUpgrade(!isWantingToUpgrade)
    */
  }, 
    // [setIsWantingToUpgrade, isWantingToUpgrade]
    []
  )

  const handleUpgradeClose = useCallback(() => {
    setIsWantingToUpgrade(false)
  }, [setIsWantingToUpgrade])

  const {
    settings
  } = useSelector(userReducerState)

  return (
    <div 
      className={` 
        cursor-pointer
        lg:basis-1/2 lg:p-1
        relative
      `}
      onClick={
        !disabled
          ? upgradeOnly
            ? () => handleUpgrade()
            : () => handleOnSelect(productKey)
          : () => ''
      }
    >
      <div className={`
        flex flex-row items-center justify-start
        border border-gray-100 shadow-md rounded-lg
        text-xs 
        ${className} 
        ${isChecked ? selectedClass : ''} 
      `}>
        {!upgradeOnly 
          ? (<Checkbox
              key={productKey} 
              onChange={!disabled ? () => handleOnSelect(productKey) : () => {}}
              checked={isChecked}
              disabled={disabled}
          />)
        : (
          <susIcons.Cross 
            className={`max-h-[16px] max-w-[16px] mx-2.5 my-2.5`} 
          />
        )}
        <div className={`
          flex flex-row flex-wrap items-center justify-start
          text-left text-xs pr-2
        `}>
            <span className={`break-all`}>{t(`offerings.productKeys.${productKey}`)}</span>
            {price !== undefined && (
              <span className={`text-[10px]`}>
                &nbsp;({toScaledPrice(price, settings.locale)})
              </span>
            )}
        </div>
      </div>

      {/**
       * NOT IMPLEMENTED YET:
       * User Choosing Upgrade Scenario 
       * */}
      <ModalWrapper
        open={isWantingToUpgrade}
        onClose={() => handleUpgradeClose()}
        hasCloseButton
      >
        <>
          <TitleWrapper variant={eTypographyVariant.h5}>Upgrade</TitleWrapper>
          <span>Are you sure you wish to upgrade? (Upgrade Delta Statement Component)</span>
        </>
      </ModalWrapper>
    </div>)
}