import React from 'react'
import { ContentBody } from 'components/pages/components/ContentBody'

import PaperWrapper from 'components/custom/PaperWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
import TextWrapper from 'components/custom/TextWrapper'
import { eTypographyVariant } from 'components/types'

export const TermsAndConditionsDocumentEnglish = () => {
  const headingClassName = 'pt-4 pb-2 text-paletteOrange uppercase'
  const orderedListClassName = 'list-[square] list-inside ml-4'

  return (
    <ContentBody title="GENERAL TERMS AND CONDITIONS OF BUSINESS of sustamize GmbH i.G.">
      <PaperWrapper>
        <TextWrapper>
          Status 25.05.2022 The English version of General Terms & Conditions of
          Business of sustamize GmbH are a translation of the respective German
          version of the General Terms & Condition of Business of sustamize
          GmbH. In case of conflicts and misunderstandings, the German version
          shall apply.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 1 Scope of these terms and conditions, special conditions
          forindividual business relations
        </TitleWrapper>
        <TextWrapper>
          (1) The following General Terms and Conditions (hereinafter referred
          toas "GTC") apply to all business relationships between sustamize
          GmbHand its customers.
        </TextWrapper>
        <TextWrapper>
          (2) In addition to these GTC, special conditions of sustamize GmbH
          applyto individual business relationships (e.g. conditions for the
          provision ofsoftware), which may contain deviations from or additions
          to these GTC, andinsofar take precedence over these GTC; they are
          agreed separately with thecustomer when placing a corresponding order
          or when placing a correspondingorder.
        </TextWrapper>
        <TextWrapper>
          (3) Other general terms and conditions used by the customer do
          notbecome part of the contract; even if sustamize GmbH has not
          expresslycontradicted them.
        </TextWrapper>
        <TextWrapper>
          (4) Individual agreements made in individual cases between sustamize
          GmbHand the customer (including side agreements, supplements and
          amendments) havepriority over these GTC and any applicable special
          conditions.
        </TextWrapper>
        <TextWrapper>
          (5) References to the applicability of statutory provisions shall
          haveclarifying significance. Even without an express reference, the
          statutoryprovisions shall apply unless they are directly amended or
          expressly excludedin these GTC or any applicable special terms and
          conditions.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 2 Offers and offer documents
        </TitleWrapper>
        <TextWrapper>
          (1) Calculations, plans, drawings, calculations, cost estimates or
          othercontract-preparing documents of sustamize GmbH (hereinafter
          referred to as"offer documents") may not be duplicated or changed or
          madeaccessible to third parties without their consent.
        </TextWrapper>
        <TextWrapper>
          In case of non-conclusion of the contract, the offer documents
          includingcopies are to be returned immediately to sustamize GmbH.
        </TextWrapper>
        <TextWrapper>
          (2) In offer documents, brochures or advertisements of sustamize
          GmbHcontained illustrations, plans, drawings or value / size
          specifications areonly approximately dimensionally accurate, unless
          they are expressly designatedas binding.
        </TextWrapper>
        <TextWrapper>
          (3) Offers of sustamize GmbH are always subject to change and
          representin each case a non-binding proposal to the customer to place
          orders or orders.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 3 Conclusion of Contract, Text Form of Legally Relevant Declarations
        </TitleWrapper>
        <TextWrapper>
          (1) Orders or commissions placed by the Customer shall constitute
          abinding offer by the Customer to conclude a contract.
        </TextWrapper>
        <TextWrapper>
          (2) The contract is concluded as soon as sustamize GmbH confirms
          theorder or the order of the customer (hereinafter referred to as
          "orderconfirmation"). The order confirmation takes place at the
          discretion of sustamizeGmbH either by writing the order or the order
          in the form of a contractualagreement or by sending an order
          confirmation or by sending a correspondinginvoice or by executing the
          order or the order.
        </TextWrapper>
        <TextWrapper>
          (3) Legally relevant declarations and notifications of the Customer
          withregard to the contract (e.g. setting of deadlines, notification of
          defects,withdrawal or reduction) shall be made at least in text form
          (e.g. as e-mail,fax, computer fax or letter). Legal formal
          requirements (e.g. § 650h BGB) andfurther proof, in particular in case
          of doubt about the legitimacy of thedeclarant, remain unaffected.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 4 Performance of sustamize GmbH
        </TitleWrapper>
        <TextWrapper>
          (1) The sustamize GmbH is entitled to provide partial services, as
          faras this is reasonable for the customer.
        </TextWrapper>
        <TextWrapper>
          (2) The sustamize GmbH is entitled towards the customer to use
          qualifiedassistants (e.g. subcontractors).
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 5 Obligations of the customer to cooperate
        </TitleWrapper>
        <TextWrapper>
          (1) The customer is obligated to support sustamize GmbH in the
          provisionof its contractual services by appropriate acts of
          cooperation, as far as thecooperation of the customer is necessary for
          the provision of the contractualservices of sustamize GmbH (e.g. the
          provision of data and documents, thedesignation of contact persons or
          the provision of the technical environment).
        </TextWrapper>
        <TextWrapper>
          (2) The customer shall provide cooperation within the meaning of
          thepreceding paragraph (1) free of charge.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 6 Performance deadlines/dates
        </TitleWrapper>
        <TextWrapper>
          (1) Any performance deadlines/dates of sustamize GmbH are
          non-bindinginformation, unless they are expressly agreed as binding.
        </TextWrapper>
        <TextWrapper>
          (2) In any case, sustamize GmbH can begin with the provision of
          theservice owed in each case, at the earliest, when any individually
          agreeddeposit or partial payment has been received by sustamize GmbH
          and the customerhas fulfilled his obligations to cooperate within the
          meaning of § 5 of theseterms and conditions or his individually agreed
          obligations to cooperate to theextent that the service provision can
          be started.
        </TextWrapper>
        <TextWrapper>
          (3) If the customer does not fulfill his obligations to cooperate in
          thesense of § 5 of these GTC or his possible individually agreed
          obligations tocooperate even after reminder by sustamize GmbH and
          expiration of a reasonabledeadline set by sustamize GmbH, sustamize
          GmbH is no longer bound to anyperformance deadlines / dates agreed as
          binding and the statutory provisionsapply.
        </TextWrapper>
        <TextWrapper>
          (4) The occurrence of a delay in delivery / performance of sustamize
          GmbHis determined by the statutory provisions.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 7 Prices
        </TitleWrapper>
        <TextWrapper>
          (1) Unless expressly stated otherwise in the offer documents, the
          pricesquoted by sustamize GmbH are in euros and ex the headquarters of
          sustamize GmbH.The prices are usually shown as net prices. The
          respectively valid legal salestax is not included, if not explicitly
          stated.
        </TextWrapper>
        <TextWrapper>
          (2) If delivery or shipping costs are incurred, they shall be borne
          bythe customer. Any delivery or shipping costs incurred shall be shown
          separatelyin the offer documents.
        </TextWrapper>
        <TextWrapper>
          Any customs duties, fees, taxes and other public charges shall be
          borneby the customer.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 8 Terms of payment
        </TitleWrapper>
        <TextWrapper>
          (1) Unless otherwise agreed, the customer must pay the remuneration
          owedby him without deduction within 14 calendar days after receipt of
          invoice to sustamizeGmbH.
        </TextWrapper>
        <TextWrapper>
          (2) If a customer is in arrears with a payment, the
          respectiveoutstanding payment amount is subject to interest during the
          delay at therespective statutory default interest rate. The sustamize
          GmbH reserves theright to assert a further damage caused by delay.
          Against merchants, the claimof sustamize GmbH to the commercial
          interest rate remains unaffected.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 9 Set-off and right of retention
        </TitleWrapper>
        <TextWrapper>
          (1) Acustomer is entitled to set-off against claims of sustamize GmbH
          only if thecounterclaim to which he is entitled is legally established
          or undisputed, orif sustamize GmbH has acknowledged the counterclaim.
          Furthermore, a customer isentitled to set-off against claims of
          sustamize GmbH, if the customer assertsany claims for defects or
          counterclaims from the same contract, on which therespective claim of
          sustamize GmbH is also based.
        </TextWrapper>
        <TextWrapper>
          (2) Acustomer has a right of retention only if his counterclaim is
          based on the samecontract on which the respective claim of sustamize
          GmbH is based.
        </TextWrapper>
        <TextWrapper>
          (3) Thelimitation of the Customer's rights of retention set out in
          paragraph (2) aboveshall also apply to a business right of retention
          under sections 369 to 372 of theGerman Commercial Code.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 10 Liability
        </TitleWrapper>
        <TextWrapper>
          (1) In caseof a breach of contractual obligations (e.g. defectiveness)
          or in case of abreach of non-contractual obligations, sustamize GmbH
          is liable according tothe statutory provisions, unless otherwise
          stated in the provisions of theseGTC.
        </TextWrapper>
        <TextWrapper>
          (2) The sustamizeGmbH is liable for damages or reimbursement of futile
          expenses, regardless ofthe legal reason, within the scope of fault
          liability only in case of intentand gross negligence, but not in case
          of simple negligence.
        </TextWrapper>
        <TextWrapper>
          In case ofinjury to life, body or health, sustamize GmbH is liable -
          subject to a milderstandard of liability according to legal
          regulations (e.g. for care in ownaffairs) - already in case of simple
          negligence.
        </TextWrapper>
        <TextWrapper>
          In case ofviolation of essential contractual obligations, sustamize
          GmbH is alreadyliable in case of simple negligence. In this case, the
          compensation of thecustomer is limited to the contract-typical,
          foreseeable damage, as far as sustamizeGmbH is not liable for injury
          to life, body or health. Essential are suchcontractual obligations,
          the fulfillment of which enable the proper executionof the contract in
          the first place and on whose compliance the customer mayregularly
          rely.
        </TextWrapper>
        <TextWrapper>
          (3) Theliability limitations resulting from the above paragraph (2)
          also apply in caseof breach of duty by and in favor of persons whose
          fault sustamize GmbH isresponsible according to legal regulations
          (e.g. employees, legalrepresentatives or organs, other vicarious
          agents).
        </TextWrapper>
        <TextWrapper>
          (4) Thelimitations of liability resulting from the above paragraphs
          (2) and (3) do notapply, as far as the sustamize GmbH
        </TextWrapper>
        <TextWrapper>
          <ol className={orderedListClassName}>
            <li>hasfraudulently concealed the existence of defects;</li>
            <li>
              has assumeda guarantee for the quality of a delivered item or the
              created work;
            </li>
            <li>has assumeda procurement risk;</li>
            <li>is liableto the customer under the Product Liability Act;</li>
            <li>
              is liableto the customer in accordance with other mandatory
              statutory liabilityprovisions.
            </li>
          </ol>
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 11 Retention of title
        </TitleWrapper>
        <TextWrapper>
          (1) Goods or services delivered by sustamize GmbH to the customer
          (hereinafter referred to as "goods subject to retention of title")
          remain the property of sustamize GmbH until full payment of all claims
          arising from the respective contract.
        </TextWrapper>
        <TextWrapper>
          (2) If the customer behaves contrary to the contract (especially if he
          is in default with the payment of the owed fee), sustamize GmbH has
          the right to with draw from the contract, after sustamize GmbH has set
          the customer a reasonable deadline for performance.
        </TextWrapper>
        <TextWrapper>
          If sustamize GmbH takes back the goods subject to retention of title
          in transactions with entrepreneurs or in installment transactions with
          consumers, this already represents a withdrawal from the contract. The
          costs incurred for the redemption are borne by the purchaser.
        </TextWrapper>
        <TextWrapper>
          Likewise it represents a resignation of the contract, if sustamize
          GmbH seizes the reservation commodity. sustamize GmbH may utilize
          taken back reservation commodity. The proceeds of the realization will
          be set off against the amounts owed by the customer to sustamize GmbH
          after deduction of an appropriate amountfor the costs of the
          realization.
        </TextWrapper>
        <TextWrapper>
          (2) The customer must treat the reserved goods with care.
        </TextWrapper>
        <TextWrapper>
          (3) In case of seizure of the reserved goods by third parties or in
          case of other interventions of third parties, the customer must point
          out the property of sustamize GmbH and inform sustamize GmbH
          immediately in writing, so that sustamize GmbH can enforce property
          rights. If the third party is not able to reimburse the judicial or
          extra judicial costs incurred by sustamize GmbH in this connection,
          the customer is liable for them.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 12 Data protection and confidentiality
        </TitleWrapper>
        <TextWrapper>
          (1) The contracting parties shall each observe the requirements of
          data protection law(e.g. DS-GVO).
        </TextWrapper>
        <TextWrapper>
          (2) The contracting parties shall conclude a commissioned processing
          agreement (cf.Art. 28 (3) DS-GVO), if this is necessary.
        </TextWrapper>
        <TextWrapper>
          (3) The contracting parties will conclude a non-disclosure agreement
          (NDA) if required.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 13 Copyright
        </TitleWrapper>
        <TextWrapper>
          (1) Rights of use of copyright protected works of sustamize GmbH (e.g.
          climate protectionconcepts or studies) are only transferred to the
          customer, if this is expressly agreed with the customer. An implied
          transfer is excluded.
        </TextWrapper>
        <TextWrapper>
          (2) sustamize GmbH is entitled to call the customer by name and using
          the company logo /brand as a reference.
        </TextWrapper>
        <TextWrapper>
          (3) Customersare insofar entitled to the use of company logo / brand
          representation of sustamize GmbH, as this results from the individual
          agreements between the parties.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 14 Applicable law
        </TitleWrapper>
        <TextWrapper>
          The law ofthe Federal Republic of Germany shall apply. The application
          of the UN Convention on Contracts for the International Sale of Goods
          is excluded.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 15 Jurisdiction
        </TitleWrapper>
        <TextWrapper>
          If the customer is a merchant in the sense of the HGB, a legal entity
          under public law or a special fund under public law, the place of
          jurisdiction for all disputes about claims (including tort claims)
          arising from the business relationship of sustamize GmbH and the
          customer, the headquarters of sustamize GmbH in Munich.
        </TextWrapper>
      </PaperWrapper>
    </ContentBody>
  )
}
