import Applicable_Standards_and_Regulations from 'assets/images/KnowledgeHub/QualityDocument/Applicable_Standards_and_Regulations.png'
import GUTcert from 'assets/images/KnowledgeHub/QualityDocument/GUTcert.png'
import DatabaseConcept from 'assets/images/KnowledgeHub/QualityDocument/DatabaseConcept.png'
import QualityLevelRating from 'assets/images/KnowledgeHub/QualityDocument/QualityLevelRating.png'
import UpdatingAndSupplementingCycleOfData from 'assets/images/KnowledgeHub/QualityDocument/UpdatingAndSupplementingCycleOfData.png'
import QaulityMatrixSummary from 'assets/images/KnowledgeHub/QualityDocument/QaulityMatrixSummary.png'
import Urkunde_Sustamize_Entwurf_final_1 from 'assets/images/KnowledgeHub/QualityDocument/Urkunde_Sustamize_Entwurf_final-1.png'
import { IAccordionData } from '../components/DynamicAccordion'
import React from 'react'
import { getImage, makeBulttePointsList, makeLink } from './helpers'

export const DataQualityAssurancData: IAccordionData[] = [
  {
    title: 'COMPLIANCE & 3<sup>RD</sup> PARTY VALIDATION',
    content:
      "sustamize has developed a process to create, store and provide CO2e footprint reference data that complies with relevant international regulations.  <br /> <br /> In order to perform a comparable and recognized footprint analysis, it is necessary to adhere to formal requirements for performing the analysis and compiling the data. Only then can a carbon footprint assessment be certified or at least be verified. The basis for emission data calculation and resource-use are based on certain basic assumptions. Due to the calculation methods, those assumptions cannot be made without errors, even in scientific work. Hence, all data (including sustamize's data) are subject to a certain level of inaccuracy.  <br /> <br />" +
      getImage(
        Applicable_Standards_and_Regulations,
        'Applicable Standards and Regulations'
      ) +
      ' <br /> However, to support our customers in their efforts to certify or validate Carbon Footprints, sustamize’s Product Footprint Engine has been third-party validated by GUTcert GmbH according to ISO 14064-3:2019-04 requirements. This means the data calculation methodology, collection, updates flow and quality management fulfill the relevant methodology requirements for determining a product carbon footprint according to the "cradle-to-gate" assessment approach in accordance with the internationally established Greenhouse Gas Protocol - Product Life Cycle Accounting & Reporting Standard. <br /> <br />' +
      getImage(GUTcert) +
      ' <br /> In the following section, you can review all relevant quality gates. For each database’s detailed calculation methodology, please review the related sections. Moreover, sustamize data is compliant with the ISO 14048 norm according to the respective fields and variables.<br /> <br />',
    isOpen: true,
  },
  {
    title: 'CO2 CONVERSION',
    content: `For the assessment of values expressed in CO2 equivalent ("CO2e" or, in some sections, "CO2" for simplicity and comparability), the greenhouse gases are converted, in accordance with the Kyoto Protocol and the IPCC assessment reports, into values expressed in CO2e on the basis of their corresponding global warming potential ("GWP") in relation to pure CO2 equivalence factors.<br /> <br /> The list of currently valid conversion factors used in the PFE are based on the reference table published here:${makeBulttePointsList(`Intergovernmental Panel on Climate Change (IPCC), 4th Assessment Overview, 2007 (${makeLink(
      'LINK',
      'https://www.ipcc.ch/assessment-report/ar4/'
    )}), or the full report at the following address: (${makeLink(
      'LINK',
      'https://www.ipcc.ch/site/assets/uploads/2018/05/ar4_wg1_full_report-1.pdf'
    )}) | Intergovernmental Panel on Climate Change (IPCC), 5th Assessment Overview, 2014 (${makeLink(
      'LINK',
      'https://www.ipcc.ch/report/ar5/syr/'
    )}), or the full report at the following address: (${makeLink(
      'LINK',
      'https://www.ipcc.ch/site/assets/uploads/2018/02/SYR_AR5_FINAL_full.pdf'
    )}) | Intergovernmental Panel on Climate Change (IPCC), 6th Assessment Overview, 2021 (${makeLink(
      'LINK',
      'https://www.ipcc.ch/assessment-report/ar6/'
    )}), ), or the full report at the following address: (${makeLink(
      'LINK',
      'https://www.ipcc.ch/report/ar6/wg1/downloads/report/IPCC_AR6_WGI_SPM_final.pdf'
    )})
    `)}
    <br />
    An example calculation of a CO2e value is given here on the basis of biogas from maize: 1 kWh = 471.8g CO2e (totaled from 78.4g CO2 / 11.88g CH4 / 0.46g N2O / 1.70g VP SO2e / 0.56g SO2 / 1.764g Nox / 0.096g dust / 1.18g CO / 0.15g NMVOC ) [Source: ${makeLink(
      'UBA:Climate Change 2013 Emissionsbilanz erneuerbarer Energieträger',
      ' https://www.umweltbundesamt.de/sites/default/files/medien/378/publikationen/climate_change_15_2013_emissionsbilanz_erneuerbarer_energietraeger_0.pdf'
    )}].
    <br /><br />
    Currently there is no distinction made among emission origin types. sustamize is actively developing a framework to incorporate the categorization of emissions detailing the amounts attributed to fossil,, biogenic and land use/land use change of every datapoint.
    `,
    isOpen: false,
  },
  {
    title: 'DATA COLLECTION PROCESS',
    content: `The sustamize Product Footprint Engine stores CO2e values calculated from different sources, with the aim of providing as many CO2e values and coverage as possible. Consequently, the data collection process is paramount. `,
    isOpen: false,
    children: [
      {
        title: 'ALLOCATION, BY-PRODUCTS AND WASTE',
        content: `As the focus of the database is on CO2e values, there are always one or more products (intermediates) resulting from a process, which therefore receive the amount of CO2e attributed to the respective product. There is generally no functional distinction between a waste and a by-product. By-products may exist, but if they are not relevant to the reference product, they are generally removed from the subsequent calculation through a corresponding allocation of CO2e (positive or negative). Otherwise, the resulting waste is considered part of the product process and its treatment. Furthermore, their disposal is covered by appropriate processes and taken into account in the overall PCF calculation.  
      <br /><br />
      The attribution rules of the relevant standards apply to the various databases. As a general rule, an appropriate physical relationship is used. However, if this is not possible, or if the procedure is unreasonable, it is possible to deviate in certain circumstances, by choosing another type of relationship, such as an economic one. 
      `,
        isOpen: true,
      },
      {
        title: 'SCOPES',
        content: `To gain a comprehensive understanding of a given CO2e value  and to contextualize it effectively, it is crucial to be aware of the scope within which its calculation occurs. Our databases predominantly focus on cradle-to-gate values, with some exceptions.. The scope of CO2e values can be one of the following:
        <br /><br />
        ${makeBulttePointsList(
          '<strong>Cradle-to-gate</strong>: This analysis spans from the beginning of a product’s supply chain, like mining (or recycling for recycled material), to the point at which the product is prepared for sale, excluding usage, the end-of-life, or recycling of final waste. | <strong>Cradle-to-grave/cradle</strong>: In contrast to cradle-to-gate, this scope includes calculations for product usage, end-of-life treatment (grave), or recycling (cradle). | <strong>Gate-to-gate</strong>: Special cases where only a specific segment of the value chain is considered, like inhouse processes or excluding some processes in the supply chain. | <strong>Well-to-tank</strong>: Emissions from a fuel’s production, including steps such as pumping, transporting oil from a rig, and refining until the fuel is ready for use. A cradle-to-gate equivalent for fuel. | <strong>Tank-to-wheel</strong>: Direct emissions associated with using a fuel . An equivalent to the use stage/end-of-life impact of a fuel. | <strong>Well-to-wheel</strong>: The combination of both well-to-tank and tank-to-wheel, providing a comprehensive assessment of a fuel’s entire impact, including both direct and indirect emissions through production. A cradle-to-grave equivalent for fuel.'
        )}`,
        isOpen: false,
      },
      {
        title: 'GENERAL DATA CALCULATION',
        content: `As is common in an LCA (Life Cycle Assessment) database, all values have an extremely elevated level of aggregation. For example, the value for a raw material that is used for a study already contains many processes and even further raw materials, etc. When generating new data, aggregation should be avoided whenever possible. sustamize therefore uses a bottom-up calculation when possible as a basic principle when generating its own data. 
      `,
        isOpen: false,
      },
      {
        title: 'PARENT/CHILD RELATION',
        content: `When calculating a PCF in bottom-up calculation software, it is possible to create a new child product from products that have already been created, so that scenario calculations can be carried out quickly and easily. It is possible for the values of these developed scenarios to be included again in the database after a test run. 
      `,
        isOpen: false,
      },
      {
        title: 'DATA SOURCES',
        content: `The PFE’s development is based on a research and processing concept in order to provide independent yet comprehensible and reliable reference data. An essential aspect of the databases is the use of comparative data from studies and other sources to ensure the best possible data quality while providing highest possible data availability.
        <br />
        <br />
        Source types: 
        <br />
        ${makeBulttePointsList(
          'Governmental reports, statistics and statements | Governmental reports, statistics and statements | Publicly available LCA and footprints analyses | Company reports (sustainability reports, energy reports, CO2 reports) | sustamize knowledge database '
        )}
        <br />
        sustamize builds the database by plausibility checking and combining data from partly commercial data, studies and research projects and self-calculated data. Operationally, the maintenance of the data is implemented on the basis of various sources. In addition to the ongoing consideration of new sources by desk research and the evaluation of data in studies, the essential part is the active calculation of the data on the basis of a requirement planning from projects and customer requests. After the collection or calculation, the data is initially stored in a “staging” database, in order to be subjected to quality control. The data only becomes available in the productive database after the data has been approved and released.
        ${getImage(DatabaseConcept, 'Database Concept')}
      `,
        isOpen: false,
      },
    ],
  },
  {
    title: 'FOSSIL, BIOGENIC AND LAND-USE-CHANGE EMISSIONS',
    content: `The distinction between the sources of CO2 emissions is becoming increasingly important. Therefore, sustamize has created a framework to provide this differentiation to customers. There are some differences in how we generate these numbers depending on the databases, and all cases will be described here. As a shorthand, we refer to this as the Fossil, Biogenic, and Land-Use Change Split (FBL-Split/CO2 Origin Composition). Usually, this split would include airplane emissions as well. However, our current focus is on base materials, and no planes are used in their production, so this can be considered zero for all material-related emissions. For the electricity database, we have added an FBL-split to the base CO2e emissions so that the generation of each energy type provides its own split, instead of just the base CO2e amount. This approach is also used for calculating other FBL-Splits during recalculation processes. Thus, the split for solar energy would differ from that of wind, coal, etc. For all material databases, the FBL-split is calculated according to the energy types used in those processes. In our generic calculation, with an energy split of 60% fossil and 40% electricity, the electricity split, as calculated above, is used. For fossil emissions, natural gas is used as the generic standard (other fossil fuels do not differ significantly; all of them are influenced by fossil emissions to over 99%, compared to biogenic or land-use-change emissions). Where the 60/40 rule does not apply, a specific split will be used (starting with metals and their component elements), which uses the exact amounts of process-specific fuels and their corresponding FBL-split.
    `,
    isOpen: false,
  },
  {
    title: 'QUALITY LEVEL RATING',
    content: `The sources used in the PFE are evaluated to provide an indication of accuracy. This takes the form of a classification, depending on the source data and calculation method. Data points are grouped into accuracy classes due to the differing quality of data sources and underlying fluctuation ranges. Productive data are generally available in class "1" (see below). 
    <br />
    <br />
    For the qualitative evaluation of a study, a matrix from Weidema (1998) adapted to the specific needs of the sustamize approach is used.
    ${getImage(QualityLevelRating)}
    <br />
    ${getImage(QaulityMatrixSummary)}
    <br />
    `,
    isOpen: false,
  },
  {
    title: 'DATA UPDATES',
    content: `The data contained in the sustamize database is updated in a variety of complementary ways. This is dependent on the rate of change and the new data published and made available. For example, the energy mix of countries are always for the previous year or even the year before that due to data published by the authorities. Data are sometimes regularly updated, but the data is also completed and extended in different cycles.
    <br />
    <br />
    ${getImage(UpdatingAndSupplementingCycleOfData)}
    <br />
    Each data point in our databases has a date added and a date last updated. Once added, the CO2e value of a data point does not change. If an update is made at a later date, it only affects other parts of the data point, for example correcting spelling errors, adding additional material information such as density, or adding other details, such as the composition of CO2e emissions. This then triggers an update that changes the date on which the data point was last updated.
    <br />
    <br />
     If a value is found to be incorrect, it is deactivated and the correct data point is added. This is done for future reference and traceability purposes, so that the old value can still be found later as an inactive value. The only time a data point can be deleted is when there are duplicates of the same value, and the information on both is similar and therefore cannot be confused.
    `,
    isOpen: false,
  },
  {
    title: 'QUALITY MANAGEMENT',
    content: `A quality management process has been defined to verify the quality of our data points. There are several types of quality management cases depending on the data added and the type of quality review performed. All members of the sustamize LCA team can carry out reviews, provided they are not the editor who originally introduced the data point.
    <br />
    `,
    isOpen: false,
    children: [
      {
        title: 'REVIEW TYPES',
        content: `<u><b>Full review of new data points:</b></u> A new data point is added only after the reviewer has verified that the data entered in each field corresponds to the specified source, and that the table is plausibly populated. Empty fields should be checked according to their priority in ISO 14048; high-priority fields should always be filled in. The plausibility check includes a comparison with other similar data points to see whether the data points fall within an expected range (for example, a metal of similar composition).      
        <br />
        <br />
        <u><b>Minor review for previous existing but newly adjusted data points</b></u> (e.g. adding slight variations of existing data points): In such cases, a complete revision is not necessary, as most of the information will remain unchanged. The main changes to data points should, however, be examined in relation to the source of the information (e.g. CO2e values) and, for all other fields, a plausibility check should be carried out, to ensure that information left unchanged does not, in fact, need to be updated.
        <br />
        <br />
        <u><b>Plausibility check within a review type:</b></u> Apart from the direct comparison between source and data point, this is an essential check, as it verifies the validity of the source. The new data is linked to other data points in the database, as well as to other fields in the database. This allows the following to be taken into account:
        <br />
        <br />
        ${makeBulttePointsList(
          'CO2e data is very different from other similar data points by a large degree,|information within a data point contradicts each other or do not match,| clusters of information match each other, checking common areas of errors (e.g., data fields left empty or unchanged) and|the relationship of data.'
        )}
        <br />
        <br />
        <u><b>Bi-annual database reviews and spot checks:</b></u> With automation and the addition of many remarkably similar data points (e.g. new metal types, standard parts...), a complete review of each new data point can prove an impossible task. For this reason, a bi-annual review of our own database, known as a spot check, has been introduced. This involves examining a randomly selected sample of the data set or database in question. This sample must be at least equal to the root of the data set. Bi-annual checks must be carried out in March and September, and may ignore any database that has been thoroughly examined in the last six months.
        `,
        isOpen: true,
      },
      {
        title: 'REVIEW PROCESS',
        content: `Should a reviewer find an error, this should be flagged and a comment made on the errors in the data points. This data point should then be returned to the original editor (if a new data point is added) to double-check the revision made, or to an authorized LCA supervisor, in the case of errors detected during spot checks. For errors that do not affect the viability of a data point (such as spelling mistakes), this process is not necessary and a simple correction can be made.
        <br />
        <br />
        If the errors are returned to the original editor of the data point, they must examine in detail the errors mentioned, the comments made and the suggested correction. If they agree with the correction, they implement it as requested and sends it back to the editor for final approval. If they disagree with the correction, they comment their disagreement and forwards the data point to an LCA supervisor empowered to decide.
        <br />
        <br />
        If an error is escalated to an LCA supervisor, the supervisor needs to review both comments (in the case of a new data point), or the reviewer comment (in the case of a bi-annual review), needs to check the source and decide about how to handle the dispute. After the decision, the data point is sent back to editor (or reviewer in case of the bi-annual review) to implement what has been decided. After the implementation by the editor, it is then sent to the reviewer for final approval.
      `,
        isOpen: false,
      },
    ],
  },
  {
    title: 'THIRD PARTY CALCULATION VIA PFE',
    content: `CO2e values resulting from PCF calculations performed by or for customers can be stored in the Product Footprint Engine (PFE). Again, the calculation is checked qualitatively and validated before it is transferred to the productive database. This process is only carried out after prior consultation and agreement with the customer in order to prevent confidential information from reaching third parties.
  `,
    isOpen: false,
  },
  {
    title: 'CERTIFICATION',
    content: `sustamize has had the Product Footprint Engine (PFE)'s conformity with GHG Protocol Product Life Cycle Accounting & Reporting Standard validated by GUTcert GmbH according to the ISO 14064-3:2019-04 standard.
    <br /> <br />
    ${getImage(Urkunde_Sustamize_Entwurf_final_1)}
    <br />
  `,
    isOpen: false,
  },
]
