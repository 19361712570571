import { createTheme, ThemeOptions } from '@mui/material/styles'
import type { Components } from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import { deDE, enUS } from '@mui/material/locale'
import { getLocal } from 'config/lib'
import { eColors } from 'components/types'

const locale = getLocal('i18nextLng') || 'en'

/* COLORS */

/**
 * Build the sustamize Theme for mui
 */
const mainTypography: TypographyOptions = {
  fontFamily: "'Poppins', sans-serif",
  fontSize: 13,
  button: {
    textTransform: 'none',
  },
}

const mainComponents: Components = {
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true,
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 16,
      },
    },
  },
  // TODO: fix override
  MuiButton: {
    styleOverrides: {
      root: {
        background: eColors.paletteGreen,
        '&:hover': {
          backgroundColor: eColors.paletteYellow,
        },
        '&:disabled': {
          backgroundColor: eColors.paletteGray3,
          color: 'black',
          boxShadow: `0 3px 5px 1px ${eColors.paletteGray2}`,
        },
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 1px rgba(27, 102, 0, .2)',
        color: 'white',
        height: 48,
        padding: '0 30px',
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 8,
      },
      switchBase: {
        padding: 1,
        '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + $track': {
            opacity: 1,
            border: 'none',
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 13,
        border: '1px solid #bdbdbd',
        backgroundColor: eColors.paletteGray,
        opacity: 1,
        transition:
          'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiList: {
    defaultProps: {
      dense: true,
    },
  },
  MuiMenuItem: {
    defaultProps: {
      dense: true,
    },
  },
  MuiTable: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiButtonGroup: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiCheckbox: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiFab: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiFormControl: {
    defaultProps: {
      margin: 'dense',
      size: 'small',
    },
  },
  MuiFormHelperText: {
    defaultProps: {
      margin: 'dense',
    },
  },
  MuiIconButton: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiInputBase: {
    defaultProps: {
      margin: 'dense',
    },
  },
  MuiInputLabel: {
    defaultProps: {
      margin: 'dense',
    },
  },
  MuiRadio: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiTextField: {
    defaultProps: {
      margin: 'dense',
      size: 'small',
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        borderRadius: 0,
      },
    },
  },
}

const lightTheme: ThemeOptions = {
  typography: mainTypography,
  palette: {
    mode: 'light',
    primary: {
      main: eColors.paletteGreen,
      light: eColors.paletteGreen,
      dark: eColors.paletteGreen,
    },
    secondary: {
      main: eColors.paletteYellow,
      light: eColors.paletteYellow,
      dark: eColors.paletteYellow,
    },
    background: {
      default: eColors.paletteGray,
      paper: '#ffffff',
    },
    warning: {
      main: '#ff4800',
    },
    error: {
      main: '#da7804',
      light: '#fddaaf',
      dark: '#b46504',
    },
    text: {
      primary: '#000000',
    },
  },
  components: {
    ...mainComponents,
    MuiButton: {
      ...mainComponents.MuiButton,
      variants: [
        {
          props: { color: 'error' },
          style: {
            backgroundColor: eColors.paletteRed,
            color: '#fff',
          },
        },
      ],
    },
    MuiListItemButton: {
      defaultProps: {
        dense: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: `#fddaaf`,
          },
          '&.Mui-focusvisible': {
            backgroundColor: `#83ff00`,
          },
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'transparent',
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundColor: eColors.paletteGray,
        },
      },
    },
  },
}

const darkTheme: ThemeOptions = {
  typography: mainTypography,
  palette: {
    mode: 'dark',
    primary: {
      main: '#63CF00',
      light: '#83ff00',
      dark: '#1b6600',
    },
    secondary: {
      main: '#da7804',
      light: '#fddaaf',
      dark: '#b46504',
    },
    background: {
      default: eColors.paletteBlack,
      paper: eColors.paletteBlack,
    },
    warning: {
      main: '#ff4800',
    },
    text: {
      primary: '#FFF',
    },
  },
  components: {
    ...mainComponents,
    MuiButton: {
      ...mainComponents.MuiButton,
      variants: [
        {
          props: { color: 'error' },
          style: {
            backgroundColor: eColors.paletteRed,
            color: '#fff',
          },
        },
      ],
    },
    MuiListItemButton: {
      defaultProps: {
        dense: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: `#b46504`,
          },
          '&.Mui-focusvisible': {
            backgroundColor: `#1b6600`,
          },
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'transparent',
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundColor: eColors.paletteBlack,
        },
      },
    },
  },
}

// const lightThemeWithLocaleExport = { lightTheme, locale === 'de' && deDE }
export const sustamizeLightTheme = createTheme(
  lightTheme,
  locale === 'de' ? deDE : enUS
)
export const sustamizeDarkTheme = createTheme(
  darkTheme,
  locale === 'de' ? deDE : enUS
)
