import React from 'react'
import { FormControl, TextField, FormLabel } from '@mui/material'
import {
  eColor,
  eInputTypes,
  eInputSize,
  eInputVariant,
} from 'components/types'

interface IProps {
  type: eInputTypes
  inputClass?: string
  labelClass?: string
  label?: string
  defaultValue?: string
  variant?: eInputVariant
  color?: eColor
  size?: eInputSize
}

const FormInputWrapper = ({
  inputClass,
  labelClass,
  defaultValue,
  type = eInputTypes.text,
  variant = eInputVariant.standard,
  color = eColor.primary,
  label,
  size,
}: IProps) => {
  return (
    <FormControl>
      <FormLabel className={labelClass} htmlFor="input">
        {label}
      </FormLabel>
      <TextField
        id="input"
        className={inputClass}
        variant={variant}
        type={type}
        defaultValue={defaultValue}
        color={color}
        size={size}
      />
    </FormControl>
  )
}

export default FormInputWrapper
