import { iSubscriptionOrder, iSubscriptionOrderCalculation } from "store/types/subscriptionOrder";
import { BASE_API } from "../urlPaths";
import { call } from "redux-saga/effects";
import reqProvider from '../coreRequests';

export function* calculateSubscriptionConfiguration(config: iSubscriptionOrder) {
  const path = `${BASE_API}/subscription/calculate`

  const data: iSubscriptionOrderCalculation = yield call(
    reqProvider.postReq, 
    path, 
    undefined, 
    config)

  return data
}