import React from 'react'
import { ContentBody } from 'components/pages/components/ContentBody'

import PaperWrapper from 'components/custom/PaperWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
import TextWrapper from 'components/custom/TextWrapper'
import { eTypographyVariant } from 'components/types'

export const TermsAndConditionsDocumentGerman = () => {
  const headingClassName = 'pt-4 pb-2 text-paletteOrange uppercase'
  const orderedListClassName = 'list-[square] list-inside ml-4'

  return (
    <ContentBody
      title="ALLGEMEINE GESCHÄFTSBEDINGUNGEN
    der sustamize GmbH"
    >
      <PaperWrapper>
        <TextWrapper>Stand: 25.05.2022</TextWrapper>
        <TitleWrapper variant={eTypographyVariant.h5} className={headingClassName}>
          § 1 Geltungsbereich dieser Geschäftsbedingungen, Sonderbedingungen für
          einzelne Geschäftsbeziehungen
        </TitleWrapper>
        <TextWrapper>
          (1) Die nachstehenden Allgemeinen Geschäftsbedingungen (im Folgenden
          „AGB“ genannt) gelten für sämtliche Geschäftsbeziehungen zwischen der
          sustamize GmbH und ihren Kunden.
        </TextWrapper>
        <TextWrapper>
          (2) Zusätzlich zu diesen AGB gelten für einzelne Geschäftsbeziehungen
          Sonderbedingungen der sustamize GmbH (z.B.Bedingungen für die
          Überlassung von Software), die Abweichungen oder Ergänzungen zu diesen
          AGB enthalten können, und insofern diesen AGB vorrangig sind; sie
          werden gesondert bei der Erteilung eines entsprechenden Auftrags oder
          bei einer entsprechenden Bestellung mit dem Kunden vereinbart.
        </TextWrapper>
        <TextWrapper>
          (3) Andere vom Kunden verwendete Allgemeine Geschäftsbedingungen
          werden nicht Vertragsinhalt; auch dann nicht,wenn die sustamize GmbH
          ihnen nicht ausdrücklich widersprochen hat.
        </TextWrapper>
        <TextWrapper>
          (4) Im Einzelfall getroffene, individuelle Vereinbarungen zwischen der
          sustamize GmbH und dem Kunden(einschließlich Nebenabreden, Ergänzungen
          und Änderungen) haben Vorrang vordiesen AGB und etwaig geltenden
          Sonderbedingungen.
        </TextWrapper>
        <TextWrapper>
          (5) Hinweise auf die Geltunggesetzlicher Vorschriften haben
          klarstellende Bedeutung. Auch ohne einen ausdrücklichen Hinweis gelten
          die gesetzlichen Vorschriften, soweit sie indiesen AGB oder etwaig
          geltenden Sonderbedingungen nicht unmittelbar abgeändert oder
          ausdrücklich ausgeschlossen werden.
        </TextWrapper>
        <TitleWrapper variant={eTypographyVariant.h5} className={headingClassName}>
          § 2 Angebote und Angebotsunterlagen
        </TitleWrapper>
        <TextWrapper>
          (1) Kalkulationen, Pläne, Zeichnungen, Berechnungen,
          Kostenvoranschläge oder andere vertragsvorbereitende Unterlagen der
          sustamize GmbH (im Folgenden „Angebotsunterlagen“) dürfen ohne deren
          Zustimmung weder vervielfältigt oder geändert noch Dritten zugänglich
          gemacht werden.
        </TextWrapper>
        <TextWrapper>
          Bei Nichtabschluss des Vertrages sind die Angebotsunterlagen
          einschließlich Kopien unverzüglich an die sustamize GmbH
          herauszugeben.
        </TextWrapper>
        <TextWrapper>
          (2) In Angebotsunterlagen, Prospektenoder Anzeigen der sustamize GmbH
          enthaltene Abbildungen, Pläne, Zeichnungenoder Wert-/Größenangaben
          sind nur annähernd maßgenau, soweit diese nichtausdrücklich als
          verbindlich bezeichnet sind.
        </TextWrapper>
        <TextWrapper>
          (3) Angebote der sustamize GmbH sind stets freibleibend und stellen
          jeweils einen unverbindlichen Vorschlag an den Kunden dar,
          Bestellungen oder Aufträge abzugeben.
        </TextWrapper>
        <TitleWrapper variant={eTypographyVariant.h5} className={headingClassName}>
          § 3 Vertragsschluss, Textform rechtserheblicher Erklärungen
        </TitleWrapper>
        <TextWrapper>
          (1) Bestellungen oder Aufträge des Kundenstellen ein verbindliches
          Angebot des Kunden auf Abschluss eines Vertrages dar.
        </TextWrapper>
        <TextWrapper>
          (2) Der Vertragsschluss kommt zustande, sobald die sustamize GmbH die
          Bestellung oder den Auftrag des Kundenbestätigt (im Folgenden
          "Auftragsbestätigung“ genannt). Die Auftragsbestätigung erfolgt nach
          Wahl der sustamize GmbH entweder durch Verschriftlichung des Auftrags
          bzw. der Bestellung in Form einer vertraglichen Vereinbarung oder
          durch Übersendung einer Auftragsbestätigung oder durch Versenden einer
          entsprechenden Rechnung oder durch Ausführung der Bestellung oder des
          Auftrags.
        </TextWrapper>
        <TextWrapper>
          (3) Rechtserhebliche Erklärungen und Anzeigen des Kunden in Bezug auf
          den Vertrag (z.B. Fristsetzung, Mängelanzeige, Rücktritt oder
          Minderung) sind zumindest in Textform (z.B. als E-Mail, Telefax,
          Computerfax oder Brief) abzugeben. Gesetzliche Formvorschriften (z.B.
          § 650hBGB) und weitere Nachweise, insbesondere bei Zweifel über die
          Legitimation des Erklärenden bleiben hiervon unberührt.
        </TextWrapper>
        <TitleWrapper variant={eTypographyVariant.h5} className={headingClassName}>
          § 4 Leistung der sustamize GmbH
        </TitleWrapper>
        <TextWrapper>
          (1) Die sustamize GmbH ist berechtigt, Teilleistungen zu erbringen,
          soweit dies dem Kunden zumutbar ist.
        </TextWrapper>
        <TextWrapper>
          (2) Die sustamize GmbH ist gegenüberdem Kunden berechtigt,
          qualifizierte Erfüllungsgehilfen (z.B. Subunternehmer)einzusetzen.
        </TextWrapper>
        <TitleWrapper variant={eTypographyVariant.h5} className={headingClassName}>
          § 5 Mitwirkungspflichten des Kunden
        </TitleWrapper>
        <TextWrapper>
          (1) Der Kunde ist verpflichtet, die sustamize GmbH bei Erbringung
          ihrer vertraglichen Leistungen durch angemessene Mitwirkungshandlungen
          zu fördern, soweit die Mitwirkung des Kunden zur Erbringung der
          vertraglichen Leistungen der sustamize GmbH erforderlich ist(z.B. das
          Bereitstellen von Daten und Unterlagen, das Benennen von
          Ansprechpartnern oder das Bereitstellen der technischen Umgebung).
        </TextWrapper>
        <TextWrapper>
          (2) Mitwirkungshandlungen im Sinne des vorstehenden Absatzes (1) hat
          der Kunde unentgeltlich zu erbringen.
        </TextWrapper>
        <TitleWrapper variant={eTypographyVariant.h5} className={headingClassName}>
          § 6 Leistungsfristen/-termine
        </TitleWrapper>
        <TextWrapper>
          (1) Etwaige Leistungsfristen/-termine der sustamize GmbH sind
          unverbindliche Angaben, sofern diese nicht ausdrücklich als
          verbindlich vereinbart werden.
        </TextWrapper>
        <TextWrapper>
          (2) In jedem Fall kann die sustamize GmbH mit der Erbringung der
          jeweils geschuldeten Leistung frühestens beginnen, wenn eine etwaige
          individuell vereinbarte An- oder Teilzahlung bei der sustamize GmbH
          eingegangen ist und der Kunde seinen Mitwirkungspflichten im Sinne des
          § 5 dieser AGB oder seinen individuell vereinbarten
          Mitwirkungspflichten insoweit nachgekommen ist, als mit der
          Leistungserbringung begonnen werden kann.
        </TextWrapper>
        <TextWrapper>
          (3) Kommt der Kunde seinen Mitwirkungspflichten im Sinne des § 5
          dieser AGB oder seinen etwaig individuell vereinbarten
          Mitwirkungspflichten auch nach Mahnung durch die sustamize GmbH und
          Ablauf einer angemessenen durch die sustamize GmbH gesetzte Frist
          nicht nach, ist die sustamize GmbH nicht mehr an etwaige als
          verbindlich vereinbarte Leistungsfristen/-termine gebunden und es
          gelten die gesetzlichen Vorschriften.
        </TextWrapper>
        <TextWrapper>
          (4) Der Eintritt eines Liefer-/Leistungsverzug der sustamize GmbH
          bestimmt sich nach den gesetzlichen Vorschriften.
        </TextWrapper>
        <TitleWrapper variant={eTypographyVariant.h5} className={headingClassName}>
          § 7 Preise
        </TitleWrapper>
        <TextWrapper>
          (1) Soweit nicht ausdrücklich etwas anderes in den Angebotsunterlagen
          ausgewiesen ist, verstehen sich die Preisangaben der sustamize GmbH in
          Euro sowie ab Sitz der sustamize GmbH. Die Preise werden in der Regel
          als Netto-Preise ausgewiesen. Die jeweils geltende gesetzliche
          Umsatzsteuer ist, wenn nicht explizit genannt, nicht enthalten.
        </TextWrapper>
        <TextWrapper>
          (2) Sofern Liefer- oder Versandkosten anfallen, sind diese vom Kunden
          zu tragen. Anfallende Liefer- oder Versandkosten werden in den
          Angebotsunterlagen gesondert ausgewiesen.
        </TextWrapper>
        <TextWrapper>
          Etwaige Zölle, Gebühren, Steuern und sonstige öffentliche Abgaben hat
          der Kunde zu tragen.
        </TextWrapper>
        <TitleWrapper variant={eTypographyVariant.h5} className={headingClassName}>
          § 8 Zahlungsbedingungen
        </TitleWrapper>
        <TextWrapper>
          (1) Soweit nicht etwas anderes vereinbart ist, hat der Kunde die von
          ihm geschuldete Vergütung ohne Abzug innerhalb von 14 Kalendertagen
          nach Rechnungserhalt an die sustamize GmbH zu zahlen.
        </TextWrapper>
        <TextWrapper>
          (2) Gerät ein Kunde mit einer Zahlung in Verzug, ist der jeweils
          offene Zahlbetrag während des Verzugs zum jeweils gesetzlichen
          Verzugszinssatz zu verzinsen. Die sustamize GmbH behält sich die
          Geltendmachung eines weitergehenden Verzugsschadens vor. Gegenüber
          Kaufleuten bleibt der Anspruch der sustamize GmbH auf den
          kaufmännischen Fälligkeitszins unberührt.
        </TextWrapper>
        <TitleWrapper variant={eTypographyVariant.h5} className={headingClassName}>
          § 9 Aufrechnung und Zurückbehaltungsrecht
        </TitleWrapper>
        <TextWrapper>
          (1) Zur Aufrechnung gegen Ansprüche der sustamize GmbH ist ein Kunde
          nur berechtigt, wenn der ihm zustehende Gegenanspruch rechtskräftigt
          festgestellt ist oder unbestritten ist oder die sustamize GmbH den
          Gegenanspruch anerkannt hat. Ferner ist ein Kunde zur Aufrechnung
          gegen Ansprüche der sustamize GmbH berechtigt, wenn der Kunde etwaige
          Mängelrügen oder Gegenansprüche aus demselben Vertrag, auf dem auch
          der betreffende Anspruch der sustamize GmbH beruht, geltend macht.
        </TextWrapper>
        <TextWrapper>
          (2) Ein Zurückbehaltungsrecht steht einem Kunden nur zu, wenn dessen
          Gegenanspruch auf demselben Vertrag beruht, auf dem der betreffende
          Anspruch der sustamize GmbH beruht.
        </TextWrapper>
        <TextWrapper>
          (3) Die vorstehend in Absatz (2)geregelte Einschränkung der
          Zurückbehaltungsrechte des Kunden gelten auch für ein
          unternehmerisches Zurückbehaltungsrecht aus §§ 369 bis 372 HGB.
        </TextWrapper>
        <TitleWrapper variant={eTypographyVariant.h5} className={headingClassName}>
          § 10 Haftung
        </TitleWrapper>
        <TextWrapper>
          (1) Bei einer Verletzung von vertraglichen Pflichten (z.B.
          Mangelhaftigkeit) oder bei einer Verletzung von außervertraglichen
          Pflichten haftet die sustamize GmbH nach den gesetzlichen
          Vorschriften, sofern sich aus den Bestimmungen dieser AGB nichts
          anderes ergibt.
        </TextWrapper>
        <TextWrapper>
          (2) Auf Schadensersatz oder Ersatz vergeblicher Aufwendungen haftet
          die sustamize GmbH, gleich aus welchem Rechtsgrund, im Rahmen der
          Verschuldenshaftung nur bei Vorsatz und grober Fahrlässigkeit, nicht
          aber bei einfacher Fahrlässigkeit.
        </TextWrapper>
        <TextWrapper>
          Bei Verletzung des Lebens, des Körpers oder der Gesundheit haftet die
          sustamize GmbH – vorbehaltlich eines milderen Haftungsmaßstabs nach
          den gesetzlichen Vorschriften (z.B. für Sorgfalt in eigenen
          Angelegenheiten) – bereits im Falle von einfacher Fahrlässigkeit.
        </TextWrapper>
        <TextWrapper>
          Bei Verletzung wesentlicher Vertragspflichten haftet die sustamize
          GmbH bereits im Falle einfacher Fahrlässigkeit. Dabei ist der
          Schadensersatz des Kunden auf den vertragstypischen, vorhersehbaren
          Schaden begrenzt, soweit die sustamize GmbH nicht wegen Verletzung des
          Lebens, des Körpers oder der Gesundheit haftet. Wesentlich sind solche
          Vertragspflichten, deren Erfüllung die ordnungsgemäße Durchführung des
          Vertrages überhaupt erst ermöglichen und auf deren Einhaltung der
          Kunde regelmäßig vertrauen darf.
        </TextWrapper>
        <TextWrapper>
          (3) Die sich aus den vorstehenden Absatz (2) ergebenden
          Haftungsbeschränkungen gelten auch bei Pflichtverletzung durch sowie
          zugunsten von Personen, deren Verschulden die sustamize GmbH
          nachgesetzlichen Vorschriften zu vertreten hat (z.B. Mitarbeiter,
          gesetzliche Vertreter oder Organe, sonstige Erfüllungsgehilfen).
        </TextWrapper>
        <TextWrapper>
          (4) Die sich aus den vorstehenden Absätzen (2) und (3) ergebenden
          Haftungsbeschränkungen gelten nicht, soweit die sustamize GmbH
        </TextWrapper>
        <TextWrapper>
          <ol className={orderedListClassName}>
            <li>das Vorliegen von Mängeln arglistig verschwiegen hat;</li>
            <li>
              eine Garantie für die Beschaffenheit einer gelieferten Sache bzw.
              des erstellten Werks übernommen hat;
            </li>
            <li>ein Beschaffungsrisiko übernommen hat;</li>
            <li>dem Kunden nach Produkthaftungsgesetz haftbar ist;</li>
            <li>
              dem Kunden nach sonstigen gesetzlich zwingenden
              Haftungstatbeständen haftbar ist.
            </li>
          </ol>
        </TextWrapper>
        <TitleWrapper variant={eTypographyVariant.h5} className={headingClassName}>
          § 11 Eigentumsvorbehalt
        </TitleWrapper>
        <TextWrapper>
          (1) Von sustamize GmbH an den Kundengelieferte Ware oder
          Dienstleistung (im Folgenden „Vorbehaltsware“ genannt)bleibt bis zur
          vollständigen Zahlung aller Forderungen aus dem jeweiligen Vertrag
          Eigentum der sustamize GmbH.
        </TextWrapper>
        <TextWrapper>
          (2) Sofern sich der Kundevertragswidrig verhält (insbesondere sofern
          er mit der Zahlung der geschuldeten Entgeltforderung in Verzug
          gekommen ist), hat die sustamize GmbH das Recht, vom Vertrag
          zurückzutreten, nachdem sustamize GmbH dem Kunden eine angemessene
          Frist zur Leistung gesetzt hat.
        </TextWrapper>
        <TextWrapper>
          Sofern die sustamize GmbH die Vorbehaltsware bei Geschäften mit
          Unternehmern oder bei Teilzahlungsgeschäften mit Verbrauchern
          zurücknimmt, stellt dies bereits einen Rücktritt vom Vertrag dar. Die
          für die Rücknahme anfallenden Kosten trägt der Käufer.
        </TextWrapper>
        <TextWrapper>
          Ebenfalls einen Rücktritt vom Vertrag stellt es dar, wenn sustamize
          GmbH die Vorbehaltsware pfändet. sustamize GmbH darf zurückgenommene
          Vorbehaltsware verwerten. Der Erlös der Verwertung wird nach Abzug
          eines angemessenen Betrags für die Kosten der Verwertung mit
          denjenigen Beträgen verrechnet, die der Kunde der sustamize GmbH
          schuldet.
        </TextWrapper>
        <TextWrapper>
          (2) Der Kunde muss die Vorbehaltswarepfleglich behandeln.
        </TextWrapper>
        <TextWrapper>
          (3) Bei Pfändungen der Vorbehaltswaredurch Dritte oder bei sonstigen
          Eingriffen Dritter muss der Käufer auf das Eigentum der sustamize GmbH
          hinweisen und sustamize GmbH unverzüglich schriftlich benachrichtigen,
          damit sustamize GmbH Eigentumsrechte durchsetzen kann. Sofern der
          Dritte die der sustamize GmbH in diesem Zusammenhangentstehenden
          gerichtlichen oder außergerichtlichen Kosten nicht zu erstatten
          vermag, haftet hierfür der Kunde.
        </TextWrapper>
        <TitleWrapper variant={eTypographyVariant.h5} className={headingClassName}>
          § 12 Datenschutz und Vertraulichkeit
        </TitleWrapper>
        <TextWrapper>
          (1) Die Vertragsparteien beachten jeweils die datenschutzrechtlichen
          Vorgaben (z.B. DS-GVO).
        </TextWrapper>
        <TextWrapper>
          (2) Die Vertragsparteien werden einen Auftragsverarbeitungsvertrag
          abschließen (vgl. Art. 28 Abs. 3 DS-GVO), sofern dies erforderlich
          ist.
        </TextWrapper>
        <TextWrapper>
          (3) Die Vertragsparteien werden eine Vertraulichkeitsvereinbarung
          (NDA) abschließen, sofern dies erforderlich ist.
        </TextWrapper>
        <TitleWrapper variant={eTypographyVariant.h5} className={headingClassName}>
          § 13 Urheberrecht
        </TitleWrapper>
        <TextWrapper>
          (1) Nutzungsrechte an urheberrechtlich geschützten Werken der
          sustamize GmbH (bspw. Klimaschutzkonzepte oder -studien) werden nur an
          den Kunden übertragen, sofern dies mit dem Kunden ausdrücklich
          vereinbart wird. Eine konkludente Übertragung ist ausgeschlossen.
        </TextWrapper>
        <TextWrapper>
          (2) sustamize GmbH ist berechtigt, den Kunden namentlich und unter
          Verwendung des Firmenlogos /-markendarstellung als Referenz zu nennen.
        </TextWrapper>
        <TextWrapper>
          (3) Kunden sind insoweit zur Nutzung von Firmenlogo
          /-markendarstellung von sustamize GmbH berechtigt, als sich dies aus
          den Individualvereinbarungen zwischen den Parteien ergibt.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 14 Anwendbares Recht
        </TitleWrapper>
        <TextWrapper>
          Es gilt das Recht der Bundesrepublik Deutschland. Die Anwendung des
          UN-Kaufrechts ist ausgeschlossen.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 15 Gerichtsstand
        </TitleWrapper>
        <TextWrapper>
          Sofern es sich bei dem Kunden um einen Kaufmann im Sinne des HGB, eine
          juristische Person des öffentlichen Rechts oder um ein
          öffentlich-rechtliches Sondervermögen handelt, ist der Gerichtsstand
          für sämtliche Streitigkeiten über Ansprüche (auch deliktsrechtlicher
          Ansprüche) aus der Geschäftsverbindung der sustamize GmbH und dem
          Kunden, der Sitz der sustamize GmbH in München.
        </TextWrapper>
      </PaperWrapper>
    </ContentBody>
  )
}
