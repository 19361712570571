import { iLooseObject } from 'pages/DataHub/filters/types'

interface iIdentification {
  id: string
  sustamizeId: string
  title: string
  description: string
}
interface iTimestamps {
  addedOn: string
  lastUpdatedOn: string
}

interface iInfoGeneral {
  descriptionDe: string
  descriptionEn: string
  oldName: string
  ukName: string
  usName: string
}

interface iCo2BaseRecycling {
  countryCorrectionRequestedFor: string,
  countryCorrectionAppliedFor: string,
  isCountryCorrectionApplied: boolean,
  common: number | null,
  median: number | null,
  mean: number | null
  min: number | null
  max: number | null
  range: number | null
  co2ValueEntries: iCo2Value[]
}

interface iCo2MetalUnitRange {
  unit: string
  rangeFrom: number
  rangeTo: number
}
// Material Technical Data
interface iCo2SintersTechnicalData {
  density: { value: number; unit: string }
  emissionFactorPrimary: number
  emissionFactorUnitSymbol: string
  rx: { value: string; unit: string }
  strength: string
}

interface iCo2MetalTechnicalData {
  density: { value: number; unit: string }
  emissionFactorPrimary: number
  emissionFactorRecycling: number
  emissionFactorUnitSymbol: string
  maximumElongation: { value: number; unit: string }
  maximumElongationRange: iCo2MetalUnitRange
  ruptureStrength: { value: number; unit: string }
  ruptureStrengthRange: iCo2MetalUnitRange
  wnrEu: string
  yieldStrength: { value: number; unit: string }
}

// Production Technical Data

interface iCo2EquipmentTechnicalData {
  investment: {
    currency: string
    additionalInvestment: number
    setupInvestment: number
    fundamentInvestment: number
    equipmentInvestment: number
  },
  rates: {
    consumableRate: number
    maintenanceRate: number
  },
  dimensions: {
    floorSize: {
      equipment: iCo2EquipmentFloorSize
      workspace: iCo2EquipmentFloorSize
    }
  },
  load: {
    fullLoadRate: number
    unit: string
    unitName: string
    unitQuantity: string
    unitRemarkEn: string
    unitRemarkDe: string
  },
  electricConsumption: iCo2EnergyConsumption[]


  // emissionFactorPrimary: number
  // emissionFactorUnitSymbol: string
  // equipmentFloorSize: number
  // equipmentId: string
  // floorSizeUnit: string
  // electricConsumption: number
  // electricConsumptionUnit: string
  // 
  // fullLoadRateUnit: string
  // investmentCurrencyUnit: string
  // investmentRemark: string
  // workspaceFloorSize: number
}

interface iCo2EnergyConsumption {
  amount: number
  unit: string
  unitName: string
  unitQuantity: string
  unitRemarkEn: string
  unitRemarkDe: string
}

interface iCo2EquipmentFloorSize {
  size: number
  unit: string
  unitName: string
  unitQuantity: string
  unitRemarkEn: string
  unitRemarkDe: string
}

interface iCo2ManufacturingTechnicalData {
  efficiency: {
    efficiencyCoefficient: number
    unit: string
  },
  energyConsumption: {
    value: number
    unit: string
  }
}



export interface iCo2Source {
  id: string,
  sustamizeId: string,
  addedOn: string
  lastUpdatedOn: string,
  title: string,
  descriptionEn: string
  descriptionDe: string
  quality: string
  year: string
  classification: string
  authors: iCo2Contributors[]
  editors: iCo2Contributors[]
  url: string
}

export interface iCo2Contributors {
  id: string,
  firstName: string,
  lastName: string,
  organization: string
}

export interface iCo2CalculationProcessModel {
  productionProcessDescription: string,
  remark: string,
  scopeSpecification: string,
  productionProcessEnergyComposition: productionProcessEnergyComposition,
  elementEmissionContributions: elementEmissionContribution[]
  energyProductionShares: iEnergyProduction
}

export interface productionProcessEnergyComposition {
  butane: number,
  cng : number,
  coal : number,
  diesel: number
  electrical : number
  kerosene : number
  lng : number
  lpg : number
  lubricatingOil : number
  naptha : number
  naturalGas : number
  oil : number
  petrol: number
  petroleumCoke : number
  propane : number
  woodChips : number,
  woodpellets : number
}

export interface elementShare{
  periodicTableSymbol: string,
  nameEn: string,
  nameDe: string,
  shareAmount: number,
  sharePercentage: string
}

export interface elementEmissionContribution{
  elementPeriodicTableSymbol: string,
  elementName: string,
  contributionPercentage: number
}

export interface iCo2OriginComposition {
  fuel: number,
  biogenic: number,
  luluc: number
}

export interface iCo2Value {
  id: string,
  addedOn: string,
  lastUpdatedOn: string,
  isCommon: boolean,
  isRecycled: boolean,
  isDynamicallyGenerated: boolean,
  referenceValue: number,
  referenceValueUnit: {id: string, symbol: string},
  productionProcessEnergyComposition: {}
  co2OriginComposition: iCo2OriginComposition,
  co2Source: iCo2Source,
  elementShareSources: iCo2Source[],
  validity: number,
  quality: number,
  scope: eScope,
  calculationProcessModel: iCo2CalculationProcessModel,
  type: string
}

export interface iCo2ValuesChipLabel {
  label: string
  color: string
}

export enum eCo2Details {
  sources = 'elementShareSources',
  process = 'calculationProcessModel',
}

export enum eScope { 
  TANK_TO_WHEEL = 'tank-to-wheel',
  WELL_TO_WHEEL  = 'well-to-wheel',
  WELL_TO_TANK = 'well-to-tank',
  CRADLE_TO_CRADLE  = 'cradle-to-cradle',
  CRADLE_TO_GATE = 'cradle-to-gate',
  CRADLE_TO_GRAVE = 'cradle-to-grave',
  GATE_TO_GATE = 'gate-to-gate'
}

export interface iCo2Emission {
  year: number,
  country: string,
  carbonIntensity: number,
  carbonIntensityUnit: string,
  deliveryShape: string,
  emissionFactorPrimary: number,
  emissionFactorRecycling: number,
  emissionFactorUnitSymbol: string,
  oldestRecord: string,
  newestRecord: string,
  totalRecords: number,
  averageRecordQuality: number,
  co2OriginComposition: iCo2OriginComposition
  co2Base: iCo2BaseRecycling
  co2Recycling: iCo2BaseRecycling
  }
  // packagingMaterialCo2Value?: number
  // packagingMaterialCo2ValueUnit?: string
  // emissionFactorUnitSymbol: string
  // emissionFactorRecycling: number
  // emissionFactorPrimary: number
  // emissionFactorPrimaryTotal: number
  // oldestRecord: string
  // newestRecord: string
  // totalRecords: number
  // deliveryShape: string
  // packagingReference: string
  // packagingThickness: string
  // emissionPerUnit: string
  // emissionFactorUptake: number
  // emissionWithoutUptake: number
  // referenceUnit: string
  // averageRecordQuality: number
  // co2Base: iCo2BaseCommon
  // co2Recycling: iCo2BaseRecycle
  // co2Values: iCo2Values[]

export interface iCo2EmissionAccordionColumn {
  key: string
  value: number | string
}

export interface iEnergyProductionShare {
  productionTypeEn: string,
  productionTypeDe: string,
  shareAmount: number,
  sourceTitle: string,
  sourceQuality: number,
  validityFrom: string,
  validityTo: string
}

//Energy Instance Data
export interface iEnergyProduction {
  nonRenewable: iEnergyProductionShare[]
  conventionalThermal: iEnergyProductionShare[]
  renewable: iEnergyProductionShare[]
  nonSpecified: iEnergyProductionShare[]
}
export interface iCo2EnergyInstanceData {
  documentation: {
    pfeVersion: string,
    documentationUrl: string
  },
  information: {
    general: { code: string }
    identification: iInfoGeneral
    technical: {
      energyProductionShares: iEnergyProduction
    }
    timestamps: iTimestamps
  }
  features: {elementShares: elementShare[]}
  co2Emission: iCo2Emission[]
}

export interface iMaterialShare {
  shareAmount: number,
  group: {descritpionEn: string, descriptionDe: string},
  density: {value: number, unit: string},
  measurement: {default: number, unit: string, incorporateMeasurement: boolean}
}

// Materials Instance Data
export interface iCo2CompositesInstanceData {
  co2Emission: iCo2Emission[],
  documentation: {
    pfeVersion: string,
    documentationUrl: string
  },
  information: {
    general: { shortName: string }
    identification: iIdentification
    technical: {
      emissionFactorPrimary: number
      emissionFactorRecycling: number
      emissionFactorUnitSymbol: string
    }
    timestamps: iTimestamps
  }
}

export interface iCo2ConstructionInstanceData {
  co2Emission: iCo2Emission[],
  documentation: {
    pfeVersion: string,
    documentationUrl: string
  },
  information: {
    general: { remark: string }
    identification: iIdentification
    technical: {
      dimensionInformation: string
      emissionFactorPrimary: number
      emissionFactorUnitSymbol: string
    }
    timestamps: iTimestamps
  }
}

export interface iCo2MetalInstanceData {
  co2Emission: iCo2Emission[],
  documentation: {
    pfeVersion: string,
    documentationUrl: string
  },
  information: {
    general: iInfoGeneral
    identification: iIdentification
    timestamps: iTimestamps
    technical: iCo2MetalTechnicalData
  }
}

export interface iCo2ElementsInstanceData {
  co2Emission: iCo2Emission[],
  documentation: {
    pfeVersion: string,
    documentationUrl: string
  },
  information: {
    general: iInfoGeneral
    identification: iIdentification
    technical: {
      dataType: string
      emissionFactorPrimary: number
      emissionFactorUnitSymbol: string
      periodicTableSymbol: string
    }
    timestamps: iTimestamps
  }
}
export interface iCo2PolymersInstanceData {
  co2Emission: iCo2Emission[],
  documentation: {
    pfeVersion: string,
    documentationUrl: string
  },
  information: {
    general: { fullName: string; shortName: string }
    identification: iIdentification
    technical: {
      density: { unit: string; value: number }
      emissionFactorPrimary: number
      emissionFactorRecycling: number
      emissionFactorUnitSymbol: string
      glassTemperature: { unit: string; value: number }
    }
    timestamps: iTimestamps
  }
}

export interface iCo2SintersInstanceData {
  co2Emission: iCo2Emission[]
  information: {
    general: {
      fullNameDe: string
      fullNameEn: string
      shortName: string
    }
    identification: iIdentification
    technical: iCo2SintersTechnicalData
    timestamps: iTimestamps
  }
}

//Production Instance Data

export interface iCo2EmployeesInstanceData {
  co2Emission: iCo2Emission[],
  documentation: {
    pfeVersion: string,
    documentationUrl: string
  },
  information: {
    general: {}
    identification: iIdentification
    technical: {
      emissionFactorPrimary: number
      emissionFactorUnitSymbol: string
    }
    timestamps: iTimestamps
  }
}

export interface iCo2EquipmentInstanceData {
  co2Emission: iCo2Emission[],
  documentation: {
    pfeVersion: string,
    documentationUrl: string
  },
  information: {
    general: { classification: string; remark: string; vendor: string }
    identification: iIdentification
    technical: iCo2EquipmentTechnicalData
    timestamps: iTimestamps
  }
}

export interface iCo2ManufacturingInstanceData {
  co2Emission: iCo2Emission[],
  documentation: {
    pfeVersion: string,
    documentationUrl: string
  },
  information: {
    general: { description :{
      descriptionEn: string
      descriptionDe: string,
      alternativeDescriptionEn: string,
      alternativeDescriptionDe: string,
    },
    group: {
      descriptionEn: string,
      descriptionDe: string
    },
    material: { descriptionEn: string,
      descriptionDe: string
    },
    processType: {
      descriptionEn: string,
      descriptionDe: string
    }
     }
    identification: iIdentification
    technical: iCo2ManufacturingTechnicalData
    timestamps: iTimestamps
  }
}

// Logistics Instance Data
export interface iCo2PackagingInstanceData {
  co2Emission: iCo2Emission[],
  documentation: {
    pfeVersion: string,
    documentationUrl: string
  },
  information: {
    general: { packagingClass: string }
    identification: iIdentification
    technical: {
      materialShares: iMaterialShare[]
    }
    timestamps: iTimestamps
  }
}

export interface iCo2TransportationInstanceData {
  co2Emission: iCo2Emission[],
  documentation: {
    pfeVersion: string,
    documentationUrl: string
  },
  information: {
    general: { remark: string; type: string }
    identification: iIdentification
    technical: {
      emissionFactorPrimary: number
      emissionFactorUnitSymbol: string
      loadRatio: number
    }
    timestamps: iTimestamps
  }
}

export interface iSingleEntityInstanceData {
  documentation: {
    pfeVersion: string,
    documentationUrl: string
  },
  information: {
    identification: iIdentification
    general: {}
    technical: {}
    timestamps: iTimestamps
  },
  features: {
    elementShares: elementShare[]
  }
  co2Emission: iCo2Emission[]
}

export interface iCo2SingleEntity {
  primaryColumn: {
    title: { name: string; unit?: string }
    primary: iLooseObject
  }
  recyclingColumn?: {
    title: { name: string; unit?: string }
    recycling?: iLooseObject
  }
  infoColumn?: {
    title?: string
    information?: iLooseObject
  }
  totalColumn?: {
    title?: string
    data?: iLooseObject
  }
}
export interface iCO2AccordionIcon {
  icon: string
  value: string
  title: string
  unit?: string
}
export interface iCo2AccordionEntity {
  country?: iCO2AccordionIcon
  emissionPerUnit?: iCO2AccordionIcon
  emissionPrimary?: iCO2AccordionIcon
  emissionRecycling?: iCO2AccordionIcon
  deliveryShape?: iCO2AccordionIcon
  uptakeConstruction?: iCO2AccordionIcon
  co2PerUnit?: iCO2AccordionIcon
  equipmentInvestment?: iCO2AccordionIcon
  validityPeriod?: iCO2AccordionIcon
}


export interface iSingleEntity {
  data: iSingleEntityInstanceData
}
export interface iSingleEntityState {
  singleEntity: iSingleEntity | null
}
