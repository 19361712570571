import LinkWrapper from 'components/custom/LinkWrapper'
import { useTranslation } from 'react-i18next'

export const OrderHistory = () => {
  const { t } = useTranslation()
  return (
    <div className="p-10 pl-4">
      {t('billing.orderHistory.contactUsLabel')}{' '}
      <LinkWrapper
        to={`mailto:support@sustamizer.com?subject=${t(
          'billing.orderHistory.contactUsTitle'
        )}:%20`}
      >
        {t('billing.orderHistory.email_anchor')}
      </LinkWrapper>
    </div>
  )
}
