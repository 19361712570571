import React from 'react'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import { RoutePaths } from 'routes/paths'
import { CalculationVariantsDocument } from './CalculationVariantsDocument'

const CalculationVariants: FC = () => {

  return (
    <CalculationVariantsDocument />
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: [RoutePaths.knowledgeHubCalculationVariants],
    title: 'knowledgeHub.calculationVariants',
    element: <CalculationVariants />,
    roles: componentPermissions[componentNames.pageKnowledgeHubCalculationVariants],
    requiresAuthentication: true,
  },
  name: 'CalculationVariants',
}
export default exportObj
