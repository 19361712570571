import { Checkbox, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup } from '@mui/material'
import SelectWrapper from 'components/custom/SelectWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
import { eAdditionalDatabaseSelection, eBillingPeriod, eInputVariant, ePackageSelection, eTypographyVariant } from 'components/types'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DatabasesAndTools } from './DatabasesAndTools'
import { eProductKey, iPricedProductListing, iProductListing, iProductOverview, iSubscriptionOrder } from 'store/types/subscriptionOrder'
import { useEffectOnce } from 'usehooks-ts'
import { sagaGetAvailableProducts, sagaUpdateSubscriptionConfiguration } from 'saga/actions/subscriptionOrder'
import { useDispatch, useSelector } from 'react-redux'
import { setSubscriptionOrder, subscriptionOrderReducerState } from 'store/slices/subscriptionOrderSlice'
import { toScaledPrice } from '../helpers'
import { userReducerState } from 'store/slices/user'
import { getProductUnscaledPrice, yearlyToMonthlyPrice } from 'pages/Billing/OrderForm/helpers'
import { organizationProfileState } from 'store/slices/organizationProfile'
import { getPackageProductKeyFromSubscriptions } from 'pages/Billing/helpers'
import { packageKeyToPackageSelection, packageSelectionToPackageKey } from '../helpers'
import { sortByFieldName } from 'components/utils/sorting'

interface OrderDetailsProps {
  packageSelection: ePackageSelection,
  additionalDatabaseSelection?: eAdditionalDatabaseSelection,
  billingPeriod: eBillingPeriod,
  className?: string
}

export const OrderDetails: FC<OrderDetailsProps> = ({
  packageSelection,
  additionalDatabaseSelection,
  billingPeriod,
  className
}) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [selectedPackage, setPackage] = useState<ePackageSelection>(packageSelection)
  const [isAddOnDbInitialized, setIsAddonDbInitialized] = useState<boolean>(false)
  const [isPackageSelectable, setIsPackageSelectable] = useState<boolean>(false)

  const { 
    productOverview,
    subscriptionOrder,
    subscriptionOrderCalculation
   } = useSelector(subscriptionOrderReducerState)

  const {
    subscriptions
  } = useSelector(organizationProfileState)

  const { settings } = useSelector(userReducerState)

  useEffect(() => {
    const pckg = getPackageProductKeyFromSubscriptions(subscriptions)
    if (!pckg && !isPackageSelectable) setIsPackageSelectable(true)
    else if (pckg !== undefined && !isPackageSelectable) setIsPackageSelectable(false)
  }, [isPackageSelectable, subscriptions])

  useEffectOnce(() => {
    const initialOrder: iSubscriptionOrder = {
      packageKey: packageSelectionToPackageKey(selectedPackage),
      billingType: billingPeriod,
      licenses: 1,
      products: [],
      termsAccepted: false
    }

    dispatch(sagaUpdateSubscriptionConfiguration(initialOrder))
    dispatch(sagaGetAvailableProducts())
  })

  useEffect(() => {
    if (!subscriptionOrder || !productOverview) return;

    let order: iSubscriptionOrder = {...subscriptionOrder}

    if (additionalDatabaseSelection && !isAddOnDbInitialized) {
      const productKey = additionalDatabaseSelectionToProductKey(additionalDatabaseSelection)
      const product: iProductListing = productOverview.addonDatabases
        .filter(db => db.productKey === productKey)[0]!

      const isProductAlreadyConfigured = order.products.map(p => p.productKey)
      .filter(key => product.productKey === key).length > 0

      if (!isProductAlreadyConfigured) {
        order.products = [product]
        dispatch(setSubscriptionOrder(order))
      }
    }
    setIsAddonDbInitialized(true)
  }, [
    subscriptionOrder, 
    productOverview, 
    additionalDatabaseSelection, 
    dispatch, 
    isAddOnDbInitialized
  ])

  const generateListLabel = (
    label: string, 
    billingPeriod = eBillingPeriod.YEARLY, 
    price?: string, 
    showBillingPeriod = true,
    labelComment?: string,
    isActive: boolean = true
  ) => {
    return (
      <div className={`text-sm flex flex-row flex-nowrap items-center justify-between w-full`}>
        <div className={`flex flex-row items-center justify-start w-full`}>
          {label}
          {labelComment && (
            <div className={`text-[9px] ml-1`}>
              ({labelComment})
            </div>
          )}
        </div>
        {!!isActive && price ? (
          <div>
            {price}
            {showBillingPeriod && (
              <span>/{t(`billingPeriod.${billingPeriod}`)}</span>
            )}
          </div>
        ) : (
          <div className="w-full text-right">
            <span>{t(`comingSoon.title`)}</span>
          </div>
        )}
      </div>
    )
  }

  const additionalDatabaseSelectionToProductKey = (db: eAdditionalDatabaseSelection): eProductKey => {
    switch (db) {
      case eAdditionalDatabaseSelection.ELECTRONICS: return eProductKey.SUST_ELECTRONICS_DB;
      case eAdditionalDatabaseSelection.CONSUMER_PRODUCTS: return eProductKey.SUST_CONSUMER_PRODUCTS_DB;
      case eAdditionalDatabaseSelection.STANDARD_PARTS: return eProductKey.SUST_STANDARD_PARTS_DB;
    }
  }

  const buildPackageMenuItems = (selection: ePackageSelection): JSX.Element[] => {
    let packages: JSX.Element[] = []
    for (const p of Object.values(ePackageSelection)) {
      packages.push(
        <MenuItem 
          key={t(`${p}.label`)} 
          value={p}
          selected={p === selectedPackage}
        >
          {t(`${p}.label`)}
        </MenuItem>
      )
    }
    return packages
  }

  const getDatabasesAndFeaturesByPackageSelection = (
    selection: ePackageSelection,
    po?: iProductOverview
  ): {
    included: iPricedProductListing[], 
    available: iPricedProductListing[], 
    upgradeOnly: iPricedProductListing[],
    additional: iPricedProductListing[],
  } => {

    let included: iPricedProductListing[] = [],
      available: iPricedProductListing[] = [], 
      upgradeOnly: iPricedProductListing[] = [],
      additional: iPricedProductListing[] = []

    if (po) {

      const allDbsAndFeatures = [
        ...po.databases
          .filter(db => !po.addonDatabases
            .map(addOnDb => addOnDb.productKey)
            .includes(db.productKey)), 
        ...po.features
      ]

      const addOn = po.addonDatabases;
      let packageKey: eProductKey, pckg: iPricedProductListing;

      switch (selection) {
        case ePackageSelection.FLEX:
          const configuredProducts = subscriptionOrder?.products?.map(p => p.productKey)
          const configuredProductPriceListings = 
            allDbsAndFeatures.filter(dbf => configuredProducts?.includes(dbf.productKey))
          included = configuredProductPriceListings || []; available = allDbsAndFeatures; upgradeOnly = []; additional = addOn;
          break;
        case ePackageSelection.STANDARD:
          packageKey = eProductKey.SUST_STANDARD_PACKAGE
          pckg = po.packages.filter(p => p.productKey === packageKey)[0]
          included = allDbsAndFeatures.filter(dbf => pckg.includedProducts?.includes(dbf.productKey))
          available = allDbsAndFeatures.filter(dbf => pckg.compatibleProducts.includes(dbf.productKey))
          upgradeOnly = allDbsAndFeatures.filter(dbf => 
            !pckg.compatibleProducts.includes(dbf.productKey)
              && !included.map(i => i.productKey).includes(dbf.productKey)
          )
          additional = po.addonDatabases.filter(ao => 
            !pckg.includedProducts?.includes(ao.productKey)
              && pckg.compatibleProducts.includes(ao.productKey)
          )
          break;
        case ePackageSelection.PRO:
          packageKey = eProductKey.SUST_PRO_PACKAGE

          pckg = po.packages.filter(p => p.productKey === packageKey)[0]
          const standardPackage = po.packages.filter(p => p.productKey === eProductKey.SUST_STANDARD_PACKAGE)[0]
          const includedProducts: eProductKey[] = [...pckg.includedProducts!, ...standardPackage.includedProducts!]

          included = allDbsAndFeatures.filter(dbf => includedProducts?.includes(dbf.productKey))
          available = allDbsAndFeatures.filter(dbf => pckg.compatibleProducts.includes(dbf.productKey))
          upgradeOnly = allDbsAndFeatures.filter(dbf => 
            !pckg.compatibleProducts.includes(dbf.productKey)
              && !included.map(i => i.productKey).includes(dbf.productKey)
          )
          additional = po.addonDatabases.filter(ao => 
            !pckg.includedProducts?.includes(ao.productKey)
              && pckg.compatibleProducts.includes(ao.productKey)
          )
          break;
      }
    } 

    return {
      included: po ? included : [],
      available: po ? available : [],
      upgradeOnly: po ? upgradeOnly : [],
      additional: po ? additional : [],
    }
  }

  const getPriceByBillingPeriod = (
    unscaledPrice: number, 
    period = eBillingPeriod.YEARLY
  ): string => {
    const price = period === eBillingPeriod.MONTHLY
      ? yearlyToMonthlyPrice(unscaledPrice) 
      : unscaledPrice

    return toScaledPrice(
      price, 
      settings.locale, 
      subscriptionOrderCalculation?.priceScale
    )
  }

  const getPricesByDatabasesAndFeaturesSelection = (
    selection: ePackageSelection,
    products: { included: iPricedProductListing[] }
  ): {monthly: string, yearly: string} => {

    let key: eProductKey, unscaledPrice: number,
      monthly: string, yearly: string

    switch (selection) {
      case ePackageSelection.PRO:
        key = eProductKey.SUST_PRO_PACKAGE
        unscaledPrice = getProductUnscaledPrice(key, productOverview)
        monthly = getPriceByBillingPeriod(unscaledPrice, eBillingPeriod.MONTHLY)
        yearly = getPriceByBillingPeriod(unscaledPrice, eBillingPeriod.YEARLY)
        return {
          monthly: monthly,
          yearly: yearly
        }
      case ePackageSelection.STANDARD:
        key = eProductKey.SUST_STANDARD_PACKAGE
        unscaledPrice = getProductUnscaledPrice(key, productOverview)
        monthly = getPriceByBillingPeriod(unscaledPrice, eBillingPeriod.MONTHLY)
        yearly = getPriceByBillingPeriod(unscaledPrice, eBillingPeriod.YEARLY)
        return {
          monthly: monthly,
          yearly: yearly
        }
      case ePackageSelection.FLEX:
        unscaledPrice = products.included.length > 0
          ? products.included
            .map(p => p.price)
            .reduce((a, b) => a + b)
          : 0

        monthly = getPriceByBillingPeriod(unscaledPrice, eBillingPeriod.MONTHLY)
        yearly = getPriceByBillingPeriod(unscaledPrice, eBillingPeriod.YEARLY)
        return {
          monthly: monthly,
          yearly: yearly
        }
    }
  }

  const handleChangePackage = (pckg: ePackageSelection) => {
    if (!subscriptionOrder) return

    let subscription: iSubscriptionOrder = {...subscriptionOrder}
    let packageKey: eProductKey|undefined;

    subscription.products = []

    switch (pckg) {
      case ePackageSelection.FLEX:
        packageKey = undefined
        break;
      case ePackageSelection.STANDARD:
        packageKey = eProductKey.SUST_STANDARD_PACKAGE
        break;
      case ePackageSelection.PRO:
        packageKey = eProductKey.SUST_PRO_PACKAGE
        break;
    }

    subscription.packageKey = packageKey
    setPackage(packageKeyToPackageSelection(packageKey))
    dispatch(setSubscriptionOrder(subscription))
  }

  const handleChangeNumberOfLicenses = (numOfLicenses: number) => {
    if (!subscriptionOrder) return

    let subscription: iSubscriptionOrder = {...subscriptionOrder}
    subscription.licenses = numOfLicenses

    dispatch(setSubscriptionOrder(subscription))
  }

  const handleSetBillingPeriod = (period: eBillingPeriod) => {
    if (!subscriptionOrder) return

    let subscription: iSubscriptionOrder = {...subscriptionOrder}
    subscription.billingType = period

    dispatch(setSubscriptionOrder(subscription))
  }

  const handleSelectAddonDatabase = (listing: iPricedProductListing) => {

    if (!subscriptionOrder) return
    let order = {...subscriptionOrder}

    const itemIndex = order.products
      ?.map(p => p.productKey)
      ?.indexOf(listing.productKey)

    let products = [...order.products]

    if (itemIndex === -1) {
      products.push({productKey: listing.productKey})
    } else {
      products.splice(itemIndex, 1)
    }

    (order as any)['products'] = [...products]
    dispatch(setSubscriptionOrder(order as iSubscriptionOrder))
  }

  return (
    <div className={`
      ${className}
      flex flex-col justify-start lg:min-w-[400px]
    `}>
      <TitleWrapper variant={eTypographyVariant.h5}>
        {t(`offerings.orderDetails`)}
      </TitleWrapper>
      <div className={`flex flex-col lg:flex-row lg:gap-x-3`}>
        <SelectWrapper
          onChange={(e) => handleChangePackage(e as ePackageSelection)}
          variant={eInputVariant.outlined}
          label={t(`offerings.package`)}
          defaultValue={selectedPackage}
          className={`min-w-[164px]`}
          disabled={!isPackageSelectable}
        >
          {buildPackageMenuItems(packageSelection)}
        </SelectWrapper>
        <SelectWrapper
          onChange={(e) => handleChangeNumberOfLicenses(parseInt(e))}
          variant={eInputVariant.outlined}
          label={t(`offerings.namedUserLicense`)}
          defaultValue={subscriptionOrder?.licenses.toString() || '1'}
          className={`min-w-[164px]`}
        >
          {Array.from(Array(10).keys()).map(i => (
            <MenuItem 
              key={i+1} 
              value={i+1} 
              selected={(i+1 === (subscriptionOrder?.licenses || 1)) as boolean}
            >
              {i+1 || 1}
            </MenuItem>
          ))}
        </SelectWrapper>
      </div>
      <div className={``}>
        <TitleWrapper variant={eTypographyVariant.h6}>
          {t(`offerings.databasesAndTools`)}
        </TitleWrapper>
        <div className={`
          flex flex-col no-wrap
          gap-y-1.5 lg:gap-y-0
          lg:flex-row lg:flex-wrap lg:justify-between
        `}>

          {/** List products included in package */}
          {getDatabasesAndFeaturesByPackageSelection(
            selectedPackage,
            productOverview
          ).included.map((product, i) => {
            
            return selectedPackage !== ePackageSelection.FLEX
              ? (
                  <DatabasesAndTools 
                    key={i}
                    packageSelection={selectedPackage}
                    productKey={product.productKey}
                    disabled={true}
                    selected={true}
                  />
              ) : null
            })
          }

          {/** List products available in package */}
          {getDatabasesAndFeaturesByPackageSelection(
            selectedPackage,
            productOverview
          ).available.map((product, i) => (
            <DatabasesAndTools 
              key={i}
              packageSelection={selectedPackage}
              productKey={product.productKey}
              disabled={false}
              selected={false}
              upgradeOnly={false}
              price={product.price}
            />
          ))}

          {/** List products available only on upgrade */}
          {getDatabasesAndFeaturesByPackageSelection(
            selectedPackage,
            productOverview
          ).upgradeOnly.map((product, i) => (
            <DatabasesAndTools 
              key={i}
              packageSelection={selectedPackage}
              productKey={product.productKey}
              disabled={false}
              selected={false}
              upgradeOnly
            />
          ))}

        </div>
      </div>
      <div className={``}>
        <FormControl className={`w-full`}>
          <FormLabel className={`text-black dark:text-white my-1`}>{t(`offerings.billingPeriod`)}</FormLabel>
          <RadioGroup 
            name={`billingPeriod`}
          >
            <FormControlLabel 
              value={eBillingPeriod.YEARLY}
              control={<Radio className={`py-0.5`} />}
              label={generateListLabel(
                t(`billingPeriod.${eBillingPeriod.YEARLY}`), 
                eBillingPeriod.YEARLY,
                getPricesByDatabasesAndFeaturesSelection(
                  selectedPackage,
                  getDatabasesAndFeaturesByPackageSelection(
                    selectedPackage,
                    productOverview
                  )
                ).yearly,
                false
              )}
              checked={subscriptionOrder?.billingType === eBillingPeriod.YEARLY}
              onClick={() => handleSetBillingPeriod(eBillingPeriod.YEARLY)}
              sx={{ '& .MuiTypography-body1': {width: '100%'}}}
            />
            <FormControlLabel 
              value={eBillingPeriod.MONTHLY}
              control={<Radio className={`py-0.5`} />}
              label={generateListLabel(
                t(`billingPeriod.${eBillingPeriod.MONTHLY}`), 
                eBillingPeriod.MONTHLY,
                getPricesByDatabasesAndFeaturesSelection(
                  selectedPackage,
                  getDatabasesAndFeaturesByPackageSelection(
                    selectedPackage,
                    productOverview
                  )
                ).monthly,
                false,
                t(`offerings.twelveMonthContract`),
              )}
              checked={subscriptionOrder?.billingType === eBillingPeriod.MONTHLY}
              onClick={() => handleSetBillingPeriod(eBillingPeriod.MONTHLY)}
              sx={{ '& .MuiTypography-body1': {width: '100%'}}}
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className={`flex-grow border-t border-gray-300 my-2`}></div>
      <div className={`flex flex-row`}>
        <div className={``}>
          {t(`offerings.doMoreWith`)}
          &nbsp;
        </div>
        <div className={`cursor-pointer`}>
          <span className={`text-secondary`}>s</span>
          <span className={`text-primary`}>ustamizer</span>
        </div>
      </div>
      <div className={`text-secondary my-2`}>
        {t(`offerings.additionalDatabases`)}
      </div>
      <FormControl className={`w-full`}>
        {isAddOnDbInitialized &&
          sortByFieldName(
            getDatabasesAndFeaturesByPackageSelection(
              packageSelection,
              productOverview
            ).additional,
            'isAvailable',
            true
          ).map((aoDb: iPricedProductListing, i: number) => {
            if (!aoDb.isAvailable) return null;

            return (<FormControlLabel
              disabled={!aoDb.isAvailable}
              key={i}
              value={aoDb.productKey}
              control={<Checkbox className={`py-0.5`} />}
              onChange={() => handleSelectAddonDatabase(aoDb)}
              label={generateListLabel(
                t(`offerings.productKeys.${aoDb.productKey}`),
                subscriptionOrder?.billingType,
                getPriceByBillingPeriod(
                  aoDb.price,
                  subscriptionOrder?.billingType
                ),
                true,
                '',
                aoDb.isAvailable
              )}
              checked={subscriptionOrder?.products.some(
                (pr) => pr.productKey === aoDb.productKey
              )}
              sx={{ '& .MuiTypography-body1': { width: '100%' } }}
            />
          )})}
      </FormControl>
    </div>
  )
}