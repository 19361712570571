import React from 'react'
import { SxProps, Typography } from '@mui/material'
import { eColor, eTypographyVariant } from 'components/types'

interface iProps {
  children: string | React.ReactNode | JSX.Element | JSX.Element[]
  id?: string
  className?: string
  sx?: SxProps
  color?: eColor
  variant?: eTypographyVariant
}

const TextWrapper = ({
  children,
  id = '',
  className = 'my-2',
  sx,
  color,
  variant,
}: iProps) => {
  return (
    <Typography
      id={id}
      className={className}
      variant={variant}
      sx={sx}
      color={color}
    >
      {children}
    </Typography>
  )
}

export default TextWrapper
