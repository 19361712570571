import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalWrapper from 'components/custom/ModalWrapper'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
import TextWrapper from 'components/custom/TextWrapper'

import { ReactComponent as AddUserSVG } from 'assets/icons/023-add-user.svg'
import { ReactComponent as CertificateSVG } from 'assets/icons/certificate.svg'
import { ReactComponent as SelectionSVG } from 'assets/icons/selection.svg'
import { ReactComponent as TasksSVG } from 'assets/icons/tasks.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
  generalSettingsOrganizationState,
  toggleOrganizationSuccessModalVisibility,
} from 'store/slices/generalSettings'
import { useSearchParams } from 'react-router-dom'
import { sagaGetOrganizationProfileDataAction } from 'saga/actions/user'

const NewOrganizationSuccessModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [, setSearchParams] = useSearchParams()
  const [isOpen, seIsOpen] = useState(false)

  //get global state for visibility of modal:
  const isGlobalStateModalOpen = useSelector(generalSettingsOrganizationState)

  // when the modal closes functionality
  const toggleModal = useCallback(() => {
    dispatch(toggleOrganizationSuccessModalVisibility())
  }, [dispatch])
  useEffect(() => {
    seIsOpen(isGlobalStateModalOpen.isOrganizationCreationSuccessModalOpen)
  }, [isGlobalStateModalOpen.isOrganizationCreationSuccessModalOpen])

  return (
    <>
      <ModalWrapper
        onClose={() => toggleModal()}
        hasCloseButton={true}
        open={isOpen}
        footerSection={
          // TODO: implement responsivness
          <div className="grid justify-end">
            <ButtonWrapper
              onClick={() => {
                dispatch(toggleOrganizationSuccessModalVisibility())
                setSearchParams({ view: 'info' })
                window.location.reload()
              }}
              aria-label={t('organization.createdSuccess.getStarted')}
            >
              {t('organization.createdSuccess.getStarted')}
            </ButtonWrapper>
          </div>
        }
      >
        {/* TODO: check wierd margin-bottom size  */}
        <div className="mx-6 mb-4">
          <TitleWrapper>{t('organization.createdSuccess.title')}</TitleWrapper>
          <TextWrapper>{t('organization.createdSuccess.update')}</TextWrapper>
          <ul>
            <li className="flex ml-6">
              <TextWrapper>
                <AddUserSVG
                  className="fill-paletteGreen w-5 h-5 inline-flex mr-2"
                  aria-label={t('organization.createdSuccess.addMemberIcon')}
                />
                {t('organization.createdSuccess.invite')}
              </TextWrapper>
            </li>
            <li className="flex ml-6">
              <TextWrapper>
                <CertificateSVG
                  className="fill-paletteGreen w-5 h-5 inline-flex mr-2"
                  aria-label={t(
                    'organization.createdSuccess.manageLicensesIcon'
                  )}
                />
                {t('organization.createdSuccess.manageLicenses')}
              </TextWrapper>
            </li>
            <li className="flex ml-6">
              <TextWrapper>
                <SelectionSVG
                  className="fill-paletteGreen w-5 h-5 inline-flex mr-2"
                  aria-label={t('organization.createdSuccess.assignRolesIcon')}
                />
                {t('organization.createdSuccess.assignRoles')}
              </TextWrapper>
            </li>
            <li className="flex ml-6">
              <TextWrapper>
                <TasksSVG
                  className="fill-paletteGreen w-5 h-5 inline-flex mr-2"
                  aria-label={t('organization.createdSuccess.updateStatusIcon')}
                />
                {t('organization.createdSuccess.updateStatusMembers')}
              </TextWrapper>
            </li>
          </ul>
        </div>
      </ModalWrapper>
      <div></div>
    </>
  )
}

export default NewOrganizationSuccessModal
