import apiRequests from 'config/services'
import { userTypes } from 'saga/actions/user'
import { setOrganizationTeam } from 'store/slices/organizationProfile'
import { iOrganizationData } from 'store/types'
import { call, put, takeLeading } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { iLooseObject } from 'pages/DataHub/filters/types'

export function* getOrganizationTeamMembersSaga(
  action: PayloadAction<iLooseObject>
) {
  try {
    const { organizationId } = action.payload
    if (!organizationId) {
      return
    }
    let result: iOrganizationData[] = yield call(
      apiRequests.getOrganizationTeamMembersDataService,
      organizationId
    )
    yield put(
      setOrganizationTeam(
        result.map((item: iOrganizationData) => {
          return {
            ...item,
            license: false,
            addOns: '-',
            actions: '',
          }
        })
      )
    )
  } catch (e) {}
}

export default function* getOrganizationTeamMembersHook() {
  yield takeLeading(
    userTypes.SAGA_GET_ORGANIZATION_TEAM_MEMBERS_DATA,
    getOrganizationTeamMembersSaga
  )
}
