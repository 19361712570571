import { put, select, takeLeading } from 'redux-saga/effects'
import { dataHubTypes } from 'saga/actions/dataHub'
import { dataHubReducerState } from 'store/slices/dataHubSlice'
import { iCustomField, iHubsSliceState } from 'store/types'
import { addAllFields } from 'store/slices/selectedFieldsSlice'

export function* setSettingsFieldsDefaultsSaga() {
  try {
    const dataHubSettings: iHubsSliceState = yield select(dataHubReducerState)
    //create a list of fields that have default values
    const defaultSelectedFields: iCustomField[] =
      yield dataHubSettings.hub.settings
        .filter((item) => (item?.defaultValue ? true : false))
        .map((item) => {
          return {
            name: item.name || '',
            displayName: item.displayName || '',
            value: item.defaultValue || '',
            info: item,
          }
        })

    //   add the default values fields to the list of selected fields (to act as default values)
    yield put(addAllFields(defaultSelectedFields))
  } catch (e) {
        //catchind errors
  }
}

export default function* setSettingsDefaultsHook() {
  yield takeLeading(
    dataHubTypes.SAGA_SET_SETTINGS_DEFAULT_FIELDS_DATA,
    setSettingsFieldsDefaultsSaga
  )
}
