import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { iCustomField,iSelectedFieldState } from 'store/types'

// for parametered slices
// import type { PayloadAction } from '@reduxjs/toolkit'

//get current reducer state
export const selectedFiltersState = (state: RootState) => state.selectedFilters

export const initialState: iSelectedFieldState = {
  filters: [],
}

//reducer Object
const selectedFieldsSlice = createSlice({
  name: 'selectedFields',
  initialState,
  reducers: {
    addAllFields: (state, action: PayloadAction<iCustomField[]>) => {
      const newFilters = action.payload
      state.filters = newFilters
    },
    alterFilter: (state, action: PayloadAction<iCustomField>) => {
      const newFilter: iCustomField = action.payload
      const indexByName = state.filters.findIndex(
        (item) => item.name === newFilter.name
      )
      const indexByNameAndValue = state.filters.findIndex(
        (item: iCustomField) =>
          item.name === newFilter.name && item.value === newFilter.value
      )

      if (indexByName !== -1 && indexByNameAndValue === -1) {
        state.filters[indexByName].value = newFilter.value
      } else if (indexByName === -1) {
        state.filters.push(newFilter)
      }
    },
    removeFilter: (state, action: PayloadAction<iCustomField>) => {
      const filterToRemove: iCustomField = action.payload
      const indexByName: number = state.filters.findIndex(
        (item) => item.name === filterToRemove.name
      )
      state.filters.splice(indexByName, 1)
    },
    clearAllFilters: (state) => {
      state.filters = []
    },

    replaceSelectedFilters: (state, action: PayloadAction<iCustomField[]>) => {
      const newSelectedFilters: iCustomField[] = action.payload
      state.filters = newSelectedFilters
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  addAllFields,
  alterFilter,
  removeFilter,
  clearAllFilters,
  replaceSelectedFilters,
} = selectedFieldsSlice.actions

export default selectedFieldsSlice.reducer
