import {
  ContentBodySection,
  ContentBody,
} from 'components/pages/components/ContentBody'
import FeaturedTable from 'components/pages/api-management/FeaturedTable'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import './ApiManagement.css'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { componentPermissions } from 'config/permissions'
import { RoutePaths } from 'routes/paths'
import { apiTableDataReducerState } from 'store/slices/apiManagementSlice'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import { eColor } from 'components/types'
// import SwaggerUI from 'swagger-ui-react'

const ApiManagement: FC = () => {
  const { t } = useTranslation()
  const apiTableData = useSelector(apiTableDataReducerState).value
  // {`Configured Clients`}
  return (
    <div data-testid="api-management-testID">
      <ContentBody title={t('automationHub.title')}>
        <ContentBodySection title={t('automationHub.configuredClients')}>
          <FeaturedTable rows={apiTableData} />
          <div className={`Api-Management-button-container`}>
            <ButtonWrapper
              data-testid="button-testid"
              onClick={() => {
                // TODO : Functionality for when the button is clicked
              }}
              color={eColor.primary}
            >
              {t('apiManagement.addNewClient')}
            </ButtonWrapper>
          </div>
        </ContentBodySection>
        <ContentBodySection title={t('apiManagement.APISpecification')}>
          {/* <SwaggerUI url="https://petstore.swagger.io/v2/swagger.json" />  */
          /*removed since it breaks testing*/}
        </ContentBodySection>
      </ContentBody>
    </div>
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.automationHub,
    element: <ApiManagement />,
    title: 'pages.apiManagement',
    roles: componentPermissions[componentNames.pageManagementAutomationHub],
    requiresAuthentication: true,
  },
  name: 'ApiManagement',
}
export default exportObj
