import { SxProps, Typography } from '@mui/material'
import { eColor, eTypographyVariant } from 'components/types'
import React from 'react'

interface IProps {
  children: string | React.ReactNode | JSX.Element | JSX.Element[]
  ariaLabel?: string
  className?: string
  id?: string
  ref?: React.MutableRefObject<null> | any
  sx?: SxProps
  color?: eColor
  variant?: eTypographyVariant
}

const TitleWrapper = ({
  children,
  ariaLabel,
  className = 'pt-4 pb-2',
  id,
  ref,
  sx,
  color = eColor.primary,
  variant = eTypographyVariant.h4,
}: IProps) => {
  return (
    <div ref={ref}>
      <Typography
        className={className}
        id={id}
        variant={variant}
        sx={sx}
        color={color}
        aria-label={ariaLabel}
      >
        {children}
      </Typography>
    </div>
  )
}

export default TitleWrapper
