import { useEventListener } from "usehooks-ts"

export const useBeforeUnload = (cb: () => void, preventDefault: boolean = true) => {
  return useEventListener('beforeunload', (e: BeforeUnloadEvent) => {
    
    Object.defineProperty(e, 'cancelable', {value: true})
    preventDefault && e.preventDefault()

    cb()
  })
}