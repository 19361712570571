import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { iLooseObject } from 'pages/DataHub/filters/types'
import { iOrganizationManageTeam } from 'pages/user/types'
import { RootState } from 'store'
import { iOrganizationData } from 'store/types'
// for parametered slices
// import type { PayloadAction } from '@reduxjs/toolkit'

export const initialState: iOrganizationData = {
  id: '',
  city: '',
  country: '',
  name: '',
  postalCode: '',
  address: '',
  owner: '',
  team: [],
  subscriptions: []
}

//get current reducer state
export const organizationProfileState = (state: RootState) =>
  state.organizationProfile

export const organizationProfileReducer = createSlice({
  name: 'organizationProfileReducer',
  initialState,
  reducers: {
    setOrganizationProfileData: (
      state,
      action: PayloadAction<iOrganizationData>
    ) => {
      const orgData: iLooseObject = action.payload
      const {
        id,
        address,
        city,
        country,
        name,
        website,
        postalCode,
        vatId,
        invoiceFirstName,
        invoiceLastName,
        invoiceEmail,
        subscriptions,
      } = orgData
      state.id = id || ''
      state.address = address || ''
      state.city = city || ''
      state.country = country || ''
      state.name = name || ''
      state.website = website || ''
      state.postalCode = postalCode || ''
      state.vatId = vatId || ''
      state.invoiceFirstName = invoiceFirstName || ''
      state.invoiceLastName = invoiceLastName || ''
      state.invoiceEmail = invoiceEmail || ''
      state.subscriptions = subscriptions
    },
    setOrganizationTeam: (
      state,
      action: PayloadAction<iOrganizationManageTeam[]>
    ) => {
      state.team = action.payload
    },
  },
})

export const { setOrganizationProfileData, setOrganizationTeam } =
  organizationProfileReducer.actions
export default organizationProfileReducer.reducer
