import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { Order } from 'pages/DataHub/filters/types'
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { iOrganizationSubscription } from 'store/types'
import CustomSvgStyled from 'components/custom/CustomSvgStyled'
import { useTranslation } from 'react-i18next'
import { eColor } from 'components/types'
import PaperWrapper from 'components/custom/PaperWrapper'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import ModalWrapper from 'components/custom/ModalWrapper'
import { visuallyHidden } from '@mui/utils'
import { getComparator, stableSort } from 'components/utils/sorting'
import { organizationProfileState } from 'store/slices/organizationProfile'
import { getFormattedDate } from 'components/utils/dates'
import { sagaCancelSubscriptionRenewal } from 'saga/actions/subscriptionOrder'
import { TooltipWrapper } from 'components/custom/TooltipWrapper'
import { sagaGetOrganizationProfileDataAction } from 'saga/actions/user'
import { subscriptionOrderReducerState } from 'store/slices/subscriptionOrderSlice'
import { generateSubscriptionName } from 'pages/Billing/helpers'
import { susIcons } from 'config/services/requests'

export interface EnhancedTableProps {
  onRequestSort: (event: MouseEvent<unknown>, property: string) => void
  order: Order
  orderBy: string
  rowCount: number
}

export interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
  noSorting?: boolean
}

export const SubscriptionsList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [cancelSubscriptionModalID, setCancelSubscriptionModalID] = useState<
    number | undefined
  >(undefined)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>('id')

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const organizationData =
    useSelector(organizationProfileState)?.subscriptions || []
  const { cancelledSubscription } = useSelector(subscriptionOrderReducerState)

  useEffect(() => {
    if (cancelledSubscription?.id) {
      dispatch(sagaGetOrganizationProfileDataAction())
    }
  }, [cancelledSubscription, dispatch])

  const subscriptionData = organizationData?.map(
    (oData: iOrganizationSubscription) => ({
      id: oData.id,
      name: oData.packageKey,
      startAt: oData.startAt,
      endBillingCycle: oData.endAt,
      nextBillingDate: oData.nextBillingDate,
      billingType: oData.billingType,
      canceled: oData.canceled,
      actions: 'actions',
    })
  )

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - subscriptionData.length)
      : 0

  const onCancelSubscription = async (id: number | undefined) => {
    if (id) {
      dispatch(sagaCancelSubscriptionRenewal({ id }))
    }
  }
  const [isHovering, setIsHovering] = useState<number | undefined>(undefined)
  const cellStyle = { paddingRight: '34px' }
  const generateTableCell = useCallback(
    (
      cellContent: any,
      index: number,
      subscriptionDataItem: iOrganizationSubscription,
      className?: string,
      columnName?: string
    ) => {
      if (
        columnName === 'endBillingCycle' ||
        columnName === 'nextBillingDate'
      ) {
        return (
          <TableCell
            align="center"
            key={`table-cell-key-${index}`}
            className={className}
            style={cellStyle}
          >
            {cellContent ? (
              columnName === 'nextBillingDate' ? (
                <div>
                  {t('billing.subscriptions.table.automatic')}
                  <br />
                  {getFormattedDate(cellContent)}
                </div>
              ) : (
                getFormattedDate(cellContent)
              )
            ) : (
              t('general.na')
            )}
          </TableCell>
        )
      } else if (columnName === 'actions') {
        return (
          <TableCell
            align="center"
            key={`table-cell-key-${index}`}
            className={className}
          >
            {!!subscriptionDataItem.canceled ? (
              <div>{t('billing.subscriptions.cancelled.label')}</div>
            ) : (
              <TooltipWrapper
                title={t('billing.subscriptions.cancelled.tooltip')}
                placement="top-end"
              >
                <div
                  onClick={() =>
                    setCancelSubscriptionModalID(subscriptionDataItem.id)
                  }
                  onMouseOver={() => {
                    if (isHovering === undefined)
                      setIsHovering(subscriptionDataItem.id)
                  }}
                  onMouseOut={() => setIsHovering(undefined)}
                  onMouseLeave={() => setIsHovering(undefined)}
                  className="w-max m-auto cursor-pointer"
                >
                  {isHovering === subscriptionDataItem.id ? (
                    <susIcons.CancelRenewalIconHover />
                  ) : (
                    <susIcons.CancelRenewalIcon className="dark:fill-white" />
                  )}
                </div>
              </TooltipWrapper>
            )}
          </TableCell>
        )
      } else {
        const getColumnContent = () => {
          switch (columnName) {
            case 'name':
              return generateSubscriptionName(
                subscriptionDataItem.id,
                subscriptionDataItem.startAt
              )
            case 'billingType':
              return t(`billingPeriod.${cellContent}`)
            default:
              return cellContent ? cellContent.toString() : t('general.na')
          }
        }

        return (
          <TableCell
            align="center"
            key={`table-cell-key-${index}`}
            className={className}
            style={cellStyle}
          >
            {getColumnContent()}
          </TableCell>
        )
      }
    },
    [t, isHovering]
  )

  const generateRow = useCallback(
    (subscriptionDataItem: any, rowIndex: number) => {
      let result: JSX.Element[] = []
      const removeKeysFromObject = ['id', 'canceled', 'startAt']

      Object.keys(subscriptionDataItem).forEach(
        (key: string, index: number) => {
          if (!removeKeysFromObject.find((rm) => rm === key))
            result.push(
              generateTableCell(
                subscriptionDataItem[key],
                index,
                subscriptionDataItem,
                '',
                key
              )
            )
        }
      )

      return result
    },
    [generateTableCell]
  )

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: t('billing.subscriptions.table.name'),
    },
    {
      id: 'endBillingCycle',
      numeric: true,
      disablePadding: false,
      label: t('billing.subscriptions.table.endBillingCycle'),
    },
    {
      id: 'nextBillingDate',
      numeric: true,
      disablePadding: false,
      label: t('billing.subscriptions.table.nextBillingDate'),
    },
    {
      id: 'billingType',
      numeric: false,
      disablePadding: false,
      label: t('billing.subscriptions.table.billingType'),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: t('billing.subscriptions.table.actions'),
      noSorting: true,
    },
  ]

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler =
      (property: string) => (event: MouseEvent<unknown>) => {
        onRequestSort(event, property)
      }
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="center"
              className="font-bold "
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                hideSortIcon={headCell?.noSorting}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={
                  headCell?.noSorting
                    ? () => ''
                    : createSortHandler(headCell.id)
                }
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? t('general.sortedDsc')
                      : t('general.sortedAsc')}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  return (
    <div>
      {!subscriptionData.length ? (
        <div className="text-center pt-10 pb-10">
          <span className="max-lg:text-lg  max-md:text-base text-black dark:text-white mr-1">
            {t('billing.subscriptions.noSubscriptionsText')}
          </span>
          <susIcons.NoSubscriptionsImage className="m-auto min-h-[263px] max-md:min-h-[240px] pt-7" />
        </div>
      ) : (
        <div className="py-6">
          <PaperWrapper>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={subscriptionData.length}
              />

              <TableBody>
                {subscriptionData &&
                  stableSort(
                    subscriptionData as any[],
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((subscriptionDataItem: any, index: number) => (
                      <TableRow
                        key={subscriptionDataItem.id}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                        style={{
                          height: 50,
                        }}
                      >
                        {generateRow(subscriptionDataItem, index)}
                      </TableRow>
                    ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 50 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              labelRowsPerPage={t('dataHub.rowsPerPage') + ':'}
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              count={subscriptionData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </PaperWrapper>

          {/* MODAL START */}
          <ModalWrapper
            onClose={() => {
              setCancelSubscriptionModalID(undefined)
            }}
            open={!!cancelSubscriptionModalID}
            footerSection={
              <div className="space-x-2 pt-2">
                <ButtonWrapper
                  color={eColor.secondary}
                  onClick={() => {
                    onCancelSubscription(cancelSubscriptionModalID)
                    setCancelSubscriptionModalID(undefined)
                  }}
                  aria-label={t('general.yes')}
                >
                  {t('general.yes')}
                </ButtonWrapper>
                <ButtonWrapper
                  onClick={() => {
                    setCancelSubscriptionModalID(undefined)
                  }}
                  aria-label={t('general.no')}
                >
                  {t('general.no')}
                </ButtonWrapper>
              </div>
            }
          >
            <p className=" pb-2">
              {t('billing.subscriptions.cancelSubscriptionModal.message')}
            </p>
          </ModalWrapper>
          {/* MODAL END */}
        </div>
      )}
    </div>
  )
}
