import React, { useCallback } from 'react'

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import PaperWrapper from 'components/custom/PaperWrapper'
import OrganizationGroupIcon from 'assets/icons/organization-intro.svg'
import CustomSvgStyled from 'components/custom/CustomSvgStyled'
import TitleWrapper from 'components/custom/TitleWrapper'
import NewOrganizationModal from './NewOrganizationModal'
import { toggleCreateOrganizationModalVisibility } from 'store/slices/generalSettings'
import { useDispatch } from 'react-redux'

const OrganizationIntroPage: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const showCreateOrganizationPopup = useCallback(() => {
    dispatch(toggleCreateOrganizationModalVisibility())
  }, [dispatch])
  return (
    <>
      <TitleWrapper className="flex justify-center mr-[17vw] mt-12 mb-2 mx-2 max-lg:text-2xl max-md:text-xl">
        {t('organizationPage.startTitle')}
      </TitleWrapper>
      <div className="flex justify-center  mx-6 max-md:mx-2 mb-6 ">
        <PaperWrapper
          className={
            'max-w-max max-lg:p-[35px] max-xl:p-[50px_75px] p-[50px_150px]'
          }
          elevation={2}
        >
          <div className="flex justify-center">
            <CustomSvgStyled
              className="min-w-[300px] w-[375px] max-md:min-w-[240px] max-h-[300px]"
              src={OrganizationGroupIcon}
            />
          </div>
          <div className="flex justify-center">
            <div className="flex justify-center text-xl mt-8">
              <div>
                <span className="max-lg:text-lg  max-md:text-base text-black dark:text-white mr-1">
                  {t('organizationPage.registerOrganization')}
                </span>
                <span
                  className="max-lg:text-lg max-md:text-base pointer-events-auto underline cursor-pointer text-green-500"
                  onClick={showCreateOrganizationPopup}
                >
                  {t('organizationPage.registerOrganizationLink')}
                </span>
              </div>
            </div>
          </div>
        </PaperWrapper>
      </div>
      <NewOrganizationModal />
    </>
  )
}

export default OrganizationIntroPage
