import { PayloadAction } from '@reduxjs/toolkit'
import { eSeverity } from 'components/types'
import apiRequests from 'config/services'
import { call, put, takeLeading } from 'redux-saga/effects'
import { subscriptionOrderActionTypes } from 'saga/actions/subscriptionOrder'
import { setToastErrors } from 'store/slices/generalSettings'
import { setCancelSubscriptionRenewal } from 'store/slices/subscriptionOrderSlice'
import { eSnackbarLocation } from 'store/types'
import { iSubscriptionCancelRenewal } from 'store/types/subscriptionOrder'

function* cancelSubscriptionRenewalSaga(
  config: PayloadAction<iSubscriptionCancelRenewal>
) {
  try {
    yield call(apiRequests.cancelSubscriptionRenewal, config.payload)
    yield put(setCancelSubscriptionRenewal(config.payload))
  } catch (e) {
    //catchind errors
    yield put(
      setToastErrors({
        messages: [
          {
            code: 'cancel-subscription-error',
            message: 'error.message',
          },
        ],
        isMessageActive: true,
        severity: eSeverity.error,
        messageType: eSnackbarLocation.default,
      })
    )
  }
}

export default function* cancelSubscriptionRenewalSagaHook() {
  yield takeLeading(
    subscriptionOrderActionTypes.SAGA_CANCEL_SUBSCRIPTION_RENEWAL,
    cancelSubscriptionRenewalSaga
  )
}
