import { useCallback, useEffect, useMemo } from 'react'
import { iSearchParameters_Data } from 'pages/DataHub/filters/types'
import { useDispatch, useSelector } from 'react-redux'
import { dataHubReducerState } from 'store/slices/dataHubSlice'
import BasicTableWrapper from 'pages/DataHub/preferences/components/BasicTableWrapper'
import { sagaGetSearchParametersData } from 'saga/actions/dataHub'
import {
  searchParametersState,
  selectedSearchCategoryState,
  setSearchCategory,
} from 'store/slices/searchParametersSlice'
import { MenuItem } from '@mui/material'
import SelectWrapper from 'components/custom/SelectWrapper'
import { iHubFilter } from 'store/types'
import { eInputVariant, eTypographyVariant } from 'components/types'
import PaperWrapper from 'components/custom/PaperWrapper'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TitleWrapper from 'components/custom/TitleWrapper'
import { capitaliseEveryWordInString } from 'config/lib'

const SavedParameters = () => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()

  const searchParametersData: iSearchParameters_Data[] = useSelector(
    searchParametersState
  )
  const searchParametersCategory: string = useSelector(
    selectedSearchCategoryState
  )

  const datahubFilters: iHubFilter[] =
    useSelector(dataHubReducerState).hub.filters
  const dataHubCategories = useMemo(() => {
    const databaseIndex = datahubFilters.findIndex(
      (item) => item.name === 'database'
    )
    return datahubFilters[databaseIndex]?.values
  }, [datahubFilters])

  //when component is loaded
  useEffect(() => {
    dispatch(sagaGetSearchParametersData())
  }, [dispatch])

  // When searchParametersCategoryIsUpdated
  useEffect(() => {
    dispatch(sagaGetSearchParametersData())
    dispatch(setSearchCategory(searchParametersCategory))
  }, [dispatch, searchParametersCategory])
  const onCategoryChange = useCallback(
    (newValue: string) => {
      //change the context name in the url
      dispatch(setSearchCategory(newValue))
      setSearchParams({
        contextName: `.${newValue}` || '.',
      })
      //request data from api endpoint
    },
    [dispatch, setSearchParams]
  )
  // Check if user is on search params page coming from single entity page - START
  const uuid = searchParams.get('uuid')
  const uuidExists: boolean = (uuid && uuid.length > 0) || false
  return dataHubCategories ? (
    <PaperWrapper>
      <div className="row m-4">
        <div className="w-full m-0">
          <TitleWrapper
            variant={eTypographyVariant.h5}
            className="text-inherit text-2xl w-full -mt-2 mb-2"
          >
            {t('dataHub.searchParameters.settingsRules')}
          </TitleWrapper>
        </div>
        <SelectWrapper
          disabled={uuidExists}
          className="w-[13.125rem]"
          variant={eInputVariant.outlined}
          defaultValue={searchParametersCategory || ''}
          onChange={(newValue: string) => onCategoryChange(newValue)}
        >
          {dataHubCategories
            ? dataHubCategories.map((item: string, index: number) => {
                const categoryValue = item.toString().slice(1)
                return (
                  <MenuItem
                    key={index + 'TableCategory'}
                    value={categoryValue.toString() || ''}
                  >
                    {capitaliseEveryWordInString(categoryValue).toString() ||
                      ''}
                  </MenuItem>
                )
              })
            : null}
        </SelectWrapper>
      </div>

      <BasicTableWrapper
        title={'title'}
        rows={searchParametersData}
      ></BasicTableWrapper>
    </PaperWrapper>
  ) : (
    <></>
  )
}

export default SavedParameters
