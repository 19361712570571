import {
  iLooseObject,
  iSaveSearchParameters,
} from 'pages/DataHub/filters/types'
import { call } from 'redux-saga/effects'
import reqProvider from '../coreRequests'
import { getUserId } from '../lib'
import { BASE_API } from '../urlPaths'

export function* saveUserSearchParameters(
  userSettings: iSaveSearchParameters
): iLooseObject | undefined {
  const userId = getUserId()

  const path = `${BASE_API}/users/${userId}/search-settings`

  const data: any = yield call(
    reqProvider.postReq,
    path,
    undefined,
    userSettings
  )
  return data
}
