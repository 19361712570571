import { getUserId } from '../lib'
import reqProvider from '../coreRequests'
import { BASE_API } from '../urlPaths'
import { iUpdateStatusSearchParameter } from 'saga/sagas/dataHub/types'
import { iLooseObject } from 'pages/DataHub/filters/types'
import { booleanify } from 'config/lib'
import { call } from 'redux-saga/effects'

export function* getSearchParametersData(
  pageNumber: number = 1,
  category: string
): any {
  const userId = getUserId()
  const path = `${BASE_API}/users/${userId}/search-settings?page=${pageNumber.toString()}&category=${category}`

  const data: any = yield call(reqProvider.getReq, path)
  return data
}

export function* deleteSearchParametersData(searchParameterId: string): any {
  const userId = getUserId()
  const path = `${BASE_API}/users/${userId}/search-settings/${searchParameterId}`

  const data = yield call(reqProvider.deleteReq, path)
  return data
}
export function* UpdateSearchParameterStatusData(
  statusData: iUpdateStatusSearchParameter
): any {
  const userId = getUserId()
  const newStatusData: iLooseObject | any = statusData.id

  const path = `${BASE_API}/users/${userId}/search-settings/${newStatusData.value}`

  const body: iLooseObject = {
    isActive: booleanify(statusData.status),
  }

  const response: any = yield call(
    reqProvider.patchReq,
    path,
    { 'Content-type': 'application/merge-patch+json' },
    body
  )
  return response
}
