import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { iEntityCO2Data } from 'store/types'
// for parametered slices
// import type { PayloadAction } from '@reduxjs/toolkit'

export const initialState: iEntityCO2Data = {
  visible: false, // temporary
}

//get current reducer state
export const entityCO2visibilityState = (state: RootState) => state.entityCoTwo

//reducer Object
export const entityCO2DataSlice = createSlice({
  name: 'dataHubReducer',
  initialState,
  reducers: {
    setVisibility: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload || false
    },
  },
})

// Action creators are generated for each case reducer function
export const { setVisibility } = entityCO2DataSlice.actions

export default entityCO2DataSlice.reducer
