import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalWrapper from 'components/custom/ModalWrapper'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import CustomStringInput from 'components/custom/CustomStringInput'
import TitleWrapper from 'components/custom/TitleWrapper'
import { Form, Field } from 'react-final-form'
import { eButtonTypes, eButtonVariant, eColor } from 'components/types'
import { iOrganizationData } from 'store/types'
import { useDispatch, useSelector } from 'react-redux'
import { sagaSetOrganizationProfileDataAction } from 'saga/actions/user'
import useFieldsErrors from 'config/hooks/useFieldsErrors'
import { iLooseObject } from 'pages/DataHub/filters/types'
import {
  clearFieldsErrors,
  generalSettingsOrganizationState,
  toggleCreateOrganizationModalVisibility,
} from 'store/slices/generalSettings'
import CountrySelectAutoComplete from 'components/custom/CountrySelectAutoComplete'
const NewOrganizationModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [customErrors, setCustomErrors] = useState<iLooseObject>({})
  const isGlobalStateOrganizationModalOpen = useSelector(
    generalSettingsOrganizationState
  )

  const onSubmit = (formData: iOrganizationData) => {
    dispatch(sagaSetOrganizationProfileDataAction(formData))
  }

  const { errorFormFields } = useFieldsErrors()

  const generateRequiredLabel = useCallback(
    (placeholder: string) => `${placeholder} *`,
    []
  )

  const generateOptionalLabel = useCallback(
    (placeholder: string) => `${placeholder}`,
    []
  )
  const submitButton = useCallback(() => {
    setCustomErrors({})
  }, [])

  const onCloseModal = useCallback(() => {
    setCustomErrors({})
    dispatch(clearFieldsErrors())
    dispatch(toggleCreateOrganizationModalVisibility())
  }, [dispatch])

  const customValidate = useCallback(
    (values: any) => {
      const errors = customErrors
      return errors
    },
    [customErrors]
  )

  useEffect(() => {
    setCustomErrors(errorFormFields)
  }, [errorFormFields])
  return (
    <>
      <ModalWrapper
        onClose={onCloseModal}
        hasCloseButton={true}
        open={isGlobalStateOrganizationModalOpen.isOrganizationCreateModalOpen}
      >
        <div className="mx-6">
          <TitleWrapper>{t('organization.register.title')}</TitleWrapper>
          <div className="row mt-4">
            <Form
              validate={customValidate}
              onSubmit={onSubmit}
              render={({ handleSubmit, form, submitting }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="space-y-2 h-auto max-h-[60vh] md:max-h-[65vh] lg:max-h-[70vh] overflow-auto">
                      <Field name="name">
                        {({ input, meta }) => (
                          <>
                            <CustomStringInput
                              name="name"
                              className="w-full"
                              label={generateRequiredLabel(
                                t('organization.register.name')
                              )}
                              placeholder={t('general.example', {
                                example: 'MyOrganization GmbH',
                              })}
                              onChange={input.onChange}
                              onBlur={() => {
                                input.onBlur()
                              }}
                              error={{
                                active: meta.error,
                                text: meta.error,
                              }}
                            />
                          </>
                        )}
                      </Field>
                      <Field name="website">
                        {({ input, meta }) => (
                          <CustomStringInput
                            name="website"
                            className="w-full"
                            label={generateOptionalLabel(
                              t('organization.register.website')
                            )}
                            placeholder={t('general.example', {
                              example: 'www.abc.com',
                            })}
                            onChange={input.onChange}
                            onBlur={() => {
                              input.onBlur()
                            }}
                            error={{
                              active: meta.error,
                              text: meta.error,
                            }}
                          />
                        )}
                      </Field>
                      <Field name="address">
                        {({ input, meta }) => (
                          <CustomStringInput
                            name="address"
                            className="w-full"
                            label={generateRequiredLabel(
                              t('organization.register.street')
                            )}
                            placeholder={t('general.example', {
                              example: 'Konrad-Zuse-Platz 8',
                            })}
                            onChange={input.onChange}
                            onBlur={() => {
                              input.onBlur()
                            }}
                            error={{
                              active: meta.error,
                              text: meta.error,
                            }}
                          />
                        )}
                      </Field>
                      <Field name="city">
                        {({ input, meta }) => (
                          <CustomStringInput
                            name="city"
                            className="w-full"
                            label={generateRequiredLabel(
                              t('organization.register.city')
                            )}
                            placeholder={t('general.example', {
                              example: 'München',
                            })}
                            onChange={input.onChange}
                            onBlur={() => {
                              input.onBlur()
                            }}
                            error={{
                              active: meta.error,
                              text: meta.error,
                            }}
                          />
                        )}
                      </Field>
                      <Field name="postalCode">
                        {({ input, meta }) => (
                          <CustomStringInput
                            name="postalCode"
                            className="w-full"
                            label={generateRequiredLabel(
                              t('organization.register.postalCode')
                            )}
                            placeholder={t('general.example', {
                              example: '425622',
                            })}
                            onChange={input.onChange}
                            onBlur={() => {
                              input.onBlur()
                            }}
                            error={{
                              active: meta.error,
                              text: meta.error,
                            }}
                          />
                        )}
                      </Field>
                      <Field name="country">
                        {({ input, meta }) => (
                          <CountrySelectAutoComplete
                            label={generateRequiredLabel(
                              t('organization.register.country')
                            )}
                            onChange={input.onChange}
                            onBlur={() => {
                              input.onBlur()
                            }}
                            error={{
                              active: meta.error,
                              text: meta.error,
                            }}
                          />
                        )}
                      </Field>
                      <Field name="vatId">
                        {({ input, meta }) => (
                          <CustomStringInput
                            name="vatId"
                            className="w-full"
                            label={generateRequiredLabel(
                              t('organization.register.vatId')
                            )}
                            placeholder={t('general.example', {
                              example: 'TP 180536',
                            })}
                            onChange={input.onChange}
                            onBlur={() => {
                              input.onBlur()
                            }}
                            error={{
                              active: meta.error,
                              text: meta.error,
                            }}
                          />
                        )}
                      </Field>
                      <Field name="invoiceFirstName">
                        {({ input, meta }) => (
                          <CustomStringInput
                            name="invoiceFirstName"
                            className="w-full"
                            label={generateOptionalLabel(
                              t('organization.register.invoiceFirstName')
                            )}
                            placeholder={t('general.example', {
                              example: 'Anne',
                            })}
                            onChange={input.onChange}
                            onBlur={() => {
                              input.onBlur()
                            }}
                            error={{
                              active: meta.error,
                              text: meta.error,
                            }}
                          />
                        )}
                      </Field>
                      <Field name="invoiceLastName">
                        {({ input, meta }) => (
                          <CustomStringInput
                            name="invoiceLastName"
                            className="w-full"
                            label={generateOptionalLabel(
                              t('organization.register.invoiceLastName')
                            )}
                            placeholder={t('general.example', {
                              example: 'Murphy',
                            })}
                            onChange={input.onChange}
                            onBlur={() => {
                              input.onBlur()
                            }}
                            error={{
                              active: meta.error,
                              text: meta.error,
                            }}
                          />
                        )}
                      </Field>
                      <Field name="invoiceEmail">
                        {({ input, meta }) => (
                          <CustomStringInput
                            name="invoiceEmail"
                            className="w-full"
                            label={generateOptionalLabel(
                              t('organization.register.invoiceEmail')
                            )}
                            placeholder={t('general.example', {
                              example: 'anne.murpghy@mail.com',
                            })}
                            onChange={input.onChange}
                            onBlur={() => {
                              input.onBlur()
                            }}
                            error={{
                              active: meta.error,
                              text: meta.error,
                            }}
                          />
                        )}
                      </Field>
                    </div>

                    <div className="flex justify-end space-x-2 pt-2 mt-6">
                      <div onClick={() => form.reset}>
                        <ButtonWrapper
                          type={eButtonTypes.button}
                          color={eColor.secondary}
                          variant={eButtonVariant.contained}
                          onClick={() => {
                            onCloseModal()
                          }}
                          disabled={submitting}
                          aria-label={t('general.cancel')}
                        >
                          {t('general.cancel')}
                        </ButtonWrapper>
                      </div>
                      <ButtonWrapper
                        onClick={submitButton}
                        type={eButtonTypes.submit}
                        aria-label={t('organization.register.register')}
                      >
                        {t('organization.register.register')}
                      </ButtonWrapper>
                    </div>
                  </form>
                )
              }}
            />
          </div>
        </div>
      </ModalWrapper>
      <div></div>
    </>
  )
}

export default NewOrganizationModal
