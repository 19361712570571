import { eBillingPeriod } from 'components/types'
import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

interface billingPeriodSwitcherProps {
  billingPeriod: eBillingPeriod
  setBillingPeriod: Dispatch<SetStateAction<eBillingPeriod>>
}

export const BillingPeriodSwitcher: FC<billingPeriodSwitcherProps> = ({
  billingPeriod,
  setBillingPeriod,
}) => {
  const { t } = useTranslation()
  return (
    <div className={`w-full flex justify-center my-2`}>
      <div
        className={`
          relative items-center flex flex-row flex-nowrap justify-evenly py-2 
          bg-[#55BC00] max-w-fit rounded-full bg-opacity-70
          shadow-md
        `}
      >
        <div
          className={`
            text-xs z-20 w-16 mx-2 text-clip text-center cursor-pointer
            transform transition-all
            ${
              billingPeriod === eBillingPeriod.YEARLY
                ? `text-[#55BC00] font-bold`
                : ``
            }
          `}
          onClick={() => setBillingPeriod(eBillingPeriod.YEARLY)}
        >
          {t(`billingPeriod.${eBillingPeriod.YEARLY}`)}
        </div>
        <div
          className={`
            text-xs z-20 w-16 mx-2 text-clip text-center cursor-pointer
            transform transition-all
            ${
              billingPeriod === eBillingPeriod.MONTHLY
                ? `text-[#55BC00] font-bold`
                : ``
            }
          `}
          onClick={() => setBillingPeriod(eBillingPeriod.MONTHLY)}
        >
          {t(`billingPeriod.${eBillingPeriod.MONTHLY}`)}
        </div>
        <div
          className={`z-10 absolute h-4/5 top-0.7 
          bg-white rounded-full
          bg-clip-background
          transform transition-transform
          right-1 shadow
          ${billingPeriod === eBillingPeriod.YEARLY ? '-translate-x-20' : ''}
        `}
        >
          <div className="w-16 mx-1"></div>
        </div>
      </div>
    </div>
  )
}
