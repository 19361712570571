import { call, put, select, takeLeading } from 'redux-saga/effects'
import apiRequests from 'config/services'
import { sagaGetOrganizationTeamMembersAction, userTypes } from 'saga/actions/user'
import { PayloadAction } from '@reduxjs/toolkit'
import { iLooseObject } from 'pages/DataHub/filters/types'
import { organizationProfileState } from 'store/slices/organizationProfile'
import { clearLocal } from 'config/lib'

export function* updateOrganizationMemberSaga(
  action: PayloadAction<iLooseObject>
) {
  try {
    yield call(apiRequests.updateOrganizationMember, action.payload)
    // update the state with the new data
    const orgData: iLooseObject = yield select(organizationProfileState)
    yield put(sagaGetOrganizationTeamMembersAction(parseInt(orgData?.id)))
    if(!!action.payload?.clearCache){
      clearLocal('jwt')
      window.location.reload()
    }

  } catch (e) {
    //catchind errors
  }
}

export default function* updateOrganizationMemberHook() {
  yield takeLeading(userTypes.SAGA_UPDATE_MEMBER, updateOrganizationMemberSaga)
}
