import ExcelJS from 'exceljs';

/**
 * The delivery shape input parameter type literal
 */
export enum eDeliveryShape { 
  Raw = 'Raw',
  Sheet = 'Sheet',
  Hollow  = 'Hollow Profile',
  Solid  = 'Solid Profile'
}

/**
 * The reference type input parameter type literal
 */
export enum eReferenceType {
  Common = 'Common',
  Median = 'Median',
  Min = 'Min',
  Max = 'Max'
}

/**
 * The context collection contains the actual row data, grouped
 * by context
 */
export type tContextCollection = {
  [context: string]: unknown[]
}

/**
 * The context item (row inside a worksheet)
 */
export interface iContextItem {

  /** The row number of the Excel worksheet */
  rowNumber: number,

  /** The search terms used for */
  terms: string|string[]

  /** Whether the material is recycled or not */
  isRecycled: boolean,

  /** Which country to apply the country correction for */
  country: string
}

/**
 * The metal row items
 */
export interface iContextMetal extends iContextItem {

  /** Which shape the material is deliverd in */
  deliveryShapes: eDeliveryShape,

  /** The statistical type of CO2 value is requested */
  referenceType: eReferenceType
}

/**
 * The polymer row items
 */
export interface iContextPolymer extends iContextItem {

  /** The statistical type of CO2 value is requested */
  referenceType: eReferenceType
}

/**
 * The file collection contains the data as context from the uploaded
 * sheet, grouped by file
 */
export type tFileContextCollection = {
  [fileName: string]: tContextCollection
}

/**
 * A collection of actual workbook objects with their filenames as keys
 */
export type tExcelWorkbookCollection = {
  [fileName: string]: ExcelJS.Workbook
}

/**
 * A log item for the file processing log
 */
export interface iProcessingError {
  fileName: string,
  rowNumber: number,
  error: string
}

/**
 * The results coming back from the server after searching the single
 * entity search API, using the context item
 */
export interface iContextRequestResult {
  co2Emission: unknown,
  description: string
}

/**
 * The state interface for the store slice of mass file upload
 */
export interface iMassFileUploadState {
  /** handling component error state */
  componentError?: string,

  /* Status indicators */
  isFilesAvailable: boolean,
  isProcessingFiles: boolean,
  isProcessingFilesCancelled: boolean,
  isProcessingFilesDone: boolean,
  currentFileProcessed?: string,
  totalNumberOfRowsInCurrentFile: number,
  numberOfRowsProcessedInCurrentFile: number,
  currentContextProcessed?: string,
  totalNumberOfRows: number,
  numberOfRowsProcessed: number,
  
  /* The actual Data */
  uploadedFiles: File[],
  fileInputCollection: tFileContextCollection,
  fileOutputCollection: tFileContextCollection,
  errors: iProcessingError[],
  processedInputFileNames: string[],
  processedWorkbooks: tExcelWorkbookCollection
}