export enum RoutePaths {
  login = '/login',
  logout = '/logout',
  accessForbidden = '/403',
  memberAccessForbidden = '/403-member',
  serverError = '/500',
  notFound = '/*',
  dashboard = '/',
  dataHub = '/data-hub',
  massUpload = '/data-hub/tools/mass-upload',
  dataHubSearchParams = '/data-hub/search-params',
  dataHubChildren = '/data-hub/:category',
  dataHubCbam = '/data-hub/cbam',
  singleEntity = '/entity/:id',
  automationHub = '/management/automation-hub',
  organizationManagement = '/management/organization-management',
  subscriptionManagement = '/management/subscription-management',
  dataCenter = '/tools/data-center',
  simulationCenter = '/tools/simulation-center',
  scope12Calculator = '/tools/scope-12-calculator',
  procurementCenter = '/tools/procurement-center',
  knowledgeHub = '/knowledge-hub',
  knowledgeHubDatabaseOverview = '/knowledge-hub/database-overview',
  knowledgeHubCalculationVariants = '/knowledge-hub/calculation-variants',
  knowledgeHubDataQualityAssurance = '/knowledge-hub/data-quality-assurance',
  knowledgeHubElectronics = '/knowledge-hub/electronics',
  knowledgeHubEnergy = '/knowledge-hub/energy',
  knowledgeHubConsumerProducts = '/knowledge-hub/consumer-products',
  knowledgeHubLogistics = '/knowledge-hub/logistics',
  knowledgeHubMaterials = '/knowledge-hub/materials',
  knowledgeHubProduction = '/knowledge-hub/production',
  helpSupport = '/help/support',
  upgrade = '/upgrade',
  organizationProfile = '/user/organization-profile',
  userSettings = '/user/settings',
  comingSoon = '/coming-soon',
  comingSoonId = '/coming-soon/:id',
  termsAndConditions = '/terms-and-conditions',
  privacyPolicy = '/privacy-policy',
  imprint = '/imprint',
  billing = '/user/billing',
  dataAsServiceAggrement = '/data-as-service-aggrement'
}
