import { call, put, takeLeading } from 'redux-saga/effects'
import apiRequests from 'config/services'
import { dataHubTypes, sagaGetSearchParametersData } from 'saga/actions/dataHub'
import { PayloadAction } from '@reduxjs/toolkit'

export function* deleteSearchParameterDataSaga(action: PayloadAction<string>) {
  try {
    const searchParameterId: string = action.payload

    yield call(apiRequests.deleteSearchParametersData, searchParameterId)
    yield put(sagaGetSearchParametersData())

    // todo: update the store with the new data (new search parameters request)
    // yield put(setSearchParametersData(result))
  } catch (e) {
        //catchind errors
  }
}

export default function* deleteSearchParametersDataHook() {
  yield takeLeading(
    dataHubTypes.SAGA_DELETE_SEARCH_PARAMETER,
    deleteSearchParameterDataSaga
  )
}
