import React from 'react'
import { ContentBody } from 'components/pages/components/ContentBody'
import LinkWrapper from './LinkWrapper'
import PaperWrapper from './PaperWrapper'
import TextWrapper from './TextWrapper'
import TitleWrapper from './TitleWrapper'
import CustomSvgStyled from './CustomSvgStyled'
import { eElementOnMouseOverEffect } from 'components/types'

interface iProps {
  title?: string
  svgSrc: string
  description?: string | JSX.Element | JSX.Element[]
  button?: JSX.Element
  buttonText?: string
  linkTo?: string
}

const InfoPageContent = ({
  title,
  svgSrc,
  description = '',
  button,
  linkTo,
}: iProps) => {
  return (
    <ContentBody className="h-screen overflow-x-auto">
      <PaperWrapper className="p-4 flex flex-col text-center justify-center sm:w-full md:w-2/3 xl:w-1/2 mx-auto space-y-4">
        {title && (
          <TitleWrapper className="max-lg:text-xl max-md:text-lg">
            {title}
          </TitleWrapper>
        )}
        {svgSrc && (
          <CustomSvgStyled
            invertedColor={true}
            src={svgSrc}
            className="w-2/3 self-center h-full"
          />
        )}
        {description && (
          <TextWrapper className="mb-6 -mt-2">{description}</TextWrapper>
        )}
        {linkTo && button && (
          <LinkWrapper
            to={linkTo}
            underline={eElementOnMouseOverEffect.none}
            className="w-fit h-fit mx-auto mb-6">
            {button}
          </LinkWrapper>
        )}
      </PaperWrapper>
    </ContentBody>
  )
}

export default InfoPageContent
