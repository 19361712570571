

import { IAccordionData } from '../components/DynamicAccordion'
import { getImage, makeBulttePointsList } from './helpers'


export const ProductionDocumentData: IAccordionData[] = [
  {
    title: 'PRODUCTION',
    content: `The production database contains three essential databases: manufacturing processes, equipment and employees.
    <br /> <br />

    As part of a detailed CO2e footprint analysis, the process chains are represented as precisely as possible and thus the process is modeled with the necessary machines, the deployment of personnel and the process times. In the case of smaller or subordinate assemblies, components or systems, however, this can sometimes lead to considerable effort. Consequently, it makes sense to use references for the manufacturing processes in these cases.
    <br /> <br />
    This database also includes values for the emissions related to employee activity. Different industries as well as different scopes and areas of emissions can be found here.
    <br /> <br />
    The equipment database contains a large number of entries for the respective manufacturing processes and production methods. Since the share of emissions of these machines’ and systems’ manufacture generally represents a negligible value in the overall Product Carbon Footprint balance, consideration (see PAS 2020, ISO 14040) is not necessary if these values are individually below a threshold of 1% of total emissions and the deviation as a whole does not sum up to more than 5%. Please note that different frameworks pertain different rules regarding cut-off criteria. Therefore, before dismissing these emissions
    <br /> <br />
    please make sure that you are acting according to the regulations of your project. So that at least indicative values can be used, reference values per machine (accuracy class 3) are specified for use in sustamize’s database. The values are calculated based on the size of the machine and system. <br />`,
    isOpen: true,
    children: [
      {
        title: 'MANUFACTURING PROCESS',
        content: `As part of a detailed CO2 footprint analysis, the process chains are represented as precisely as possible and thus the process is modeled with the necessary machines, the deployment of personnel and the process times. In the case of smaller or subordinate assemblies, components or systems, however, this can sometimes lead to considerable effort. Consequently, it makes sense that references for manufacturing processes are used in these cases.
        <br /> <br />
        sustamize has calculated the typical energy values of more than 400 manufacturing technologies depending on component sizes and/ or weight as well as other relevant factors and gathered them in a database.`,
        isOpen: true,
      },
      {
        title: 'EQUIPMENT',
        content: `The machine and equipment database contains a large number of entries for the respective manufacturing processes and production methods. Since the share of emissions of manufacture of these machines and systems generally represents a negligible value in the overall balance, consideration (see PAS 2020, ISO 14040) is not necessary, if these values are individually below 1% of the total emissions and the deviation as a whole does not sum up to more than 5%. So that at least indicative values can be used, reference values per machine (accuracy class 3) are specified for use in the sustamize database. Other frameworks pertain other rules and cut-off criteria, therefore before dismissing these emissions please make sure that you are acting according to the regulations of your project. The values are calculated according to machine and system size. The value calculation is based on a generic production in Germany. As the production chain of machines are rather complex, a recalculation according to different countries’ electricity mixes for this generic level would currently not increase the validity of the database.`,
        isOpen: false,
      },
      {
        title: 'EMPLOYEES',
        content: `It can often be useful to know the average CO2e footprint of an average employee (including elements such as heating, electricity consumption, travel, etc.), either because this is the main source of emissions, or to think about how to allocate overheads from business areas not directly linked to production to individual products. For this reason, sustamize has created a database containing examples of employee-related emissions. There are different areas of work (such as production or banking) as well as different sources of emissions (such as heating, electricity, etc.) and a few examples from different countries. The information contained is taken directly from these sources and does not currently include any recalculations linked to the various countries.`,
        isOpen: false,
      },
    ],
  },
]
