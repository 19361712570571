import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import {
  iProductOverview,
  iSubscriptionCancelRenewal,
  iSubscriptionOrder,
  iSubscriptionOrderCalculation,
  iSubscriptionOrderState,
} from 'store/types/subscriptionOrder'


const initialState: iSubscriptionOrderState = {
  productOverview: undefined,
  subscriptionOrder: undefined,
  subscriptionOrderCalculation: undefined,
  cancelledSubscription: { id: -1 },
  isLoadingNewCalculation: false,
  isLoadingSubmitSubscription: false,
  subscriptionSubmitResponseCode: undefined,
  subscriptionSubmitError: {}
}

export const subscriptionOrderReducerState = (state: RootState) =>
  state.subscriptionOrder

export const subscriptionOrderReducer = createSlice({
  name: 'subscriptionOrderReducer',
  initialState,
  reducers: {
    resetState: (state) => {
      Object.keys(state).forEach(key => {
        (state as any)[key] = (initialState as any)[key]
      })
    },
    setProductOverview: (state, payload: PayloadAction<iProductOverview>) => {
      state.productOverview = payload.payload
    },
    setSubscriptionOrder: (
      state,
      payload: PayloadAction<iSubscriptionOrder>
    ) => {
      state.subscriptionOrder = payload.payload
    },
    setSubscriptionOrderCalculation: (
      state,
      payload: PayloadAction<iSubscriptionOrderCalculation>
    ) => {
      state.subscriptionOrderCalculation = payload.payload
      state.isLoadingNewCalculation = false
    },
    setCancelSubscriptionRenewal: (
      state,
      payload: PayloadAction<iSubscriptionCancelRenewal>
    ) => {
      state.cancelledSubscription = payload.payload
    },
    startLoadingNewCalculation: (state) => {
      state.isLoadingNewCalculation = true
    },
    toggleLoadingSubmitSubscription: (state) => {
      state.isLoadingSubmitSubscription 
        = !state.isLoadingSubmitSubscription
    },
    setSubscriptionSubmitStatus: (state, payload: PayloadAction<{status: number, response: any}>) => {
      state.subscriptionSubmitResponseCode = payload.payload.status
      state.subscriptionSubmitError = payload.payload.response
    }
  }
})

export const {
  resetState,
  setProductOverview,
  setSubscriptionOrder,
  setSubscriptionOrderCalculation,
  setCancelSubscriptionRenewal,
  startLoadingNewCalculation,
  toggleLoadingSubmitSubscription,
  setSubscriptionSubmitStatus,
} = subscriptionOrderReducer.actions

export default subscriptionOrderReducer.reducer
