import React, { FC } from 'react'
import { componentNames } from '../config/roles'
import { Trans, useTranslation } from 'react-i18next'
import { componentPermissions } from 'config/permissions'
import { RoutePaths } from 'routes/paths'
import Group35113Image from 'assets/images/Group-35113.svg'
import BackArrow from 'assets/icons/back-arrow.svg'
import CustomSvgStyled from 'components/custom/CustomSvgStyled'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import InfoPageContent from 'components/custom/InfoPageContent'
import { eButtonVariant } from 'components/types'
import { Link, useLocation } from 'react-router-dom'

export const askYourAdminData=  {
  state: {
    title: 'offerings.generic.errors.403.title',
    description:'offerings.generic.errors.403.description',
    link: "https://share-eu1.hsforms.com/1sJ_ORfUpSdycMzhu2lS-WQfaf1j"
  },
}

const Member403: FC = () => {
  const { t } = useTranslation()
  const state = askYourAdminData.state

  return (
    <InfoPageContent
      title={t(state?.title) || t('403.titlea')}
      svgSrc={Group35113Image}
      button={
        <ButtonWrapper variant={eButtonVariant.outlined}>
          <CustomSvgStyled
            invertedColor={true}
            src={BackArrow}
            className="mr-2"
            size={{ height: 20, width: 20, unit: 'px' }}
          />
          {t('general.backToHomepage')}
        </ButtonWrapper>
      }
      description={
        state?.description ? (
          <Trans
            i18nKey={state?.description}
            components={{
              a: <Link to={state?.link} target="_blank" />,
              span: <span className={`text-primary underline`} />,
            }}
          />
        ) : (
          t('403.description')
        )
      }
      linkTo="/"
    />
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.memberAccessForbidden,
    title: '403.title',
    element: <Member403 />,
    roles: componentPermissions[componentNames.page403],
    requiresAuthentication: false,
  },
  name: 'Custom403',
}
export default exportObj
