import TextWrapper from 'components/custom/TextWrapper'
import { susIcons } from 'config/services/requests'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

export const EmptyData = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col justify-center items-center mt-5 h-[200px] w-100">
      <TextWrapper className="text-2xl max-lg:text-xl max-md:text-lg">
        {t('search.noResults')}
      </TextWrapper>
      <susIcons.DataPoints className="max-lg:w-[95px] max-lg:h-[95px] w-[125px] h-[125px]" />
    </div>
  )
}
