import {  put, takeLeading } from "redux-saga/effects";
import { massFileUploadActionTypes } from "saga/actions/massFileUpload";
import { addInputFiles } from "store/slices/massFileUploadSlice";

function* addMassUploadInputFilesSaga(payload: any) {
  yield put(addInputFiles(payload.payload));
}

export default function* addMassUploadInputFilesSagaHook() {
  yield takeLeading(
    massFileUploadActionTypes.SAGA_ADD_INPUT_FILES,
    addMassUploadInputFilesSaga
  );
}