import { susIcons } from "config/services/requests"
import { FC, useState, useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"

interface iPackageOfferingFeaturesProps {
  features: string,
  descriptionHeading?: string
  description?: string,
  isMultipleColumns?: boolean
}

export const PackageOfferingFeatures: FC<iPackageOfferingFeaturesProps> = ({
  features,
  descriptionHeading,
  description,
  isMultipleColumns = false
}) => {

  const [_features, setFeatures] = useState<string[]>([])
  const { t } = useTranslation()
  const Checkmark = susIcons.Checkmark;

  const renderFeature = (i: number, i18nKey: string) => {
    return (
      <div className={`
        group/feature 
        relative flex lg:flex-row 
        ${isMultipleColumns ? ' flex-row w-1/2 justify-start ' : ' w-full flex-col '}
      `} key={i}>
        <div className={`
          flex flex-row items-center justify-stretch w-full
          ml-1 mr-2 gap-2
        `}>
          <div>
            <Checkmark className="w-3 h-3" />
          </div>
          <div className={`
            flex-grow w-full text-xs py-2 
            ${!isMultipleColumns ? ' lg:border-b border-b-gray-300 dark:border-b-gray-700 group-last/feature:border-0 ': ''}
          `}>
            <Trans i18nKey={i18nKey} />
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    const numOfFeaturesGuess = Array.from(Array(30).keys())
    const featuresSet: string[] = [];

    const isEndsWithGuess = (key: string): boolean => {
      const str = t(key)
      return /\d+$/.test(str)
    }

    numOfFeaturesGuess.forEach(i => {
      
      const i18nKey = `${features}.${i}`
      const endsWithGuess = isEndsWithGuess(i18nKey)
      if (endsWithGuess) return null

      featuresSet.push(i18nKey)
    })

    setFeatures([])
    setFeatures(featuresSet)

  }, [features, t])

  return (
    <div className={`w-full`}>

      {descriptionHeading && t(descriptionHeading) !== "" && 
        <div className={`
          ${_features.length === 0 ? 'text-xl my-6' : 'mb-2 pt-3 font-bold text-xs'}
          text-center
        `}>
          {t(descriptionHeading)}
        </div>
      }
      {description && t(description) !== '' && 
        <div className={`text-xs pt-2 mb-3 pl-1.5 w-full text-center`}>
          {description && t(description)}
        </div>
      }

      <div className={`flex ${isMultipleColumns ? ' flex-row flex-wrap ' : ' flex-col '} lg:flex-row lg:flex-wrap flex-grow-0 flex-shrink basis-auto`}>
        {_features.map((f, i) => renderFeature(i, f))}
      </div>
    </div>
  )
}