import { call, put, select, takeLeading } from 'redux-saga/effects'
import apiRequests from 'config/services'
import {
  sagaGetOrganizationTeamMembersAction,
  userTypes,
} from 'saga/actions/user'
import { PayloadAction } from '@reduxjs/toolkit'
import { organizationProfileState } from 'store/slices/organizationProfile'
import { iLooseObject } from 'pages/DataHub/filters/types'

export function* activateTeamMemberSaga(action: PayloadAction<number>) {
  try {
    const orgData: iLooseObject = yield select(organizationProfileState)
    const teamMemberId = action.payload
    yield call(apiRequests.activateDeactivateTeamMember, teamMemberId)
    // update the state with the new data
    yield put(sagaGetOrganizationTeamMembersAction(parseInt(orgData?.id)))
  } catch (e) {
    //catchind errors
  }
}

export default function* activateTeamMemberSagaHook() {
  yield takeLeading(userTypes.SAGA_ACTIVATE_TEAM_MEMBER, activateTeamMemberSaga)
}
