import type { Row } from 'components/pages/api-management/types'

export const apiData: Row[][] = [
  [
    { value: 'configuredClients.name' },
    { value: 'configuredClients.ip' },
    { value: 'configuredClients.systemline' },
    { value: 'configuredClients.remainingCredits' },
    { value: 'configuredClients.created' },
  ],
  [
    { value: 'Madrid Client' },
    { value: 'madrid.some-client.api' },
    { value: 'prod' },
    { value: 4_829 },
    { value: '2022/04/02 14:32:15' },
  ],
  [
    { value: 'USA Client' },
    { value: 'los-angeles.another-client.api' },
    { value: 'prod' },
    { value: 98_381 },
    { value: '2022/02/12 18:27:45' },
  ],
  [
    { value: 'Test' },
    { value: 'test.test-client.api' },
    { value: 'test' },
    { value: 3_247 },
    { value: '2020/07/14 08:15:11' },
  ],
  [
    { value: 'QA System' },
    { value: 'qa-box.quality-client.api' },
    { value: 'int' },
    { value: 2_938 },
    { value: '2021/12/08 06:55:29' },
  ],
  [
    { value: 'Simulation Box' },
    { value: 'sim.sims-client.api' },
    { value: 'test' },
    { value: 492 },
    { value: '2021/10/24 17:33:10' },
  ],
]
