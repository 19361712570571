import { ClickAwayListener, InputBase, Paper } from '@mui/material'
import React, { useState, useCallback, KeyboardEvent, useEffect } from 'react'
import SearchIcon from '@mui/icons-material/SearchRounded'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import './style.css'

interface IProps {
  label: string
  placeholderProp?: string
  onSearch?: (value?: string) => void
  className?: string
  customValue?: string
  // showPlaceholderTips?: boolean
}

const SearchBar = ({
  label,
  // showPlaceholderTips = false,
  placeholderProp,
  onSearch,
  className,
  customValue,
}: IProps) => {
  const [value, setValue] = useState<string>('')
  const [inputFocus, setInputFocus] = useState(false)

  useEffect(() => setValue(customValue || ''), [customValue])

  // this code is for self-typing placeholder animation
  // TODO: to be decided if still needed
  // const { t } = useTranslation()
  // const [placeholder, setPlaceholder] = useState(placeholderProp)
  // const [placeholderToShow, setPlaceholderToShow] = useState('')
  // const [showSearchPlaceholder, setShowSearchPlaceholder] = useState(true)
  // const typingDelayMiliseconds = 220
  // const timeToDisplayPlaceholderMiliseconds = 6_000

  // const searchTerms: string[] = useMemo(
  //   () => (typeof placeholderProp === 'object' ? placeholderProp : []),
  //   [placeholderProp]
  // )

  // let [searchTermVariantId, setSearchTermVariantId] = useState(0)

  // const generateRandomVariantId = useCallback(() => {
  //   const min = 0
  //   const max = searchTerms.length
  //   let newVariantId = searchTermVariantId

  //   // generate a different number every time
  //   do {
  //     // 👇️ get number between min (inclusive) and max (exclusive)
  //     newVariantId = Math.floor(Math.random() * (max - min)) + min
  //   } while (newVariantId === searchTermVariantId)
  //   setSearchTermVariantId(newVariantId)
  // }, [searchTerms.length, searchTermVariantId])

  // const resetPlaceholder = useCallback(() => {
  //   setPlaceholder('')
  //   setPlaceholderIndex(0)
  // }, [])

  // const generatePlaceholder = useCallback((): string => {
  //   resetPlaceholder()
  //   generateRandomVariantId()
  //   return `${t('general.search')} ${searchTerms[searchTermVariantId]}`
  // }, [
  //   resetPlaceholder,
  //   generateRandomVariantId,
  //   t,
  //   searchTerms,
  //   searchTermVariantId,
  // ])
  // ^^^^ this code is for self-typing placeholder animation

  const updateValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value)
  }, [])

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.key === 'Enter') if (onSearch) onSearch(value)
    },
    [onSearch, value]
  )

  // const toggleDisplayRandomPlaceholderAnimation = useCallback(
  //   (e: React.FocusEvent<HTMLInputElement>) => {
  //     const isFocused = e.type === 'focus'
  //     const isFocusedOut = e.type === 'blur'

  //     // setOuterFocus(!isFocused)

  //     setShowSearchPlaceholder(!isFocused)
  //     if (isFocused) resetPlaceholder()

  //     if (isFocusedOut && !showPlaceholderTips) {
  //       if (typeof placeholderProp === 'string') setPlaceholder(placeholderProp)
  //     }
  //   },
  //   [placeholderProp, resetPlaceholder, showPlaceholderTips]
  // )

  // const [placeholderIndex, setPlaceholderIndex] = useState(0)

  // const typingPlaceholder: any = useCallback(() => {
  //   setPlaceholder(placeholderToShow.slice(0, placeholderIndex))

  //   const finishedTyping = placeholderIndex === placeholderToShow.length
  //   if (finishedTyping) {
  //     if (placeholderToShow.length > 0) {
  //       setTimeout(() => {
  //         setPlaceholderToShow(generatePlaceholder())
  //       }, timeToDisplayPlaceholderMiliseconds)
  //     } else {
  //       setPlaceholderToShow(generatePlaceholder())
  //     }
  //   } else setPlaceholderIndex(placeholderIndex + 1)
  // }, [placeholderIndex, placeholderToShow, generatePlaceholder])

  // useEffect(() => {
  //   if (showPlaceholderTips) {
  //     // show when input is empty and not in focus
  //     if (value.length === 0 && showSearchPlaceholder) {
  //       const intr = setTimeout(typingPlaceholder, typingDelayMiliseconds)
  //       return () => {
  //         clearInterval(intr)
  //       }
  //     }
  //   }
  // }, [
  //   value,
  //   showSearchPlaceholder,
  //   typingDelayMiliseconds,
  //   typingPlaceholder,
  //   showPlaceholderTips,
  // ])

  // useEffect(() => {
  //   if (showPlaceholderTips) {
  //     i18next.on('languageChanged', () => {
  //       setPlaceholderToShow('')
  //       resetPlaceholder()
  //     })
  //   }
  // }, [resetPlaceholder, showPlaceholderTips])

  const handleClickAway = () => {
    if (value.length === 0) setInputFocus(false)
  }

  return (
    <Paper
      className={`${className} mt-2
      rounded-[10px]
      sm:min-w-[150px]
      max-w-sm
      flex`}
      elevation={2}
      onFocus={() => {
        setInputFocus(true)
      }}
      data-testid="SearchBar-testId">
      {inputFocus && (
        <div
          onClick={() => {
            setInputFocus(false)
            setValue('')
          }}
          className="ArrowBackIconWrapper">
          <ArrowBackIcon color={`primary`} className="ml-2 mr-1 self-center" />
        </div>
      )}
      {!inputFocus && (
        <SearchIcon color={`primary`} className="ml-2 mr-1 self-center" />
      )}
      <ClickAwayListener onClickAway={handleClickAway}>
        <InputBase
          placeholder={placeholderProp}
          value={value}
          onChange={updateValue}
          onKeyDown={onKeyDown}
          className="flex-auto border-0"
          inputProps={{
            'aria-label': label,
          }}
        />
      </ClickAwayListener>
      {inputFocus && (
        <div
          onClick={() => {
            if (onSearch) onSearch(value)
          }}
          className="SearchIconWrapper">
          <SearchIcon color={`primary`} className="ml-1 mr-2 self-center" />
        </div>
      )}
    </Paper>
  )
}

export default SearchBar
