import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { RoutePaths } from 'routes/paths'
import { userReducerState } from 'store/slices/user'
import { eLocale, userState } from 'store/types'
import { TermsAndConditionsDocumentEnglish } from './components/TermsAndConditionsDocumentEnglish'
import { TermsAndConditionsDocumentGerman } from './components/TermsAndConditionsDocumentGerman'

const TermsAndConditions: FC = () => {
  const userInfo: userState = useSelector(userReducerState)

  switch (userInfo.settings.locale) {
    case eLocale.German:
      return <TermsAndConditionsDocumentGerman />
    default:
      return <TermsAndConditionsDocumentEnglish />
  }
}
const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.termsAndConditions,
    title: 'footer.TermsAndConditions',
    element: <TermsAndConditions />,
    roles: componentPermissions[componentNames.termsAndConditions],
    requiresAuthentication: true,
  },
  name: 'TermsAndConditions',
}
export default exportObj
