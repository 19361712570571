import React, { FC, useCallback, useEffect, useState } from 'react'
import { Alert, FormControl } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { authCheck } from 'config/services/requests/coreRequests'
import { RoutePaths } from 'routes/paths'
import { assets } from 'config/services/requests'
import FullHero from 'components/custom/FullHero/FullHero'
import LanguageSwitcher from 'components/custom/LanguageSwitcher'
import PaperWrapper from 'components/custom/PaperWrapper'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import { eButtonVariant } from 'components/types'
import { useSearchParams } from 'react-router-dom'

const Login: FC = () => {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState('')

  const [searchParams] = useSearchParams()
  const errorCode = searchParams.get('errorCode')

  const handleLogin = useCallback(async () => {
    authCheck()
  }, [])

  useEffect(() => {
    if(errorCode){
      const errorId = `request.response.${errorCode}.message`
      if (errorId === t(errorId)) {
        setErrorMessage(`request.response.loginErrorGeneral.message`)
      }else{
        setErrorMessage(errorId)
      }
    }
  }, [errorCode])

  return (
    <>
      <FullHero>
        <PaperWrapper
          className={`p-12 flex flex-col`}
          elevation={2}
          sx={{
            padding: `40px`,
          }}
        >
          <img
            src={assets.logo}
            width={300}
            height={50}
            alt={`sustamize logo`}
          />
          <p className={`text-xs text-center py-4 max-w-[300px]`}>
            {t('login.description')}
          </p>
          <FormControl>
            <ButtonWrapper
              variant={eButtonVariant.contained}
              onClick={handleLogin}
            >
              {t('login.loginButton')}
            </ButtonWrapper>
          </FormControl>
          <div className="mt-4 flex justify-center text-xs">
            <span className="text-gray-500 mr-1"> {t('login.poweredBy')}</span>
            <img
              className='max-w-[100px] h-[100%] w-[100%]'
              src={assets.sustamizeLogo}
              alt={`sustamize logo`}
            />
          </div>
        </PaperWrapper>

        {errorMessage ? (
          <div
            className={`fixed top-0 right-0 ml-auto mr-auto left-0 right-0 w-max  p-3`}
          >
            <Alert
              severity="error"
              color="error"
              onClose={() => setErrorMessage('')}
            >
              {t(errorMessage)}
            </Alert>
          </div>
        ) : (
          <></>
        )}

        <PaperWrapper
          className={`fixed bottom-0 right-0 m-6 p-3`}
          elevation={2}
        >
          <LanguageSwitcher />
        </PaperWrapper>
      </FullHero>
    </>
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.login,
    title: 'login.title',
    element: <Login />,
    roles: [],
    requiresAuthentication: false,
  },
  name: 'Login',
}
export default exportObj
