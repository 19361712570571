import { call, put, takeLatest } from 'redux-saga/effects'
import apiRequests from 'config/services'
import { setCountryCodes } from 'store/slices/generalDataSlice'
import { countryActionType } from 'saga/actions/country'
import { iLooseObject } from 'pages/DataHub/filters/types'

const mapToCountryCodes = (results: any[]) =>
  results.map((item) => item['alternativeId'])

export function* getCountrySaga() {
  try {
    const { results }: iLooseObject = yield call(apiRequests.getCountryList)
    const countryCodes: string[] = mapToCountryCodes(results)
    yield put(setCountryCodes(countryCodes))
  } catch (e) {
    //catchind errors
  }
}

export default function* getCountryDataSagaHook() {
  yield takeLatest(countryActionType.SAGA_GET_COUNTRY_LIST, getCountrySaga)
}
