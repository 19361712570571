import { all } from 'redux-saga/effects'
import clearUserDataSagaHook from 'saga/sagas/user/clearUserData'
import deleteUserSagaHook from 'saga/sagas/user/deleteUser'
import getUserDataSagaHook from 'saga/sagas/user/getUserData'
import setUserDataSagaHook from 'saga/sagas/user/setUserData'
import getCurrencyDataSagaHook from './sagas/currency/getCurrencyData'
import getDataHubSearchResultsHook from './sagas/dataHub/dataHubSearch'
import getDataHubCategoriesHook from './sagas/dataHub/getDataHubCategories'
import getDataHubSagaHook from './sagas/dataHub/getDataHubs'
import getSearchPArametersDataHook from './sagas/dataHub/getSearchParametersData'
import setSEttingsDefaultsHook from './sagas/dataHub/setSettingsFieldsDefaults'
import getSingleEntityDataHook from './sagas/dataHub/singleEntity'
import addMassUploadInputFilesSagaHook from './sagas/massFileUpload/addInputFiles'
import removeMassUploadInputFilesSagaHook from './sagas/massFileUpload/removeInputFiles'
import {
  resetMassUploadStateSagaHook,
  cancelMassUploadFileProcessingSagaHook,
} from './sagas/massFileUpload/cancelFileProcessing'
import startMassUploadFileProcessingSagaHook from './sagas/massFileUpload/startFileProcessing'
import setOrganizationProfileDataSagaHook from './sagas/user/setOrganizationData'
import getOrganizationProfileDataSagaHook from './sagas/user/getOrganizationData'
import { setMassFileUploadComponentErrorSagaHook } from './sagas/massFileUpload/handleComponentError'
import downloadProcessedWorkbookSagaHook from './sagas/massFileUpload/downloadProcessedWorkbook'
import setUserSettingsSagaHook from './sagas/dataHub/saveSearchParameters'
import deleteSearchParametersDataHook from './sagas/dataHub/deleteSearchParameter'
import updateStatusSearchParameterDataHook from './sagas/dataHub/updateSearchParameterStatus'
import getCountryDataSagaHook from './sagas/country/getGeneralData'
import addOrganizationMemberHook from './sagas/user/addOrganizationMember'
import getOrganizationTeamMembersHook from './sagas/user/getOrganizationTeamMembers'
import getAvailableProductsSagaHook from './sagas/subscriptionOrder/getAvailableProducts'
import calculateSubscriptionConfigurationSagaHook from './sagas/subscriptionOrder/calculateSubscriptionConfiguration'
import submitSubscriptionOrderConfigurationSagaHook from './sagas/subscriptionOrder/submitSubscriptionConfiguration'
import updateSubscriptionConfigurationSagaHook from './sagas/subscriptionOrder/updateSubscriptionConfiguration'
import updateOrganizationMemberHook from './sagas/user/updateOrganizationMember'
import deleteTeamMemberSagaHook from './sagas/user/deleteTeamMember'
import activateTeamMemberSagaHook from './sagas/user/activateTeamMember'
import deactivateTeamMemberSagaHook from './sagas/user/deactivateTeamMember'
import cancelSubscriptionRenewalSagaHook from './sagas/subscriptionOrder/cancelSubscriptionRenewal'

export default function* rootSaga() {
  yield all([
    getUserDataSagaHook(),
    setUserDataSagaHook(),
    clearUserDataSagaHook(),
    setOrganizationProfileDataSagaHook(),
    getOrganizationProfileDataSagaHook(),
    getOrganizationTeamMembersHook(),
    deleteUserSagaHook(),
    getCurrencyDataSagaHook(),
    getDataHubSagaHook(),
    getDataHubCategoriesHook(),
    getDataHubSearchResultsHook(),
    setUserSettingsSagaHook(),
    getSingleEntityDataHook(),
    addMassUploadInputFilesSagaHook(),
    removeMassUploadInputFilesSagaHook(),
    startMassUploadFileProcessingSagaHook(),
    cancelMassUploadFileProcessingSagaHook(),
    setMassFileUploadComponentErrorSagaHook(),
    resetMassUploadStateSagaHook(),
    downloadProcessedWorkbookSagaHook(),
    setSEttingsDefaultsHook(),
    getSearchPArametersDataHook(),
    deleteSearchParametersDataHook(),
    updateStatusSearchParameterDataHook(),
    getCountryDataSagaHook(),
    addOrganizationMemberHook(),
    getAvailableProductsSagaHook(),
    updateSubscriptionConfigurationSagaHook(),
    calculateSubscriptionConfigurationSagaHook(),
    submitSubscriptionOrderConfigurationSagaHook(),
    updateOrganizationMemberHook(),
    deleteTeamMemberSagaHook(),
    activateTeamMemberSagaHook(),
    deactivateTeamMemberSagaHook(),
    cancelSubscriptionRenewalSagaHook(),
  ])
}
