import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { iCustomField, iHubFilter } from 'store/types'
import { capitaliseString, removeDash } from 'config/lib'

interface IProps {
  values?: string[]
  name: string
  defaultValue?: string | boolean
  filter: iHubFilter
  displayName?: string
  isDisabled?: boolean
  onChange?: (value: iCustomField) => void
}

export const CustomSelectBox = ({
  values,
  name,
  defaultValue = '',
  displayName,
  isDisabled = false,
  filter,
  onChange,
}: IProps) => {
  const [value, setValue] = useState(defaultValue || '')

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
    return () => {
      setValue('')
    }
  }, [defaultValue, value])

  return (
    // TODO: `min-[695px]:w-[14.375rem]` this implementation is too dependant on menu width (SMZ-695, SMZ-696)
    <Box className="xs:w-full sm:w-full min-[695px]:w-[14.375rem] lg:w-[14.375rem]">
      <FormControl fullWidth>
        <InputLabel id={`${name}-CustomSelectBoxId-label`}>
          {displayName}
        </InputLabel>
        <Select
          disabled={isDisabled}
          labelId={`${name}-CustomSelectBoxId-label`}
          id={`${name}-CustomSelectBoxId`}
          value={value}
          label={displayName}
          onChange={(e) => {
            const currentValue: string | boolean = e.target.value

            setValue(currentValue)
            if (onChange)
              onChange({
                name: name,
                displayName: displayName as string,
                value: currentValue,
                info: filter,
              })
          }}
        >
          {values
            ? values.map((item: string, index: number) => {
                const isContextType =
                  filter.isContext === true && filter.name === 'type'
                const isContextCategory =
                  filter.isContext === true && filter.name === 'database'
                const justTypeSplit = item.split('.')
                const rawType = justTypeSplit[justTypeSplit.length - 1]
                const justTypeValue = capitaliseString(rawType)
                const justCategoryValue = capitaliseString(
                  item.substring(1, item.length)
                )
                return (
                  <MenuItem key={`selectMenuItem${index}`} value={item}>
                    {isContextType
                      ? justTypeValue
                      : isContextCategory
                      ? removeDash(justCategoryValue)
                      : removeDash(capitaliseString(item))}
                  </MenuItem>
                )
              })
            : null}
        </Select>
      </FormControl>
    </Box>
  )
}
