import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import React, { useEffect, useState } from 'react'
import { RoutePaths } from 'routes/paths'
import OrganizationIntroPage from './components/OrganizationIntroPage'
import { useDispatch, useSelector } from 'react-redux'
import { sagaGetOrganizationProfileDataAction } from 'saga/actions/user'
import { useSearchParams } from 'react-router-dom'
import OrganizationTab from './components/organization-tab'
import { userReducerState } from 'store/slices/user'
import { organizationProfileState } from 'store/slices/organizationProfile'

const OrganizationProfile = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const [organizationId, setOrganizationId] = useState(null)
  const [visibleComponent, setVisibleComponent] = useState(
    <OrganizationIntroPage />
  )
  const userGlobalState = useSelector(userReducerState)
  const orgInfo: any = useSelector(organizationProfileState)
    useEffect(() => {
    const localOrganizationId =
      (userGlobalState &&
        userGlobalState?.organization &&
        userGlobalState?.organization?.id) ||
      orgInfo?.id
    if (localOrganizationId) {
      dispatch(sagaGetOrganizationProfileDataAction())
      setOrganizationId(localOrganizationId)
    }
  }, [dispatch, orgInfo?.id, userGlobalState])
    useEffect(() => {
    const localView: string = searchParams.get('view') || ''
    switch (localView) {
      case 'info':
        setVisibleComponent(<OrganizationTab />)
        break
      case 'team':
        setVisibleComponent(<OrganizationTab tabPanelIndex={1} />)
        break
      default:
        if (organizationId) {
          setVisibleComponent(<OrganizationTab />)
        } else {
          setVisibleComponent(<OrganizationIntroPage />)
        }
        break
    }
  }, [organizationId, searchParams])

  return <div className="w-full ">{visibleComponent}</div>
}
const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.organizationProfile,
    title: 'pages.organization',
    element: <OrganizationProfile />,
    roles: componentPermissions[componentNames.organizationProfile],
    restrictedRoles:
      componentPermissions[componentNames.organizationProfileRestricted],
    requiresAuthentication: true,
  },
  name: 'OrganizationProfile',
}
export default exportObj
