import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import { iCustomField, iHubFilter } from 'store/types'
import { useTranslation } from 'react-i18next'

interface IProps {
  value?: string[]
  name: string
  defaultValue?: string | boolean
  isHidden?: boolean
  filter: iHubFilter
  displayName: string
  minDate?: string
  maxDate?: string
  onChange?: (value: iCustomField) => void
}
export const CustomDatePicker = ({
  name,
  displayName,
  minDate,
  maxDate,
  filter,
  onChange,
  defaultValue,
  isHidden,
}: IProps) => {
  // TODO: set default value
  const [date, setDate] = useState<Dayjs | null>(dayjs())
  const { t } = useTranslation()
  useEffect(() => {
    if (defaultValue) setDate(dayjs(defaultValue as string))
  }, [defaultValue, setDate])

  const dateFormat: string = 'YYYY/MM/DD'

  return !isHidden ? (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        minDate={minDate}
        maxDate={maxDate}
        label={t(`dataHub.${name}`)}
        value={date?.format(dateFormat)}
        inputFormat={dateFormat}
        onChange={(newDate) => {
          setDate(dayjs(newDate))
          if (onChange) {
            onChange({
              name: name,
              displayName: displayName,
              value: dayjs(newDate)?.format(dateFormat),
              info: filter,
            })
          }
        }}
        renderInput={(params) => (
          <TextField {...params} className="sm:w-full md:w-[13.125rem]" />
        )}
      />
    </LocalizationProvider>
  ) : null
}
