import { call, put, takeLeading } from 'redux-saga/effects'
import apiRequests from 'config/services'
import { dataHubTypes } from 'saga/actions/dataHub'
import { addDataHubCategories } from 'store/slices/menuItems'
import { RoutePaths } from 'routes/paths'
import { IconsTypes } from 'config/services/requests'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { removeDash } from 'config/lib'
import { mocks } from 'config/mock'
import { eMenuGroups, iMenusType } from 'store/types/menuItems'
import { setHubCategories } from 'store/slices/dataHubCategories'

export function* getDataHubCategoriesSaga() {
  try {
    let categories: string[] = yield call(apiRequests.getDataHubCategories)

    if (categories.length > 0) {
      const newDataHubChildrenMenus: iMenusType[] = categories.map((item) => {
        const indexOfItem = Object.keys(IconsTypes).indexOf(
          item.substring(1).toLowerCase()
        )

        return {
          routePath: RoutePaths.dataHub.toString() + `?contextName=${item}`, //format for data-hub categories navigation
          label: removeDash(item), // capitalizes and removes "-"
          group: eMenuGroups.dataHubCategory,
          icon: Object.values(IconsTypes)[indexOfItem] || IconsTypes.energy,
          roles: componentPermissions[componentNames.pageDataHub],
        }
      })

      //generate new menu
      const newMenu = [
        ...mocks.menuItemsDataHubFavoritesGroup,
        ...newDataHubChildrenMenus,
        ...mocks.menuItemsDataHubOptionGroup,
        ...mocks.menuItemsDataHubResourceGroup
      ]
      yield put(addDataHubCategories(newMenu))
      yield put(setHubCategories(categories))
    }
  } catch (e) {
        //catchind errors
  }
}

export default function* getDataHubCategoriesHook() {
  yield takeLeading(
    dataHubTypes.SAGA_GET_DATA_HUB_CATEGORIES,
    getDataHubCategoriesSaga
  )
}
