import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import { RoutePaths } from 'routes/paths'
import { ContentBody } from 'components/pages/components/ContentBody'
import { DynamicAccordion, IAccordionData } from './components/DynamicAccordion'
import { ProductionDocumentData } from './Data/ProductionDocumentData'

const ProductionDocument: FC = () => {
  return (
      <ContentBody title="knowledgeHub.production">
        {ProductionDocumentData.map((d: IAccordionData, key: number) => (
          <DynamicAccordion data={d} index={key} key={key} />
        ))}
      </ContentBody>
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.knowledgeHubProduction,
    title: 'knowledgeHub.production',
    element: <ProductionDocument />,
    roles: componentPermissions[componentNames.pageKnowledgeHubProduction],
    requiresAuthentication: true,
  },
  name: 'KnowledgeHubProduction',
}
export default exportObj
