import { extractNumbersFromString } from 'config/lib'
import { iLooseObject } from 'pages/DataHub/filters/types'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearFieldsErrors,
  generalSettingsState,
} from 'store/slices/generalSettings'
import { iFieldsErrorData } from 'store/types'
export default function useFieldsErrors() {
  const [errorFormFields, setErrorFormFields] = useState<iLooseObject>({})
  const fieldsErrorData: iFieldsErrorData =
    useSelector(generalSettingsState).fieldsErrorData
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    const fieldsStructure: iLooseObject = {}
    fieldsErrorData.messages.forEach((item: iLooseObject) => {
      // fieldsStructure[item.propertyPath] = ''
      const numbers: number[] = extractNumbersFromString(item.message)
      fieldsStructure[item.propertyPath] = t(
        `request.response.${item.code}.message`,
        { limit: numbers[0] }
      )
    })

    setErrorFormFields(fieldsStructure)
  }, [fieldsErrorData, t])

  const clearGlobalErrors = useCallback(() => {
    dispatch(clearFieldsErrors())
  }, [dispatch])

  return { errorFormFields, clearGlobalErrors }
}
