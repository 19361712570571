import SearchBar from 'components/custom/SearchBar'
import { ContentBody } from 'components/pages/components/ContentBody'
import FiltersSection from 'pages/DataHub/filters/FiltersSection'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import {
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { RoutePaths } from 'routes/paths'
import { useDispatch } from 'react-redux'
import {
  sagaGetDataHubAction,
  sagaGetDataHubCategoriesAction,
  sagaGetDataHubSearchAction,
} from 'saga/actions/dataHub'
import { useSelector } from 'react-redux'
import {
  clearHubCategories,
  dataHubCategoriesReducerState,
} from 'store/slices/dataHubCategories'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { visuallyHidden } from '@mui/utils'
import { dataHubReducerState } from 'store/slices/dataHubSlice'
import {
  getUrlParamValue,
  serializeUrlAttributes,
  urlToObject,
} from 'config/lib'
import {
  Data,
  EnhancedTableProps,
  HeadCell,
  iLooseObject,
  Order,
} from './types'
import './style.css'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { ChipsWrapper } from './components/ChipsWrapper'
import { susIcons } from 'config/services/requests'
import TextWrapper from 'components/custom/TextWrapper'
import { eTypographyVariant } from 'components/types'
import { getComparator, stableSort } from 'components/utils/sorting'
import { EmptyData } from 'components/common/EmptyData'

const DataHub: FC = () => {
  let navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const categoriesState = useSelector(dataHubCategoriesReducerState).categories

  const currentDataHubData: Data[] | any = useSelector(dataHubReducerState).hub

  const DataPointIcon = susIcons.DataPoints
  const { t } = useTranslation()
  let locationParams = useLocation()

  // first load:
  useEffect(() => {
    return () => {
      // clear categories global state
      dispatch(clearHubCategories())
    }
  }, [dispatch])
  //if params change in the url:
  useEffect(() => {
    setPage(0)

    const newObjectTest: iLooseObject = {}
    for (const [param, value] of Array.from(searchParams)) {
      /** Initialize the array to avoid not-iterable errors */
      if (newObjectTest[param] === undefined) {
        newObjectTest[param] = []
      }

      /** Add data to existing array */
      newObjectTest[param] = [...newObjectTest[param], value]
    }

    const serializedUrl = `?${serializeUrlAttributes(newObjectTest)}`

    dispatch(sagaGetDataHubSearchAction(serializedUrl))
    // data hub data (results, FILTERS, context, prefferences) updates based on  are requested in
    dispatch(sagaGetDataHubAction(serializedUrl))
  }, [dispatch, locationParams, searchParams])

  // TODO Chip: Optimise this use effect (single dispatch implementation)
  useEffect(() => {
    const searchParamsString: string = locationParams.search
    //get and generate Data Hub Child menu items
    if (categoriesState.length === 0) {
      dispatch(sagaGetDataHubCategoriesAction())
    } else {
      //
      // -- Get hub data (filters)
      //

      //get datahub data
      dispatch(sagaGetDataHubAction(decodeURI(searchParamsString)))
      //get search results for datahub based on context, terms, filters
      dispatch(
        sagaGetDataHubSearchAction(
          '?' + decodeURI(searchParamsString.substring(1))
        )
      )
    }
  }, [dispatch, categoriesState, locationParams])

  const searchResults = currentDataHubData.results || []

  const rows = searchResults.map((item: Data) => {
    return {
      id: item.id,
      name: item.name,
      context: item.context,
      category: item.category,
      type: item.type,
    }
  })

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('dataHub.name'),
    },
    {
      id: 'category',
      numeric: false,
      disablePadding: false,
      label: t('dataHub.category'),
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: t('dataHub.type'),
    },
  ]

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler =
      (property: keyof Data) => (event: MouseEvent<unknown>) => {
        onRequestSort(event, property)
      }

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? t('general.sortedDsc')
                      : t('general.sortedAsc')}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  function EnhancedTableToolbar() {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant={eTypographyVariant.h6}
          id="tableTitle"
          component="div"
        >
          {t('search.results')}
        </Typography>
      </Toolbar>
    )
  }

  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof Data>('category')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(20)

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClick = (
    event: MouseEvent<unknown>,
    id: string,
    context: string
  ) => {
    navigate(`/entity/${id}?contextName=${context}`)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  const isRowsEmpty =
    (rows.length === 0 && currentDataHubData.context?.length > 0) ||
    rows === undefined ||
    rows === null

  const onSearchChange = useCallback(
    (value: string | undefined) => {
      const urlPath = locationParams.search
      const urlParams: iLooseObject = urlToObject(urlPath)

      // !TODO : make new format
      const newParams = JSON.parse(JSON.stringify(urlParams))
      const contextName = getUrlParamValue(urlParams, 'contextName')

      if (contextName) newParams['contextName'] = contextName
      if (value) newParams['terms'] = value.split(' ').join(',')
      else delete newParams['terms']

      setSearchParams({
        ...newParams,
        contextName: contextName || '.',
      })
    },
    [setSearchParams, locationParams.search]
  )
  return (
    <ContentBody title={t('dataHub.title')}>
      <SearchBar
        label={t('general.searchTopicInput', { topic: t('dataHub.title') })}
        placeholderProp={t('general.search')}
        onSearch={onSearchChange}
        customValue={
          searchParams.get('terms')?.toString().split(',').join(' ') || ''
        }
      />
      <div className="mt-4">
        <FiltersSection />
      </div>
      <ChipsWrapper />
      <div>
        <Box>
          <Paper>
            <EnhancedTableToolbar />
            {!isRowsEmpty ? (
              <>
                <TableContainer className="p-4 pl-7  w-full">
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                  >
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {stableSort(rows, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row: any, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`

                          return (
                            <TableRow
                              hover
                              onClick={(event) =>
                                handleClick(event, row.id, row.context)
                              }
                              tabIndex={-1}
                              key={`tableRow-${row.name}-${index}`}
                              className="cursor-pointer"
                            >
                              <TableCell
                                component="th"
                                id={`TableCell${labelId}`}
                                scope="row"
                                className="w-[50%]"
                                padding="none"
                              >
                                {row.name}
                              </TableCell>
                              <TableCell className="pl-5 w-[25%]" align="left">
                                {row.category}
                              </TableCell>
                              <TableCell className="pl-5 w-[25%]" align="left">
                                {row.type}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  labelRowsPerPage={t('dataHub.rowsPerPage') + ':'}
                  rowsPerPageOptions={[20, 50]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <EmptyData />
            )}
          </Paper>
        </Box>
      </div>
    </ContentBody>
  )
}
const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.dataHub,
    element: <DataHub />,
    title: 'dataHub.title',
    roles: componentPermissions[componentNames.pageDataHub],
    requiresAuthentication: true,
  },
  name: 'DataHub',
}

export default exportObj
