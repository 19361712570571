export enum Roles {
  /**
   * Technical Roles
   * [technical roles](https://sustamize.atlassian.net/wiki/spaces/SUSTAMIZEL/pages/15040533/Technical+Roles)
   */

  /**
   * Administrative roles
   * [administrative roles](https://sustamize.atlassian.net/wiki/spaces/SUSTAMIZEL/pages/15171593/Administrative+Roles)
   */
  ROLE_ADMIN = 'ROLE_ADMIN',

  /**
   * Runtime roles
   * [runtime roles](https://sustamize.atlassian.net/wiki/spaces/SUSTAMIZEL/pages/14843940/Runtime+Roles)
   */
  ROLE_USER_ADMIN = 'user-admin',

  /**
   * Product Roles
   * [product roles](https://sustamize.atlassian.net/wiki/spaces/SUSTAMIZEL/pages/15269891/Product+Roles)
   */

  /** Package */
  ROLE_COMMERCIAL_PACKAGE_STANDARD = 'commercial:package:standard',
  ROLE_COMMERCIAL_PACKAGE_PRO = 'commercial:package:pro',
  ROLE_COMMERCIAL_PACKAGE_ENTERPRISE = 'commercial:package:enterprise',

  /** Database */
  ROLE_COMMERCIAL_DB_CONSUMER_PRODUCTS = 'commercial:database:consumer-products',
  ROLE_COMMERCIAL_DB_ENERGY = 'commercial:database:energy',
  ROLE_COMMERCIAL_DB_LOGISTICS = 'commercial:database:logistics',
  ROLE_COMMERCIAL_DB_MATERIALS = 'commercial:database:materials',
  ROLE_COMMERCIAL_DB_PRODUCTION = 'commercial:database:production',
  ROLE_COMMERCIAL_DB_SEARCH = 'commercial:database:search',

  /** API */
  ROLE_COMMERCIAL_API_CONSUMER_PRODUCTS = 'commercial:api:consumer-products',
  ROLE_COMMERCIAL_API_ENERGY = 'commercial:api:energy',
  ROLE_COMMERCIAL_API_LOGISTICS = 'commercial:api:logistics',
  ROLE_COMMERCIAL_API_MATERIALS = 'commercial:api:materials',
  ROLE_COMMERCIAL_API_PRODUCTION = 'commercial:api:production',
  ROLE_COMMERCIAL_API_SEARCH = 'commercial:api:search',

  /** Feature */
  ROLE_COMMERCIAL_FEATURE_API = 'commercial:feature:api',
  ROLE_COMMERCIAL_FEATURE_MASS_UPLOAD = 'commercial:feature:mass-upload',
  ROLE_COMMERCIAL_FEATURE_RESEARCH_REQUEST = 'commercial:feature:research-request',
  ROLE_COMMERCIAL_FEATURE_WISH_LIST = 'commercial:feature:wish-list',

  /** Documentation */
  ROLE_COMMERCIAL_DOC_ISO_14048 = 'commercial:documentation:iso-14048',

  /** Organization */
  ROLE_ORG_USER = 'org-user',
  ROLE_ORG_OWNER = 'org-owner',
  ROLE_ORG_ADMIN = 'org-admin',
}

export enum componentNames {
  //pages
  pageDashboard = 'Dashboard Page',
  pageDataHub = 'Data Hub',
  pageSingleEntity = 'Single Entity',
  pageHelpKnowledgeHub = 'Help Knowledge Hub Page',
  pageKnowledgeHubDatabaseOverview = 'Database overview',
  pageKnowledgeHubCalculationVariants = 'Calculation variants',
  pageKnowledgeHubDataQualityAssurance = 'Data quality',
  pageKnowledgeHubElectronics = 'Electronics',
  pageKnowledgeHubEnergy = 'Energy',
  pageKnowledgeHubLogistics = 'Logistics',
  pageKnowledgeHubMaterials = 'Materials',
  pageKnowledgeHubProduction = 'Production',
  pageHelpSupport = 'Help Support Page',
  pageManagementAutomationHub = 'Management Automation Management Page',
  pageManagementOrganizationManagement = 'Management Organization Management Page',
  pageManagementSubscriptionManagement = 'Management Subscription Management Page',
  pageMassUpload = 'Mass File Upload Page',
  pageToolsDataHub = 'Tools Data Hub Page',
  pageToolsProcurementHub = 'Tools Procurement Hub Page',
  pageToolsScope12Calculator = 'ToolsScope12Calculator Page',
  pageToolsSimulationHub = 'Tools Simulation Hub Page',
  pageUserSettings = 'User Settings Page',
  pageSearchParameters = 'Search Parameters Page',
  page403 = '403 Page',
  page404 = '404 Page',
  page500 = '500 Page',
  pageLogin = 'Login Page',
  pageLogout = 'Logout Page',
  organizationProfile = 'Organization Profile',
  privacyPolicy = 'PrivacyPolicy',
  imprint = 'Imprint',
  billing = 'Billing',

  //components
  applicationBar = 'Application Bar',
  fullHero = 'Full Hero',
  navigationDrawer = 'navigation Drawer',
  roleCheckWrapper = 'Role Check Wrpapper',
  featuredTable = 'featured Table',
  contentBody = 'Content Body',
  bomUpload = 'Bom Upload',
  dataExplorer = 'Data Explorer',
  applicationFooter = 'Application Footer',
  graph = 'Graph',
  lastUsed = 'Last Used',
  upgrade = 'Upgrade',
  comingSoon = 'Coming soon',
  comingSoonId = 'Coming soon/:id',
  termsAndConditions = 'termsAndConditions',
  dataAsServiceAggrement = 'Data as Service Aggrement',
  organizationProfileRestricted = 'organization profile restricted',
  billingRestricted = 'billing restricted',
}
