import { call, put, takeLeading } from 'redux-saga/effects'
import { userTypes } from 'saga/actions/user'
import { initialState, setUserData } from 'store/slices/user'
import { clearLocal } from 'config/lib'
import apiRequests from 'config/services'
import { PayloadAction } from '@reduxjs/toolkit'
export function* clearUserDataSaga(action: PayloadAction<boolean>) {
  const justLogout = action.payload
  try {
    if (justLogout) {
      let result: boolean = yield call(apiRequests.logoutUser)
      if (result) {
        yield put(setUserData(initialState))
        clearLocal('jwt')
      }
    } else {
      yield put(setUserData(initialState))
      clearLocal('jwt')
    }
  } catch (e) {
    //catchind errors
  }
}

export default function* clearUserDataSagaHook() {
  yield takeLeading(userTypes.SAGA_CLEAR_USER_PROFILE_DATA, clearUserDataSaga)
}
