import {
  ContentBody,
  ContentBodySection,
} from 'components/pages/components/ContentBody'
import FileUpload from 'pages/MassUpload/components/Dropzone'
import DataExplorer from 'components/pages/tools/DataExplorer/DataExplorer'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RoutePaths } from 'routes/paths'

const DataHub: FC = () => {
  const { t } = useTranslation()

  return (
    <ContentBody title={t('dataCenter.title')}>
      <ContentBodySection title={t('dataCenter.bom')}>
        <FileUpload visible />
      </ContentBodySection>
      <ContentBodySection title={t('dataHub.bom')}>
        <DataExplorer />
      </ContentBodySection>
    </ContentBody>
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.dataCenter,
    element: <DataHub />,
    title: 'dataHub.title',
    roles: componentPermissions[componentNames.pageToolsDataHub],
    requiresAuthentication: true,
  },
  name: 'DataHub',
}
export default exportObj
