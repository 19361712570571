import {
  iSubscriptionCancelRenewal,
  iSubscriptionOrder,
} from 'store/types/subscriptionOrder'

export const subscriptionOrderActionTypes = {
  SAGA_GET_AVAILABLE_PRODUCTS: 'SAGA_GET_AVAILABLE_PRODUCTS',
  SAGA_UPDATE_SUBSCRIPTION_CONFIGURATION:
    'SAGA_UPDATE_SUBSCRIPTION_CONFIGURATION',
  SAGA_CALCULATE_SUBSCRIPTION_CONFIGURATION:
    'SAGA_CALCULATE_SUBSCRIPTION_CONFIGURATION',
  SAGA_SUBMIT_SUBSCRIPTION_CONFIGURATION:
    'SAGA_SUBMIT_SUBSCRIPTION_CONFIGURATION',
  SAGA_CANCEL_SUBSCRIPTION_RENEWAL: 'SAGA_CANCEL_SUBSCRIPTION_RENEWAL',
}

export function sagaGetAvailableProducts() {
  return {
    type: subscriptionOrderActionTypes.SAGA_GET_AVAILABLE_PRODUCTS,
  }
}

export function sagaUpdateSubscriptionConfiguration(
  config: iSubscriptionOrder
) {
  return {
    type: subscriptionOrderActionTypes.SAGA_UPDATE_SUBSCRIPTION_CONFIGURATION,
    payload: config,
  }
}

export function sagaCalculateSubscriptionConfiguration(
  config: iSubscriptionOrder
) {
  return {
    type: subscriptionOrderActionTypes.SAGA_CALCULATE_SUBSCRIPTION_CONFIGURATION,
    payload: config,
  }
}

export function sagaSubmitSubscriptionConfiguration(
  config: iSubscriptionOrder
) {
  return {
    type: subscriptionOrderActionTypes.SAGA_SUBMIT_SUBSCRIPTION_CONFIGURATION,
    payload: config,
  }
}

export function sagaCancelSubscriptionRenewal(
  config: iSubscriptionCancelRenewal
) {
  return {
    type: subscriptionOrderActionTypes.SAGA_CANCEL_SUBSCRIPTION_RENEWAL,
    payload: config,
  }
}
