import { BASE_API } from '../urlPaths'
import { call } from 'redux-saga/effects'
import reqProvider from '../coreRequests'
import { iSubscriptionCancelRenewal } from 'store/types/subscriptionOrder'

export function* cancelSubscriptionRenewal(config: iSubscriptionCancelRenewal) {
  const path = `${BASE_API}/subscription/${config.id}/cancel-renewal`
  const data: {} = yield call(reqProvider.postReq, path, undefined)
  return data
}
