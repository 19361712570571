import { call, put, takeLeading } from 'redux-saga/effects'
import apiRequests from 'config/services'
import { dataHubTypes } from 'saga/actions/dataHub'
import { PayloadAction } from '@reduxjs/toolkit'
import { setSearchResults } from 'store/slices/dataHubSlice'
import { iDataHubSearch } from 'store/types'

export function* getDataHubSearchResultsSaga(action: PayloadAction<string>) {
  const context = action.payload

  try {
    let results: iDataHubSearch[] = yield call(
      apiRequests.dataHubSearch,
      `results${context}`
    )

    // save search results to global state
    yield put(setSearchResults(results))
  } catch (e) {
        //catchind errors
  }
}

export default function* getDataHubSearchResultsHook() {
  yield takeLeading(
    dataHubTypes.SAGA_GET_DATA_HUB_SEARCH,
    getDataHubSearchResultsSaga
  )
}
