import React, { FC, useEffect } from 'react'
import { ContentBody } from 'components/pages/components/ContentBody'
import { componentNames } from 'config/roles'
import { Outlet } from 'react-router-dom'
import './style.css'
import { useTranslation } from 'react-i18next'

import { componentPermissions } from 'config/permissions'
import { RoutePaths } from 'routes/paths'
import LinkCardWrapper from 'components/custom/LinkCardWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
import PaperWrapper from 'components/custom/PaperWrapper'
import {
  eLinkTarget,
  eTypographyVariant,
  iBlogPostCard,
} from 'components/types'
import { clearHubCategories } from 'store/slices/dataHubCategories'
import { useDispatch } from 'react-redux'

const Home: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // first load:
  useEffect(() => {
    return () => {
      // clear categories global state
      dispatch(clearHubCategories())
    }
  }, [dispatch])
  const relativePathToBlogImage = 'assets/images/index'
  const blogPosts: iBlogPostCard[] = [
    {
      title: 'Recycling Quotas, Secondary Material Quotas... What Is the Right Terminology?',
      imageSrc: `${relativePathToBlogImage}/6475d8927e884ecc0d2d7137_Scrap-p-2600.jpeg`,
      to: 'https://www.sustamize.com/blog/recycling-quotas-secondary-material-quotas-what-is-the-right-terminology',
    },
    {
      title: 'Product Carbon Footprint Calculation Bottom-Up',
      imageSrc: `${relativePathToBlogImage}/627a28493346e0c3d4172f77_AdobeStock_290098754-p-2000-600px.png`,
      to: 'https://www.sustamize.com/blog/pcf-calculation-bottom-up',
    },
    {
      title: '5 reasons why producing companies should measure their PCF',
      imageSrc: `${relativePathToBlogImage}/62b46d91adfdfb7bbd6cc7d1_5 reasons Bild JPG-p-2000-600px.png`,
      to: 'https://www.sustamize.com/blog/5-reasons-why-producing-companies-should-measure-their-pcf',
    },
    {
      title: 'CO2 pricing: a measure to reduce emissions',
      imageSrc: `${relativePathToBlogImage}/629a0f4849b83092e72891db_CO2 price-p-2000-600px.png`,
      to: 'https://www.sustamize.com/blog/co2-pricing',
    },
    {
      title: 'Company-relevant footprints overview',
      imageSrc: `${relativePathToBlogImage}/62aae8f9e473148f44cc9cbd_Fußabdrücke Bild-p-2000-600px.png`,
      to: 'https://www.sustamize.com/blog/company-relevant-footprints-overview',
    },
    {
      title: 'Decarbonizing concrete: a pressing issue',
      imageSrc: `${relativePathToBlogImage}/62b98799a83391f592255dfd_[blog] concrete-p-2000-600px.png`,
      to: 'https://www.sustamize.com/blog/decarbonizing-concrete-a-pressing-issue',
    },
    {
      title:
        'A Meaningful Approach to CO2 Reporting for Manufacturing Companies',
      imageSrc: `${relativePathToBlogImage}/62cc2493827b6564d498c17c_62208a5bd501096a31c0fbe8_[Blog] Sinnvolles Vorgehen beim CO2-Reporting-600px.png`,
      to: 'https://www.sustamize.com/blog/a-meaningful-approach-to-co2-reporting-for-manufacturing-companies',
    },
    {
      title: 'Does “Science-Based” Always Mean Accurate?',
      imageSrc: `${relativePathToBlogImage}/632c30fa79fb9d74481777b7_Science-p-2000-600px.png`,
      to: 'https://www.sustamize.com/blog/does-science-based-always-mean-accurate',
    },
    {
      title: 'Product Carbon Footprint Is Not Only About Carbon Dioxide (CO2)',
      imageSrc: `${relativePathToBlogImage}/6316fc66f7d7f612de2e648b_Question-p-2000-600px.png`,
      to: 'https://www.sustamize.com/blog/product-carbon-footprint-is-not-only-about-carbon-dioxide-co2',
    },
    {
      title:
        'Study: How Do German Manufacturers React to the Increasing Societal Pressure for Decarbonisation?',
      imageSrc: `${relativePathToBlogImage}/63636d66d4d4dde9641b0aa9_Manufacture 1-p-2000-600px.png`,
      to: 'https://www.sustamize.com/blog/study-how-do-german-manufacturers-react-to-the-increasing-societal-pressure-for-decarbonisation',
    },
    {
      title:
        '6 Ways AI Can Help Reduce Carbon Emissions',
      imageSrc: `${relativePathToBlogImage}/6481664d8e41f40e20c81d6d_Cover AI pic-p-2600.jpeg`,
      to: 'https://www.sustamize.com/blog/6-ways-ai-can-help-reduce-carbon-emissions',
    },
    {
      title:
        'Animal, Vegan and Plant-Based Leather: What Is Truly More Climate-Friendly?',
      imageSrc: `${relativePathToBlogImage}/6454b0c72c310581ebd315c8_Leathers-p-2600.jpeg`,
      to: 'https://www.sustamize.com/blog/animal-vegan-and-plant-based-leather-what-is-truly-more-climate-friendly',
    },
  ]

  return (
    <>
      <ContentBody aria-label={t('index.title')} title={t('index.title')}>
        <PaperWrapper className="p-4">
          <TitleWrapper
            variant={eTypographyVariant.h5}
            ariaLabel={t('index.news_insights')}
            className="pb-4 pt-2 pl-8"
          >
            {t('index.news_insights')}
          </TitleWrapper>
          <div className="row w-full justify-start max-[1100px]:justify-center px-4 pb-3">
            {blogPosts.map((post, index) => (
              <LinkCardWrapper
                blogPost={post}
                target={eLinkTarget.blank}
                className="flex"
                key={`blogPost-${index}-${post.to}`}
              />
            ))}
          </div>
        </PaperWrapper>
      </ContentBody>
      <Outlet />
    </>
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.dashboard,
    title: 'pages.home',
    element: <Home />,
    roles: componentPermissions[componentNames.pageDashboard],
    requiresAuthentication: true,
  },
  name: 'Home',
}
export default exportObj
// Commented out ('dispatch' is defined but never used  @typescript-eslint/no-unused-vars)
// function dispatch(arg0: any) {
//   throw new Error('Function not implemented.')
// }
