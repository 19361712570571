import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import { RoutePaths } from 'routes/paths'
import {DatabaseOverviewData} from './Data/DatabaseOverviewData'
import { ContentBody } from 'components/pages/components/ContentBody'
import { IAccordionData, DynamicAccordion } from './components/DynamicAccordion'

const DatabaseOverview: FC = () => {
  return (
      <ContentBody title="knowledgeHub.databaseOverview">
        {DatabaseOverviewData.map((d: IAccordionData, key: number) => (
          <DynamicAccordion data={d} index={key} key={key} />
        ))}
      </ContentBody>
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.knowledgeHubDatabaseOverview,
    title: 'knowledgeHub.databaseOverview',
    element: <DatabaseOverview />,
    roles: componentPermissions[componentNames.pageKnowledgeHubDatabaseOverview],
    requiresAuthentication: true,
  },
  name: 'DatabaseOverview',
}
export default exportObj
