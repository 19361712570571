import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { iFormInputError } from 'components/types'
import { useDispatch, useSelector } from 'react-redux'
import { sagaGetCountryCodesAction } from 'saga/actions/country'
import { countryCodesState } from 'store/slices/generalDataSlice'
import countries from 'i18n-iso-countries'
import { eLocale, userState } from 'store/types'
import { userReducerState } from 'store/slices/user'

interface iProps {
  label: string
  defaultValue?: string
  onChange: (value: string) => void
  onBlur?: (value: unknown) => void
  className?: string
  helperText?: string
  error?: iFormInputError
}

const CountrySelectAutoComplete = ({
  label,
  defaultValue,
  onChange,
  onBlur,
  className = '',
  helperText,
  error = { active: false, text: '' },
}: iProps) => {
  const userInfo: userState = useSelector(userReducerState)
  const userLanguage = userInfo?.settings?.locale || eLocale.English
  const [inputValue, setInputValue] = useState<string>('')

  // Support English & German languages.
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
  countries.registerLocale(require('i18n-iso-countries/langs/de.json'))

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(sagaGetCountryCodesAction())
  }, [dispatch])

  useEffect(() => {
    setInputValue(defaultValue || '')
  }, [defaultValue, setInputValue])

  const countryCodes = useSelector(countryCodesState).filter(
    (item: string) =>
      ['WBS_AFRICA', 'ALL_TOTAL', 'OECD1', 'OECD2', 'OECD3', 'OECD4'].indexOf(
        item
      ) === -1
  )

  const handleChange = useCallback(
    (newValue: string) => {
      if (onChange) {
        if (newValue.length === 0) setInputValue(newValue)
        onChange(newValue)
      }
    },
    [onChange]
  )

  return (
    <Autocomplete
      value={inputValue}
      defaultValue={inputValue}
      id="country-select-demo"
      className={className}
      options={countryCodes}
      autoHighlight
      getOptionLabel={(option) =>
        countries.getName(option, userLanguage) || option
      }
      onChange={(
        event: React.SyntheticEvent<Element, Event>,
        value: string | null
      ) => {
        setInputValue(value || '')
        handleChange(value || '')
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          // onClickCapture={() => handleChange(option)} // not sure why this is persisting the dropdown open
          {...props}
          value={option}
        >
          {option.length === 3 && (
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${countries
                .alpha3ToAlpha2(option)
                .toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${countries
                .alpha3ToAlpha2(option)
                .toLowerCase()}.png 2x`}
              alt=""
            />
          )}
          {countries.getName(option, userLanguage)} ({option})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          helperText={error.active ? error.text : helperText}
          value={inputValue}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChange(event.target.value)
          }
          error={error.active}
          onBlur={onBlur}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  )
}

export default CountrySelectAutoComplete
