import { iNewMember } from 'pages/user/types'
import reqProvider from '../coreRequests'
import { iLooseObject } from 'pages/DataHub/filters/types'
import { call, select } from 'redux-saga/effects'
import { BASE_API } from '../urlPaths'
import { organizationProfileState } from 'store/slices/organizationProfile'

export function* addNewOrganizationMember(newMember: iNewMember) {
  const orgData: iLooseObject = yield select(organizationProfileState)
  const { id: orgId } = orgData
  const path = `${BASE_API}/organizations/${orgId}/users`
  const data: iLooseObject = yield call(
    reqProvider.postReq,
    path,
    undefined,
    newMember
  )
  return data
}
