import { Roles } from 'config/roles'
import { IconsTypes } from 'config/services/requests'
import { RoutePaths } from 'routes/paths'

export enum eMenuGroups {
  dataHubCategory = 'dataHub.category',
  dataHubOptions = 'dataHub.options',
  dataHubTools = 'dataHub.tools',
  dataHubResources = 'dataHub.resources',
  knowledgeHubGeneral = 'general.general',
  knowledgeHubDataCategory = 'knowledgeHub.dataCategory',
  knowledgeHubDocuments = 'knowledgeHub.documents',
}

export enum eMenusSortType {
  asc = 'ASC',
  dsc = 'DSC',
}

export interface iMenuGroups {
  name: eMenuGroups
  sort: eMenusSortType
}

export interface iMenusType {
  routePath: RoutePaths | string
  group?: eMenuGroups
  label: string
  icon?: IconsTypes
  childPages?: iMenusType[]
  roles?: Roles[]
  restrictedRoles?: Roles[],
  externalLink?: string
}

export interface iMenuItemsState {
  menu: iMenusType[]
}
