import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { tFileContextCollection, iMassFileUploadState } from "store/types/massFileUpload";
import { PayloadAction } from '@reduxjs/toolkit'
import ExcelJS from 'exceljs';

export const initialState: iMassFileUploadState = {
  componentError: undefined,
  isFilesAvailable: false,
  isProcessingFiles: false,
  isProcessingFilesCancelled: false,
  isProcessingFilesDone: false,
  currentFileProcessed: undefined,
  totalNumberOfRowsInCurrentFile: 0,
  numberOfRowsProcessedInCurrentFile: 0,
  currentContextProcessed: undefined,
  totalNumberOfRows: 0,
  numberOfRowsProcessed: 0,
  uploadedFiles: [],
  fileInputCollection: {},
  fileOutputCollection: {},
  errors: [],
  processedInputFileNames: [],
  processedWorkbooks: {}
}

/** Reducer State */
export const massFileUploadReducerState = (state: RootState) => state.massFileUpload;

/** Reducer */
export const massFileUploadReducer = createSlice({
  name: 'massFileUploadReducer',
  initialState,
  reducers: {
    resetState: (state) => {
      Object.keys(state).forEach(key => {
        (state as any)[key] = (initialState as any)[key];
      });
    },
    toggleProcessingFiles: (state) => {
      state.isProcessingFiles = !state.isProcessingFiles;
    },
    addInputFiles: (state, action: PayloadAction<File[]>) => {

      /** Same name not allowed */
      let namesInState: string[] = [];
      let newNames: string[] = [];
      state.uploadedFiles.forEach(file => namesInState.push(file.name));
      action.payload.forEach(file => newNames.push(file.name));
      if (namesInState.some(name => newNames.includes(name))) return;

      /** Don't exceed 3 items */
      if (state.uploadedFiles.length === 0) {
        state.uploadedFiles = action.payload;
      } else if (state.uploadedFiles.length >= 3) {
        state.uploadedFiles = action.payload;
      } else {
        state.uploadedFiles = [...state.uploadedFiles, ...action.payload];
      }
      state.isFilesAvailable = true;
    },
    removeInputFiles: (state, action: PayloadAction<string>) => {
      state.uploadedFiles.forEach((file, index) => {
        if (file.name !== action.payload) return;
        state.uploadedFiles.splice(index, 1);
      });

      if (state.uploadedFiles.length < 1)
        state.isFilesAvailable = false;
    },
    setComponentError: (state, payload) => {
      state.componentError = payload.payload;
    },
    setFileInputCollection: (state, payload) => {
      state.fileInputCollection = payload.payload;
    },
    setTotalNumberOfRows: (state, payload) => {
      state.totalNumberOfRows = payload.payload;
    },
    setFileOutputCollection: (state, action: PayloadAction<tFileContextCollection>) => {
      state.fileOutputCollection = {...action.payload};
    },
    setInputFileCompleted: (state, action: PayloadAction<string>) => {
      state.processedInputFileNames.push(action.payload);
    },
    accumulateProcessedRows: (state) => {
      state.numberOfRowsProcessed++;
    },
    setCurrentFileProcessed: (state, action: PayloadAction<string|undefined>) => {
      state.currentFileProcessed = action.payload; 
      
      /** Reset the counter on the previous file */
      state.totalNumberOfRowsInCurrentFile = 0;
      state.numberOfRowsProcessedInCurrentFile = 0;
    },
    setCurrentContextProcessed: (state, action: PayloadAction<string|undefined>) => {
      state.currentContextProcessed = action.payload;
    },
    cancelProcessingFiles: (state) => {
      state.isProcessingFilesCancelled = true;
    },
    setIsProcessingFilesDone: (state) => {
      state.isProcessingFilesDone = true;
      state.isProcessingFiles = true;

      /** Reset the counter on the files */
      state.totalNumberOfRowsInCurrentFile = 0;
      state.numberOfRowsProcessedInCurrentFile = 0;
    },
    setTotalNumberOfRowsInCurrentFile: (state, action: PayloadAction<number>) => {
      state.totalNumberOfRowsInCurrentFile = action.payload;
    },
    accumulateNumberOfRowsProcessedInCurrentFile: (state) => {
      state.numberOfRowsProcessedInCurrentFile += 1;
    },
    addProcessedWorkbook: (state, action: PayloadAction<{[fileName: string]: ExcelJS.Workbook}>) => {
      for (const [ key, value ] of Object.entries(action.payload)) {
        (state.processedWorkbooks as any)[key] = value;
      }
    }
  }
});

/** Assign reducer Actions */
export const {
  resetState,
  setComponentError,
  addInputFiles,
  removeInputFiles,
  toggleProcessingFiles,
  setFileInputCollection,
  setTotalNumberOfRows,
  setFileOutputCollection,
  setInputFileCompleted,
  setCurrentFileProcessed,
  setCurrentContextProcessed,
  accumulateProcessedRows,
  cancelProcessingFiles,
  setIsProcessingFilesDone,
  setTotalNumberOfRowsInCurrentFile,
  accumulateNumberOfRowsProcessedInCurrentFile,
  addProcessedWorkbook,
} = massFileUploadReducer.actions;

export default massFileUploadReducer.reducer;