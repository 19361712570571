import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { RoutePaths } from 'routes/paths'
import { userReducerState } from 'store/slices/user'
import { eLocale, userState } from 'store/types'
import {DataAsServiceAggrementEnglish} from './components/DataAsServiceAggrementEnglish'
import {DataAsServiceAggrementGerman} from './components/DataAsServiceAggrementGerman'

export const DataAsServiceAggrement: FC = () => {
  const userInfo: userState = useSelector(userReducerState)

  switch (userInfo.settings.locale) {
    case eLocale.German:
      return <DataAsServiceAggrementGerman />
    default:
      return <DataAsServiceAggrementEnglish />
  }
}
const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.dataAsServiceAggrement,
    title: 'footer.DataAsServiceAggrement',
    element: <DataAsServiceAggrement />,
    roles: componentPermissions[componentNames.dataAsServiceAggrement],
    requiresAuthentication: true,
  },
  name: 'DataAsServiceAggrement',
}
export default exportObj
