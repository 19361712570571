/**
 * Icon imports for menu items
 */

import { ReactComponent as HomeIcon } from 'assets/icons/012-home.svg'
import { ReactComponent as DataHubIcon } from 'assets/icons/032-searching-data-in-database.svg'
import { ReactComponent as SearchAllIcon } from 'assets/icons/032-searching-data-in-database.svg'
import { ReactComponent as LastSearchesIcon } from 'assets/icons/009-historisch.svg'
import { ReactComponent as MyFavoritesIcon } from 'assets/icons/008-star.svg'
import { ReactComponent as MyFeedbackIcon } from 'assets/icons/011-comment-check.svg'
import { ReactComponent as Checkmark } from 'assets/icons/check.svg'
import { ReactComponent as PreferencesIcon } from 'assets/icons/039-settings.svg'
import { ReactComponent as RequestDataResearchIcon } from 'assets/icons/040-edit.svg'
import { ReactComponent as AutomationHubIcon } from 'assets/icons/017-arrows.svg'
import { ReactComponent as AutomationHubOverviewIcon } from 'assets/icons/027-graph.svg'
import { ReactComponent as ClientOrganizationsIcon } from 'assets/icons/024-marketing.svg'
import { ReactComponent as APIClientConnectionsIcon } from 'assets/icons/025-link.svg'
import { ReactComponent as KnowledgeHubIcon } from 'assets/icons/002-idea.svg'
import { ReactComponent as DatabaseOverviewIcon } from 'assets/icons/006-file.svg'
import { ReactComponent as CalculationVariantsIcon } from 'assets/icons/028-keys.svg'
import { ReactComponent as DataQualityAssuranceIcon } from 'assets/icons/005-medal.svg'
import { ReactComponent as FrequentlyAskedIcon } from 'assets/icons/018-faq.svg'
import { ReactComponent as ElectronicsIcon } from 'assets/icons/014-chip.svg'
import { ReactComponent as EnergyIcon } from 'assets/icons/001-renewable-energy.svg'
import { ReactComponent as ConsumerProductsIcon } from 'assets/icons/007-shopping-cart.svg'
import { ReactComponent as CbamIcon } from 'assets/icons/cbam.svg'
import { ReactComponent as LogisticsIcon } from 'assets/icons/004-delivery.svg'
import { ReactComponent as MaterialsIcon } from 'assets/icons/015-steel.svg'
import { ReactComponent as ProductionIcon } from 'assets/icons/016-conveyor-belt.svg'
import { ReactComponent as SourcesIcon } from 'assets/icons/020-global-search.svg'
import { ReactComponent as WhitepapersIcon } from 'assets/icons/019-copy.svg'
import { ReactComponent as HelpDeskIcon } from 'assets/icons/037-request.svg'
import { ReactComponent as HelpIcon } from 'assets/icons/003-help.svg'
import { ReactComponent as SupportIcon } from 'assets/icons/021-help-desk.svg'
import { ReactComponent as DataPointsIcon } from 'assets/icons/041-data-points.svg'
import { ReactComponent as EmptyAvatarIcon } from 'assets/icons/042-empty-avatar.svg'
import { ReactComponent as Organization } from 'assets/icons/organization.svg'
import { ReactComponent as EmptyUserIcon } from 'assets/icons/030-user.svg'
import { ReactComponent as Profile } from 'assets/icons/profile.svg'
import { ReactComponent as Wallet } from 'assets/icons/wallet.svg'
import { ReactComponent as Upload } from 'assets/icons/041-upload.svg'
import { ReactComponent as Trash } from 'assets/icons/delete.svg'
import { ReactComponent as Back } from 'assets/icons/back.svg'
import { ReactComponent as Cross } from 'assets/icons/cross.svg'
import { ReactComponent as NoSubscriptionsImage } from 'assets/images/no_subscriptions_image.svg'
import { ReactComponent as CancelRenewalIcon } from 'assets/icons/cancel-event.svg'
import { ReactComponent as CancelRenewalIconHover } from 'assets/icons/cancel-event-hover.svg'
import { Roles } from 'config/roles'
import { iLooseObject } from 'pages/DataHub/filters/types'

export const assets = {
  logoOnly:
    'https://sustamize-public-assets.s3.eu-central-1.amazonaws.com/logo/sustamize_logo_only.svg',
  logo: 'https://public2sustamize.blob.core.windows.net/assets/img/sustamizer_logo.svg',
  sustamizeLogo: 'https://public2sustamize.blob.core.windows.net/assets/img/sustamize_logo.png',
  currentUser:
    'https://sustamize-public-assets.s3.eu-central-1.amazonaws.com/img/profile_girl.jpg',
  cutterSparks:
    'https://sustamize-public-assets.s3.eu-central-1.amazonaws.com/img/cutter_sparks.jpg',
  emptyStorage:
    'https://sustamize-public-assets.s3.eu-central-1.amazonaws.com/img/empty_storage.jpg',
  hotMetal:
    'https://sustamize-public-assets.s3.eu-central-1.amazonaws.com/img/hot_metal.jpg',
  pcbBoard:
    'https://sustamize-public-assets.s3.eu-central-1.amazonaws.com/img/pcb_board.jpg',
  pipeChaos:
    'https://sustamize-public-assets.s3.eu-central-1.amazonaws.com/img/pipe_chaos.jpg',
  windPower:
    'https://sustamize-public-assets.s3.eu-central-1.amazonaws.com/img/wind_power.jpg',
  artOne:
    'https://sustamize-public-assets.s3.eu-central-1.amazonaws.com/img/art/art-1.png',
  artTwo:
    'https://sustamize-public-assets.s3.eu-central-1.amazonaws.com/img/art/art-2.png',
  artThree:
    'https://sustamize-public-assets.s3.eu-central-1.amazonaws.com/img/art/art-3.png',
  artFour:
    'https://sustamize-public-assets.s3.eu-central-1.amazonaws.com/img/art/art-4.png',
  artFive:
    'https://sustamize-public-assets.s3.eu-central-1.amazonaws.com/img/art/art-5.png',
  dataAsAServiceAgreementPdf: 
    'https://public2sustamize.blob.core.windows.net/assets/doc/Data-as-a-service-agreement.pdf'
}

export enum IconsTypes {
  HomeIcon = 'HomeIcon',
  DataHub = 'DataHub',
  AutomationHub = 'AutomationHub',
  AutomationHubOverview = 'AutomationHubOverview',
  ClientOrganizations = 'ClientOrganizations',
  APIClientConnections = 'APIClientConnections',
  KnowledgeHub = 'KnowledgeHub',
  DatabaseOverview = 'DatabaseOverview',
  CalculationVariants = 'CalculationVariants',
  DataQualityAssurance = 'DataQualityAssurance',
  FrequentlyAsked = 'FrequentlyAsked',
  'consumer-products' = 'ConsumerProducts',
  energy = 'Energy',
  logistics = 'Logistics',
  materials = 'Materials',
  production = 'Production',
  electronics = 'Electronics',
  Sources = 'Sources',
  Cbam = 'Cbam',
  Whitepapers = 'Whitepapers',
  HelpIcon = 'HelpIcon',
  Support = 'Support',
  SearchAll = 'SearchAll',
  LastSearches = 'LastSearches',
  MyFavorites = 'MyFavorites',
  MyFeedback = 'MyFeedback',
  Preferences = 'Preferences',
  RequestDataResearch = 'RequestDataResearch',
  Organization = 'Organization',
  Profile = 'Profile',
  Wallet = 'Wallet',
  Upload = 'Upload',
  Trash = 'Trash',
  Back = 'Back',
  Checkmark = 'Checkmark',
  Cross = 'Cross',
  NoSubscriptionsImage = 'NoSubscriptionsImage',
  CancelRenewalIcon = 'CancelRenewalIcon',
  CancelRenewalIconHover = 'CancelRenewalIconHover',
}

export const style = {
  drawerWidth: `280px`,
  smallWidth: `80px`,
}

export const susIcons: iLooseObject = {
  HomeIcon: HomeIcon,
  DataHub: DataHubIcon,
  EmptyUser: EmptyUserIcon,
  AutomationHub: AutomationHubIcon,
  AutomationHubOverview: AutomationHubOverviewIcon,
  ClientOrganizations: ClientOrganizationsIcon,
  APIClientConnections: APIClientConnectionsIcon,
  KnowledgeHub: KnowledgeHubIcon,
  DatabaseOverview: DatabaseOverviewIcon,
  CalculationVariants: CalculationVariantsIcon,
  DataQualityAssurance: DataQualityAssuranceIcon,
  FrequentlyAsked: FrequentlyAskedIcon,
  DataPoints: DataPointsIcon,
  Electronics: ElectronicsIcon,
  Energy: EnergyIcon,
  ConsumerProducts: ConsumerProductsIcon,
  Logistics: LogisticsIcon,
  Materials: MaterialsIcon,
  Production: ProductionIcon,
  Sources: SourcesIcon,
  Cbam: CbamIcon,
  Whitepapers: WhitepapersIcon,
  EmptyAvatar: EmptyAvatarIcon,
  HelpIcon: HelpIcon,
  HelpDeskIcon: HelpDeskIcon,
  Support: SupportIcon,
  SearchAll: SearchAllIcon,
  LastSearches: LastSearchesIcon,
  MyFavorites: MyFavoritesIcon,
  MyFeedback: MyFeedbackIcon,
  Preferences: PreferencesIcon,
  RequestDataResearch: RequestDataResearchIcon,
  Organization: Organization,
  Profile: Profile,
  Wallet: Wallet,
  Upload: Upload,
  Trash: Trash,
  Back: Back,
  Checkmark: Checkmark,
  Cross: Cross,
  NoSubscriptionsImage: NoSubscriptionsImage,
  CancelRenewalIcon: CancelRenewalIcon,
  CancelRenewalIconHover: CancelRenewalIconHover,
}
export enum ITermsAccepted {
  ON = 'on'
}
export interface JwtData {
  iat: number
  exp: number
  sub: string
  roles: Roles[]
  first_name: string
  last_name: string
  email: string
  terms_accepted: ITermsAccepted
}