import * as React from 'react'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RoutePaths } from 'routes/paths'

import { ReactComponent as ComingSoonLogo } from 'assets/images/coming-soon.svg'
import PaperWrapper from 'components/custom/PaperWrapper'
import ContentBodyWrapper from 'components/custom/ContentBodyWrapper'
import LinkWrapper from 'components/custom/LinkWrapper'

const Support: FC = () => {
  const { t } = useTranslation()

  return (
    <ContentBodyWrapper title={t('support.title')}>
      <PaperWrapper>
        <div className="p-4 flex flex-col text-center justify-center">
          <ComingSoonLogo className="w-[18%] self-center h-full mb-2" />
          <p className="p-4 text-center text-lg">
            {t('support.email')}{' '}
            <LinkWrapper
              to={`mailto:support@sustamizer.com?subject=sustamize ${t(
                'support.title'
              )}:%20`}>
              {t('support.email_anchor')}
            </LinkWrapper>
            .
          </p>
        </div>
      </PaperWrapper>
    </ContentBodyWrapper>
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: [RoutePaths.helpSupport, RoutePaths.helpSupport],
    title: 'support.title',
    element: <Support />,
    roles: componentPermissions[componentNames.pageHelpSupport],
    requiresAuthentication: true,
  },
  name: 'ComingSoon',
}
export default exportObj
