import reqProvider from '../coreRequests'
import { iLooseObject } from 'pages/DataHub/filters/types'
import { call, select } from 'redux-saga/effects'
import { BASE_API } from '../urlPaths'
import { organizationProfileState } from 'store/slices/organizationProfile'

export function* updateOrganizationMember(payload: iLooseObject) {
  const orgData: iLooseObject = yield select(organizationProfileState)
  const { id: orgId } = orgData
  const path = `${BASE_API}/organizations/${orgId}/users/${payload.userId}`
  const data: iLooseObject = yield call(
    reqProvider.patchReq,
    path,
    { 'Content-type': 'application/merge-patch+json' },
    { 
      roles: payload.roles, 
      subscriptionId: payload.subscriptionId,
      attributes: payload.attributes
    }
  )
  return data
}
