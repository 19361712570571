import {
  Button,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material'
import { ReactComponent as SubscriptionAssignIcon } from 'assets/icons/040-edit.svg'
import { ReactComponent as EmptySubscriptions } from 'assets/images/no_rules_set_yet.svg'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import CustomSvgStyled from 'components/custom/CustomSvgStyled'
import ModalWrapper from 'components/custom/ModalWrapper'
import TextWrapper from 'components/custom/TextWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
import {
  eTypographyVariant,
  eButtonSize,
  eButtonTypes,
  eColor,
  ePackageSelection,
  eButtonVariant,
  eAdditionalDatabaseSelection,
} from 'components/types'
import { generateSubscriptionName } from 'pages/Billing/helpers'
import { iOrganizationManageTeam } from 'pages/user/types'
import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RoutePaths } from 'routes/paths'
import {
  sagaGetOrganizationProfileDataAction,
  sagaGetOrganizationTeamMembersAction,
  sagaUpdateMemberAction,
} from 'saga/actions/user'
import { organizationProfileState } from 'store/slices/organizationProfile'
import { iOrganizationData, iOrganizationProfileState, iOrganizationSubscription } from 'store/types'
import { eProductKey } from 'store/types/subscriptionOrder'
export const getSubscriptionValue = (subscriptionKey: eProductKey) => {
  switch (subscriptionKey) {
    case eProductKey.SUST_STANDARD_PACKAGE:
      return ePackageSelection.STANDARD + '.label'

    case eProductKey.SUST_PRO_PACKAGE:
      return ePackageSelection.PRO + '.label'

    case eProductKey.SUST_ELECTRONICS_DB:
      return eAdditionalDatabaseSelection.ELECTRONICS + '.label'

    default:
      return ePackageSelection.FLEX + '.label'
  }
}

export const getCurrentSubscription =  (id: number, allSubscriptions: iOrganizationSubscription[]) =>
    allSubscriptions?.find((sub: iOrganizationSubscription) => sub.id === id)


export const LicenseAssignment = ({
  subscriptionId,
  userData,
}: {
  subscriptionId: number | undefined
  userData: iOrganizationManageTeam
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [confirmationSubscriptionModal, setConfirmationSubscriptionModal] =
    useState<boolean>(false)
  const [assignSubscriptionModal, setAssignSubscriptionModal] =
    useState<boolean>(false)
  const [selectedLicense, setSelectedLicense] = useState<number | undefined>(
    undefined
  )
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState<
    number | undefined
  >(Number(subscriptionId))
const isPending = userData.status === 'pending'
  const disabled = isPending || !!currentSubscriptionId

  // re-load when assignSubscriptionModal modal opens
  useEffect(() => {
    if (assignSubscriptionModal)
      dispatch(sagaGetOrganizationProfileDataAction())
  }, [assignSubscriptionModal, dispatch])

  // get active and available license to assign
  const activeSubscriptions: iOrganizationSubscription[] | undefined =
    useSelector(organizationProfileState).subscriptions?.filter(
      (sData) =>
        sData.totalLicenses - sData.activeLicenses > 0 && !!sData.active
    )

  const allSubscriptions: iOrganizationSubscription[] | undefined = useSelector(
    organizationProfileState
  ).subscriptions

  const onLicenseAssign = () => {
    setCurrentSubscriptionId(Number(selectedLicense))
    dispatch(
      sagaUpdateMemberAction({
        userId: userData.id,
        subscriptionId: Number(selectedLicense),
      })
    )
    setAssignSubscriptionModal(false)
  }

  const subscriptionKey =
    currentSubscriptionId && allSubscriptions&&
    getCurrentSubscription(currentSubscriptionId, allSubscriptions)?.packageKey

  const subscriptionValue = subscriptionKey
    ? getSubscriptionValue(eProductKey[subscriptionKey])
    : ''

  const getIncludedProductKeys = (
    packageKey: eProductKey | undefined,
    includedProductKeys: eProductKey[]
  ) => {
    const addOnsDataBases = includedProductKeys.filter(
      (ipk) => ipk !== packageKey
    )
    let keys = ''
    addOnsDataBases.forEach((db) => {
      keys = keys + ' + ' + t(getSubscriptionValue(db)) + ' '
    })


    return keys
  }
  
const getSubscriptionLabel = (sData: iOrganizationSubscription) => {
  return (
    <div className="text-sm pt-4 ">
      <b>{generateSubscriptionName(sData.id, sData.startAt)}:</b>
      <br />
      <div className="text-xs">
        {sData.packageKey && t(getSubscriptionValue(sData.packageKey))}{' '}
        {t('organization.license.licenseLabel')}{' '}
        {sData.includedProductKeys?.length
          ? t(getIncludedProductKeys(sData.packageKey,  sData.includedProductKeys))
          : ' '}
        (
        <span className="text-gray-500 ">
          {` ${sData.totalLicenses - sData.activeLicenses} `}
          {t('organization.license.noOfAvailableLicensesLabel', {
            totalLicenses: sData.totalLicenses,
          })}
        </span>
        )
      </div>
    </div>
  )
}

  return (
    <div>
      {!isPending && (subscriptionValue || !disabled) ? (
        <Button
          disabled={disabled && !!subscriptionValue}
          className={'h-7 bg-transparent text-black px-2'}
          fullWidth
          sx={{
            '&:hover': {
              boxShadow: `0 3px 5px 1px rgba(27, 102, 0, .2)`,
            },
            '&.Mui-focusVisible': {
              boxShadow: `0 3px 5px 1px rgba(27, 102, 0, .2)`,
            },
            '&.Mui-active': {
              boxShadow: `0 3px 5px 1px rgba(27, 102, 0, .2)`,
            },
          }}
          variant={eButtonVariant.contained}
          endIcon={
            !disabled &&
            !subscriptionValue && <SubscriptionAssignIcon className="h-4 w-4" />
          }
          style={{ justifyContent: 'space-between' }}
          onClick={() => setAssignSubscriptionModal(true)}
        >
          {t(subscriptionValue) || '-'}
        </Button>
      ) : (
        '-'
      )}

      {/* MODAL START Assign Subscription */}
      <ModalWrapper
        onClose={() => setAssignSubscriptionModal(false)}
        open={!!assignSubscriptionModal}
        hasCloseButton
        className={'lg:min-w-[800px]'}
        footerSection={
          activeSubscriptions?.length ? (
            <div className="flex justify-end space-x-5 pt-2 pb-4 pr-0 max-w-[300px] lg:mr-0 m-auto">
              <ButtonWrapper
                onClick={() => setConfirmationSubscriptionModal(true)}
                type={eButtonTypes.submit}
                size={eButtonSize.medium}
                aria-label={t('organization.license.save')}
                fullWidth
                disabled={!selectedLicense}
              >
                {t('organization.license.save')}
              </ButtonWrapper>
              <ButtonWrapper
                color={eColor.secondary}
                size={eButtonSize.medium}
                onClick={() => setAssignSubscriptionModal(false)}
                aria-label={t('general.cancel')}
                fullWidth
              >
                {t('general.cancel')}
              </ButtonWrapper>
            </div>
          ) : (
            <></>
          )
        }
      >
        <div className="py-6 px-6">
          <TitleWrapper
            variant={eTypographyVariant.h1}
            className="text-3xl w-full mb-10"
          >
            {t('organization.license.title')}
          </TitleWrapper>
          <TextWrapper className="text-base d-flex">
            <p>
              <span className="text-primary mr-1">
                {t('organization.license.email')}
              </span>
              <span className="text-base">{userData.email}</span>
            </p>
          </TextWrapper>
          <Divider className="mt-2 mb-6" />
          {activeSubscriptions?.length ? (
            <div>
              <TextWrapper
                variant={eTypographyVariant.h2}
                className="text-lg mb-2"
              >
                {t('organization.license.availableLicenses')}
              </TextWrapper>
              <RadioGroup
                className="ml-8"
                name={`assign-license`}
                defaultValue={`typical`}
              >
                {activeSubscriptions?.map(
                  (sData, key) =>
                    sData.packageKey && (
                      <FormControlLabel
                        key={key}
                        value={sData.id}
                        control={<Radio />}
                        label={getSubscriptionLabel(sData)}
                        onClick={(e: any) => setSelectedLicense(e.target.value)}
                        disabled={
                          !(sData.totalLicenses - sData.activeLicenses > 0)
                        }
                      />
                    )
                )}
              </RadioGroup>
            </div>
          ) : (
            <div className="flex place-content-center p-10">
              <div className="flex flex-col items-center">
                <Typography
                  variant={`h6`}
                  color={`dark`}
                  sx={{
                    marginBottom: `20px`,
                  }}
                  role="heading"
                  aria-label={t('dataHub.searchParameters.noRulesSetYet')}
                >
                  <Trans
                    i18nKey={t('organization.license.empytSubscriptionsList')}
                    components={{
                      a: <Link to={RoutePaths.billing} />,
                      span: <span className={`text-primary underline`} />,
                    }}
                  />
                </Typography>
                <div>
                  <EmptySubscriptions />
                </div>
              </div>
            </div>
          )}
        </div>
      </ModalWrapper>

      {/* MODAL START Double Check Modal Before Assigning Subscription to User */}
      <ModalWrapper
        onClose={() => {
          setConfirmationSubscriptionModal(false)
        }}
        open={!!confirmationSubscriptionModal}
        footerSection={
          <div className="space-x-2 pt-2">
            <ButtonWrapper
              color={eColor.secondary}
              onClick={() => {
                onLicenseAssign()
                setConfirmationSubscriptionModal(false)
              }}
              aria-label={t('general.yes')}
            >
              {t('general.yes')}
            </ButtonWrapper>
            <ButtonWrapper
              onClick={() => {
                setConfirmationSubscriptionModal(false)
              }}
              aria-label={t('general.no')}
            >
              {t('general.no')}
            </ButtonWrapper>
          </div>
        }
      >
        <>
          <p className=" pb-2 font-bold">
            {t('organization.license.confirmModal.title')}
          </p>
          <p className=" pb-2 pt-2">
            {t('organization.license.confirmModal.message')}
          </p>
        </>
      </ModalWrapper>
      {/* MODAL END */}
    </div>
  )
}
