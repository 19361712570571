import React, { useState } from 'react'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import * as config from 'config/services/requests'
import PaperWrapper from 'components/custom/PaperWrapper'
import TextWrapper from 'components/custom/TextWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
const InfoPreferences = () => {
  const [close, setClose] = useState(false)

  const handleClose = () => setClose(true)
  const handleOpen = () => setClose(false)
  const HelpDeskIcon = config.susIcons.HelpDeskIcon
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-x-6">
      <div>
        <TitleWrapper sx={{ lineHeight: '1.5rem' }}>
          {t('dataHub.searchParameters.title')}
        </TitleWrapper>
      </div>

      <HelpDeskIcon
        width={25}
        height={25}
        style={{ cursor: 'pointer' }}
        className={close ? '' : 'dark:fill-white'}
        onClick={handleOpen}
      />

      <PaperWrapper
        className={`px-5 w-[65%] py-3.5 ${close ? 'invisible' : ''}`}
        sx={{ borderRadius: '0.375rem', position: 'relative' }}
      >
        <TextWrapper> {t('dataHub.optionsInfo')}</TextWrapper>
        <IconButton
          aria-label={t('general.close')}
          color="inherit"
          sx={{
            position: 'absolute',
            top: '0.1875rem',
            right: '0.0625rem',
          }}
          size="small"
          onClick={handleClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </PaperWrapper>
    </div>
  )
}

export default InfoPreferences
