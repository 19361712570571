import React from 'react'
import { Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { eLocale, iCo2OriginComposition, userState } from 'store/types'
import DataWithLabel from './DataWithLabel'

import './SingleEntityDetails.css'
import { useSelector } from 'react-redux'
import { userReducerState } from 'store/slices/user'

interface iProps {
  data: iCo2OriginComposition
}

const SingleEntityCo2OriginDetails = ({ data }: iProps) => {
  const { t } = useTranslation()
  const userInfo: userState = useSelector(userReducerState)

  return (
    <Paper className="singleEntity-dialog-wrapper">
      <div className="group">
        <DataWithLabel
          label={t('singleEntity.details.description')}
          data={data.fuel}
        />
      </div>
      <div className="group">
        <DataWithLabel
          label={t('singleEntity.details.comment')}
          data={
            userInfo.settings.locale === eLocale.German ? data.fuel : data.fuel
          }
        />
      </div>
    </Paper>
  )
}

export default SingleEntityCo2OriginDetails
