import { PayloadAction } from '@reduxjs/toolkit'
import { eSeverity } from 'components/types'
import apiRequests from 'config/services'
import { iLooseObject } from 'pages/DataHub/filters/types'
import { call, put, takeLeading } from 'redux-saga/effects'
import {
  sagaGetSearchParametersData,
  sagaSetSettingsDefaultFieldsData,
} from 'saga/actions/dataHub'
import { userTypes } from 'saga/actions/user'
import { setToastErrors } from 'store/slices/generalSettings'
import { eSnackbarLocation } from 'store/types'

export function* saveSearchParametersSaga(action: PayloadAction<any>) {
  try {
    const { searchParameters } = action.payload
    const savedParameters: iLooseObject = yield call(
      apiRequests.saveUserSearchParameters,
      searchParameters
    )
    // if the request is succesfully, clear and reset to the default param fields
    // if an error is returned and the response is undefined: clear and reset the default
    if (savedParameters) {
      yield put(
        setToastErrors({
          messages: [
            {
              code: 'settings-success',
              message: 'success.message',
            },
          ],
          isMessageActive: true,
          severity: eSeverity.success,
          messageType: eSnackbarLocation.default,
        })
      )
      const { contextParam } = action?.payload
      const setParams = action?.payload?.setParams
      const contextList = contextParam.split('.')
      const newContext =
        contextList.length > 2
          ? contextList.slice(0, -1).join('.')
          : contextList.join('.')
      const urlParamsFromEntity: iLooseObject = {}
      ;['uuid', 'entityTitle'].forEach((item) => {
        if (searchParameters[item]) {
          urlParamsFromEntity[item] = searchParameters[item]
        }
      })

      setParams({
        contextName: searchParameters['uuid'] ? contextParam : newContext,
        ...urlParamsFromEntity,
      })
      yield put(sagaSetSettingsDefaultFieldsData())
      yield put(sagaGetSearchParametersData())
    }
  } catch (e) {
    // yield put({type: 'TODO_FETCH_FAILED})
  }
}

export default function* setUserSettingsSagaHook() {
  yield takeLeading(
    userTypes.SAGA_SAVE_SEARCH_PARAMETERS,
    saveSearchParametersSaga
  )
}
