import {
  createWorkbookFromFile,
  excelFilesToFileContextCollection,
  getTotalContextCollectionRecords,
  getTotalInputFileContextCollectionDataRecords,
} from 'config/services/MassUpload'
import { call, put, select, takeLeading } from 'redux-saga/effects'
import { massFileUploadActionTypes } from 'saga/actions/massFileUpload'
import {
  accumulateNumberOfRowsProcessedInCurrentFile,
  accumulateProcessedRows,
  addProcessedWorkbook,
  cancelProcessingFiles,
  massFileUploadReducerState,
  setComponentError,
  setCurrentContextProcessed,
  setCurrentFileProcessed,
  setFileOutputCollection,
  setInputFileCompleted,
  setIsProcessingFilesDone,
  setTotalNumberOfRows,
  setTotalNumberOfRowsInCurrentFile,
  toggleProcessingFiles,
} from 'store/slices/massFileUploadSlice'
import {
  tFileContextCollection,
  iContextItem,
  iMassFileUploadState,
} from 'store/types/massFileUpload'
import apiRequests from 'config/services'
import { iDataHubSearch, iSingleEntity } from 'store/types'
import {
  buildSearchRequestUri,
  buildSingleEntityRequestObject,
} from 'config/services/MassUpload/request'
import { buildContextRequestResults } from 'config/services/MassUpload/helpers'
import ExcelJS from 'exceljs'
import { isRoleMissingResponseError } from 'config/typeguards'

function* startMassFileUploadFileProcessingSaga() {
  yield put(toggleProcessingFiles())

  /** Retrieve current state */
  let state: iMassFileUploadState = yield select(massFileUploadReducerState)

  /** Add the workbooks to be processed, for later download process */
  for (let file of state.uploadedFiles) {
    let wb: ExcelJS.Workbook = yield call(createWorkbookFromFile, file)
    yield put(addProcessedWorkbook({ [file.name]: wb }))
  }

  /** Build Input File Collection and commit to Store */
  let fileInputCollection: tFileContextCollection = yield call(
    excelFilesToFileContextCollection,
    state.uploadedFiles
  )

  /** Calculate and set statistics for status calculations */
  yield put(
    setTotalNumberOfRows(
      getTotalInputFileContextCollectionDataRecords(fileInputCollection)
    )
  )

  /** Build the file output collection for writing to the store */
  let fileOutputCollection: tFileContextCollection = {}

  /** Loop all files in the input collection */
  for (const [fileName, contextCollection] of Object.entries(
    fileInputCollection
  )) {
    /** See whether the user cancelled the operation */
    state = yield select(massFileUploadReducerState)
    if (state.isProcessingFilesCancelled) break

    /** Set output collection */
    fileOutputCollection[fileName] = {}
    yield put(setCurrentFileProcessed(fileName))
    yield put(
      setTotalNumberOfRowsInCurrentFile(
        getTotalContextCollectionRecords(contextCollection)
      )
    )

    /** Perform a search call to search API for each context */
    for (const [contextName, contextValues] of Object.entries(
      contextCollection
    )) {
      /** See whether the user cancelled the operation */
      state = yield select(massFileUploadReducerState)
      yield put(setCurrentContextProcessed(contextName))
      if (state.isProcessingFilesCancelled) break

      /** Set output collection */
      fileOutputCollection[fileName][contextName] = []

      /** Each context item (worksheet row) */
      for (const contextItem of contextValues) {
        /** See whether the user cancelled the operation */
        state = yield select(massFileUploadReducerState)
        if (state.isProcessingFilesCancelled) break

        /** Perform a search, first */
        let searchUri = buildSearchRequestUri(
          contextName,
          (contextItem as iContextItem).terms
        )

        let searchResults: iDataHubSearch[] = yield call(
          apiRequests.dataHubSearch,
          searchUri
        )

        /** Break on no search results, but accumulate anyway */
        if (searchResults.length < 1) {
          yield put(accumulateProcessedRows())
          yield put(accumulateNumberOfRowsProcessedInCurrentFile())
          continue
        }

        /** Fetch the entity, second */
        let entityResult: unknown = yield call(
          apiRequests.getSingleEntityRequest,
          buildSingleEntityRequestObject(
            searchResults[0].id,
            contextName,
            contextItem as iContextItem
          ),
          false
        )

        /** Add result to output file context, third */
        if (isRoleMissingResponseError(entityResult)) {
          yield put(setComponentError(`~~massUpload.errors.missingPermissions`))
        } else {
          let result = buildContextRequestResults(
            entityResult as iSingleEntity,
            contextItem as iContextItem
          )
          fileOutputCollection[fileName][contextName].push(result)
        }

        /** Accumulate Rows processed */
        yield put(accumulateProcessedRows())
        yield put(accumulateNumberOfRowsProcessedInCurrentFile())
      }
    }

    /** Push updated output collection into state */
    yield put(setFileOutputCollection(fileOutputCollection))
    yield put(setInputFileCompleted(fileName))
  }

  /** Ramp down the processing */
  if (state.isProcessingFilesCancelled) yield put(cancelProcessingFiles())
  state = yield select(massFileUploadReducerState)
  yield put(setCurrentFileProcessed(undefined))
  yield put(setCurrentContextProcessed(undefined))
  yield put(setIsProcessingFilesDone())
}

export default function* startMassUploadFileProcessingSagaHook() {
  yield takeLeading(
    massFileUploadActionTypes.SAGA_START_FILE_PROCESSING,
    startMassFileUploadFileProcessingSaga
  )
}
