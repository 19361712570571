import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { currencyState, currencyValuesState } from 'store/types'

export const initialState: currencyState = {
  rates: [],
}

//get current reducer state
export const currencyReducerState = (state: RootState) => state.currency

//reducer Object
export const currencyReducer = createSlice({
  name: 'currencyReducer',
  initialState,
  reducers: {
    setCurrencyData: (state, action: PayloadAction<currencyValuesState[]>) => {
      state.rates = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCurrencyData } = currencyReducer.actions

export default currencyReducer.reducer
