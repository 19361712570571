import apiRequests from 'config/services'
import {
  sagaGetOrganizationTeamMembersAction,
  userTypes,
} from 'saga/actions/user'
import { setOrganizationProfileData } from 'store/slices/organizationProfile'
import { iOrganizationData } from 'store/types'
import { PayloadAction } from '@reduxjs/toolkit'
import { iLooseObject } from 'pages/DataHub/filters/types'
import {
  clearFieldsErrors,
  toggleCreateOrganizationModalVisibility,
  toggleOrganizationSuccessModalVisibility,
} from 'store/slices/generalSettings'
import { call, put, takeLeading } from 'redux-saga/effects'

//SET

export function* setOrganizationProfileDataSaga(
  action: PayloadAction<iOrganizationData>
) {
  const organizationFormData = action.payload
  try {
    let response: iLooseObject = yield call(
      apiRequests.setOrganizationProfileData,
      organizationFormData
    )
    yield put(setOrganizationProfileData(response.data))
    if (response.status === 201) {
      //hide registration modal
      yield put(toggleCreateOrganizationModalVisibility())
      // show success modal
      yield put(toggleOrganizationSuccessModalVisibility())
      yield put(sagaGetOrganizationTeamMembersAction(response.data.id))

    }
  } catch (e) {
    //catchind errors
  }
}
//UPDATE
export function* updateOrganizationProfileSaga(action: PayloadAction<any>) {
  const organizationFormData = action.payload.organizationData
  const setSearchParams = action.payload.setSearchParams

  try {
    let response: iLooseObject = yield call(
      apiRequests.updateOrganizationProfileData,
      organizationFormData
    )
    if (response.status === 200) {
      yield put(setOrganizationProfileData(response.data))
      yield put(sagaGetOrganizationTeamMembersAction(response.id))
      yield setSearchParams({ view: 'info' })
      yield put(clearFieldsErrors())
    }
  } catch (e) {
    //catchind errors
  }
}

export default function* setOrganizationProfileDataSagaHook() {
  yield takeLeading(
    userTypes.SAGA_SET_ORGANIZATION_PROFILE_DATA,
    setOrganizationProfileDataSaga
  )

  yield takeLeading(
    userTypes.SAGA_UPDATE_ORGANIZATION_PROFILE_DATA,
    updateOrganizationProfileSaga
  )
}
