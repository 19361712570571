import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { currencyReducerState } from 'store/slices/currency'
import { setLocal } from 'config/lib'
import {
  sagaSetUserCurrencyProfileAction,
  sagaUserProfileRequestAction,
} from 'saga/actions/user'
import { userReducerState } from 'store/slices/user'
import { sagaGetLatestExchangeRates } from 'saga/actions/currency'
import { currencyValuesState, userState } from 'store/types'

const CurrencySwitcher = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(sagaGetLatestExchangeRates())
    dispatch(sagaUserProfileRequestAction())
  }, [dispatch])

  const currencyExchangeRates = useSelector(currencyReducerState).rates
  const userInfo: userState = useSelector(userReducerState)
  const defaultCurrency = userInfo.settings.currency

  return (
    <div className="flex items-center justify-center">
      <FormControl fullWidth={true}>
        <InputLabel id="currency-switcher-label">
          <CurrencyExchangeIcon />
        </InputLabel>
        <Select
          labelId="currency-switcher-label"
          id="currency-switcher"
          defaultValue={defaultCurrency}
          value={defaultCurrency}
          onChange={(e) => {
            const selectedCurrency = e.target.value
            setLocal('currency', selectedCurrency)
            dispatch(sagaSetUserCurrencyProfileAction(selectedCurrency))
          }}
          label={t('general.currencySelector')}
          autoWidth
          data-testid="CurrencySelect-testId">
          {currencyExchangeRates.map((currency: currencyValuesState, index) => {
            const val = currency.currencyCode.toString()
            const key = `currencyCode${val}-${index}`

            return (
              <MenuItem
                key={key}
                value={val}
                data-testid={`CurrencyVariant-${currency.currencyCode}-testId`}>
                {t(`general.currency.${currency.currencyCode}`)}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}

export default CurrencySwitcher
