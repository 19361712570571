import { call, select } from 'redux-saga/effects'
import { iOrganizationData, userState } from 'store/types'
import reqProvider from '../coreRequests'
import { BASE_API } from '../urlPaths'
import { iLooseObject } from 'pages/DataHub/filters/types'
import { userReducerState } from 'store/slices/user'
import { organizationProfileState } from 'store/slices/organizationProfile'

export function* getOrganizationProfileData(): any {
  const userInfo: userState = yield select(userReducerState)
  const orgInfo: iOrganizationData = yield select(
    organizationProfileState
  )
  const organizationId = orgInfo.id || userInfo?.organization?.id
  if (!organizationId) {
    return false
  }
  const path = `${BASE_API}/organizations/${organizationId}` // TODO: Replace with actual path API
  const data: iOrganizationData = yield call(reqProvider.getReq, path)
  return data
}

export function* setOrganizationProfileData(
  organizationFormData: iOrganizationData
) {
  // Object.keys(organizationFormData).forEach((key) => {})
  const path = `${BASE_API}/organizations` // TODO: Replace with actual path API

  const response: iLooseObject = yield call(
    reqProvider.postReq,
    path,
    undefined,
    organizationFormData
  )

  return { data: response.data, status: response.status }
}
export function* updateOrganizationProfileData(
  organizationFormData: iOrganizationData
) {
  const orgInfo: iOrganizationData = yield select(
    organizationProfileState
  )
  const finalOrgData: iLooseObject = {
    ...organizationFormData,
    name: !organizationFormData.name ? '' : organizationFormData.name,
    address: !organizationFormData.address ? '' : organizationFormData.address,
    city: !organizationFormData.city ? '' : organizationFormData.city,
    postalCode: !organizationFormData.postalCode
      ? ''
      : organizationFormData.postalCode,
    country: !organizationFormData.country
      ? null
      : organizationFormData.country,
    vatId: !organizationFormData.vatId ? null : organizationFormData.vatId,
    invoiceFirstName: !organizationFormData.invoiceFirstName
      ? null
      : organizationFormData.invoiceFirstName,
    invoiceLastName: !organizationFormData.invoiceLastName
      ? null
      : organizationFormData.invoiceLastName,
    invoiceEmail: !organizationFormData.invoiceEmail
      ? null
      : organizationFormData.invoiceEmail,
    website: !organizationFormData.website
      ? null
      : organizationFormData.website,
  }

  //create a new final empty json object
  // temporarely make a json obj from the organization data type

  const userInfo: userState = yield select(userReducerState)

  const organizationId = orgInfo?.id || userInfo?.organization.id
  const path = `${BASE_API}/organizations/${organizationId}` // TODO: Replace with actual path API// TODO: Replace with actual path API

  const response: iLooseObject = yield call(
    reqProvider.patchReq,
    path,
    { 'Content-Type': 'application/merge-patch+json' },
    finalOrgData
  )
  return response
}
