import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import { RoutePaths } from 'routes/paths'
import { ContentBody } from 'components/pages/components/ContentBody'
import { IAccordionData, DynamicAccordion } from './components/DynamicAccordion'
import { LogisticsDocumentData } from './Data/LogisticsDocumentData'

const LogisticsDocument: FC = () => {
  return (
      <ContentBody title="knowledgeHub.logistics">
        {LogisticsDocumentData.map((d: IAccordionData, key: number) => (
          <DynamicAccordion data={d} index={key} key={key} />
        ))}
      </ContentBody>
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.knowledgeHubLogistics,
    title: 'knowledgeHub.logistics',
    element: <LogisticsDocument />,
    roles: componentPermissions[componentNames.pageKnowledgeHubLogistics],
    requiresAuthentication: true,
  },
  name: 'Logistics',
}
export default exportObj
