import { ePricingMode } from "components/types";
import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface BigTabProps {
  pricingMode: ePricingMode,
  setPricingMode: Dispatch<SetStateAction<ePricingMode>>
}

export const PricingModeBigTab: FC<BigTabProps> = ({
  pricingMode, 
  setPricingMode
}) => {

  const { t } = useTranslation()

  return(
    <div
      className={`
        relative mb-3 text-center 
        flex flex-col md:flex-row md:justify-start items-center md:items-stretch
        transform -translate-y-5 dark:-translate-y-4
      `}
    >
      <div className={`
        max-w-min px-4 cursor-pointer py-2 rounded-b-md
        transform transition-all
        ${pricingMode === ePricingMode.COMMERCIALS ? 'bg-[#d3f4b0] shadow dark:text-black' : ''}
      `}
        onClick={() => setPricingMode(ePricingMode.COMMERCIALS)}>
        <div className={`text-xl min-w-xs`}>{t('pricing.commercials')}</div>
        <div className={`text-xs`}>{t('pricing.commercialsDescription')}</div>
      </div>
      <div className={`
        max-w-min px-4 cursor-pointer py-2 rounded-b-md
        transform transition-all
        ${pricingMode === ePricingMode.DEVELOPERS ? 'bg-[#d3f4b0] shadow dark:text-black' : ''}
      `}
        onClick={() => setPricingMode(ePricingMode.DEVELOPERS)}>
        <div className={`text-xl min-w-xs`}>{t('pricing.developers')}</div>
        <div className={`text-xs`}>{t('pricing.developersDescription')}</div>
      </div>
    </div>
  )
}