import { useTheme } from '@mui/material'
import React from 'react'
interface IProps {
  className?: string
  onClick?: () => void
  children?: JSX.Element | JSX.Element[]
}

const MainMenuIconWrapper = ({ children, onClick, className }: IProps) => {
  const theme = useTheme()

  return (
    <div
      className={`
      sm:shadow-md sm:rounded-md sm:hover:border-l-paletteGreeen
      lg:w-full lg:shadow-none lg:rounded lg:hover:border-l-paletteYellow
      flex justify-center align-middle box-border border-l-4 border-l-transparent text-center m-0 pt-2 pb-2 border-b border-y-[color:var(--paletteLightGrayDelimiter)] dark:border-y-[color:var(--paletteDarkGrayDelimiter)]
     hover:border-l-4  hover:cursor-pointer hover:bg-[color:var(--paletteLightGray)]  hover: border-l-4  hover:cursor-pointer
      dark:hover:bg-[color:var(--paletteDarkGrayDelimiter)]
      ${className} ${theme.palette.mode}`}
      onClick={onClick}
    >
      <div className="w-[var(--mainMenuWidth)] p-2 overflow-hidden text-xs sm:p-5 lg:p-2">
        {children}
      </div>
    </div>
  )
}

export default MainMenuIconWrapper
