import apiRequests from 'config/services'
import { userTypes } from 'saga/actions/user'
import { setOrganizationProfileData } from 'store/slices/organizationProfile'
import { iOrganizationData } from 'store/types'
import { call, put, takeLeading } from 'redux-saga/effects'

export function* getOrganizationProfileDataSaga() {
  try {
    let result: iOrganizationData = yield call(
      apiRequests.getOrganizationProfileData
    )
    yield put(setOrganizationProfileData(result))
  } catch (e) {}
}

export default function* getOrganizationProfileDataSagaHook() {
  yield takeLeading(
    userTypes.SAGA_GET_ORGANIZATION_PROFILE_DATA,
    getOrganizationProfileDataSaga
  )
}
