import { Chip } from '@mui/material'
import { Stack } from '@mui/system'
import {
  camelCaseToSpaces,
  filterStateToUrlParams,
  urlToObject,
} from 'config/lib'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useSearchParams } from 'react-router-dom'
import { iLooseObject } from '../types'
import { clearAllFilters } from 'store/slices/selectedFieldsSlice'
import { iCustomField } from 'store/types'

export const ChipsWrapper = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const urlPath = location.search
  const chipParams: iLooseObject = urlToObject(urlPath)
  const paramsList = Object.entries(chipParams)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  // delete chip
  const handleChipDelete = useCallback(
    (item: string) => {
      const newParams: iLooseObject = JSON.parse(JSON.stringify(chipParams))
      delete newParams[item]
      // TODO: Remove from redux each chip from this callback so state updates instantly when clicked (fixes last item bug)
      //
      setSearchParams(newParams)
      // make a search based off new params
    },
    [chipParams, setSearchParams]
  )
  return (
    <Stack
      direction="row"
      flexWrap={'wrap'}
      columnGap={1}
      rowGap={1}
      marginBottom={2}
      marginTop={2}
    >
      {urlPath.length > 0
        ? paramsList
            .filter((item) => ['contextName', 'terms'].indexOf(item[0]) === -1)
            .map((item: any, index: number) => {
              if (item[0].length > 0)
                return (
                  <Chip
                    key={`chip${index}`}
                    label={camelCaseToSpaces(item[0])}
                    variant="outlined"
                    onDelete={(filter: iCustomField) =>
                      handleChipDelete(item[0])
                    }
                  />
                )
              return null
            })
        : null}
      {paramsList.filter((item) => {
        if (item[0].length > 0)
          return ['contextName', 'terms'].indexOf(item[0]) === -1
        return null
      }).length > 0 && urlPath.length > 0 ? (
        <>
          <span
            className="flex flex-col justify-center ml-2underline text-[color:var(--paletteYellow)] cursor-pointer"
            onClick={() => {
              dispatch(clearAllFilters())
              // Change url params
              const newParams: iLooseObject = filterStateToUrlParams(
                searchParams,
                []
              )
              if (searchParams.get('terms')) {
                newParams['terms'] = searchParams.get('terms')
              }
              if (searchParams.get('contextName')) {
                newParams['contextName'] = searchParams.get('contextName')
              }
              setSearchParams(newParams)
            }}
          >
            {t('search.clearAll')}
          </span>
        </>
      ) : null}
    </Stack>
  )
}
