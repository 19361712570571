import { ContentBody } from 'components/pages/components/ContentBody'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RoutePaths } from 'routes/paths'

const ProcurementHub: FC = () => {
  const { t } = useTranslation()
  return <ContentBody title={t('procurementHub.title')}></ContentBody>
}
const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.procurementCenter,
    element: <ProcurementHub />,
    title: 'procurementHub.title',
    roles: componentPermissions[componentNames.pageToolsProcurementHub],
    requiresAuthentication: true,
  },
  name: 'ProcurementHub',
}

export default exportObj
