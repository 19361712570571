import { Grid, Slider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { iCustomField, iHubFilter } from 'store/types'

import './CustomRangeValueSlider.css'

interface iProps {
  minValue?: number
  maxValue?: number
  unit?: string
  key: any
  name: string
  displayName: string
  filter: iHubFilter
  defaultValue?: string
  onChange?: (value: iCustomField) => void
}

// TODO: check minValue, maxValue
export const CustomRangeValueSlider = ({
  minValue = 0,
  maxValue = 100,
  unit,
  name,
  key,
  displayName,
  defaultValue,
  filter,
  onChange,
}: iProps) => {
  const [value, setValue] = useState<number>(minValue)
  const [sliderSteps, setsliderSteps] = useState<number>()
  const [displayValue, setDisplayValue] = useState<boolean>(false)

  const maxNumStepsAllowed = 100

  const marks = [
    {
      value: minValue,
      label: minValue.toString(),
    },
    {
      value: maxValue,
      label: maxValue.toString(),
    },
  ]

  useEffect(() => {
    if (filter.steps !== undefined) {
      setsliderSteps(
        Math.min(filter.steps, (maxValue - minValue) / maxNumStepsAllowed)
      )
    } else {
      setsliderSteps((maxValue - minValue) / maxNumStepsAllowed)
    }
  }, [filter, maxValue, minValue])

  const getDefaultValue = useCallback(() => {
    return defaultValue ? parseInt(defaultValue) : minValue
  }, [defaultValue, minValue])

  useEffect(() => {
    const tempDefaultValue = getDefaultValue()
    setValue(tempDefaultValue as number)
    if (tempDefaultValue) setDisplayValue(true)
  }, [defaultValue, getDefaultValue])

  const valueText = useCallback(
    (value: number) => {
      return `${value} ${unit}`
    },
    [unit]
  )

  const valueLabel = useCallback(() => {
    let label = displayName

    if (displayValue) {
      label += ` ${value} ${unit}`
    } else {
      label += ` (${unit})`
    }

    return label
  }, [displayName, displayValue, value, unit])

  const handleChange = useCallback(
    (value: number | number[]) => {
      const newValue: number = typeof value === 'number' ? value : value[0]
      setValue(newValue as number)

      if (onChange) {
        onChange({
          name: name,
          displayName: displayName,
          value: value.toString() || '',
          info: filter,
        })
      }
    },
    [displayName, filter, name, onChange]
  )

  return (
    <Box key={key} className="customRangeValueSlider-wrapper">
      <Typography id="input-slider" className="customRangeValueSlider-label">
        {valueLabel()}
      </Typography>
      <Grid container spacing={1.5} sx={{ marginLeft: 0, width: '100%' }}>
        <Slider
          aria-label={displayName}
          value={value}
          getAriaValueText={valueText}
          sx={{ marginBottom: 0 }}
          marks={marks}
          min={minValue}
          max={maxValue}
          step={sliderSteps}
          onChange={(event: Event, newValue: number | number[]) => {
            setDisplayValue(false)
            setValue(newValue as number)
          }}
          valueLabelDisplay="auto"
          onChangeCommitted={(
            event: Event | SyntheticEvent<Element, Event>,
            value: number | Array<number>
          ) => {
            setDisplayValue(true)
            handleChange(value || 0)
          }}
        />
      </Grid>
    </Box>
  )
}
