import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import React, { useEffect } from 'react'
import NavigationDrawer from './NavigationDrawer/NavigationDrawer'
import RoleCheckWrapper from './RoleCheckWrapper'
import { useLocation } from 'react-router-dom'
import { RoutePaths } from 'routes/paths'
import ApplicationBar from './menu/ApplicationBar/ApplicationBar'
import { FooterSection } from 'components/Footer/FooterSection'

interface IProps {
  children?: JSX.Element | JSX.Element[]
}
export const Wrapper = ({ children }: IProps) => {
  const appBarRoles = componentPermissions[componentNames.applicationBar]
  const history = useLocation()

  const excludeBarsInPaths = [
    RoutePaths.login.toString(),
    RoutePaths.notFound.toString(),
  ]
  const excludeFooterInPaths = [
    RoutePaths.login.toString(),
    RoutePaths.notFound.toString(),
  ]

  const hideAppBar = excludeBarsInPaths.indexOf(history.pathname) === -1
  const hideDrawer = excludeBarsInPaths.indexOf(history.pathname) === -1
  const hideFooter = excludeFooterInPaths.indexOf(history.pathname) === -1

  // Scroll to top when URL or Search Changes 
  const { pathname, search } = useLocation();
  useEffect(() => {
   const myDiv = document.getElementById('containerDiv');
   if(myDiv)
      myDiv.scrollTop = 0;
  }, [pathname, search]);



  return (
    <div className="flex flex-col h-full">
      {hideAppBar ? (
        <RoleCheckWrapper roles={appBarRoles}>
          <ApplicationBar />
        </RoleCheckWrapper>
      ) : null}
      <div
        data-testid="wrapper-testID"
        className="flex flex-row w-full h-[calc(100vh-_var(--topBarHeight))] justify-start content-start"
      >
        {hideDrawer ? <NavigationDrawer /> : null}
        <div id="containerDiv" className="flex ml-0 items-start flex-col pl-0 w-full overflow-y-scroll h-full justify-between">
          {children}
          {hideFooter ? (
            <>
              <FooterSection />
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}
