import { useEffect } from 'react'
import PaperWrapper from 'components/custom/PaperWrapper'
import React from 'react'
import { useTranslation } from 'react-i18next'

import TitleWrapper from 'components/custom/TitleWrapper'

import { Tabs, Tab } from '@mui/material'
import { Box } from '@mui/system'
import OrganizationEditPage from './organization-edit-page'
import OrganizationManageTeam from './organization-manage-team'
import { useSearchParams } from 'react-router-dom'
interface iProps {
  tabPanelIndex?: number
}
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  onClick?: () => void
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

const OrganizationTab = ({ tabPanelIndex }: iProps) => {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(0)
  const [, setSearchParams] = useSearchParams()

  useEffect(() => {
    setValue(tabPanelIndex || 0)
  }, [tabPanelIndex])

  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue)
  return (
    <div className="flex justify-center mx-6 max-[680px]:mx-3 my-3">
      <PaperWrapper
        elevation={2}
        className="w-[80%] max-[680px]:w-full max-lg:w-[92.5%] px-10 max-lg:px-4 py-3"
      >
        <TitleWrapper className="pt-0 pb-2">
          {t('pages.organization')}
        </TitleWrapper>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="organization tabs"
          >
            <Tab
              onClick={() => setSearchParams({ view: 'info' })}
              className="text-lg text-black dark:text-white"
              label={t('organization.edit.profile')}
            />
            <Tab
              onClick={() => setSearchParams({ view: 'team' })}
              className="text-lg ml-10 text-black dark:text-white"
              label={t('organization.edit.title')}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <OrganizationEditPage />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OrganizationManageTeam />
        </TabPanel>
      </PaperWrapper>
    </div>
  )
}

export default OrganizationTab
