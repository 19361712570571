
import { useState } from 'react'
import { FC } from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TitleWrapper from 'components/custom/TitleWrapper'
import { eElementOnMouseOverEffect, eTypographyVariant } from 'components/types'
const tableOfContentHover = eElementOnMouseOverEffect.hover
  const headingClassName = 'pt-4 pb-2 text-paletteOrange uppercase'
  const heading6BoldTextClassName =
    'pt-4 pb-2 text-paletteDarkBlack dark:text-white normal-case'
  const orderedListDiscClassName = 'list-disc list-inside m-4'
  const documentVersioningTextWrapperClassName = 'mt-14'

  const accordionHeadingClassName = 'text-paletteOrange uppercase'


export interface IAccordionData {
    title: string;
    content?: string;
    isOpen?: boolean;
    children?: IAccordionData[];
    noNumberChildren?: IAccordionData[];
   
  }
  
  
export  const DynamicAccordion: FC<{
  data: IAccordionData
  index: number
  isChild?: boolean
  parentKey?: number
  isNumbering?: boolean
}> = ({ data, index, isChild,parentKey, isNumbering = true }) => {
  const [expanded, setExpanded] = useState(!!data?.isOpen || false)
  const handleChange = () => {
    setExpanded(!expanded)
  }

  const getNumber = () => {
    return isChild && parentKey !== undefined
      ? `${parentKey + 1}.${index + 1}`
      : index + 1
  }
  return (
    <Accordion
      elevation={2}
      className="rounded-2xl before:hidden mt-5 mb-5"
      onChange={handleChange}
      expanded={expanded}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="">
        <TitleWrapper
          variant={
            !isChild ? eTypographyVariant.h6 : eTypographyVariant.subtitle1
          }
          className={accordionHeadingClassName}
        >
          <p
            className="d-inline"
            dangerouslySetInnerHTML={{
              __html: isNumbering ? `${getNumber()}. ${data.title}` : data.title,
            }}
          ></p>
        </TitleWrapper>
      </AccordionSummary>
      <AccordionDetails className="">
        {data.content && (
          <p dangerouslySetInnerHTML={{ __html: data.content }}></p>
        )}
        {data.children &&
          data.children.map((child, key) => (
            <DynamicAccordion
              key={key}
              data={child}
              index={key}
              isChild={true}
              parentKey={index}
            />
          ))}
          {data.noNumberChildren &&
          data.noNumberChildren.map((child, key) => (
            <DynamicAccordion
              key={key}
              data={child}
              index={key}
              isChild={true}
              parentKey={index}
              isNumbering={false}
            />
          ))}
      </AccordionDetails>
    </Accordion>
  )
}