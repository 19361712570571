import { FC, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import {
  sagaAddMassUploadInputFile,
  sagaSetMassUploadComponentError,
} from 'saga/actions/massFileUpload'
import { eTypographyVariant } from 'components/types'

interface DropzoneProps {
  visible: boolean
}

const Dropzone: FC<DropzoneProps & React.HTMLAttributes<HTMLDivElement>> = ({
  visible,
  ...props
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const filenameRule = new RegExp(/(?!\.)xlsx?$/)
      const correctFormat = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`
      const maxNumberOfFiles = 3
      let isError = false
      let approvedFiles: File[] = []

      /** Clear any error messages from the store */
      dispatch(sagaSetMassUploadComponentError(undefined))

      /** See whether the user is trying to upload too many files */
      if (acceptedFiles.length > maxNumberOfFiles) {
        isError = true
        let errorMessage = t('massUpload.errors.tooManyFiles', {
          maxNumberOfFiles: maxNumberOfFiles,
        })
        dispatch(sagaSetMassUploadComponentError(errorMessage))
        return
      }

      /** Check file name and type */
      acceptedFiles.forEach((file) => {
        const isNameCorrect = filenameRule.test(file.name)
        const isFormatCorrect = file.type === correctFormat ? true : false

        if (isNameCorrect && isFormatCorrect) {
          approvedFiles.push(file)
        } else {
          isError = true
          let errorMessage = t('massUpload.errors.incorrectFileType')
          dispatch(sagaSetMassUploadComponentError(errorMessage))
        }
      })

      /** If all is good, dispatch */
      if (!isError) {
        dispatch(sagaAddMassUploadInputFile(approvedFiles))
      }
    },
    [dispatch, t]
  )

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const styles = {
    visible: `transition transition-width w-full flex flex-col justify-center items-center h-24 border border-dashed border-gray-500 lg:h-48`,
    hidden: `hidden`,
  }

  return (
    <div
      {...getRootProps()}
      {...props}
      className={!visible ? styles.hidden : styles.visible}
    >
      <input {...getInputProps()} />
      <Typography
        variant={eTypographyVariant.caption}
        className={`w-4/5 text-center`}
      >
        {t('massUpload.uploadDragArea', { fileType: '.xls(x)' })}
      </Typography>
    </div>
  )
}

export default Dropzone
