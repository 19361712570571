import * as config from 'config/services/requests'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { menuItemsReducerState } from 'store/slices/menuItems'
import ChildMenuItems from '../menu/ChildMenuItems'
import MainMenuIconWrapper from 'components/custom/MainMenuIconWrapper/MainMenuIconWrapper'
import {
  clearToastErrors,
  isDrawerOpenReducerState,
  toggleDrawer,
} from 'store/slices/generalSettings'
import { RoutePaths } from 'routes/paths'
import { userReducerState } from 'store/slices/user'
import { Roles, componentNames } from 'config/roles'
import { LightModeRounded, DarkModeRounded } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { componentPermissions } from 'config/permissions'
import CustomSvgStyled from '../CustomSvgStyled'
import RoleCheckWrapper from '../RoleCheckWrapper'
import { useState, useCallback } from 'react'
import { sagaClearUserProfileAction } from 'saga/actions/user'
import UserProfileIcon from 'assets/icons/030-user.svg'
import { useTheme } from '@mui/material'

interface IProps {
  children?: JSX.Element | JSX.Element[]
}

const NavigationDrawer = ({ children }: IProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useLocation()
  let navigate = useNavigate()
  const isDrawerOpen = useSelector(isDrawerOpenReducerState)
  const menuItems = useSelector(menuItemsReducerState).menu
  const userData = useSelector(userReducerState)
  const userRoles = userData.roles

  const isOnChildlessMainMenu: boolean =
    [RoutePaths.userSettings.toString(), RoutePaths.comingSoon.toString()].some(
      (item) => history.pathname.includes(item)
    ) || history.pathname === RoutePaths.dashboard.toString()
  const menuItemIndex = menuItems.findIndex(
    (item) => item.routePath === history.pathname
  )
  const mainMenuPathHasChildren: boolean =
    typeof menuItems[menuItemIndex]?.childPages !== 'undefined'

  const hideMenuForMobileClasses = ' sm:hidden lg:flex lg:inline-flex '

  const UserMenuItems = () => {
    const theme = useTheme()
    const isDarkMode = theme.palette.mode === 'dark'

    const toggleDarkMode = () => {
      if (typeof window !== 'undefined') {
        let colorScheme = localStorage.getItem('color-scheme')
        switch (colorScheme) {
          case 'dark':
            localStorage.setItem('color-scheme', 'light')
            break
          case 'light':
            localStorage.setItem('color-scheme', 'dark')
            break
          default:
            localStorage.setItem('color-scheme', 'dark')
        }
      }
      navigate(0)
    }

    const [isUserMenuOpen, setUserMenuOpen] = useState<null | HTMLElement>(null)
    const handleUserMenuClose = useCallback(() => {
      setUserMenuOpen(null)
    }, [])
    const handleUserAvatarClick = (e: React.MouseEvent<HTMLDivElement>) =>
      setUserMenuOpen(e.currentTarget)

    const handleLogout = () => {
      dispatch(clearToastErrors())
      dispatch(sagaClearUserProfileAction())
      navigate(RoutePaths.login)
    }
    const handleMenuClick = (route: string) => {
      handleUserMenuClose()
      navigate(route)
    }

    return (
      <div className="flex flex-col items-center mr-2">
        <MainMenuIconWrapper onClick={toggleDarkMode}>
          <div className="flex-column">
            <IconButton
              size={`small`}
              edge={`start`}
              color={`primary`}
              aria-label={`toggle light/dark`}
            >
              {isDarkMode ? <LightModeRounded /> : <DarkModeRounded />}
            </IconButton>
            <div className="p-0 text-xs bg-inherit">
              {' '}
              {!isDarkMode ? t('general.lightMode') : t('general.darkMode')}
            </div>
          </div>
        </MainMenuIconWrapper>

        <MainMenuIconWrapper>
          <div className="flex-column" onClick={handleUserAvatarClick}>
            <IconButton
              id={`user-options-avatar`}
              edge={`start`}
              size={'small'}
              color={`primary`}
              aria-label={`account options`}
              className="h-17 w-17"
              aria-controls={isUserMenuOpen ? 'user-options-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={isUserMenuOpen ? 'true' : undefined}
            >
              {' '}
              <CustomSvgStyled
                src={UserProfileIcon}
                className={isDarkMode ? 'invert-[1]' : ''}
              />
            </IconButton>
            <div className="p-0 text-xs bg-inherit">{userData.lastName}</div>
          </div>
          <Menu
            id={`user-options-menu`}
            anchorEl={isUserMenuOpen}
            open={Boolean(isUserMenuOpen)}
            onClose={handleUserMenuClose}
            MenuListProps={{
              'aria-labelledby': `user-options-avatar`,
            }}
            className="left-[8rem]"
          >
            <MenuItem
              onClick={() => {
                handleMenuClick(RoutePaths.userSettings)
              }}
            >
              {t('pages.mySettings')}
            </MenuItem>
            <RoleCheckWrapper
              restrictedRoles={
                componentPermissions[
                  componentNames.organizationProfileRestricted
                ]
              }
            >
              <MenuItem
                onClick={() => handleMenuClick(RoutePaths.organizationProfile)}
              >
                {t('pages.organization')}
              </MenuItem>
            </RoleCheckWrapper>
            <MenuItem onClick={handleLogout}>{t('pages.logout')}</MenuItem>
          </Menu>
        </MainMenuIconWrapper>
      </div>
    )
  }

  return (
    <div
      className={`${
        isDrawerOpen.isDrawerOpen
          ? 'lg:flex lg:inline-flex lg:border-l-[color:var(--paletteLightGrayDelimiter)] sm:border-l-0'
          : hideMenuForMobileClasses
      } flex self-start items-start flex-row top-[var(--topBarHeight)] w-auto shadow-[inset_-1px_-2px_2px_rgba(0,0,0,0.3)] h-full`}
    >
      <div
        className={`sm:w-screen sm:flex sm:flex-wrap lg:w-[var(--mainMenuWidth)] overflow-hidden lg:items-stretch lg:h-[100%] flex-col justify-between ${
          !isOnChildlessMainMenu || mainMenuPathHasChildren
            ? hideMenuForMobileClasses
            : ''
        }`}
      >
        <div>
          {menuItems
            .filter((menuItem) =>
              userRoles.some((userRole) =>
                menuItem.roles
                  ? menuItem.roles.indexOf(userRole) !== -1 ||
                    menuItem.restrictedRoles
                    ? !userRoles.some((item: Roles) =>
                        menuItem.restrictedRoles
                          ? menuItem.restrictedRoles.indexOf(item) !== -1
                          : false
                      )
                    : false
                  : true
              )
            )
            .map((obj, i) => {
              const childPages = obj.childPages ?? []
              const selectedChildPage: boolean =
                childPages.findIndex(
                  (item) => history.pathname === item.routePath
                ) !== -1
              const MainMenuIcon = obj.icon
                ? config.susIcons[obj.icon] || config.IconsTypes.DataHub
                : () => null
              const isSelectedMenuItem =
                history.pathname === obj?.routePath || selectedChildPage

              const handleMenuClick = () => {
                if (obj?.externalLink) {
                  // Open external link in a new tab
                  window.open(obj.externalLink, '_blank')
                } else if (obj?.routePath) {
                  // Navigate internally
                  const navigatePath = obj.routePath.toString()
                  navigate(navigatePath)

                  if (!obj?.childPages) {
                    dispatch(toggleDrawer())
                  }
                }
              }

              return (
                <div
                  key={'menu-item-' + i}
                  className="sm:w-1/3 sm:m-[8%] lg:m-0 lg:w-full"
                >
                  {obj.label ? (
                    <>
                      <MainMenuIconWrapper onClick={handleMenuClick}>
                        <Link
                          className="flex items-center flex-col"
                          to={obj.routePath ? obj.routePath : '#'}
                        >
                          <MainMenuIcon
                            className={` flex max-w-[24px] sm:max-h-[24px] hover:text-primary hover:fill-[color:var(--paletteGreen)] ${
                              isSelectedMenuItem
                                ? 'fill-[color:var(--paletteGreen)] dark:fill-[color:var(--paletteGreen)] text-primary'
                                : 'dark:fill-white'
                            }`}
                          />
                          <div
                            className="p-0 text-xs bg-inherit"
                            role="region"
                            aria-label={t(obj.label)}
                          >
                            {t(obj.label).toUpperCase()}
                          </div>
                        </Link>
                      </MainMenuIconWrapper>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )
            })}
        </div>
        <UserMenuItems />
      </div>
      <ChildMenuItems
        className={
          isOnChildlessMainMenu && !mainMenuPathHasChildren
            ? hideMenuForMobileClasses
            : ''
        }
        menuItems={menuItems}
      />
    </div>
  )
}

export default NavigationDrawer
