import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import { iFormInputError } from 'components/types'
interface IProps {
  name: string
  label?: string
  placeholder?: string
  defaultValue?: string
  onChange: (value: string) => void
  onBlur?: (value: unknown) => void
  className?: string
  helperText?: string
  error?: iFormInputError
}

const CustomStringInput = ({
  name,
  onChange,
  onBlur,
  label,
  placeholder,
  defaultValue,
  className = 'w-full',
  helperText,
  error = { active: false, text: '' },
}: IProps) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [placeholderText, setPlaceholderText] = useState('')

  useEffect(() => {
    setInputValue(defaultValue || '')
    setPlaceholderText(placeholder || '')
  }, [defaultValue, placeholder, setInputValue])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target) {
        const newValue = event.target.value
        setInputValue(newValue)

        if (onChange) {
          onChange(newValue)
        }
      }
    },
    [onChange]
  )

  return (
    <TextField
      name={name}
      id={label}
      className={className}
      label={label}
      helperText={error.active ? error.text : helperText}
      defaultValue={inputValue}
      value={inputValue}
      placeholder={placeholderText}
      onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event)}
      type="string"
      error={error.active}
      fullWidth
      onBlur={onBlur}
    />
  )
}

export default CustomStringInput
