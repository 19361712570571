import { Paper } from '@mui/material'
import CurrencySwitcher from './custom/CurrencySwitcher'
import LanguageSwitcher from './custom/LanguageSwitcher'

const ApplicationFooter = () => {
  return (
    <div className="w-full text-center mt-8 mb-4 mb-auto">
      <div className="flex justify-center">
        <Paper className="p-3 w-80" elevation={2}>
          <div className="grid grid-cols-2 gap-2">
            <LanguageSwitcher />
            <CurrencySwitcher />
          </div>
        </Paper>
      </div>
    </div>
  )
}

export default ApplicationFooter
