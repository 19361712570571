import { useEffect, useState } from 'react'
import './App.css'
import myRoutes from './routes'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material'
import 'config/globalStyles.css'

import { sustamizeDarkTheme, sustamizeLightTheme } from 'config/sustamize.theme'
import { useDispatch, useSelector } from 'react-redux'
import { PrivateRoute, PublicRoute } from 'routes/customRouteWrappers'
import {
  HistoryContext,
  UrlContextManipulator,
} from 'config/context/UrlContext'

import {
  messageReducerState,
  removeToastItemByCode,
  toggleDrawer,
} from 'store/slices/generalSettings'
import { eSnackbarLocation } from 'store/types'
import { sagaUserProfileRequestAction } from 'saga/actions/user'
import Snackbar from 'awesome-snackbar'
import { useTranslation } from 'react-i18next'
import { eSeverity } from 'components/types'

function App(): JSX.Element {
  const userPrefersDark: boolean = useMediaQuery('(prefers-color-scheme: dark)')
  const { t } = useTranslation()

  /** useUrl Hook Context */
  const [history, setHistory] = useState<URL[]>([])
  const defaultHistoryContext = { history, setHistory }

  const dispatch = useDispatch()
  const messageState = useSelector(messageReducerState).toastErrorData
  //first thing to do when the page loads for the first time ever (this should load only once.state dependencies must not be added to it )
  useEffect(() => {
    //request to get user information
    dispatch(sagaUserProfileRequestAction())
  }, [dispatch])

  useEffect(() => {
    if (userPrefersDark) {
      dispatch(toggleDrawer())
    }
  }, [dispatch, userPrefersDark])
  let colorScheme = localStorage.getItem('color-scheme')
  const isColorSchemeLight = !colorScheme || colorScheme === 'light'
  const currentColorScheme = isColorSchemeLight ? 'light' : 'dark'

  const publicRoutes = (myModule: any, index: number, condition: boolean) => {
    return condition ? (
      <Route
        key={`route${index}`}
        path={myModule.routeProps.path}
        element={<PublicRoute myModule={myModule} />}
      />
    ) : (
      myModule.routeProps.path.map((path: string) => (
        <Route
          key={`route${index}`}
          path={path}
          element={<PublicRoute myModule={myModule} />}
        />
      ))
    )
  }

  const privateRoutes = (myModule: any, index: number, condition: boolean) => {
    return condition ? (
      <Route
        key={`route${index}`}
        path={myModule.routeProps.path}
        element={<PrivateRoute myModule={myModule} />}
      />
    ) : (
      myModule.routeProps.path.map((path: string) => (
        <Route
          key={`route${index}`}
          path={path}
          element={<PrivateRoute myModule={myModule} />}
        />
      ))
    )
  }
  useEffect(() => {
    let toastBackgroundColor = 'var(--paletteRed)'
    let toastTextColor = 'var(--paletteWhite)'
    switch (messageState.severity) {
      case eSeverity.error:
        toastBackgroundColor = 'var(--paletteRed)'
        break
      case eSeverity.info:
        toastBackgroundColor = 'var(--paletteYellow)'
        toastTextColor = 'var(--paletteBlack)'
        break
      case eSeverity.success:
        toastBackgroundColor = 'var(--paletteGreen)'
        break
      case eSeverity.warning:
        toastBackgroundColor = 'var(--paletteOrange)'
        break
      default:
        break
    }
    if (
      messageState.isMessageActive &&
      messageState.messageType === eSnackbarLocation.default
    ) {
      messageState.messages.forEach((item) => {
        new Snackbar(
          `<div class="container pl-[0px] pr-[0px] pb-[5px]">
          <b class="message text-[18px]">${t(
            `request.response.${item?.code}.title`
          )}</b>
          <p class="pl-[0px] pr-[0px] text-[14px] pt-[10px]">${t(
            `request.response.${item?.code}.message`
          )}</p>
          `,
          {
            timeout: 5000,
            position: 'bottom-right',
            actionText: 'X',
            style: {
              container: [
                ['background-color', toastBackgroundColor],
                ['border-radius', '5px'],
                ['padding-top', '15px'],
                ['align-items', 'baseline'],
              ],
              message: [['color', toastTextColor]],
              bold: [['font-weight', 'bold']],
              actionButton: [['color', toastTextColor]],
            },
          }
        )
        dispatch(removeToastItemByCode(item?.code as string))
      })
    }
  }, [dispatch, messageState, t])

  return (
    <div className={currentColorScheme}>
      <ThemeProvider
        theme={isColorSchemeLight ? sustamizeLightTheme : sustamizeDarkTheme}
      >
        <HistoryContext.Provider value={defaultHistoryContext}>
          <Router>
            <UrlContextManipulator />
            <Routes>
              {myRoutes.map((myModule: any, index: number) => {
                const pathIsString =
                  typeof myModule.routeProps.path === 'string'

                return myModule.routeProps.requiresAuthentication
                  ? privateRoutes(myModule, index, pathIsString)
                  : publicRoutes(myModule, index, pathIsString)
              })}
            </Routes>
          </Router>
        </HistoryContext.Provider>

        <CssBaseline />
      </ThemeProvider>
    </div>
  )
}

export default App
