import { Checkbox, FormControl, FormControlLabel } from '@mui/material'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
import {
  eBillingPeriod,
  eButtonSize,
  eColor,
  eTypographyVariant,
} from 'components/types'
import { susIcons } from 'config/services/requests'
import { Children, FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { sagaSubmitSubscriptionConfiguration, sagaUpdateSubscriptionConfiguration } from 'saga/actions/subscriptionOrder'
import { iOrganizationData } from 'store/types'
import { eProductKey, iDiscountPriceListing, iSubscriptionOrder, iSubscriptionOrderCalculation } from 'store/types/subscriptionOrder'
import { toScaledPrice } from '../helpers'
import { userReducerState } from 'store/slices/user'
import { setSubscriptionOrder } from 'store/slices/subscriptionOrderSlice'
import { RoutePaths } from 'routes/paths'

const positionClass = `flex flex-row lg:gap-x-8 gap-x-4 lg:min-w-[300px] justify-between mb-2`
const remarkClass = ``

interface PositionProps {
  name: string,
  productKey: eProductKey,
  pricePerLicense: string,
  numberOfLicenses: number,
  priceTotal: string,
  isDeletable: boolean,
  i18nFn: any,
  remark?: string
  billingType: eBillingPeriod | undefined
}

interface SimplePositionProps {
  name: string,
  priceTotal: string,
  isDiscount?: boolean,
  className?: string
}

interface PositionContainerProps {
  removeHandler: (p: PositionProps) => void,
  children: JSX.Element[],
  isLoading?: boolean,
  className?: string,
}

const Position = (props: PositionProps) => {
  return (
    <div className={`min-w-max`}>
      <div className={``}>
        <span className={`font-bold`}>{props.name}</span>
        {props.remark && (
          <span className={remarkClass}>({props.remark})</span>
        )}
      </div>
      <div className={positionClass}>
        <span>
          {props.pricePerLicense}
          &nbsp;x {props.numberOfLicenses}
          &nbsp;
          {props.i18nFn(`offerings.license`, { count: props.numberOfLicenses })}
          &nbsp;x 1{' '}
          {props?.billingType === eBillingPeriod.YEARLY
            ? props.i18nFn(`offerings.year`)
            : props.i18nFn(`offerings.month`)}
        </span>
        <span>{props.priceTotal}</span>
      </div>
    </div>
  )
}

const SimplePosition = ({
  name,
  priceTotal,
  isDiscount = false,
  className
} : SimplePositionProps) => {

  const discountPriceStyle = `text-paletteRed`

  return (
    <div className={`${positionClass} ${isDiscount && `text-xs`} ${className}`}>
      <span className={`italic`}>{name}</span>
      <span className={isDiscount ? discountPriceStyle : ``}>
        {isDiscount ? `-` : `` }{priceTotal}
      </span>
    </div>
  )
}

const PositionContainer = ({
  removeHandler,
  children,
  isLoading,
  className
}: PositionContainerProps) => {

  const iconClass = `
    fill-paletteGray3 dark:fill-gray-100
    w-4 h-4 mb-1 ml-3
    lg:-mr-8
    absolute right-0 top-0
    cursor-pointer
  `

  return (
    <div className={className}>
      {Children.map(children, (child, i) => {

        if (child === null) return

        const props: PositionProps = (child.props)
        if (!props) return null

        return (
          <div className={`min-w-max relative lg:min-w-[380px]`}>
            <child.type {...child.props} />

            {props?.isDeletable
              ?  <susIcons.Trash 
                  className={iconClass}
                  onClick={() => removeHandler(props)}
                ></susIcons.Trash>
              : <div className={iconClass}></div>
            }

          </div>
      )})}
    </div>
  )
}

interface OrderSummaryProps {
  organization: iOrganizationData,
  subscriptionOrder?: iSubscriptionOrder,
  subscriptionOrderCalculation?: iSubscriptionOrderCalculation,
  children?: JSX.Element[],
  className?: string,
  isLoading: boolean
}

export const OrderSummary: FC<OrderSummaryProps> = ({
  organization,
  subscriptionOrder,
  subscriptionOrderCalculation,
  children,
  className,
  isLoading
  }) => {

  const {t} = useTranslation()
  const dispatch = useDispatch()

  const [discountListings, setDiscountListings] = useState<iDiscountPriceListing[]>([])

  useEffect(() => {

    /** Reset */
    setDiscountListings([])

    let flattenedDiscounts = subscriptionOrderCalculation
      ?.productPrices
      ?.map(pp => pp.discounts)
    
    if (flattenedDiscounts && flattenedDiscounts.length > 0) {
      const allDiscounts = flattenedDiscounts
        .reduce((prev, curr) => prev.concat(curr))

      allDiscounts && setDiscountListings(allDiscounts)
    }

  }, [subscriptionOrderCalculation])

  const {
    settings
  } = useSelector(userReducerState)

  const renderDiscounts = (discounts: iDiscountPriceListing[]): JSX.Element[] =>
    discounts.map((discount, i) => (
        <SimplePosition
          className={`mb-0.5`}
          name={t(`offerings.productKeys.${discount.productKey}`)}
          priceTotal={toScaledPrice(discount.discountAmount.unscaled, settings.locale)}
          key={i}
          isDiscount
        />
    ))

  const handleRemovePosition = (p: PositionProps) => {
    if (!subscriptionOrder) return

    let order: iSubscriptionOrder = {...subscriptionOrder}

    let filteredProducts = order.products
      .filter(product => product.productKey !== p.productKey)

    /** Remove Mass Upload upon Materials Removal */
    if (
      p.productKey === eProductKey.SUST_MATERIALS_DB
        && order.products
          .map(p => p.productKey)
          .includes(eProductKey.SUST_MASS_UPLOAD_FEATURE)
    ) {
      filteredProducts = filteredProducts
        .filter(fp => fp.productKey !== eProductKey.SUST_MASS_UPLOAD_FEATURE)
    }

    order.products = filteredProducts

    dispatch(setSubscriptionOrder(order))
  }

  const toggleAcceptTerms = () => {
    const isAcceptTerms = subscriptionOrder?.termsAccepted

    if (isAcceptTerms !== undefined) {

      const newSubscriptionConfiguration: iSubscriptionOrder = {
        ...subscriptionOrder!,
        ...{termsAccepted: !isAcceptTerms}
      }

      dispatch(
        sagaUpdateSubscriptionConfiguration(
          newSubscriptionConfiguration
        )
      )
    }
  }

  const handleSubmitOrder = () => {
    subscriptionOrder &&
      dispatch(sagaSubmitSubscriptionConfiguration(subscriptionOrder))
  }

  const opaque = {
    none: `opacity-0`,
    half: `opacity-50`,
    full: `opacity-100`
  }

  return !subscriptionOrderCalculation
  ? (<div className={`
    ${className}
    min-w-[150px] lg:min-w-[300px] 
    lg:border-l lg:border-l-gray-300 lg:ml-4
  `}>
      <TitleWrapper variant={eTypographyVariant.h5}>
        {t(`offerings.orderSummary`)}
      </TitleWrapper>
      <div className={`flex flex-row justify-center mt-2 lg:mt-8 lg:min-w-[380px]`}>
        <span className={`text-xs`}>{t(`offerings.calculating`)}...</span>
      </div>
    </div>
  )
  : (
    <>
    <div className={`
      ${className}
      lg:min-w-[450px]
      lg:border-l lg:border-l-gray-300 lg:ml-4
    `}>
      <TitleWrapper variant={eTypographyVariant.h5}>
        {t(`offerings.orderSummary`)}
      </TitleWrapper>
      <div className={`
        w-full text-sm
        ${isLoading ? opaque.half : opaque.full}
      `}>
        <PositionContainer 
          removeHandler={handleRemovePosition}
          isLoading={isLoading}
        >
            {subscriptionOrderCalculation?.productPrices?.map((lineItem, i) => {

              const { 
                productKey,
                origPrice
              } = lineItem

              const toDecimal = (unscaled: number, scaling: number): number => {
                return unscaled / Math.pow(10, scaling)
              }

              const scale = subscriptionOrderCalculation.priceScale
              const numberOfLicenses = subscriptionOrder?.licenses || 0
              const totalPrice = numberOfLicenses * toDecimal(
                origPrice.unscaled, 
                scale
              )

              /** Package Positions */
              if ( productKey === eProductKey.SUST_STANDARD_PACKAGE
                || productKey === eProductKey.SUST_PRO_PACKAGE) {
                  return (
                    <Position
                      key={i}
                      name={t(`offerings.productKeys.${productKey}`)}
                      productKey={productKey}
                      numberOfLicenses={numberOfLicenses}
                      pricePerLicense={toScaledPrice(origPrice.unscaled, settings.locale, scale)}
                      priceTotal={toScaledPrice(totalPrice, settings.locale, 0)}
                      isDeletable={false}
                      i18nFn={t}
                      billingType={subscriptionOrder?.billingType}
                    />
                  )
              
              /** Product Positions */
              } else {
                return (
                  <Position
                    key={i}
                    name={t(`offerings.productKeys.${productKey}`)}
                    productKey={productKey}
                    pricePerLicense={toScaledPrice(
                      origPrice.unscaled,
                      settings.locale,
                      scale
                    )}
                    numberOfLicenses={numberOfLicenses}
                    priceTotal={toScaledPrice(totalPrice, settings.locale, 0)}
                    isDeletable
                    i18nFn={t}
                    billingType={subscriptionOrder?.billingType}
                  />
                )
              }})}
          {/*}
          */}
        </PositionContainer>
        <PositionContainer 
          removeHandler={()=>{}}
          isLoading={isLoading}
          className={`mt-4`}
        >
          {discountListings.length > 0 ? (
            renderDiscounts(discountListings) as JSX.Element[]
          ) : []}
        </PositionContainer>
      {subscriptionOrderCalculation?.productPrices?.length < 1 && (
        <span>{t(`offerings.noItemsSelected`)}</span>
      )}
    </div>
      
      <div className={`
        ${isLoading ? opaque.half : opaque.full}
      `}>
        <div className={`w-full flex flex-col flex-nowrap`}>
          <div className={`w-full flex flex-row justify-between border-t border-t-gray-600 pt-2 mt-2`}>
            <span className={`font-bold text-sm`}>{t(`offerings.totalNet`)}</span>
            <span className={`text-sm`}>
              {toScaledPrice(
                subscriptionOrderCalculation.totalNet.unscaled, 
                settings.locale,
                subscriptionOrderCalculation.priceScale
              )}
            </span>
          </div>
          <div className={`w-full flex flex-row justify-between`}>
            <div className={`flex flex-col items-start justify-center`}>
              <span className={`text-sm`}>
                {t(`offerings.VAT`)} {subscriptionOrderCalculation.vatPercent}%
              </span>
              <span className={`text-[7px] text-paletteRed`}>
                {t(`offerings.vatZero`)}
              </span>
            </div>
            <span className={`text-sm`}>
              {toScaledPrice(
                subscriptionOrderCalculation.vatAmount.unscaled, 
                settings.locale,
                subscriptionOrderCalculation.priceScale
              )}
            </span>
          </div>
          <div className={`w-full flex flex-row justify-between border-t border-t-gray-600 pt-2 mt-2`}>
            <span className={`font-bold text-sm`}>{t(`offerings.totalGross`)}</span>
            <span className={`text-sm`}>
              {toScaledPrice(
                subscriptionOrderCalculation.totalGross.unscaled, 
                settings.locale,
                subscriptionOrderCalculation.priceScale
              )}
            </span>
          </div>
        </div>
      </div>

      <div className={`mt-2`}>
        <FormControl>
          <FormControlLabel
              control={<Checkbox className={`py-0.5`} />}
              label={<span className={`text-xs`}>
                {<Trans 
                  i18nKey={`offerings.acceptDaaS`} 
                  components={{
                    a: <Link to={RoutePaths.dataAsServiceAggrement} target='_blank' />,
                    span: <span className={`text-primary underline`} />
                  }}
                />}
              </span>}
              checked={subscriptionOrder?.termsAccepted} 
              onChange={() => toggleAcceptTerms()}
              sx={{ '& .MuiTypography-body1': {width: '100%'}}}
            />
        </FormControl>
      </div>

      <div className={`flex flex-row justify-end mt-3 lg:-mr-8`}>
        <ButtonWrapper
          color={eColor.secondary}
          size={eButtonSize.small}
          disabled={
            !subscriptionOrder?.termsAccepted
              || (subscriptionOrder?.packageKey === undefined 
                && (subscriptionOrder?.products?.length || 0) < 1)
          }
          className={`max-h-9`}
          onClick={() => handleSubmitOrder()}
        >
          {t(`offerings.purchase`)}
        </ButtonWrapper>
      </div>
    </div>
  </>
  ) 
}