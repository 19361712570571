import { iOffering } from "store/types/billingPricing";
import { eAdditionalDatabaseSelection, eColor, tOfferingType } from "components/types";
import Popularity from '../assets/icons/popularity.svg';
import { iPricing } from "store/types/billingPricing";
import { eProductKey } from "store/types/subscriptionOrder";

interface iOfferingSettings {
  addOnDatabaseKey?: eProductKey,
  i18nPrefix: string,
  labelColor: string,
  labelIcon?: string,
  pricing?: iPricing,
  isElevated?: boolean,
  isFeaturesMultipleColumns?: boolean,
  isActionDisabled?: boolean,
  actionColor?: eColor,
  actionFunction?: () => void,
}

export const fallbackFunctionForUpgradeScenario = () => window && window
  .open(`https://share-eu1.hsforms.com/18y3Y42pRSZKENQ3xPp8H4Afaf1j`, '_blank')
  ?.focus()

const developerFunction = () => window && window
  .open(`https://share-eu1.hsforms.com/10ly5Yf6yRPuOljlGmC72iwfaf1j`, `_blank`)
  ?.focus()

const settings: {[key: string]: {[key: string]: iOfferingSettings }} = {
  generic: {
    electronics: {
      addOnDatabaseKey: eProductKey.SUST_ELECTRONICS_DB,
      i18nPrefix: "offerings.generic.electronics.",
      labelColor: '#63CF00',
      pricing: {
        annualPrice: 2300,
        monthlyPrice: 220
      },
      isFeaturesMultipleColumns: true,
      actionColor: eColor.primary,
    }
  },
  commercials: {
    flex: {
      i18nPrefix: "offerings.commercials.flex.",
      labelColor: '#1B6600',
      pricing: {
        annualPrice: 250,
        monthlyPrice: 24
      },
      actionColor: eColor.primary,
    },
    standard: {
      i18nPrefix: "offerings.commercials.standard.",
      labelColor: '#63CF00',
      labelIcon: Popularity,
      pricing: {
        annualPrice: 3300,
        monthlyPrice: 316
      },
      isElevated: true,
      actionColor: eColor.primary,
    },
    pro: {
      i18nPrefix: "offerings.commercials.pro.",
      labelColor: '#A9EC62',
      pricing: {
        annualPrice: 4600,
        monthlyPrice: 441
      },
      actionColor: eColor.primary,
    },
    enterprise: {
      i18nPrefix: "offerings.commercials.enterprise.",
      labelColor: '#FA951D',
      actionColor: eColor.secondary,
      actionFunction: developerFunction
    } 
  },
  developers: {
    starter: {
      i18nPrefix: "offerings.developers.starter.",
      labelColor: '#1B6600',
      pricing: {
        annualPrice: 5300,
        annualPriceSecondary: 3100
      },
      actionColor: eColor.secondary,
      actionFunction: developerFunction
    },
    rocket: {
      i18nPrefix: "offerings.developers.rocket.",
      labelColor: '#63CF00',
      pricing: {
        annualPrice: 8300,
        annualPriceSecondary: 5100
      },
      actionColor: eColor.secondary,
      actionFunction: developerFunction
    }
  }
}

const buildOffering = (settings: iOfferingSettings): iOffering => {

  const prefix = settings.i18nPrefix;

  return {
    addOnDatabaseKey: settings?.addOnDatabaseKey,
    label: prefix + "label",
    labelColor: settings.labelColor,
    labelIcon: settings.labelIcon,
    isElevated: settings.isElevated,
    price: {
      prefix: prefix + "price.prefix",
      suffix: prefix + "price.suffix",
      pricing: settings.pricing
    },
    isFeaturesMultipleColumns: settings.isFeaturesMultipleColumns || false,
    descriptionHeading: prefix + "descriptionHeading",
    description: prefix + "description",
    features: prefix + "features",
    action: prefix + "action",
    isActionDisabled: settings.isActionDisabled || false,
    actionColor: settings.actionColor,
    actionFunction: settings.actionFunction
  }
}

export const getOfferingsByType = (type: tOfferingType): iOffering[] => {
  let offerings: iOffering[] = []

  for (const p of Object.values(settings[type])) {
    const offering = buildOffering(p)
    offerings.push(offering)
  }

  return offerings
}