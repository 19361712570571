import { call, put, select, takeLeading } from 'redux-saga/effects'
import apiRequests from 'config/services'
import {
  sagaGetOrganizationTeamMembersAction,
  userTypes,
} from 'saga/actions/user'
import { PayloadAction } from '@reduxjs/toolkit'
import { organizationProfileState } from 'store/slices/organizationProfile'
import { iLooseObject } from 'pages/DataHub/filters/types'
import { setToastErrors } from 'store/slices/generalSettings'
import { eSeverity } from 'components/types'
import { eSnackbarLocation } from 'store/types'

export function* deleteTeamMemberSaga(action: PayloadAction<number>) {
  try {
    const orgData: iLooseObject = yield select(organizationProfileState)
    const teamMemberId = action.payload
    yield call(apiRequests.deleteTeamMember, teamMemberId)
    yield put(
      setToastErrors({
        messages: [
          {
            code: 'delete-member-success',
            message: 'success.message',
          },
        ],
        isMessageActive: true,
        severity: eSeverity.success,
        messageType: eSnackbarLocation.default,
      })
    )
    // update the state with the new data
    yield put(sagaGetOrganizationTeamMembersAction(parseInt(orgData?.id)))
  } catch (e) {
    //catchind errors
    yield put(
      setToastErrors({
        messages: [
          {
            code: 'delete-member-error',
            message: 'error.message',
          },
        ],
        isMessageActive: true,
        severity: eSeverity.error,
        messageType: eSnackbarLocation.default,
      })
    )
  }
}

export default function* deleteTeamMemberSagaHook() {
  yield takeLeading(userTypes.SAGA_DELETE_TEAM_MEMBER, deleteTeamMemberSaga)
}
