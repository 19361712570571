import EnergyMix_Table1 from 'assets/images/KnowledgeHub/EnergyMixDocument/EnergyMix_Table1.png'
import EnergyGrid from 'assets/images/KnowledgeHub/EnergyMixDocument/EnergyGrid.png'
import { IAccordionData } from '../components/DynamicAccordion'
import { getImage, makeBulttePointsList, makeLink } from './helpers'


export const EnergyMixDocumentData: IAccordionData[] = [
  {
    title: 'ENERGY DATABASES',
    content:
    `The emissions and the use of resources for the production and manufacturing of parts, components and the basic materials strongly depend on the local energy mix. For processes of any kind to be assessed on a country-specific basis, the current energy mixes of the countries must be available. To this end, sustamize has developed a database that consists of two components, on the one hand the energy equivalents in CO2e for the various energy sources and, on the other hand, the most recent energy source distributions in the respective countries.
    <br /><br />
    <u><b>Base for Calculation</b></u>
    <br /> 
    Different methods for energy production result in different CO2e emission amounts. There are three main influencing factors:
    ${makeBulttePointsList('The direct CO2e emissions depending on the fuel type used;|differences in conversion efficiency of fuel into electricity;| and the CO2e emissions generated by the power generator’s production.' ,"list-decimal")}
    <br />
    For example, due to its chemical composition, the combustion of one liter of diesel generates 2.65 kg of CO2e emissions. For natural gas, the CO2e emissions amounts to 1.63 kg of CO2e per liter of gas. The amount of energy released in this process as well as the efficiency at which this energy can be converted into electricity also differs strongly depending on the technology used. The emissions attributed to the generation of other products involved in the same process might also reduce the primarily attributed emissions (for example steam or heat used elsewhere).
    <br />
    <br />
    These factors result in a typical range or average value for these production technologies. In the table below, CO2e emission values are displayed according to different fuels and production technologies. The emissions below also include upstream emissions from fuel production and/or equipment production. These values are later used in the calculation of a grid’s total CO2e emissions.
    <br />
    <br />
    Common and well-researched technologies will have a range from which an average was calculated. Other less common types of energy generation are either rarely mentioned as they are not differentiated on large scale analysis (like general coal power compared to thermal power stations), have a very small influence (like geothermal) or have only a small number of researched sources and thus pertain no ranges. The table below displays an average amount of energy used for calculation.
    <br />
    <br />
    <u><b>Average CO2e values per power generation method:</b></u>
    <br />
    The values displayed are the ones used in the schema to calculate the final CO2e values related to electric energy mixes. They are later combined with the percentage of the different types of energy generation. They are based on our current research into different types of electricity generation and might be subject to change in the future, as new studies arise, or technological advancements emerge in certain fields.
    <br />
    <br />
    ${getImage(EnergyMix_Table1)}
    <br />
    <u><b>Calculation Process</b></u>
    <br />
    The approach for calculating the CO2e reference data for energy follows the schema presented below. Each data point in the sustamize reference database for electricity strictly follows this process schema. The different steps of data generation can be described as follows:
    <br />
    ${makeBulttePointsList("Acquisition of the percentage distribution of energy-by-energy production method within a grid|Application of a base conversion factor for the CO2e value of each energy production method|Calculation of the total cumulative CO2e emission result of the total grid")}
    ${getImage(EnergyGrid, "Types of Energy Generation")}
    <br />
    The grids’ base composition of the electricity mixes stems from ${makeLink('http://ourworldindata.org/', 'http://ourworldindata.org/')}. This base data is converted in the form of percentage of electric energy generated by different sources. These percentages are then converted into a corresponding amount of CO2e emissions. Finally, these emissions are summed up to generate the final total of all CO2e emissions.
    <br /><br />
    As a fictitious example, the final CO2e-value of an electric grid that would consist of 100 % coal energy would be 920 g CO2e per kWh (920 gCO2e/kWh x 1 = 920 gCO2e/kWh). If instead of 100 % coal the energy mix would consist of 50 % coal and 50 % of wind energy, the result would be 468 g of CO2e per kWh (920 gCO2e/kWh x 0.5 + 16 gCO2e/kWh x 0.5 = 460 g CO2e/kWh + 8 g CO2e/kWh = 468 g CO2e/kWh).
    <br /><br />
    <u><b>Current Status:  </u></b>
    <br />
    Data availability differs depending on country and thus when new data is available from countries can vary a lot. We update regularly as data become available which means that some countries will be ahead of others in regards to what the most current year of available data is. Here is a list of our current state (as of Juli 2023)
    <br /><br />
    2022 Data: OECD Total, Argentina, Austria, Australia, Bosnia and Herzegovina, Bangladesh, Belgium, Bulgaria, Burundi, Bolivia, Brazil, Canada, Switzerland, China, Colombia, Costa Rica, Cyprus, Czech Republic, Germany, Denmark, Ecuador, Estonia, Egypt, Spain, Finland, France, United Kingdom, Georgia, Greece, Croatia, Hungary, Ireland, India, Iran, Italy, Japan, Kenya, South Korea, Lithuania, Luxembourg, Latvia, Moldova, Montenegro, Mongolia, Malta, Mexico, Nigeria, Netherlands, Norway, New Zealand, Oman, Peru, Philippines, Pakistan, Poland, Portugal, Qatar, Romania, Serbia, Russia, Sweden, Singapore, Slovenia, Slovakia, El Salvador, Thailand, Tunisia, Turkey, Taiwan, Ukraine, United States, Uruguay, Vietnam, South Africa, Ivory Coast, World, North Macedonia, OECD Europe, OECD Americas, OECD Asia Oceania.
    <br /><br />
    That means currently we have no new CO2e values for:
    <br /><br />
    2021 Data: Afghanistan, Albania, Algeria, American Samoa, Angola, Antigua and Barbuda, Armenia, Aruba, Bahamas, Bahrain, Barbados, Belarus, Belize, Benin, Botswana, Brunei, Burkina Faso, Cambodia, Cameroon, Central African Republic, Chile, Cook Islands, Cuba, Democratic Republic of the Congo, Dominican Republic, Ethiopia, Fiji, Ghana, Guatemala, Guinea, Haiti, Honduras, Hong Kong, Iceland, Indonesia, Iraq, Israel, Ivory Coast, Jamaica, Jordan, Kazakhstan, Kuwait, Kyrgyzstan, Laos, Lebanon, Liberia, Libya, Madagascar, Malaysia, Mali, Morocco, Mozambique, Myanmar, Namibia, Nicaragua, Niger, North Korea, Palestine, Panama, Papua New Guinea, Paraguay, Puerto Rico, Republic of Congo, Rwanda, Saudi Arabia, Senegal, Somalia, Sri Lanka, Sudan, Syria, Tajikistan, Tanzania, Togo, Trinidad and Tobago, Uganda, United Arab Emirates, Uzbekistan, Venezuela, Africa Continent Average, Yemen, Zambia, Zimbabwe.
    `,
    isOpen: true,
  },

]
