import LinkWrapper from 'components/custom/LinkWrapper'
import {
  eColors,
  eElementOnMouseOverEffect,
  eLinkTarget,
} from 'components/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { userReducerState } from 'store/slices/user'
import { eLocale, userState } from 'store/types'
import { RoutePaths } from 'routes/paths'

export const FooterSection: FC = () => {
  const { t } = useTranslation()
  const year = new Date().getFullYear()
  const userInfo: userState = useSelector(userReducerState)
  const locale = userInfo.settings.locale

  return (
    <footer className="flex dark:bg-paletteDarkBlack border-t-[1px] border-paletteGrey dark:border-paletteDarkGrayDelimiter w-full content-around items-center text-xs p-5 place-self-end">
      <div className="text-start w-[40%]">
        {t('footer.copyright', { year: year })}
      </div>
      <div className={`text-end w-[60%] color-[${eColors.paletteGreen}]`}>
        <LinkWrapper
          className="px-2"
          target={eLinkTarget.blank}
          to={RoutePaths.dataAsServiceAggrement}
          underline={eElementOnMouseOverEffect.hover}
        >
          {t('footer.DataAsServiceAggrement')}
        </LinkWrapper>{' '}
        |{' '}
        <LinkWrapper
          className="px-2"
          target={eLinkTarget.blank}
          to={
            locale === eLocale.German
              ? 'https://www.sustamize.com/terms-conditions#privacy-policy'
              : 'https://www.sustamize.com/terms-conditions'
          }
          underline={eElementOnMouseOverEffect.hover}
        >
          {t('footer.TermsAndConditions')}
        </LinkWrapper>{' '}
        |{' '}
        <LinkWrapper
          className="px-2"
          target={eLinkTarget.blank}
          to={
            locale === eLocale.German
              ? 'https://www.sustamize.com/data-privacy#privacy-policy'
              : 'https://www.sustamize.com/data-privacy'
          }
          underline={eElementOnMouseOverEffect.hover}
        >
          {t('footer.PrivacyPolicy')}
        </LinkWrapper>{' '}
        |{' '}
        <LinkWrapper
          className="px-2"
          target={eLinkTarget.blank}
          to={
            locale === eLocale.German
              ? 'https://www.sustamize.com/imprint#privacy-policy'
              : 'https://www.sustamize.com/imprint#terms-and-conditions'
          }
          underline={eElementOnMouseOverEffect.hover}
        >
          {t('footer.Imprint')}
        </LinkWrapper>
      </div>
    </footer>
  )
}
