import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { dataHubReducerState } from 'store/slices/dataHubSlice'
import DataHubFilterWrapper from 'pages/DataHub/filters/components/DataHubFilterWrapper'
import { eComponentType } from 'store/types'

import './SearchPreferences.css'
import { sagaSetSettingsDefaultFieldsData } from 'saga/actions/dataHub'
import TitleWrapper from 'components/custom/TitleWrapper'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import { useSearchParams, useNavigate } from 'react-router-dom'

const SearchPreferences = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [expand, setExpand] = useState(true)
  const [searchParams] = useSearchParams()
  const [isBackButtonVisible, setIsBackButtonVisible] = useState(false)
  let navigate = useNavigate()
  const searchSettings: any = useSelector(dataHubReducerState).hub.settings

  useEffect(() => {
    // when page loads for the first time, it should get the settings from dataHub and put them in the selected fields / filters reducer in the appropriate type  ONLY for default available values
    dispatch(sagaSetSettingsDefaultFieldsData())
  }, [dispatch])

  useEffect(() => {
    const uuid = searchParams.get('uuid')
    const isFromSingleEntity = uuid && uuid.length > 0
    setIsBackButtonVisible(isFromSingleEntity || false)
  }, [searchParams])
  const onBackButtonClick = useCallback(() => {
    const uuid = searchParams.get('uuid')
    const contextName = searchParams.get('contextName')
    const pathBackToEntity = `/entity/${uuid}?contextName=${contextName}`
    navigate(pathBackToEntity)
  }, [navigate, searchParams])
  return (
    <>
      {isBackButtonVisible ? (
        <div>
          <ButtonWrapper onClick={() => onBackButtonClick()}>
            {t('dataHub.searchParameters.backTo')}{' '}
            {searchParams.get('entityTitle')}
          </ButtonWrapper>
        </div>
      ) : null}
      <Accordion
        elevation={2}
        className="search-params rounded-2xl before:hidden"
        onChange={(event: SyntheticEvent, expanded: boolean) =>
          setExpand(expanded)
        }
        expanded={expand}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="search-params"
        >
          <TitleWrapper className="text-inherit text-2xl w-full ml-4">
            {t('dataHub.parameterSettings')}
          </TitleWrapper>
        </AccordionSummary>
        <AccordionDetails className="search-params-details">
          <div className="datahub-wrapper">
            <DataHubFilterWrapper
              filters={searchSettings}
              componentType={eComponentType.settings}
              handleExpand={(value) => setExpand(value)}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default SearchPreferences
