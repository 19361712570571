import { useEventListener } from 'usehooks-ts'

export const usePopstate = (
  cb: () => void,
  preventDefault: boolean = true,
  hard: boolean = false
) => {
  return useEventListener('popstate', (e) => {
    
    Object.defineProperty(e, 'cancelable', { value: true })

    if (preventDefault) {
      e.preventDefault()
      e.stopImmediatePropagation()
      e.stopPropagation()
    }

    if (hard) {
      // Some hacky solution is needed here...
    }

    cb()
  })
}