import { call, put, takeLeading } from 'redux-saga/effects'
import apiRequests from 'config/services'
import { userTypes } from 'saga/actions/user'
import { setUserData } from 'store/slices/user'
import { userState } from 'store/types'
import { iUserProfileUrls } from 'store/types/generalSettings'
import { setUserProfileUrls } from 'store/slices/generalSettings'

export function* getUserProfileDataSaga() {
  try {
    let result: userState = yield call(apiRequests.getUserData)
    yield put(setUserData(result))
    
  } catch (e) {
        //catchind errors
  }
}

export function* getUserProfileUrlsSaga() {
  try {
    let result: iUserProfileUrls = yield call(apiRequests.getUserProfileUrls)

    yield put(setUserProfileUrls(result))
  } catch (e) {
        //catchind errors
  }
}

export default function* getUserDataSagaHook() {
  yield takeLeading(
    userTypes.SAGA_FETCH_USER_PROFILE_DATA,
    getUserProfileDataSaga
  )

  yield takeLeading(
    userTypes.SAGA_GET_USER_PROFILE_URLS,
    getUserProfileUrlsSaga
  )
}
