import { Wrapper } from 'components/custom/Wrapper'
import { deleteCookie, getCookie, getToken, setLocal } from 'config/lib'
import { Roles } from 'config/roles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import routes from 'routes'
import { setDefaultMenuItems, setUserMenuItems } from 'store/slices/menuItems'
import { userReducerState } from 'store/slices/user'
import { RoutePaths } from './paths'
import { iLooseObject } from 'pages/DataHub/filters/types'


export function PrivateRoute({
  myModule,
}: {
  myModule: iLooseObject
}): JSX.Element {
  let auth = false
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const [isUserDataExists, setIsUserDataExists] = useState<boolean>(false)

  const userData = useSelector(userReducerState)

  const componentRoles = myModule.routeProps.roles
  const componentRestrictedRoles = myModule.routeProps.restrictedRoles
  const redirectToUpgrade = myModule.routeProps.redirectToUpgrade
  const requiresAuthentication =
    myModule.routeProps.requiresAuthentication === true

  const hasRoleAccess = componentRoles.some(
    (item: Roles) => userData.roles.indexOf(item) !== -1
  )

  const upgradeRouteIndex = routes.findIndex((r) => r.name === 'Upgrade')
  const upgradeElement =
    upgradeRouteIndex !== -1
      ? React.cloneElement(routes[upgradeRouteIndex].routeProps.element, {})
      : null

  useEffect(() => {
    //reset menu items to default mock structure, when going on home page
    if (location.pathname.indexOf('/user/') === 0) {
      dispatch(setUserMenuItems())
    }
    if (location.pathname === RoutePaths.dashboard) {
      dispatch(setDefaultMenuItems())
    }
  }, [location, dispatch])

  useEffect(() => {
    document.title = `${t(myModule.routeProps.title)} | ${t(
      'general.basePageTitle'
    )}`
  }, [myModule.routeProps.title, t])
  // whenever the user data is updated:
  useEffect(() => {
    if (userData.id && userData.id.length > 0) {
      setIsUserDataExists(true)
    }
  }, [userData])

  // get jwt from local storage
  const jwt = getToken()
  // if jwt exists in local storage
  if (jwt) {
    auth = true
  } else {
    let srvJWT: string = ''
    const jwtFromUrl: string | null = new URLSearchParams(
      window.location.search
    ).get('jwt')
    // id jwt is in url
    if (jwtFromUrl) {
      srvJWT = jwtFromUrl
    } else {
      srvJWT = getCookie('jwt')
      deleteCookie('jwt')
    }

    // if cookie jwt exists
    if (srvJWT) {
      // set jwt to local storage
      setLocal('jwt', srvJWT)
      //remove jwt from cookie
      //set as authenticated;
      auth = true
    }
    if (jwtFromUrl) {
      document.location = window.location.pathname
    }
  }
  const props = {}
  const MyRouteComponent = React.cloneElement(
    myModule.routeProps.element,
    props
  )
  if (!requiresAuthentication) {
    return React.cloneElement(MyRouteComponent)
  }

  if (!hasRoleAccess) {
    console.warn('User does not have the appropriate roles')
  }
  if (!auth) {
    console.warn('User is not authenticated')
  }
  if (!isUserDataExists && auth) {
    return (
      <div className="flex w-screen h-screen justify-center text-center align-middle items-center">
        <span>...</span>
      </div>
    )
  }
  // if restricted roles allow access to the children
  const restrictedRolesAllowAccess = componentRestrictedRoles
    ? !userData.roles.some(
        (item: Roles) => componentRestrictedRoles.indexOf(item) !== -1
      )
    : false

  const isOrgUser = userData.roles.find((r) => r === Roles.ROLE_ORG_USER)
  

  const noAccessContent = ()=> {
    /**
     * I added the "replace" prop to Naviate, which fixes the issue, too. I did like the
     * inline solution a little better, since you can still see the menu.
     */
    // <Navigate to={RoutePaths.upgrade} replace={true} /> // just a redirect to login page
    return !!redirectToUpgrade ? (
      !isOrgUser ? (
        <Navigate to={RoutePaths.upgrade} replace={true} />
      ) : (
        // redirect to askYourAdmin page if user is not org admin
        <Navigate to={RoutePaths.memberAccessForbidden} replace={true} />
      )
    ) : restrictedRolesAllowAccess ? (
      upgradeElement && (
        <Wrapper>{React.cloneElement(upgradeElement, {})}</Wrapper>
      )
    ) : (
      <Navigate to={RoutePaths.accessForbidden} replace={true} /> // just a redirect 403 page
    )
  }

  const authenticatedSection = () => {
    return hasRoleAccess || (!hasRoleAccess && restrictedRolesAllowAccess) ? (
      <Wrapper>{React.cloneElement(MyRouteComponent)}</Wrapper>
    ) : noAccessContent()
  }

  return (
    <>
      {auth ? (
        authenticatedSection()
      ) : (
        <Navigate to={RoutePaths.login} replace={true} /> // just a redirect to login page
      )}
    </>
  )
}

export const PublicRoute = ({ myModule }: { myModule: any }) => {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = `${t(myModule.routeProps.title)} | ${t(
      'general.basePageTitle'
    )}`
  }, [myModule.routeProps.title, t])
  const props = {}
  const MyRouteComponent = React.cloneElement(
    myModule.routeProps.element,
    props
  )
  return MyRouteComponent
}
