import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLeading } from "redux-saga/effects";
import { subscriptionOrderActionTypes } from "saga/actions/subscriptionOrder";
import { setSubscriptionOrder } from "store/slices/subscriptionOrderSlice";
import { iSubscriptionOrder } from "store/types/subscriptionOrder";

function* updateSubscriptionConfigurationSaga(config: PayloadAction<iSubscriptionOrder>) {
  yield put(setSubscriptionOrder(config.payload))
}

export default function* updateSubscriptionConfigurationSagaHook() {
  yield takeLeading(
    subscriptionOrderActionTypes.SAGA_UPDATE_SUBSCRIPTION_CONFIGURATION,
    updateSubscriptionConfigurationSaga
  )
}