import React, { useMemo } from 'react'
import { Roles } from 'config/roles'
import { useSelector } from 'react-redux'
import { userReducerState } from 'store/slices/user'

interface IProps {
  children: JSX.Element
  roles?: Roles[]
  restrictedRoles?: Roles[]
}
export default function RoleCheckWrapper({
  children,
  roles,
  restrictedRoles,
}: IProps) {
  const userRoles = useSelector(userReducerState).roles
  // if roles mach the user roles
  const rolesAllowAccess = useMemo(
    () =>
      roles
        ? userRoles.some((item: Roles) => roles.indexOf(item) !== -1)
        : false,
    [roles, userRoles]
  )

  // if restricted roles allow access to the children
  const restrictedRolesAllowAccess = useMemo(
    () =>
      restrictedRoles
        ? !userRoles.some((item: Roles) => restrictedRoles.indexOf(item) !== -1)
        : false,
    [restrictedRoles, userRoles]
  )

  return (
    <>
      {restrictedRolesAllowAccess || rolesAllowAccess ? <>{children}</> : null}
    </>
  )
}
