import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import {
  eElementOnMouseOverEffect,
  eLinkTarget,
  eTypographyVariant,
  iBlogPostCard,
} from 'components/types'
import TextWrapper from './TextWrapper'
import LinkWrapper from './LinkWrapper'
import { useTranslation } from 'react-i18next'

interface iProps {
  blogPost: iBlogPostCard
  target?: eLinkTarget
  width?: number
  className?: string
}

const LinkCardWrapper = ({
  blogPost,
  target = eLinkTarget.self,
  width,
  className,
}: iProps) => {
  const { t } = useTranslation()

  // TODO!!!: validate imageSrc, maybe offer an iccon fallback if broken url (ie /assets/icons/025-link.svg)
  // open scenario: create custom image loader with built-in url checker
  const getImage = () => {
    if (blogPost.imageSrc) {
      return require('../../' + blogPost.imageSrc)
    }
  }

  const getWidth = () => {
    if (width) {
      return width
    }

    return '362px'
  }

  return (
    <LinkWrapper
      to={blogPost.to}
      underline={eElementOnMouseOverEffect.none}
      target={target}
      className={`${className} m-4`}
    >
      <Card sx={{ width: getWidth() }}>
        {blogPost.imageSrc && (
          <CardMedia
            component="img"
            sx={{ height: '260px', width: '384px' }}
            image={getImage()}
            alt={blogPost.imageAlt || t('index.blogPostCardDefaultImageAlt')}
          />
        )}
        <CardContent>
          {blogPost.title && <TextWrapper>{blogPost.title}</TextWrapper>}
          {blogPost.description && (
            <TextWrapper variant={eTypographyVariant.body2}>
              {blogPost.description}
            </TextWrapper>
          )}
        </CardContent>
      </Card>
    </LinkWrapper>
  )
}

export default LinkCardWrapper
