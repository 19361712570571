import React from 'react'
import { ContentBody } from 'components/pages/components/ContentBody'

import PaperWrapper from 'components/custom/PaperWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
import TextWrapper from 'components/custom/TextWrapper'
import { eTypographyVariant } from 'components/types'
import { Link } from 'react-router-dom'
import { assets } from 'config/services/requests'

export const DataAsServiceAggrementEnglish = () => {
  const headingClassName = 'pt-4 pb-2 text-paletteOrange uppercase'
  const lowerAlphaListClassName = 'list-[none] list-inside ml-4'

  return (
    <ContentBody title="SUSTAMIZER – DATA-AS-A-SERVICE AGREEMENT (DaaSA)">
      <PaperWrapper>
      <Link
          to={assets.dataAsAServiceAgreementPdf}
          rel="noreferrer"
          target='_blank'
          className={`${headingClassName} flex justify-end text-right underline`}
        >
          Download
        </Link>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
        PREAMBLE
        </TitleWrapper>
        <TextWrapper>
        <strong>This Data-as-a-Service Agreement (hereinafter: "DaaSA") applies to all contractual 
        relationships in which a licensee as an end user acquires a licence to use the sustamize 
        data by contract with the licensor.</strong>
        </TextWrapper>
        <TextWrapper>
        <strong>Access to the legally protected sustamize databases on sustamizer.com is only permitted 
        with unrestricted acceptance of the following contractual conditions.</strong>
        </TextWrapper>
        <TextWrapper>
        <strong>This DaaSA is drawn up in German and English. The German version alone is authoritative 
        for the legal effects between the parties.</strong>
        </TextWrapper>
        <TextWrapper>
        BY USING THE SUSTAMIZE DATABASES ON SUSTAMIZER.COM FOR THE FIRST TIME THE LICENSEE 
        ACKNOWLEDGES UNCONDITIONALLY THE VALIDITY AND BINDING NATURE OF THIS DATA-AS-A-SERVICE-AGREEMENT.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          Section 1 Definitions
        </TitleWrapper>
        <TextWrapper>
        The terms used in this DaaSA have the following meanings:
        </TextWrapper>
        <TextWrapper>
        <strong>Work result</strong> is the concrete CO2 footprint of a product 
        and/or company (e.g. 10 kg CO2e / kg of a product) worked out by the licensor.
        </TextWrapper>
        <TextWrapper>
        <strong>The licensor</strong> is sustamize GmbH, a company under German law with 
        headquarters in Munich, registered under HRB 277048.
        </TextWrapper>
        <TextWrapper>
        <strong>Licensee</strong> is a entrepreneur iSv. § 14 BGB, who uses the sustamize databases 
        as an end user for the purpose of sustainability assessments.
        </TextWrapper>
        
        <TextWrapper><strong>Named-User-License</strong> describes the licensing of the contractual sustamize
         data and databases via Sustamizer on the basis of registered, named natural persons.</TextWrapper>
        <TextWrapper><strong>Sustamizer</strong> is a web-based software tool of the Licensor, 
        through which the Licensee gains access to the sustamize data and sustamize- databases within 
        the scope defined in each case by contract.</TextWrapper>
        <TextWrapper><strong>sustamize databases</strong> are legally protected databases of the licensor 
        with for the creation of a CO2 footprint of products and companies and other in connection with 
        CO2 balance, decarbonization, LCA (Life Cycle Assessment) relevant sustamize data and 
        calculation logics.</TextWrapper>
        <TextWrapper><strong>sustamize data</strong> are the individual data and data sets of the sustamize 
        databases, including raw data, calculation logics, work results and other information of the licensor. 
        The contractual sustamize data are non-personal data on environmental emissions, in particular 
        greenhouse gases (measured in CO2 equivalents) and resource consumptions (measured in material 
        intensities), which occur in the course of the production, manufacture, use, scrapping, reuse or 
        recycling of materials and/or services.</TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          Section 2 Scope DaaSA, Subject of the contract
        </TitleWrapper>
        <TextWrapper>
          (1) The Licensor provides the Licensee with online read access to its sustamize databases under 
          the terms and conditions of this DaaSA, and in doing so grants the Licensee paid and time-limited 
          data licenses to the sustamize data ("<strong>Data-as-a-Service</strong>").
        </TextWrapper>
        <TextWrapper>
          (2) The scope of the provided sustamize data results from the individual contract/order, a framework 
          agreement or other documents accompanying the conclusion of the contract, 
          (hereinafter in each case: "contractual") with the licensee. Insofar as expressly agreed there 
          between the parties, the Licensor shall also provide training and consulting services and/or other 
          additional services to the Licensee, in each case for a separate fee.
        </TextWrapper>
        <TextWrapper>
          (3) The General Terms and Conditions of sustamize in the version valid at the time of conclusion 
          of the contract (available at: https://www.sustamize.com/terms-conditions), apply in addition, as 
          far as this DaaSA does not contain any deviating regulation.
        </TextWrapper>
        <TextWrapper>
          (4) The Licensee's general terms and conditions shall not apply. This shall also apply if such 
          regulations are not expressly contradicted.
        </TextWrapper>
        
        
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          Section 3 Service description, service level
        </TitleWrapper>
        <TextWrapper>
          (1) The calculation logics for the emission factors in the sustamize databases were developed 
          according to the requirements according to the standard "GHG Protocol - Product Life Cycle Accounting 
          & Reporting Standard" (edition September 2011) and validated according to the requirements of 
          ISO 14064-3:2019-04 (certificate of conformity dated 23.01.2023 from GUT Certifizierungsgesellschaft
           für Managementsysteme mbH). Internal QM measures are carried out for quality assurance.
        </TextWrapper>
        <TextWrapper>
          (2) The licensor keeps the sustamize databases up to date to the best of his knowledge. 
          However, due to constant changes in technology, development and the regulatory environment, 
          the Licensor cannot guarantee the timeliness, accuracy or freedom from error of the sustamize data.
        </TextWrapper>
        <TextWrapper>
          The Licensor shall have no further contractual obligations to update or improve or to provide new 
          data (e.g. updates, upgrades) within existing data licenses.
        </TextWrapper>
        <TextWrapper>
          (3) The Licensor guarantees that the collection of the sustamize data as well as the calculation 
          logic underlying the work results essentially comply with the specifications of the GHG Protocol. 
          In the event of updates to the GHG Protocol, the Licensor undertakes to implement these within 
          a reasonable period of time. Furthermore, the Licensor does not guarantee the timeliness, 
          accuracy and correctness of the sustamize data.
        </TextWrapper>        
        <TextWrapper>
          (4) The Licensor makes no representations, warranties, or promises in connection with the commercial, 
          legal, or other use regarding the contractual sustamize data. The Licensor makes no warranty for 
          any statements or actions of a commercial or legal nature that the Licensee may derive from the 
          use of the sustamize data. In particular, the Licensor does not warrant that the use of the 
          sustamize data meets any legal, regulatory or other requirements with respect to the declaration 
          of environmental emissions (e.g. in the context of corporate social responsibility reporting).
        </TextWrapper>
        <TextWrapper>
          (5) For comprehensibility, the licensee has access to information (e.g. about the structure and 
          the creation of the sustamize databases) via the application of Sustamizer "Knowledge Center". 
          The knowledge center serves exclusively as user information about the possible applications of 
          the sustamizer and does not contain any warranty, guarantee or other promise about the data 
          quality and/or functional range of the sustamizer.
        </TextWrapper>
        <TextWrapper>
          (6) The licensor provides the sustamize data via the software Sustamizer available on working 
          days (Monday to Friday without federal holidays) in the time 9:00 to 17:00 (CET) with a service 
          level of 95 % in the annual average. Further regulations regarding the service level agreement 
          (SLA) are agreed under Section 9.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          Section 4 Granting of data licences, restrictions of use
        </TitleWrapper>
        <TextWrapper>
          (1) The sustamize databases of the licensor are legally protected, especially according to § 4 
          UrhG (database work) and §§ 87a ff. UrhG (protection of the database producer). The licensee 
          acknowledges that the licensor has expended considerable investment and creative effort in the production of the sustamize databases and the individual sustamize data, and that these are the property of the licensor.
        </TextWrapper>
        <TextWrapper>
          (2) The sustamize databases including the sustamize data contained in the software application Sustamizer are of considerable economic value and are subject to strict confidentiality measures. The sustamize databases including the sustamize data and the used software are confidential information and therefore also protected as business secrets iSd. GeschGehG protected.
        </TextWrapper>
        <TextWrapper>
          (3) Licensor grants the licensee, in each case by individual order or framework agreement, the right to use the respective contractual sustamize data via the access of the sustamizer for the purpose of:
        </TextWrapper>
        <TextWrapper>
        Carrying out sustainability assessments (carbon footprints of products, materials, services, companies, suppliers; life cycle assessment).
        </TextWrapper>
        <TextWrapper>
        (hereinafter: "<strong>contractual purpose</strong>")
        </TextWrapper>
        <TextWrapper>
        under the following licence conditions:
        </TextWrapper>
        <TextWrapper>
        The right for commercial use of the sustamize data as well as the sustamize databases includes the non-exclusive (simple), time-limited, revocable right,
        </TextWrapper>
        <div>
          <ol className={lowerAlphaListClassName}>
            <li>(a) to use the sustamize data as well as the sustamize databases within the scope of an online read access via the sustamizer and its software applications within the scope of named user licenses acquired by individual/framework contract,</li>
            <li>(b) to use the work results freely within the scope of the purpose of the contract, in particular to store, reproduce and pass them on
            </li>
          </ol>
        </div>
        <TextWrapper>
          (4) The Licensee shall not be entitled to any rights beyond those expressly granted under paragraph 3 above. In particular, but not conclusively, the Licensee shall not be entitled to
        </TextWrapper>
        <div>
          <ol className={lowerAlphaListClassName}>
            <li>(a) to use the Sustamizer, the sustamize databases and the sustamize data beyond the scope of the granted Named User License (e.g. by unauthorized multiple use of an account);</li>
            <li>(b) to publish, distribute or otherwise disclose the contractual sustamize data or the sustamize databases to third parties to an extent that exceeds insignificant amounts ; mandatory copyright restrictions remain unaffected.</li>
            <li>(c) sell, rent, lease, loan, distribute, act as an agent or provider of, or grant to any third party any type of license rights with respect to the sustamize data or the sustamize databases, or any portion thereof;</li>
            <li>(d) to assign or otherwise transfer rights to the contractual sustamize data or the sustamize databases or parts thereof to third parties (e.g. as a sub- or sub-license);</li>
            <li>(e) perform, cause to be performed, permit or authorize reverse assembling, reverse compiling, back calculation, decompiling, disassembling, hacking or other reverse engineering or the extraction of sustamize data; this shall be without prejudice to any mandatory limitations under copyright law;</li>
            <li>(f) dynamically publish or otherwise disclose the sustamize data through or integrated into a software tool (e.g. application) for internal use or for external use by third parties;</li>
            <li>(g) by any other action, circumvent or attempt to circumvent the use restrictions of this DaaSA.</li>
          </ol>
        </div>
        <TextWrapper>
          (5) The licensee is further strictly forbidden to use the sustamize data of the licensor for the creation of a database or any other work that is comparable or otherwise in competition with the sustamize databases (e.g. by unauthorized use of screen-scraping techniques or by means of other data extractions contrary to the contract).
        </TextWrapper>
        <TextWrapper>
          (6) The indirect use (e.g. triggering of processing activities by software components originating from third parties via interface) of the Sustamizer, the sustamize databases and data is prohibited. This also applies to the use of the sustamize data and databases in the context of training artificial intelligence applications. The licensor reserves the right to use the sustamize data for text and data mining (§ 44b Abs. 3 UrhG).
        </TextWrapper>
        <TextWrapper>
          (7) The licensee is entitled, unless otherwise agreed, to use the title/name of the contractual sustamize databases as well as names/indicators/logos/images of the licensor, in particular for the purpose of self-promotion.
        </TextWrapper>
        <TextWrapper>
          (8) The Licensor expressly reserves all rights to know-how and trade secrets, all copyrights, industrial property rights (e.g. trademarks, patents), rights to names and other intellectual property rights. Rights shall be granted to the Licensee exclusively to the extent of this DaaSA, unless otherwise agreed by individual or framework agreement between the parties.
        </TextWrapper>
        <TextWrapper>
          (9) The granting of rights to the Licensee shall only become effective in accordance with Section 158 (1) of the German Civil Code (BGB) when the Licensee has paid the respective contractually owed license fees (see Section 10 DaaSA) in full and without reservation. The Licensor may provisionally permit the use of the contractual sustamize data and sustomize databases even before this point in time, however, such provisional permission does not constitute a granting of rights of use.
        </TextWrapper>
        <TextWrapper>
          (10) With regard to any types of use not covered by this grant of rights of use, the Licensor is in principle prepared to grant the Licensee further rights in the respective individual/framework agreement in return for additional remuneration, subject to compatibility with the business interests of the Licensor.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          Section 5 Intellectual property, legal positions on data and derived data
        </TitleWrapper>
        <TextWrapper>
          (1) Insofar as copyright or 	other legal positions arise through the collection, compilation, storage, preparation or other processing of sustamize data by the Licensor, these rights belong exclusively to the Licensor. This applies in particular, but not exclusively, to the rights as a database producer under the Copyright Act.
        </TextWrapper>
        <TextWrapper>
          (2) The factual-technical access of the Licensee to the data of the Licensor does not open up to the Licensee a position similar or comparable to an owner and/or exclusive rights of use with regard to the sustamize data.
        </TextWrapper>
        <TextWrapper>
          (3) As far as the licensee processes the sustamize data contrary to the provisions of § 4 (granting of data licenses, restrictions of use) contrary to the contract and thereby creates new or modified databases or other works, the licensor receives a free, temporally unlimited and exclusive back license to these databases and/or other works with the right to own exclusive exploitation by the licensor. Any claims of the Licensor for surrender of data and/or work results obtained in breach of contract by the Licensee and other contractual and/or statutory claims of the Licensor shall remain unaffected hereby.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}>
          Section 6	Data security, data protection
        </TitleWrapper>
        <TextWrapper>
          (1)	The parties undertake to take all necessary and customary measures to ensure data security. The parties shall ensure by means of technical, organizational and other measures required by the state of the art that the data of the respective other party are protected at all times against unauthorized access by third parties.
        </TextWrapper>
        <TextWrapper>
          (2) The Parties shall comply with the relevant statutory provisions (DSGVO, etc.), to the extent applicable.
        </TextWrapper>   
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}>
          Section 7 Services of the Licensor
        </TitleWrapper>
        <TextWrapper>
          (1)	The Licensor provides the Licensee with the contractual sustamize data as well as the sustamize databases through the web-based software tool "sustamizer" within the scope of an online read access. The licensee is enabled to use the software stored and running on the servers of a service provider commissioned by the licensor via an internet connection during the term of this DaaSA within the scope of the purpose of the contract (§ 4 para. 3).
        </TextWrapper>
        <TextWrapper>
          (2)	The Licensor provides the Licensee with the access to the sustamizer at the router exit of the data center, in which the server with the Software is located, into the general Internet ("<strong>handover point</strong>"), for use. However, the Licensor does not owe the establishment and maintenance of the data connection between the IT systems of the Licensee and the described handover point. The Licensee shall bear sole responsibility for the functionality of its Internet access, including the transmission paths, as well as its own computer network. If separately agreed between the parties, the data may also be provided via an interface provided by the Licensor; the above shall apply accordingly in this case. 
        </TextWrapper>
        <TextWrapper>
          (3)	The licensor has the right to mention his company name as the source (e.g. "Source: sustamize databases") 	when the licensee uses the sustamize data for third parties (e.g. advertising, marketing) - as far as this use is contractually permitted to the licensee. As far as markings or reservations of rights are made by the licensor, in particular copyright notices, trademarks, serial numbers or similar, these may not be removed, changed or made unrecognizable without the licensor's consent.
        </TextWrapper>
        <TextWrapper>
          (4)	The Licensor shall be entitled to modify the Sustamizer	's service offering (e.g. structure of the database, content and user interface) if and to the extent that the fulfillment of the Licensee's contractual purpose (see Section 4 (3)) is not significantly impaired thereby and the equivalence ratio of the contract is not impaired. The changes must be necessary with regard to the up-to-dateness of the service offer, the usability, the availability or the offered contents for the Licensor in order to ensure the functionality or the competitiveness of the database offer. The Licensee shall be notified of any such changes by the Licensor in a timely manner. 
        </TextWrapper>
        <TextWrapper>
          (5)	The Licensee is entitled to 	continue a Named User License 	granted by the Licensor by replacing an Employee if the Named User in question is prevented from exercising the license for organizational reasons, by leaving the Company, parental leave or death. 
        </TextWrapper>      
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}>
          Section 8	Obligations of the Licensee
        </TitleWrapper>
        <TextWrapper>
          (1)	By providing the necessary technical infrastructure, the Licensee shall ensure that the data provided can be transmitted via its web browser from the transfer point (Section 7(2)). The Licensee shall bear the sole responsibility for the operability of its Internet access including the transmission paths as well as its computer network.
        </TextWrapper>
        <TextWrapper>
          (2)	The use of sustamize data requires registration, unless otherwise agreed. The licensee is obligated to provide the data requested during registration correctly and completely.
        </TextWrapper>
        <TextWrapper>
          (3)	The Licensee is obliged to prevent unauthorized access by third parties to the protected areas of the Sustamizer by taking suitable precautions. For this purpose, the Licensee shall, to the extent necessary, instruct its employees to comply with copyright law. The Licensee shall carry out regular data backups of its own data and shall use a virus protection program in the latest version on its computer network.
        </TextWrapper>
        <TextWrapper>
          (4)	The Licensee is not entitled to modify the Sustamizer 	computer programs used by the Licensor (e.g. database software, calculation programs, calculation logics) or to manipulate or influence them in any other way (e.g. by reverse assembling, reverse compiling, back calculation, decompiling, disassembling, hacking or other reverse engineering). The database contents are subject to strict secrecy measures in accordance with the GeschGehG. 
        </TextWrapper>
        <TextWrapper>
          (5)	If a master agreement exists, Licensee shall inform Licensor regularly, at least quarterly, without being requested to do so, about the stock of Named User Licenses used and shall provide Licensor with further information (e.g. order confirmations) upon request; documents may be redacted if business secrets exist. 
        </TextWrapper>
        <TextWrapper>
          (6)	In the event of overuse, the Licensee shall notify the Licensor of such overuse without undue delay. The Licensor shall be entitled to royalties from the Licensee to the extent of the overuse, whereby the Licensor shall be placed in the same economic position as if the overuse had been remunerated in accordance with the provisions of Section 10 and Section 11. The Licensee shall provide information on the volume of use of the excess use. Paragraph 8 shall apply. Any further contractual and statutory claims of the Licensor shall remain unaffected.
        </TextWrapper>
        <TextWrapper>
          (7)	The Licensee shall provide the Licensor with reasonable support in taking any necessary measures against an infringement of the Licensor's rights by third parties (e.g. infringement of copyrights, database rights, trade secrets), in particular by providing information and documentation for the preservation of evidence, whereby the Licensee's trade secrets shall be given due consideration.
        </TextWrapper>
        <TextWrapper>
          (8)	Insofar as third parties assert claims against the Licensor due to infringements of rights which are attributable to actions and/or omissions of the Licensee which are in breach of duty, the Licensee shall indemnify the Licensor in full against any claims of third parties. The indemnification claim shall also include the costs of the necessary extrajudicial and judicial costs, including the costs for any expert opinions that may be required. The indemnification shall not apply if the Licensee is not responsible for the infringement.
        </TextWrapper>
        <TextWrapper>
          (9)	The licensee will comply with all legal regulations applicable to the use of the sustamize data. He will take all necessary measures to avoid corruption as well as violations of the rules of fair competition. The sustamize data is also subject to criminal law protection; reference is made to the provisions of the Criminal Code for the protection of data, in particular § 202a StGB (spying on data), § 202b StGB (interception of data), § 303a StGB (data alteration).
        </TextWrapper>
        <TextWrapper>
          (10)	Unless otherwise expressly agreed between the parties, the Licensee shall not be entitled to transfer 	the (license) rights and obligations under 	this DaaSA to third parties, to assign or pledge claims under this Agreement or otherwise encumber them with the right of a third party. Third parties within the meaning of these provisions shall also be affiliated companies of the Licensee within the meaning of. § Section 15 of the German Stock Corporation Act (AktG). The provision under § 7 para. 5 shall remain unaffected. 
        </TextWrapper>
        <TextWrapper>
          (11)	The licensee is obligated not to produce and distribute other products and/or services directly or indirectly with the sustamize data during the contract period, which are in competition with the sustamize databases.  Competition in this sense is in particular the creation of databases with the sustamize data to perform sustainability assessments (CO2 footprints of products, materials, services, companies, suppliers; life cycle assessment ).
        </TextWrapper>
        <TextWrapper>
          (12)	Offsetting and/or retention with counterclaims of the Licensee against claims of the Licensor shall not be permissible unless these are undisputed or have been legally established.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          Section 9	Service Level Agreement (SLA)
        </TitleWrapper>
        <TextWrapper>
        The performance specification (§ 3 DaaSA) for the temporal and technical availability of the sustamize data is specified as follows:
        </TextWrapper>
        <TextWrapper>
          (1)	The <strong>time availability</strong> according to Section 3(5) indicates the percentage of the time period for which the Sustamizer is available at the Delivery Point (see Section 7(2)). Outside this period the Licensor is not obliged to make the Software available for use. When calculating the actual availability, downtimes not attributable to the Licensor shall be considered as available times. These harmless downtimes are:
        </TextWrapper>
        <div>
          <ol className={lowerAlphaListClassName}>
            <li>(a)	maintenance or other services agreed with the Licensee, which do not allow access to Sustamizer;</li>
            <li>(b) maintenance work that becomes necessary unexpectedly, if this work was not caused by a breach of the Licensor's obligations to provide the Services (force majeure, in particular unforeseeable hardware/software failures, strikes, war, pandemics, natural events, etc.);</li>
            <li>(c) downtimes due to virus or hacker attacks, insofar as the Licensor has taken the agreed protective measures or, in the absence of an agreement, the usual protective measures;</li>
            <li>(d) Downtimes due to specifications of the Licensee, due to unavailability of the Licensee's equipment or due to other interruptions caused by the Licensee (e.g. failure of the Licensee to cooperate);</li>
            <li>(e) Downtime extensions caused due to blocking of console or remote access by Licensee;</li>
            <li>(f) Downtime for the installation of urgently required security patches;</li>
            <li>(g)	Downtime due to software errors in Licensee's applications or due to errors in the system and system-related software triggered by Licensee's applications or data;</li>
            <li>(h)	Downtime caused by third parties (persons not attributable to the Licensor).</li>
          </ol>
        </div>
        <TextWrapper>
          (2) The technical availability refers to the availability of the software offered to the Licensee for use at the transfer point (Section 7(2)). Impairments in the area of data transmission from this handover point to the Licensee and/or in the area of the IT system of the Licensee itself shall not be taken into account. Special provisions with regard to the handover point of the sustamizer or - in case of explicitly agreed provision via an interface - the technical features of the interface provided by the Licensor, result from <strong>Appendix 1.</strong> 
        </TextWrapper>
        <TextWrapper>
          (3) The Licensee assumes the obligation to immediately notify the Licensor of any impairment of availability (hereinafter referred to as "disruptions"). Reports are to be sent by e-mail to the following address: <strong>support@sustamize.com</strong>. Incoming requests will be processed during the regular business hours of the Licensor (Mon-Fri (except national holidays); 9 am - 5 pm German time).
        </TextWrapper>
        <TextWrapper>
          (4)	Faults subject to the provisions of this SLA shall be classified as follows:
        </TextWrapper>
        <div>
          <ol className={lowerAlphaListClassName}>
            <li>(a)	Priority 1: Malfunctions with high impact or high urgency, e.g. malfunctions where the Licensee is not able to use Sustamizer or its basic functions. Priority 1 disruptions may also be present in case of high severity security vulnerabilities. </li>
            <li><br></br>Response time = [24 hours]</li><br></br>
            <li>(b)	Priority 2: Malfunctions with medium impact or medium urgency, e.g. if the Licensee can partially use Sustamizer despite the malfunction, the essential functions are usable, but the malfunction causes significant restrictions on use and requires additional work and/or costs. Priority 2 disruptions may also occur in the case of information security vulnerabilities of medium severity.</li>
            <li><br></br>Response time = [72 hours]</li><br></br>
            <li>(c)	Priority 3: Disruptions with low impact or low urgency, e.g. when the licensee is able to use Sustamizer despite the disruption, but some functions are not usable, resulting in low usage restrictions. Priority 3 disruptions can also occur in the case of information security vulnerabilities with a low severity level. </li>
            <li><br></br>Response time = [96 hours]</li><br></br>
          </ol>
        </div>
        <TextWrapper>
          (5) Upon receipt of a proper fault report from the Licensee, the Licensor shall endeavor to start analyzing and, if possible, eliminating the fault within the response times pursuant to Paragraph 4 at the latest. The work to eliminate the malfunction shall be carried out within the scope of the Licensor's possibilities in compliance with its contractual obligations. A claim to the elimination of the fault within a certain time does not follow from the agreement of the reaction times. There shall be no claim to restoration of the availability of the software insofar as the agreed availability is guaranteed. 
        </TextWrapper>
        <TextWrapper>
          (6) A malfunction to be remedied by the Licensor shall not be deemed to exist in the event of impairments to data transmission outside the data network operated by the Licensor, e.g. due to line failure or malfunction at other providers or telecommunication providers, or a use of the system capacities provided in breach of the contract, e.g. due to an excessive number of accesses by the Licensee.
        </TextWrapper>
        <TextWrapper>
          (7)	If the Licensee reports a malfunction to the Licensor and it turns out after an inspection that the malfunction did not occur within the Licensor's data network or that the Licensor is not responsible for the malfunction for other reasons, the Licensor may charge the Licensee for the services rendered to detect the malfunction at the hourly rates of the Licensor applicable to such services, unless the Licensee could not have detected that the malfunction did not occur within the Licensor's data network even if it had exercised due diligence.
        </TextWrapper>
        <TextWrapper>
          (8)	In the following cases, the Licensee shall be exempt from 	payment of the license fees on a pro rata basis, i.e. pro rata temporis:
        </TextWrapper>
        <div>
          <ol className={lowerAlphaListClassName}>
            <li>(a)	For the time during which a Priority I Disruption exists, the Licensee shall be fully exempt from payment of the agreed fee.</li>
            <li>(b)	For the period of time for which there is a Priority II or Priority III disruption, the Licensee shall be exempt from payment of the fee in the amount of 60 %.</li>
          </ol>
        </div>
        <TextWrapper>
          (9)	In all other respects, the provisions of Section 16 shall apply.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}>
          Section 10	Royalties
        </TitleWrapper>
        <TextWrapper>
          (1)		The license fees to be paid by the Licensee shall be based on the price list valid at the time of the order with individual agreement or framework agreement or on an agreement in text form between the Licensor and the Licensee.
        </TextWrapper>
        <TextWrapper>
          (2)	The provisions of §§ 313, 314 BGB shall remain unaffected.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}>
          Section 11	Terms of payment
        </TitleWrapper>
        <TextWrapper>
          (1)	All payments to the licensor shall be made in EURO and to the bank account specified by the licensor in text form.
        </TextWrapper>
        <TextWrapper>
          (2)	Unless otherwise agreed by the contracting parties, all payments shall be made after the conclusion of an individual contract/order or, within a framework agreement, with the retrieval of an individual licence.
        </TextWrapper>
        <TextWrapper>
          (3)	Invoices from the licensor are due for payment without deduction 15 calendar days after receipt of the invoice.
        </TextWrapper>
        <TextWrapper>
          (4)	All remuneration is subject to statutory value added tax, provided that the licensor is subject to value added tax at the time of payment or has opted for it. If the obligation to pay value added tax or the option to pay value added tax arises subsequently, the value added tax can be claimed subsequently against invoice upon presentation of the certificate of obligation to pay value added tax issued by the competent tax office.
        </TextWrapper>
        <TextWrapper>
          (5) If the Licensee is required by applicable law to pay a portion of the net price as withholding tax or comparable levy to a competent tax authority, it shall pay the Licensee, in addition to the remaining net amount, the amount corresponding to the amount to be paid to the tax authority, so that the Licensor receives the agreed net price in any case.
        </TextWrapper>
        <TextWrapper>  
          If the Licensee is in default with a payment despite a reminder, the Licensor may block further access to the Sustamizer.
         </TextWrapper>
        <TextWrapper>
          (6)	In the event of repeated default in payment by the licensee, the licensor shall be entitled to demand advance payments for the further provision of services, the amount of which may be determined by the licensor at its reasonable discretion (Sec. 315 BGB).
        </TextWrapper>
        <TextWrapper>
          (7)	The statutory provisions (e.g. Sec. 320, 273 BGB) remain unaffected.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}>
          Section 12	Term of Licences
        </TitleWrapper>
        <TextWrapper>
          (1)	Unless otherwise agreed between the parties in writing or in text form, the data licenses granted by the Licensor within an individual or framework agreement are limited in time to one year. The data licenses shall be automatically extended for one additional year at a time, unless one party gives notice in text form to 30 days prior to a further extension.
        </TextWrapper>
        <TextWrapper>
          (2)	The licensor shall have a special right of extraordinary termination of a licence if:
        </TextWrapper>
        <div>
          <ol className={lowerAlphaListClassName}>
            <li>(a) the licensee is in default of payment for a period of more than 2 months despite a reminder and the setting of a deadline; </li>
            <li>(b) if the licensee suffers a substantial deterioration of its assets which leads to the expectation that invoices to the licensee will no longer be paid;</li>
            <li>(c) the licensee infringes any intellectual property of the licensor (e.g. use or processing of the sustamize data in breach of contract);</li>
            <li>(d) the licensee otherwise culpably breaches an obligation and does not remedy this breach within a reasonable period of time set by the licensor.</li>
          </ol>
        </div>
        <TextWrapper>
          (3) Upon termination taking effect pursuant to par. 2, the rights granted to the licensee under this DaaSA shall terminate. Any claims of the licensee for repayment of licence fees or other claims of the licensee of any kind are excluded.
        </TextWrapper>
        <TextWrapper>
          (4) In the case of a framework agreement between the parties, the licence term shall remain unaffected by the execution of an agreed ordinary right of termination; the parties shall make a separate provision to this effect in the framework agreement.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}>
          Section 13	Obligation to delete and surrender
        </TitleWrapper>
        <TextWrapper>
          (1)	The licensee is obligated to finally and permanently delete any sustamize data and database software of the sustamize databases under his control after the 	end of the license period, as long as this does not conflict with mandatory legal regulations or mandatory information security requirements (e.g. backup). The deletion and its documentation must be carried out in accordance with the recognized rules of technology and in compliance with the respective maximum protection standards. Upon request by the Licensor, the Licensee shall declare in lieu of an oath all deletion measures and their compliance with the contract.
        </TextWrapper>
        <TextWrapper>
          (2)	<strong>Work result</strong> shall be exempt from the obligation to delete.
        </TextWrapper>
        <TextWrapper>
          (3)	Information, materials, records, documents, hardware/software and other contents of the Licensor, which are available to the Licensee in embodied form, including all copies of the contractual sustamize data on data carriers or hardware devices, must be returned to the Licensor at the end of the license term or destroyed at the request of the Licensor or in the event of non-acceptance. The provision of paragraph 1 shall apply accordingly.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}>
          Section 14	Liability for Third Party Rights
        </TitleWrapper>
        <TextWrapper>
          (1)	In the event that third parties make claims against the licensee for infringement of copyrighted database works by the sustamize data and this is the responsibility of the licensor, the licensor shall indemnify the licensee against these claims insofar as they are justified. 
        </TextWrapper>
        <TextWrapper>
          (2)	The licensee is obliged to inform the licensor immediately if a third party makes claims against the licensee in connection with the sustamize data. If the licensee fails to notify sustamize in time and damages or other disadvantages (e.g. extrajudicial or judicial costs) arise from this, the licensor is not liable for this.
        </TextWrapper>
        <TextWrapper>
          (3) To the extent that there are justified claims by third parties pursuant to paragraph 1, the Licensor may, at its option, effect subsequent performance vis-à-vis the Licensee in that the Licensor
        </TextWrapper>
        <div>
          <ol className={lowerAlphaListClassName}>
            <li>(a)	obtains from the party having the right to dispose of the infringed database right in favor of the Licensee a right of use sufficient for the Licensee's contractual purpose (cf. Section 4(3)), or</li>
            <li>(b) modifies or substitutes the sustamize data subject to paragraph 1 with effects on the intended use acceptable to Licensee; or </li>
            <li>(c) provides a new version of the sustamize data specified in paragraph 1, the use of which does not violate the database rights of third parties. </li>
          </ol>
        </div>
        <TextWrapper>
          (4) The Licensee shall support the Licensor in the defense against claims of third parties to the extent reasonable and conducive to the defense and settlement discussions, at the expense of the Licensor (e.g. by providing information, documentation, preservation of evidence).
        </TextWrapper>
        <TextWrapper>
          (5)	The Licensor shall not be liable for any infringement of third party rights to the extent that the infringement is due to an act or omission of the Licensee in breach of its obligations.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}>
          Section 15	Other liability
        </TitleWrapper>
        <TextWrapper>
          (1)	The Licensor shall be liable for intent and gross negligence in accordance with the statutory provisions.
        </TextWrapper>
        <TextWrapper>
          (2)	The Licensor shall only be liable for slight negligence in the event of a breach of a material contractual obligation (cardinal obligation) and in the event of damage resulting from injury to life, limb or health.
        </TextWrapper>
        <TextWrapper>
          (3)	Unless a case of paragraph 1 exists, the following shall apply in the event of a breach of an essential contractual obligation (cardinal obligation) pursuant to paragraph 2: Material contractual obligations are those whose observance is a necessary prerequisite for the performance of the contract, or whose breach jeopardizes the achievement of the purpose of the contract and the Licensee could justifiably rely on the observance of the obligation concerned. The Licensor shall only be liable for foreseeable damage, the occurrence of which must typically be expected, to the extent specified in the following paragraph 4.
        </TextWrapper>
        <TextWrapper>
          (4)	The parties agree that the foreseeable damage typical for the contract shall not exceed an amount equal to 50 % of the annual net sales generated by the Licensor from the Licensee.
        </TextWrapper>
        <TextWrapper>
          (5)	In all other respects, the liability of the Licensor is excluded, including strict liability for damages for defects that were already present at the time the contract was concluded.
        </TextWrapper>
        <TextWrapper>
          (6)	The above provisions shall also apply in favor of the Licensor's vicarious agents.
        </TextWrapper>
        <TextWrapper>
          (7)	Claims for subsequent performance shall become statute-barred 12 months after delivery. Excluded from this are claims for damages and claims for defects in the event of fraudulent concealment of a defect.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}>
          Section 16	Secrecy, confidentiality
        </TitleWrapper>
        <TextWrapper>
          (1)	Each Party shall keep confidential any information and documents received from the other Party in connection with the performance of this DaaSA. Within its own company, information and documents of the other Party may only be brought to the attention of or made accessible to personnel who require the information and documents for the performance of this DaaSA and/or an individual/framework agreement concluded on the basis thereof and who themselves have been contractually obliged to maintain confidentiality. Each Party shall not disclose any information or documents of the other Party to third parties (including affiliated companies within the meaning of Section 15 of the German Stock Corporation Act) without the prior written consent of the other Party. If such consent exists, the third party shall be bound in writing to confidentiality in accordance with the provisions of this § 17 prior to disclosure. The obligation of confidentiality shall continue to exist after the end of the licence and contract for as long as such information and documents remain secret.
        </TextWrapper>
        <TextWrapper>
          (2)	Information or documents which are generally known are exempt from the obligation of confidentiality pursuant to par. 1 above.
        </TextWrapper>
        <TextWrapper>
          (3)	Licensee must use the sustamize data exclusively for the contractual purpose according to Section 4(3). Licensee is obliged to protect the sustamize data and all access keys, codes, etc. provided to him from access by unauthorised third parties by suitable measures and to keep them secret. All physical and non-physical copies of the sustamize data must be kept in a protected location.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}>
          Section 17	Force majeure
        </TitleWrapper>
        <TextWrapper>
          Neither of the contractual partners is responsible for damage and delays based on causes beyond the control of the respective contractual partner. Such causes are in particular strikes, lockouts, Labour disputes, natural disasters, pandemics, war, military conflicts, civil unrest, acts of sabotage, legal or official orders.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}>
          Section 18	Final provisions
        </TitleWrapper>
        <TextWrapper>
          (1)		If the agreements of the parties in an individual or framework agreement deviate from this DaaSA, the agreement of the parties in the individual or framework agreement shall apply in case of doubt.
        </TextWrapper>
        <TextWrapper>
          (2)	Should individual provisions of this DaaSA be or become invalid, this shall not affect the validity of the remaining provisions. Instead of the invalid provision, that provision shall apply which the parties would have agreed upon in good faith according to the originally intended purpose from an economic point of view. The same shall apply in the event of a loophole in the contract.
        </TextWrapper>
        <TextWrapper>
          (3)	This DaaSA and the contractual relationship between the parties shall be governed by the laws of the Federal Republic of Germany, excluding the UN Sales Convention.
        </TextWrapper>
        <TextWrapper>
          (4)	The place of jurisdiction in dealings with merchants shall be the Licensor's registered office (Munich). However, the Licensor shall also be entitled, at its discretion, to bring an action at the Licensee's place of business.
        </TextWrapper>
      </PaperWrapper>
    </ContentBody>
  )
}
