import { iDataHubType } from './types'
import reqProvider from '../coreRequests'
import { DATA_API } from '../urlPaths'
import { call } from 'redux-saga/effects'

//iDataHubSearchDataType
export function* getDataHubData(
  searchQueryParamPath: string
): iDataHubType | any {
  const path = `${DATA_API}/context${searchQueryParamPath}`
  const data: iDataHubType | any = yield call(reqProvider.getReq, path)
  return data
}
