import { FC, useEffect, useState } from 'react';
import { OrderDetails } from './components/OrderDetails';
import { OrderSummary } from './components/OrderSummary';
import ModalWrapper from 'components/custom/ModalWrapper';
import { eAdditionalDatabaseSelection, eBillingPeriod, eButtonSize, eButtonVariant, eColor, ePackageSelection, eTypographyVariant } from 'components/types';
import { useDispatch, useSelector } from 'react-redux';
import { userReducerState } from 'store/slices/user';
import TitleWrapper from 'components/custom/TitleWrapper';
import ButtonWrapper from 'components/custom/ButtonWrapper';
import { iOrganizationData } from 'store/types';
import { organizationProfileState } from 'store/slices/organizationProfile';
import { sagaGetOrganizationProfileDataAction } from 'saga/actions/user';
import { useEffectOnce } from 'usehooks-ts';
import { subscriptionOrderReducerState } from 'store/slices/subscriptionOrderSlice';
import { sagaCalculateSubscriptionConfiguration, sagaGetAvailableProducts} from 'saga/actions/subscriptionOrder';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'routes/paths';

interface OrderProps {
  open: boolean,
  onClose: () => void,
  packageSelection: ePackageSelection,
  additionalDatabaseSelection?: eAdditionalDatabaseSelection,
  billingPeriod: eBillingPeriod,
  resetOrderFormStatus: boolean
}

export const OrderForm: FC<OrderProps> = ({
  open,
  onClose,
  packageSelection,
  additionalDatabaseSelection,
  billingPeriod,
  resetOrderFormStatus,
}) => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  /** Organization */
  const dispatch = useDispatch()
  const { organization } = useSelector(userReducerState)

  useEffectOnce(() => {
    organization && dispatch(sagaGetOrganizationProfileDataAction())
  })
  const organizationData: iOrganizationData | any = useSelector(
    organizationProfileState
  )
  const [submitStatus, setSubmitStatus] = useState<'success'|'error'|undefined>(undefined)
  const [submitError, setSubmitError] = useState<any>()

  /** Products and Subscriptions */
  useEffectOnce(() => {
    dispatch(sagaGetAvailableProducts())
  })

  const {
    subscriptionOrder,
    subscriptionOrderCalculation,
    isLoadingNewCalculation,
    isLoadingSubmitSubscription,
    subscriptionSubmitResponseCode,
    subscriptionSubmitError
  } = useSelector(subscriptionOrderReducerState)

  useEffect(() => {
    subscriptionOrder 
      && dispatch(sagaCalculateSubscriptionConfiguration(subscriptionOrder))
  }, [subscriptionOrder, dispatch])

  useEffect(() => {
    switch (subscriptionSubmitResponseCode) {
      case undefined: return;
      case 200: 
        setSubmitStatus('success'); 
        organization && dispatch(sagaGetOrganizationProfileDataAction())
        break;
      default: 
        setSubmitStatus('error')
        setSubmitError(subscriptionSubmitError)
        break;
    }
  }, [
    subscriptionSubmitResponseCode, 
    setSubmitError, 
    setSubmitStatus, 
    subscriptionSubmitError,
    organization,
    dispatch
  ])

  useEffect(() => {
    if (resetOrderFormStatus) {
      setSubmitStatus(undefined)
      setSubmitError(undefined)
    }
  }, [resetOrderFormStatus])

  const opaque = {
    full: 'opacity-0',
    none: 'opacity-100'
  }

  return organizationData?.id 
  ? (
    <ModalWrapper 
      open={open}
      hasCloseButton 
      onClose={onClose}
      isLoading={isLoadingSubmitSubscription}
    >
      <div className={``}>
        
        {/** New Order Placement */}
        {submitStatus === undefined && (
          <div className={`
            flex flex-col lg:flex-row
            ${!subscriptionOrderCalculation ? `${opaque.full}` : `${opaque.none}`}
            transform transition-all duration-200
          `}>
            <OrderDetails 
              packageSelection={packageSelection} 
              additionalDatabaseSelection={additionalDatabaseSelection}
              billingPeriod={billingPeriod}
              className={`basis-1/2 lg:pl-4 lg:pr-4`}
            />
            <OrderSummary 
              organization={organizationData} 
              className={`basis-1/2 lg:pl-8 lg:pr-8`}
              subscriptionOrder={subscriptionOrder}
              subscriptionOrderCalculation={subscriptionOrderCalculation}
              isLoading={isLoadingNewCalculation}
            />
          </div>
        )}

        {/** Order Successful */}
        {submitStatus === 'success' && (
          <div className={``}>
            <TitleWrapper variant={eTypographyVariant.h5}>
              {t('offerings.orderConfirmation.title')}
            </TitleWrapper>
            <div className={`flex flex-col`}>
              <span>{t(`offerings.orderConfirmation.body`)}</span>
              <ButtonWrapper 
                variant={eButtonVariant.text}
                onClick={onClose}
                className={`self-end mt-3`}
              >
                {t(`general.close`)}
              </ButtonWrapper>
            </div>
          </div>
        )}

        {/** Order Unsuccessful */}
        {submitStatus === 'error' && (
          <div className={``}>
            <TitleWrapper variant={eTypographyVariant.h5} color={eColor.error}>
              {t(`offerings.orderFailed.title`)}
            </TitleWrapper>
            <div className={`flex flex-col`}>
              <span>
                {t(`offerings.orderFailed.body`)}
                <br />
              </span>
              <span className={`font-bold`}>Error: </span>
              <span>{submitError?.toString()}</span>
              <ButtonWrapper 
                variant={eButtonVariant.text}
                color={eColor.error}
                onClick={onClose}
                className={`self-end mt-3`}
              >
                {t(`general.close`)}
              </ButtonWrapper>
            </div>
          </div>
        )}
      </div>
    </ModalWrapper>
  ) 
  : (
    <ModalWrapper
      open={open}
      hasCloseButton
      onClose={onClose}
    >
      <div className='flex flex-col content-center lg:p-6'>
        <TitleWrapper variant={eTypographyVariant.h5}>
          {t(`offerings.registerOrganization`)}
        </TitleWrapper>
        <span>{t(`offerings.orgDataUse`)}</span>
        <div className='flex flex-row justify-center mt-4'>
          <ButtonWrapper 
            variant={eButtonVariant.contained}
            size={eButtonSize.medium}
            onClick={() => navigate(RoutePaths.organizationProfile) }
            >
              {t(`organization.register.register`)}
          </ButtonWrapper>
        </div>
      </div>
    </ModalWrapper>
  )
}