import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { calculateSubscriptionConfiguration } from "config/services/requests/subscription/calculateSubscriptionConfiguration";
import { call, put, takeLatest } from "redux-saga/effects";
import { subscriptionOrderActionTypes } from "saga/actions/subscriptionOrder";
import { setSubscriptionOrderCalculation, startLoadingNewCalculation } from "store/slices/subscriptionOrderSlice";
import { iSubscriptionOrder, iSubscriptionOrderCalculation } from "store/types/subscriptionOrder";

function* calculateSubscriptionConfigurationSaga(payload: PayloadAction<iSubscriptionOrder>) {
  yield put(startLoadingNewCalculation())
  const result: AxiosResponse<iSubscriptionOrderCalculation> = yield call(
    calculateSubscriptionConfiguration, 
    payload.payload
  )
  yield put(setSubscriptionOrderCalculation(result.data))
}

export default function* calculateSubscriptionConfigurationSagaHook() {
  yield takeLatest(
    subscriptionOrderActionTypes.SAGA_CALCULATE_SUBSCRIPTION_CONFIGURATION,
    calculateSubscriptionConfigurationSaga
  )
}