import { configureStore } from '@reduxjs/toolkit'
import isDrawerOpenSlice from './slices/generalSettings'
import apiTableDataSlice from './slices/apiManagementSlice'
import createSagaMiddleware from 'redux-saga'
import rootSaga from 'saga/'
import userSlice from './slices/user'
import organizationProfileSlice from './slices/organizationProfile'
import currencySlice from './slices/currency'
import dataHubsSlice from './slices/dataHubSlice'
import menuItemsSlice from './slices/menuItems'
import dataHubCategoriesSlice from './slices/dataHubCategories'
import selectedFieldsSlice from './slices/selectedFieldsSlice'
import userSettingsSlice from './slices/searchParametersSlice'
import messageReducerSlice from './slices/generalSettings'
import entityCO2DataSlice from './slices/entityCO2Data'
import singleEntitySlice from './slices/singleEntity'
import massFileUploadSlice from './slices/massFileUploadSlice'
import generalSettingsSlice from './slices/generalSettings'
import generalDataSlice from './slices/generalDataSlice'
import subscriptionOrderSlice from './slices/subscriptionOrderSlice'

let sagaMiddleware = createSagaMiddleware()
const middleware = (getDefaultMiddleware: any) => [
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
  sagaMiddleware,
]

export const store = configureStore({
  reducer: {
    isDrawerOpen: isDrawerOpenSlice,
    messageState: messageReducerSlice,
    apiTableData: apiTableDataSlice,
    user: userSlice,
    organizationProfile: organizationProfileSlice,
    currency: currencySlice,
    dataHub: dataHubsSlice,
    dataHubCategories: dataHubCategoriesSlice,
    menuItems: menuItemsSlice,
    selectedFilters: selectedFieldsSlice,
    userSettings: userSettingsSlice,
    entityCoTwo: entityCO2DataSlice,
    singleEntityData: singleEntitySlice,
    massFileUpload: massFileUploadSlice,
    generalSettings: generalSettingsSlice,
    generalData: generalDataSlice,
    subscriptionOrder: subscriptionOrderSlice
  },
  middleware,
})
sagaMiddleware.run(rootSaga)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
