import { dataHubs } from './data-hub/hubs'
import {
  menuItems,
  menuItemsDataHubFavoritesGroup,
  menuItemsDataHubOptionGroup,
  menuItemsDataHubResourceGroup,
  userMenuItems,
} from './menuItems'

export const mocks = {
  dataHubs,
  menuItems,
  menuItemsDataHubFavoritesGroup,
  menuItemsDataHubOptionGroup,
  menuItemsDataHubResourceGroup,
  userMenuItems,
}
