import { call, put, takeLeading } from 'redux-saga/effects'
import apiRequests from 'config/services'
import { dataHubTypes, sagaGetSearchParametersData } from 'saga/actions/dataHub'
import { PayloadAction } from '@reduxjs/toolkit'
import { iUpdateStatusSearchParameter } from './types'

export function* updateStatusSearchParameterDataSaga(
  action: PayloadAction<iUpdateStatusSearchParameter>
) {
  try {
    const statusData: iUpdateStatusSearchParameter = action.payload

    yield call(apiRequests.UpdateSearchParameterStatusData, statusData)
    yield put(sagaGetSearchParametersData())
  } catch (e) {
        //catchind errors
  }
}

export default function* updateStatusSearchParameterDataHook() {
  yield takeLeading(
    dataHubTypes.SAGA_UPDATE_SEARCH_PARAMETER_STATUS,
    updateStatusSearchParameterDataSaga
  )
}
