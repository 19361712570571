import {  put, takeLeading } from "redux-saga/effects";
import { massFileUploadActionTypes } from "saga/actions/massFileUpload";
import { removeInputFiles } from "store/slices/massFileUploadSlice";

function* removeMassUploadInputFilesSaga(payload: any) {
  yield put(removeInputFiles(payload.payload));
}

export default function* removeMassUploadInputFilesSagaHook() {
  yield takeLeading(
    massFileUploadActionTypes.SAGA_REMOVE_INPUT_FILES,
    removeMassUploadInputFilesSaga
  );
}