import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { iSearchParameters_Data } from 'pages/DataHub/filters/types'
import { RootState } from 'store'
import { searchParameterState } from 'store/types/userSearchSettings'

const initialState: searchParameterState = {
  searchParameters: [],
  searchCategory: 'materials',
}

export const searchParametersState = (state: RootState) =>
  state.userSettings.searchParameters

export const getSearchParametersInitialState = () => initialState

export const selectedSearchCategoryState = (state: RootState) =>
  state.userSettings.searchCategory

export const searchParametersReducer = createSlice({
  name: 'searchParametersReducer',
  initialState,
  reducers: {
    setSearchParametersData: (
      state,
      action: PayloadAction<iSearchParameters_Data[]>
    ) => {
      const newParameters: iSearchParameters_Data[] = action.payload

      state.searchParameters = newParameters
    },
    setSearchCategory: (state, action: PayloadAction<string>) => {
      const newCategory: string = action.payload
      state.searchCategory = newCategory
    },
  },
})

export const { setSearchParametersData, setSearchCategory } =
  searchParametersReducer.actions

export default searchParametersReducer.reducer
