import { put, takeLeading } from "redux-saga/effects";
import { massFileUploadActionTypes } from "saga/actions/massFileUpload";
import { cancelProcessingFiles, resetState } from "store/slices/massFileUploadSlice";


function* cancelMassFileUploadFileProcessingSaga() {
  yield put(cancelProcessingFiles());
}

export function* cancelMassUploadFileProcessingSagaHook() {
  yield takeLeading(
    massFileUploadActionTypes.SAGA_CANCEL_FILE_PROCESSING,
    cancelMassFileUploadFileProcessingSaga
  )
}

function* resetMassFileUploadStateSaga() {
  yield put(resetState());
}

export function* resetMassUploadStateSagaHook() {
  yield takeLeading(
    massFileUploadActionTypes.SAGA_RESET_STATE,
    resetMassFileUploadStateSaga
  );
}