import { call } from 'redux-saga/effects'
import reqProvider from '../coreRequests'
import { BASE_API } from '../urlPaths'

export function* logoutUser(): any {
  const path = `${BASE_API}/logout`

  const data: any = yield call(reqProvider.getReq, path)
  return data ? true : false
}
