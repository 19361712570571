import React, { FC } from "react"
import { PackageOfferingPrice } from "./packageOfferingPrice";
import { PackageOfferingFeatures } from "./packageOfferingFeatures";
import { PackageOfferingAction } from "./packageOfferingAction";
import { useTranslation } from "react-i18next";
import PaperWrapper from "components/custom/PaperWrapper";
import { isThisADarkColor } from "pages/Billing/helpers";
import CustomSvgStyled from "components/custom/CustomSvgStyled";

interface iPackageOfferingProps {
  title: string,
  labelColor: string,
  labelIcon?: string,
  descriptionHeading?: string,
  description: string,
  elevated?: boolean,
  children: JSX.Element[]
}

export const PackageOffering: FC<iPackageOfferingProps> = ({
  title,
  labelColor,
  labelIcon,
  elevated = false,
  children
}) => {

  const { t } = useTranslation()

  /** Resolve Child Slots */
  let price, features, action;
  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) return;
    
    if (child.type === PackageOfferingPrice) {
      price = child;
    } else if (child.type === PackageOfferingFeatures) {
      features = child;
    } else if (child.type === PackageOfferingAction) {
      action = child;
    } else {
      features = child;
    }
  });

  return !price || !action
    ? (
        <>
          Make sure to include the Price, Features and Action Component.
        </>
      )
    : (
      <PaperWrapper className={`
        flex flex-col flex-grow-0 flex-shrink-1 basis-full
        mt-3 mb-3
        lg:max-w-sm lg:mt-14 lg:mb-12
        transform transition-transform
        ${elevated ? 'lg:-translate-y-7 lg:scale-105 lg:shadow-lg' : 'lg:scale-95'}
        hover:shadow-lg hover:shadow-gray-400
        cursor-default
      `}>

        <div 
          className={`
            flex flex-row flex-wrap rounded-t-xl py-2 justify-center gap-2 items-center shadow-inner
            ${isThisADarkColor(labelColor) ? 'text-white' : 'text-black'}
          `}
          style={{backgroundColor: labelColor}}
        >
          {elevated &&
            <div className={`
              w-full rounded-t-xl -mt-2
              flex flex-row justify-center items-center
              bg-[#FFAE35]
              text-xs text-white
              mb-1
            `}>{t('pricing.mostPopular')}</div>
          }
          {labelIcon !== undefined && 
            <CustomSvgStyled src={labelIcon} />
          }
          {t(title)}
        </div>
        <div className={`flex flex-col p-2 h-full`}>
          <div className={`flex flex-grow-0 flex-shrink-0 justify-center`}>
            {price}
          </div>
          <div className={`flex flex-grow flex-shrink flex-base-auto`}>
            {features}
          </div>
          <div className={`flex flex-grow-0 flex-shrink-0 justify-center py-2`}>
            {action}
          </div>
        </div>

      </PaperWrapper>
    );
}