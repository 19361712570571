import React, { useEffect } from 'react'
import TranslateIcon from '@mui/icons-material/Translate'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { sagaSetUserLanguageProfileAction } from 'saga/actions/user'
import { userReducerState } from 'store/slices/user'
import { useSelector } from 'react-redux'
import { eLocale, userState } from 'store/types'

export interface iLanguagesAvailable {
  [key: string]: {
    name: string
  }
}
const LanguageSwitcher = () => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const userInfo: userState = useSelector(userReducerState)

  useEffect(() => {
    const userLanguage = userInfo?.settings?.locale || eLocale.English
    if (userLanguage) {
      i18n.changeLanguage(userLanguage)
    }
  }, [i18n, userInfo])

  const lngs: iLanguagesAvailable = {
    en: { name: 'English' },
    de: { name: 'Deutsch' },
  }
  const label = t('general.languageSelector')

  return (
    <div className="flex items-center justify-center">
      <FormControl fullWidth={true}>
        <InputLabel id="language-switcher-label">
          <TranslateIcon />
        </InputLabel>
        <Select
          labelId="language-switcher-label"
          id="language-switcher"
          value={i18n.resolvedLanguage}
          onChange={(e: SelectChangeEvent<string>) => {
            const selectedLanguage = e.target.value
            i18n.changeLanguage(selectedLanguage)
            dispatch(sagaSetUserLanguageProfileAction(selectedLanguage))
          }}
          label={label}
          autoWidth>
          {Object.keys(lngs).map((lng: string) => (
            <MenuItem key={lng} value={lng}>
              {lngs[lng].name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default LanguageSwitcher
