import { eBillingPeriod } from 'components/types'
import { eCurrencyCodes } from './currency'

export interface iProductOverview {
  databases: iPricedProductListing[]
  addonDatabases: iPricedProductListing[]
  discounts: iDiscountedProductListing[]
  features: iPricedProductListing[]
  packages: iPricedProductListing[]
}

export interface iProductListing {
  productKey: eProductKey
  type: eProductType
  description: string
  compatibleProducts: eProductKey[]
  includedProducts?: eProductKey[]
  isAvailable: boolean
}

export interface iPricedProductListing extends iProductListing {
  price: number
}

export interface iDiscountedProductListing extends iProductListing {
  percentage: number
}

export enum eProductKey {
  SUST_LOGISTICS_PACKAGING_DB = 'SUST_LOGISTICS_PACKAGING_DB',
  SUST_MATERIALS_DB = 'SUST_MATERIALS_DB',
  SUST_LOGISTICS_TRANSPORT_DB = 'SUST_LOGISTICS_TRANSPORT_DB',
  SUST_ENERGY_DB = 'SUST_ENERGY_DB',
  SUST_ELECTRONICS_DB = 'SUST_ELECTRONICS_DB',
  SUST_CONSUMER_PRODUCTS_DB = 'SUST_CONSUMER_PRODUCTS_DB',
  SUST_PRODUCTION_EMPLOYEE_DB = 'SUST_PRODUCTION_EMPLOYEE_DB',
  SUST_PRODUCTION_EQUIPMENT_DB = 'SUST_PRODUCTION_EQUIPMENT_DB',
  SUST_PRODUCTION_MANUFACTURING_DB = 'SUST_PRODUCTION_MANUFACTURING_DB',
  SUST_STANDARD_PACKAGE = 'SUST_STANDARD_PACKAGE',
  SUST_PRO_PACKAGE = 'SUST_PRO_PACKAGE',
  SUST_DISCOUNT_LICENSES_6 = 'SUST_DISCOUNT_LICENSES_6',
  SUST_DISCOUNT_EXTRA_DB_PRO = 'SUST_DISCOUNT_EXTRA_DB_PRO',
  SUST_DISCOUNT_LICENSES_3 = 'SUST_DISCOUNT_LICENSES_3',
  SUST_DISCOUNT_EXTRA_DB_STANDARD = 'SUST_DISCOUNT_EXTRA_DB_STANDARD',
  SUST_MASS_UPLOAD_FEATURE = 'SUST_MASS_UPLOAD_FEATURE',
  SUST_ISO_14048_FEATURE = 'SUST_ISO_14048_FEATURE',
  SUST_STANDARD_PARTS_DB = 'SUST_STANDARD_PARTS_DB',
}

export interface iFormattedPrice {
  formatted: string
  unscaled: number
}

export enum eProductType {
  DATABASE = 'database',
  DISCOUNT = 'discount',
  FEATURE = 'feature',
  PACKAGE = 'package',
}

export interface iSubscriptionOrder {
  billingType: eBillingPeriod
  packageKey?: eProductKey.SUST_STANDARD_PACKAGE | eProductKey.SUST_PRO_PACKAGE
  licenses: number
  termsAccepted?: boolean
  products: { productKey: eProductKey }[]
}

export interface iProductPriceListing {
  productKey: eProductKey
  price: iFormattedPrice
  origPrice: iFormattedPrice
  discounts: iDiscountPriceListing[]
}

export interface iDiscountPriceListing {
  productKey: eProductKey
  discountAmount: iFormattedPrice
  discountedLicenses: number
  addonDiscount: boolean
  licenseDiscount: boolean
  unitAmount: iFormattedPrice
}

export interface iSubscriptionOrderCalculation {
  vatPercent: number
  vatAmount: iFormattedPrice
  totalGross: iFormattedPrice
  totalNet: iFormattedPrice
  pricePerMonthMonthly: iFormattedPrice
  pricePerMonthYearly: iFormattedPrice
  remainingDaysInMonth: number
  totalDaysInMonth: number
  priceScale: number
  currency: eCurrencyCodes
  productPrices: iProductPriceListing[]
  version: number
}

export interface iSubscriptionOrderState {
  productOverview?: iProductOverview,
  subscriptionOrder?: iSubscriptionOrder,
  subscriptionOrderCalculation?: iSubscriptionOrderCalculation,
  isLoadingNewCalculation: boolean,
  isLoadingSubmitSubscription: boolean,
  subscriptionSubmitResponseCode?: number,
  subscriptionSubmitError: any,
  cancelledSubscription?: iSubscriptionCancelRenewal
}

export interface iSubscriptionCancelRenewal {
  id: number
}
