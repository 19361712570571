import React, { MouseEvent, useCallback, useEffect, useState } from 'react'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { RoutePaths } from 'routes/paths'
import { useDispatch, useSelector } from 'react-redux'
import { currencyReducerState } from 'store/slices/currency'
import { currencyValuesState } from 'store/types'
import { useTranslation } from 'react-i18next'
import { userReducerState } from 'store/slices/user'
import { iLanguagesAvailable } from 'components/custom/LanguageSwitcher'
import { Box, Modal, MenuItem } from '@mui/material'
import {
  sagaDeleteUserAction,
  sagaSetUserCurrencyProfileAction,
  sagaSetUserLanguageProfileAction,
  sagaUserProfileRequestAction,
  sagaUserProfileUrlsAction,
} from 'saga/actions/user'
import { sagaGetLatestExchangeRates } from 'saga/actions/currency'
import { setLocal } from 'config/lib'
import SelectWrapper from 'components/custom/SelectWrapper'
import i18n from 'i18n'
import { useNavigate } from 'react-router-dom'
import { userProfileUrlsState } from 'store/slices/generalSettings'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import ContentBodyWrapper from 'components/custom/ContentBodyWrapper'
import { eColor, eTypographyVariant } from 'components/types'
import PaperWrapper from 'components/custom/PaperWrapper'
import TextWrapper from 'components/custom/TextWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'

export const UserSettings = () => {
  const currencyExchangeRates = useSelector(currencyReducerState).rates
  const userProfileUrls = useSelector(userProfileUrlsState)
  const { changePasswordUrl, updateProfileUrl } = userProfileUrls
  const userInfo = useSelector(userReducerState)
  const [open, setOpen] = useState(false)
  const { firstName, lastName, email, settings } = userInfo
  const userCurrency = settings.currency
  const userLanguage = settings.locale
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleDeleteUser = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      dispatch(sagaDeleteUserAction(navigate))
    },
    [dispatch, navigate]
  )

  const handleSelectedLanguage = useCallback(
    (value: string) => {
      setLocal('language', value)
      i18n.changeLanguage(value)
      dispatch(sagaSetUserLanguageProfileAction(value))
    },
    [dispatch]
  )
  const handleSelectedCurrency = useCallback(
    (value: string) => {
      setLocal('currency', value)
      dispatch(sagaSetUserCurrencyProfileAction(value))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(sagaGetLatestExchangeRates())
    dispatch(sagaUserProfileRequestAction())
    dispatch(sagaUserProfileUrlsAction())
    if (userLanguage) i18n.changeLanguage(userLanguage)
  }, [dispatch, userLanguage])

  const languages: iLanguagesAvailable = {
    en: { name: 'English' },
    de: { name: 'Deutsch' },
  }
  return (
    <>
      <ContentBodyWrapper title={t('pages.mySettings')}>
        <div className="flex w-full h-full items-start justify-center mb-6">
          <PaperWrapper className="flex flex-col lg:min-w-[776px] max-w-max gap-y-4 py-5 px-5">
            <p className="text-xl font-bold">
              {t('profile.userSettingsTitle')}
            </p>
            <div>
              <label htmlFor="firstName">{t('profile.firstName')}</label>
              <p className="opacity-50 " id="firstName">
                {firstName}
              </p>
            </div>
            <div>
              <label htmlFor="lastName">{t('profile.lastName')}</label>
              <p className="opacity-50" id="lastName">
                {lastName}
              </p>
            </div>
            <div>
              <label htmlFor="email">{t('profile.email')}</label>
              <p className="opacity-50 " id="email">
                {email}
              </p>
            </div>
            <div>
              <div className="md:w-full lg:w-[75%] xl:w-[55%] 2xl:w-[65%]">
                <label className="label font-bold pl-0 pb-0 -mb-3">
                  {t('profile.language')}
                </label>

                <SelectWrapper
                  defaultValue={userLanguage}
                  onChange={handleSelectedLanguage}
                  className="select-sm 2xl:select-md p-0 max-w-[88%] w-full">
                  {Object.keys(languages).map((lng: string) => (
                    <MenuItem key={lng} value={lng}>
                      {languages[lng].name}
                    </MenuItem>
                  ))}
                </SelectWrapper>

                <label className="label pl-0 font-bold mt-4 pb-0 -mb-3">
                  {t('profile.currency')}
                </label>
                <SelectWrapper
                  defaultValue={userCurrency}
                  onChange={handleSelectedCurrency}
                  className="select-sm 2xl:select-md p-0 max-w-[88%] w-full">
                  {currencyExchangeRates.map(
                    (currency: currencyValuesState) => (
                      <MenuItem
                        key={currency.currencyCode}
                        value={currency.currencyCode.toString()}>
                        {t(`general.currency.${currency.currencyCode}`)}
                      </MenuItem>
                    )
                  )}
                </SelectWrapper>
              </div>
            </div>
            <div className="flex flex-wrap gap-y-2 gap-x-3 mt-12 mb-2">
              <ButtonWrapper onClick={handleOpen} color={eColor.secondary}>
                {t('button.deleteAcc')}
              </ButtonWrapper>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className=" absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[40%]">
                  <PaperWrapper className="p-4">
                    <TitleWrapper
                      id="modal-modal-title"
                      variant={eTypographyVariant.h6}
                      className="2xl:text-xl">
                      {t('profile.modal.title')}
                    </TitleWrapper>
                    <TextWrapper
                      id="modal-modal-description"
                      className="mb-4"
                      sx={{ marginTop: 2 }}>
                      {t('profile.modal.message')}
                    </TextWrapper>
                    <div className="flex flex-wrap gap-2" aria-hidden="true">
                      <ButtonWrapper
                        onClick={handleDeleteUser}
                        color={eColor.secondary}
                        aria-label={t('general.yes')}>
                        {t('general.yes')}
                      </ButtonWrapper>
                      <ButtonWrapper
                        onClick={() => handleClose()}
                        aria-label={t('general.no')}>
                        {t('general.no')}
                      </ButtonWrapper>
                    </div>
                  </PaperWrapper>
                </Box>
              </Modal>
              {changePasswordUrl && (
                <ButtonWrapper
                  onClick={() => (window.location.href = changePasswordUrl)}>
                  {t('profile.changePassword')}
                </ButtonWrapper>
              )}
              {updateProfileUrl && (
                <ButtonWrapper
                  onClick={() => (window.location.href = updateProfileUrl)}>
                  {t('profile.editProfile')}
                </ButtonWrapper>
              )}
            </div>
          </PaperWrapper>
        </div>
      </ContentBodyWrapper>
    </>
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.userSettings,
    title: 'pages.mySettings',
    element: <UserSettings />,
    roles: componentPermissions[componentNames.pageUserSettings],
    requiresAuthentication: true,
  },
  name: 'UserSettings',
}
export default exportObj