import React, { useState } from 'react'
import { Box, Modal, Paper, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { sagaDeleteUserAction } from 'saga/actions/user'
import { useNavigate } from 'react-router-dom'
import './ModalTransitions.css'
import { userReducerState } from 'store/slices/user'
import { eButtonVariant, eColor, eTypographyVariant } from 'components/types'
import ButtonWrapper from 'components/custom/ButtonWrapper'

const PersonalSettings = () => {
  const { t } = useTranslation()
  const userInfo = useSelector(userReducerState)
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault()
    setOpen(false)
  }

  return (
    <>
      <Typography
        variant={eTypographyVariant.h5}
        data-testid={`personal-settings-title-testID`}
      >
        {t('profile.personalSettings.title')}
      </Typography>
      <Typography>
        <label htmlFor={t('profile.personalSettings.email')} className="mr-2">
          {t('profile.personalSettings.email')}
        </label>
        <span>{userInfo.email}</span>
      </Typography>
      <Typography>
        <label
          htmlFor={t('profile.personalSettings.firstName')}
          className="mr-2"
        >
          {t('profile.personalSettings.firstName')}
        </label>
        <span>{userInfo.firstName}</span>
      </Typography>
      <Typography>
        <label
          htmlFor={t('profile.personalSettings.lastName')}
          className="mr-2"
        >
          {t('profile.personalSettings.lastName')}
        </label>
        <span>{userInfo.lastName}</span>
      </Typography>
      <Typography>
        <label htmlFor={t('general.username')} className="mr-2">
          {t('general.username')}
        </label>
        <span>{userInfo.username}</span>
      </Typography>
      <div className="mt-2">
        <ButtonWrapper
          onClick={handleOpen}
          variant={eButtonVariant.contained}
          color={eColor.error}
        >
          {t('profile.personalSettings.modal.title')}
        </ButtonWrapper>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="Modal-Transitions">
            <Paper className="p-4 border-solid border-2 border-primary">
              <Typography
                id="modal-modal-title"
                variant={eTypographyVariant.h6}
                component="h2"
              >
                {t('profile.personalSettings.modal.title')}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t('profile.personalSettings.modal.message')}
              </Typography>
              <div className="space-x-2 pt-2" aria-hidden="true">
                <ButtonWrapper
                  variant={eButtonVariant.outlined}
                  color={eColor.error}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault()
                    dispatch(sagaDeleteUserAction(navigate))
                  }}
                  aria-label={t('general.yes')}
                >
                  {t('general.yes')}
                </ButtonWrapper>
                <ButtonWrapper
                  onClick={(e: React.MouseEvent) => handleClose(e)}
                  aria-label={t('general.no')}
                >
                  {t('general.no')}
                </ButtonWrapper>
              </div>
            </Paper>
          </Box>
        </Modal>
      </div>
    </>
  )
}
export default PersonalSettings
