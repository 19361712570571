import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Checkbox,
  TextField,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { iCustomField, iHubFilter } from 'store/types'

interface IProps {
  options?: string[]
  name: string
  displayName: string
  defaultValue?: string | boolean
  filter: iHubFilter
  onChange?: (value: iCustomField) => void
}

export const CustomAutoComplete = ({
  options,
  name,
  displayName,
  defaultValue,
  filter,
  onChange,
}: IProps) => {
  const getDefaultValue = useCallback(() => {
    if (typeof defaultValue === 'string') {
      return defaultValue.length > 0 ? defaultValue.split(',') : []
    } else if (Array.isArray(defaultValue)) {
      return defaultValue
    } else {
      return []
    }
  }, [defaultValue])

  const [value, setValue] = useState<string[]>([])
  const [focusForLabel, setfocusForLabel] = useState(false)

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  useEffect(() => {
    setValue(getDefaultValue())
  }, [defaultValue, getDefaultValue])

  const updateValue = useCallback(
    (selected: boolean, option: string) => {
      const index = value.indexOf(option)
      if (!selected) {
        if (index === -1) {
          setValue([...value, option])
        }
      } else {
        if (index !== -1) {
          setValue([...value.slice(0, index), ...value.slice(index + 1)])
        }
      }
    },
    [value]
  )

  const onTriggerEvents = useCallback(
    (newValue?: string[]) => {
      if (newValue !== undefined && onChange) {
        setValue([...value, ...newValue])
        onChange({
          name: name,
          displayName: displayName,
          value: newValue,
          info: filter,
        })
      }
    },
    [displayName, filter, name, onChange, value]
  )

  let i = 0
  return (
    <Autocomplete
      value={value}
      multiple
      id={`${name}-CustomAutocompleteId`}
      options={options || []}
      disableCloseOnSelect
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => {
        i++
        return (
          <span
            key={'AcOption-' + option + '-' + i}
            onClick={() => updateValue(selected, option)}
          >
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected as boolean}
              />
              {option}
            </li>
          </span>
        )
      }}
      className="sm:w-full md:w-[14.375rem]"
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            focusForLabel || displayName.length < 20
              ? displayName
              : displayName.slice(0, 18).concat('...')
          }
          placeholder={displayName.slice(0, 18).concat('...')}
          onFocus={() => setfocusForLabel(true)}
          onBlur={() => setfocusForLabel(false)}
        />
      )}
      onClose={() => {
        onTriggerEvents()
      }}
      onChange={(
        event: SyntheticEvent<Element, Event>,
        value: string[],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<string> | undefined
      ) => {
        onTriggerEvents(value)
      }}
    />
  )
}
