import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { iGeneralData } from 'store/types/countryCode'
// for parametered slices
// import type { PayloadAction } from '@reduxjs/toolkit'

export const initialState: iGeneralData = {
  countryCodes: [],
}

//get current reducer state
export const countryCodesState = (state: RootState) =>
  state.generalData.countryCodes

//reducer Object
export const countryCodesDataSlice = createSlice({
  name: 'countryCodeReducer',
  initialState,
  reducers: {
    setCountryCodes: (state, { payload }: PayloadAction<string[]>) => {
      state.countryCodes = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCountryCodes } = countryCodesDataSlice.actions

export default countryCodesDataSlice.reducer
