

import { IAccordionData } from '../components/DynamicAccordion'
import { getImage, makeBulttePointsList } from './helpers'


export const ElectronicsDocumentData: IAccordionData[] = [
  {
    title: 'ELECTRONICS',
    content:
    `This database contains CO2e emissions related to various electronics, such as hardware components, semi-finished and finished products. It comprises of 41 main classes of electronics like isolators, optoelectronics, switches, or embedded computers that are then split into a total of 852 additional subclasses of more specific electronic components, e.g. for embedded computers: accessories, interface boards and single board computers (SBCs). The purpose is to give companies easy access to a first impression and benchmark for their calculations.
    <br /><br />
    <u><b>Calculation Process</b></u>
    </br>
   
    The database for electronics is based on expert opinions and estimations that consider the used materials compositions for production and the complexity of the industrial processes of the different subclasses according to reference parts or components. It is kept at a high level of aggregation with no calculation. This means all entries have a quality rating of 5 (see aforementioned section on quality rating) and should be understood as an indicative reference value for that type of electrical or electronic component.
    <br /><br />
    <u><b>Process Chain Assumption</b></u>
    </br>
    As there is no base calculation, there cannot be any process chain options. These are estimated reference values with the base assumption of production in China.`,
    isOpen: true,
  },

]
