import { useEffect } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tabs, Tab } from '@mui/material'
import { Box } from '@mui/system'
import { useSearchParams } from 'react-router-dom'
import { OrderHistory } from './OrderHistory'
import { SubscriptionsList } from './SubscriptionsList'

interface iProps {
  tabPanelIndex?: number
}
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  onClick?: () => void
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}
const getCurrentView = (currentView: string) => {
  switch (currentView) {
    case 'subscriptions-list':
      return 0
      break
    case 'order-history':
      return 1
      break
    default:
      return 0
  }
}
const SubscriptionsTab = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [value, setValue] = React.useState(
    getCurrentView(
      searchParams.get('view')?.toString().split(',').join(' ') || ''
    )
  )

  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue)
  return (
    <div>
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="billing tabs">
          <Tab
            onClick={() => setSearchParams({ view: 'subscriptions-list' })}
            className="text-lg text-black dark:text-white"
            label={t('billing.subscriptions.title')}
          />
          <Tab
            onClick={() => setSearchParams({ view: 'order-history' })}
            className="text-lg ml-10 text-black dark:text-white"
            label={t('billing.orderHistory.title')}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <SubscriptionsList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrderHistory />
      </TabPanel>
    </div>
  )
}

export default SubscriptionsTab
