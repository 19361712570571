import { eBillingPeriod, ePricingMode } from "components/types"
import { getCurrencySymbol } from "pages/Billing/helpers"
import { FC } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { userReducerState } from "store/slices/user"
import { eCurrencyCodes, eLocale, userState } from "store/types"
import { iPricing } from "store/types/billingPricing"

interface iPackageOfferingPriceProps {
  prefix?: string,
  pricing?: iPricing,
  suffix?: string,
  billingPeriod: eBillingPeriod,
  mode: ePricingMode
}

export const PackageOfferingPrice: FC<iPackageOfferingPriceProps> = ({
  prefix,
  pricing,
  suffix,
  billingPeriod,
  mode
}) => {

  const { t } = useTranslation()
  const userInfo: userState = useSelector(userReducerState)

  return (
    <div className={`flex flex-col items-center mb-2`}>
      <div className={`text-xs mb-1`}>
        {prefix && t(prefix)}
      </div>
      <div className={`relative`}>

        {pricing && 
          <span className={`float-center align-top text-lg leading-3`}>
            {getCurrencySymbol(eCurrencyCodes.EUR)}&nbsp;
          </span>
        }
        {pricing && (billingPeriod === eBillingPeriod.YEARLY || mode === ePricingMode.DEVELOPERS) &&
          <span className={`float-center text-2xl font-bold text-center`}>{
            new Intl.NumberFormat(
              userInfo?.settings?.locale || eLocale.English, 
              {
                minimumFractionDigits: pricing.annualPrice % 1 === 0 ? 0 : 2
              }
            ).format(pricing.annualPrice)
          }&nbsp;</span>
        } 
        {pricing && billingPeriod === eBillingPeriod.MONTHLY && 
          <span className={`float-center text-2xl font-bold text-center`}>{
            pricing?.monthlyPrice && new Intl.NumberFormat(
              userInfo?.settings?.locale || eLocale.English, 
              {
                minimumFractionDigits: pricing.monthlyPrice && pricing.monthlyPrice % 1 === 0 ? 0 : 2
              }
            ).format(pricing?.monthlyPrice)
          }&nbsp;</span> 
        }
        {suffix && pricing?.annualPriceSecondary
          ? <span className={`float-center text-xs text-center max-w-fit`}>
            <Trans i18nKey={suffix} values={{
              priceAfterOneYear: pricing?.annualPriceSecondary && new Intl.NumberFormat(
                userInfo?.settings?.locale || eLocale.English, 
                {
                  minimumFractionDigits: pricing.annualPriceSecondary && pricing.annualPriceSecondary % 1 === 0 ? 0 : 2
                }
              ).format(pricing?.annualPriceSecondary),
              currencySymbol: getCurrencySymbol(eCurrencyCodes.EUR)
            }} />
            </span>
          : <span className={`float-center text-xs text-center max-w-fit`}>{t(suffix!)}</span>
        }
        {!pricing && 
          <span className={`text-xl font-bold float-center block text-center`}>
            {t(`general.contactUs`)}
          </span>
        }

      </div>
    </div>
  )
}