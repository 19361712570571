import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { eSnackbarLocation } from 'store/types'
import { Alert, Snackbar } from '@mui/material'
import {
  eHorizontal,
  eSeverity,
  eSnackbarVariant,
  eVertical,
  iSnackbarPosition,
} from 'components/types'
import { useDispatch } from 'react-redux'
import { clearToastErrors } from 'store/slices/generalSettings'
import { iLooseObject } from 'pages/DataHub/filters/types'
import SettingsIcon from '@mui/icons-material/Settings'

interface IProps {
  message?: iLooseObject
  position?: iSnackbarPosition
  hideDuration?: number
  severity?: eSeverity
  messageType?: eSnackbarLocation
  className?: string
  variant?: eSnackbarVariant
  open?: boolean
}

const SnackbarWrapper = ({
  message = { title: '', message: '' },
  position = { horizontal: eHorizontal.right, vertical: eVertical.bottom },
  hideDuration = 5000,
  severity = eSeverity.error,
  messageType,
  className,
  variant = eSnackbarVariant.filled,
  open = false,
}: IProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const [alertMessage, setAlertMessage] = useState<iLooseObject>({
    title: '',
    message: '',
  })
  const handleClose = () => {
    setIsOpen(false)
    dispatch(clearToastErrors())
  }
  useEffect(() => {
    setIsOpen(open)
    setAlertMessage(message)
  }, [message, open])

  return messageType === eSnackbarLocation.default ? (
    <Snackbar
      autoHideDuration={hideDuration}
      anchorOrigin={position}
      open={isOpen}
      onClose={handleClose}
      className="w-full"
      transitionDuration={400}
    >
      {
        <Alert
          onClose={handleClose}
          className={`items-center max-lg:w-[70%] ${className} `}
          severity={severity}
          variant={variant}
          icon={<SettingsIcon />}
        >
          <p className="text-lg font-semibold">{t(alertMessage?.title)}</p>
          <p className="text-sm">{t(alertMessage?.message)}</p>
        </Alert>
      }
    </Snackbar>
  ) : (
    <Alert
      onClose={handleClose}
      className={`items-center w-[45%] max-lg:w-[70%] ${className} `}
      severity={severity}
      variant={variant}
      icon={<SettingsIcon />}
    >
      <p className="text-lg font-semibold">{t(alertMessage?.title)}</p>
      <p className="text-sm">{t(alertMessage?.message)}</p>
    </Alert>
  )
}
export default SnackbarWrapper
