import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import { RoutePaths } from 'routes/paths'
import { ContentBody } from 'components/pages/components/ContentBody'
import { MaterialsDocumentData } from './Data/MaterialsDocumentData'
import { IAccordionData, DynamicAccordion } from './components/DynamicAccordion'


const MaterialsDocument: FC = () => {
  return (
    <ContentBody title="knowledgeHub.materials">
      {MaterialsDocumentData.map((d: IAccordionData, key: number) => (
        <DynamicAccordion data={d} index={key} key={key} />
      ))}
    </ContentBody>
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.knowledgeHubMaterials,
    title: 'knowledgeHub.materials',
    element: <MaterialsDocument />,
    roles: componentPermissions[componentNames.pageKnowledgeHubMaterials],
    requiresAuthentication: true,
  },
  name: 'KnwoledgeHubMaterials',
}
export default exportObj
