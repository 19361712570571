export const massFileUploadActionTypes = {
  SAGA_ADD_INPUT_FILES: 'SAGA_ADD_INPUT_FILES',
  SAGA_REMOVE_INPUT_FILES: 'SAGA_REMOVE_INPUT_FILES',
  SAGA_START_FILE_PROCESSING: 'SAGA_START_FILE_PROCESSING',
  SAGA_CANCEL_FILE_PROCESSING: 'SAGA_CANCEL_FILE_PROCESSING',
  SAGA_SET_COMPONENT_ERROR: 'SAGA_SET_COMPONENT_ERROR',
  SAGA_RESET_STATE: 'SAGA_RESET_STATE',
  SAGA_DOWNLOAD_WORKBOOK: 'SAGA_DOWNLOAD_WORKBOOK'
}

export function sagaAddMassUploadInputFile(files: File[]) {
  return {
    type: massFileUploadActionTypes.SAGA_ADD_INPUT_FILES,
    payload: files
  }
}

export function sagaRemoveMassUploadInputFile(name: string) {
  return {
    type: massFileUploadActionTypes.SAGA_REMOVE_INPUT_FILES,
    payload: name
  }
}

export function sagaStartMassUploadFileProcessing() {
  return {
    type: massFileUploadActionTypes.SAGA_START_FILE_PROCESSING
  }
}

export function sagaCancelMassUploadFileProcessing() {
  return {
    type: massFileUploadActionTypes.SAGA_CANCEL_FILE_PROCESSING
  }
}

export function sagaSetMassUploadComponentError(errorMessage: string|undefined) {
  return {
    type: massFileUploadActionTypes.SAGA_SET_COMPONENT_ERROR,
    payload: errorMessage
  }
}

export function sagaResetMassFileUploadState() {
  return {
    type: massFileUploadActionTypes.SAGA_RESET_STATE
  }
}

export function sagaDownloadProcessedWorkbook(fileName: string) {
  return {
    type: massFileUploadActionTypes.SAGA_DOWNLOAD_WORKBOOK,
    payload: fileName
  }
}