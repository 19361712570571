import React, { FC } from 'react'
import { componentNames } from '../config/roles'
import { useTranslation } from 'react-i18next'
import { componentPermissions } from 'config/permissions'
import { RoutePaths } from 'routes/paths'
import Group35113Image from 'assets/images/Group-35113.svg'
import BackArrow from 'assets/icons/back-arrow.svg'
import CustomSvgStyled from 'components/custom/CustomSvgStyled'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import InfoPageContent from 'components/custom/InfoPageContent'
import LinkWrapper from 'components/custom/LinkWrapper'
import { eButtonVariant } from 'components/types'

const Custom500: FC = () => {
  const { t } = useTranslation()

  return (
    <InfoPageContent
      title={t('500.title')}
      svgSrc={Group35113Image}
      button={
        <ButtonWrapper
          variant={eButtonVariant.outlined}
        >
          <CustomSvgStyled
            invertedColor={true}
            src={BackArrow}
            className="mr-2"
            size={{ height: 20, width: 20, unit: 'px' }}
          />
          {t('general.backToHomepage')}
        </ButtonWrapper>
      }
      description={
        <>
          {t('500.description_prefix')}
          <LinkWrapper
            to={`mailto:support@sustamizer.com?subject=sustamize-${t(
              'support.title'
            )}:%20`}
          >
            {t('500.support_anchor')}
          </LinkWrapper>
          {t('500.description_suffix')}
        </>
      }
      linkTo="/"
    />
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.serverError,
    title: '500.title',
    element: <Custom500 />,
    roles: componentPermissions[componentNames.page500],
    requiresAuthentication: false,
  },
  name: 'Custom500',
}
export default exportObj
