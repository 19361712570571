import React from 'react'

interface iProps {
  label: string
  data: number | string
}

const FormInput = ({ label, data }: iProps) => {
  return (
    <div className="row">
      <label htmlFor={label}>{label}:</label>
      <input />
    </div>
  )
}

export default FormInput
