import { iDataHubType } from 'config/services/requests/dataHub/types'
import { allDataHubCategories, eDataHubNames } from './types'

const removeHubCategory = (item: eDataHubNames) => {
  const index = allDataHubCategories.indexOf(item)
  return allDataHubCategories.splice(index, 1)
}

export const dataHubs: iDataHubType[] = [
  {
    hubName: eDataHubNames.all,
    filters: [
      {
        name: 'category',
        displayName: 'Sub Category',
        type: 'array',
        elementType: 'string',
        values: allDataHubCategories,
      },
      {
        name: 'validityStart',
        displayName: 'Validity Start',
        type: 'datetime',
      },
      {
        name: 'validityEnd',
        displayName: 'Validity End',
        type: 'datetime',
      },
    ],
  },
  {
    hubName: eDataHubNames.energy,
    filters: [
      {
        name: 'category',
        displayName: 'Sub Category',
        type: 'array',
        elementType: 'string',
        values: [...removeHubCategory(eDataHubNames.energy)],
      },
      {
        name: 'validityStart',
        displayName: 'Validity Start',
        type: 'datetime',
      },
      {
        name: 'validityEnd',
        displayName: 'Validity End',
        type: 'datetime',
      },
    ],
  },

  {
    hubName: eDataHubNames.consumables,
    filters: [
      {
        name: 'category',
        displayName: 'Sub Category',
        type: 'array',
        elementType: 'string',
        values: [...removeHubCategory(eDataHubNames.consumables)],
      },
      {
        name: 'validityStart',
        displayName: 'Validity Start',
        type: 'datetime',
      },
      {
        name: 'validityEnd',
        displayName: 'Validity End',
        type: 'datetime',
      },
    ],
  },
  {
    hubName: eDataHubNames.documentation,
    filters: [
      {
        name: 'category',
        displayName: 'Sub Category',
        type: 'array',
        elementType: 'string',
        values: [...removeHubCategory(eDataHubNames.documentation)],
      },
      {
        name: 'validityStart',
        displayName: 'Validity Start',
        type: 'datetime',
      },
      {
        name: 'validityEnd',
        displayName: 'Validity End',
        type: 'datetime',
      },
    ],
  },
  {
    hubName: eDataHubNames.logistics,
    filters: [
      {
        name: 'category',
        displayName: 'Sub Category',
        type: 'array',
        elementType: 'string',
        values: [...removeHubCategory(eDataHubNames.logistics)],
      },
      {
        name: 'validityStart',
        displayName: 'Validity Start',
        type: 'datetime',
      },
      {
        name: 'validityEnd',
        displayName: 'Validity End',
        type: 'datetime',
      },
    ],
  },
  {
    hubName: eDataHubNames.materials,
    filters: [
      {
        name: 'category',
        displayName: 'Sub Category',
        type: 'array',
        elementType: 'string',
        values: [...removeHubCategory(eDataHubNames.materials)],
      },
      {
        name: 'validityStart',
        displayName: 'Validity Start',
        type: 'datetime',
      },
      {
        name: 'validityEnd',
        displayName: 'Validity End',
        type: 'datetime',
      },
    ],
  },
  {
    hubName: eDataHubNames.production,
    filters: [
      {
        name: 'category',
        displayName: 'Sub Category',
        type: 'array',
        elementType: 'string',
        values: [...removeHubCategory(eDataHubNames.production)],
      },
      {
        name: 'validityStart',
        displayName: 'Validity Start',
        type: 'datetime',
      },
      {
        name: 'validityEnd',
        displayName: 'Validity End',
        type: 'datetime',
      },
    ],
  },
]
