export enum eInputTypes {
  text = 'text',
  checkbox = 'checkbox',
  file = 'file',
  date = 'date',
  email = 'email',
  password = 'password',
  radio = 'radio',
  range = 'range',
}

export interface iFormInputError {
  active: boolean
  text: string
}

export interface iCountry {
  code: string
  label?: string
  phone?: string
  suggested?: boolean
}

export enum eButtonTypes {
  reset = 'reset',
  button = 'button',
  submit = 'submit',
}
export interface iFormInputError {
  active: boolean
  text: string
}

export enum eColor {
  primary = 'primary',
  secondary = 'secondary',
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

export enum eHorizontal {
  center = 'center',
  left = 'left',
  right = 'right',
}
export enum eLinkTarget {
  self = '_self',
  blank = '_blank',
  parent = 'parent',
}

export enum eElementOnMouseOverEffect {
  always = 'always',
  hover = 'hover',
  none = 'none',
}

export type tButtonSize = 'small' | 'medium' | 'large'

export enum eVertical {
  bottom = 'bottom',
  top = 'top',
}

export interface iSnackbarPosition {
  horizontal: eHorizontal
  vertical: eVertical
}

export enum eSeverity {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}
export enum eErrorDelivery {
  request = 'request',
}

export enum eSnackbarVariant {
  standard = 'standard',
  filled = 'filled',
  outlined = 'outlined',
}

export interface ElementSize {
  height: number
  width: number
  unit: 'px' | '%' | 'rem'
}

export enum eButtonSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum eInputSize {
  small = 'small',
  medium = 'medium',
}

export enum eButtonVariant {
  outlined = 'outlined',
  contained = 'contained',
  text = 'text',
}

export enum eInputVariant {
  standard = 'standard',
  filled = 'filled',
  outlined = 'outlined',
}

export enum eTypographyVariant {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  subtitle1 = 'subtitle1',
  subtitle2 = 'subtitle2',
  body1 = 'body1',
  body2 = 'body2',
  caption = 'caption',
  overline = 'overline',
  inherit = 'inherit',
}
export interface iBlogPostCard {
  title?: string
  imageSrc: string
  imageAlt?: string
  description?: string
  to: string
}

export enum eSnackbackVariant {
  filled = 'filled',
  outlined = 'outlined',
  standard = 'standard',
}

export enum eColors {
  paletteGreen = '#4fa500',
  paletteYellow = '#dba507',
  paletteRed = '#D50000',
  // const palettelightBlue = '#8ec7d2'
  // const paletteBlue = '#0d6986'
  // const paletteDarkBlue = '#07485b'
  paletteGray = '#fafafa',
  paletteGray2 = '#cacaca',
  paletteGray3 = '#d9d9d9',
  paletteBlack = '#121212',
  paletteBorderBlack = '#414141',
}
export interface iElementSize {
  height?: number
  width?: number
  unit: 'px' | '%' | 'rem'
}

export type tOfferingType = 'commercials'|'developers'|'generic'
export enum ePricingMode { 
  COMMERCIALS = 'commercials',
  DEVELOPERS = 'developers'
}
export enum eBillingPeriod { 
  YEARLY = 'yearly',
  MONTHLY = 'monthly'
}

export enum ePackageSelection {
  FLEX = 'offerings.commercials.flex',
  STANDARD = 'offerings.commercials.standard',
  PRO = 'offerings.commercials.pro'
}

export enum eAdditionalDatabaseSelection {
  STANDARD_PARTS = 'offerings.generic.standardParts',
  ELECTRONICS = 'offerings.generic.electronics',
  CONSUMER_PRODUCTS = 'offerings.generic.consumerProducts'
}
export interface iMenuWrapperValues {
  label: string
  value: string
}
