import { createSlice } from '@reduxjs/toolkit'

import { apiData } from 'mock/api-management'
import { RootState } from 'store'
import { apiTableDataState } from 'store/types'
// for parametered slices
// import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: apiTableDataState = {
  value: apiData,
}

//get current reducer state
export const apiTableDataReducerState = (state: RootState) => state.apiTableData

//reducer Object
export const apiTableData = createSlice({
  name: 'apiTableData',
  initialState,
  reducers: {
    changeState: (state) => {},
  },
})

// Action creators are generated for each case reducer function
export const { changeState } = apiTableData.actions

export default apiTableData.reducer
