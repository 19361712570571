import axios, { AxiosRequestHeaders } from 'axios'
import { clearLocal, setLocal } from 'config/lib'
import {
  iLooseObject,
  iRoleMissingResponseError,
} from 'pages/DataHub/filters/types'
import { call } from 'redux-saga/effects'
import { handleErrors } from './errorHandling'
import { getDefaultHeaders } from './lib'
import { AUTH_API } from './urlPaths'
import { userState } from 'store/types'

export const authCheck = () => {
  clearLocal('jwt')

  const redirectPath: string =
    AUTH_API +
      `/connect/keycloak?redirectUrl=${encodeURIComponent(
        window.location.origin
      )}` || ''
  window.location.replace(redirectPath)
}
const replaceTokenIfReceived = (responseHeaders: iLooseObject) => {
  if (responseHeaders['x-new-token']) {
    clearLocal('jwt')
    setLocal('jwt', responseHeaders['x-new-token'])
  }
}

export function* getReq<T>(
  path: string,
  headers: AxiosRequestHeaders = {},
  redirect: boolean = true,
  userInfo: userState| undefined = undefined
): iRoleMissingResponseError | any {
  try {
    const response = yield axios.get<T>(path, {
      headers: { ...getDefaultHeaders(), ...headers },
    })
    const { data, headers: responseHeaders } = response
    replaceTokenIfReceived(responseHeaders)
    return data
  } catch (error: any) {
    return yield call(handleErrors, error, redirect, false, userInfo)
  }
}

export function* postReq<T, B>(
  path: string,
  headers?: AxiosRequestHeaders,
  body?: B
): iLooseObject {
  try {
    const response = yield axios.post<T>(path, body, {
      headers: {
        ...getDefaultHeaders(),
        ...headers,
      },
    })
    const { headers: responseHeaders } = response

    replaceTokenIfReceived(responseHeaders)
    return response
  } catch (error: any) {
    return yield call(handleErrors, error)
  }
}
export function* putReq<T, B>(
  path: string,
  headers?: AxiosRequestHeaders,
  body?: B
): any {
  try {
    const response = yield axios.put<T>(path, body, {
      headers: { ...getDefaultHeaders(), ...headers },
    })

    const { data, headers: responseHeaders } = response
    replaceTokenIfReceived(responseHeaders)
    return data
  } catch (error: any) {
    return yield call(handleErrors, error)
  }
}
export function* patchReq<T, B>(
  path: string,
  headers?: AxiosRequestHeaders,
  body?: B
): any {
  try {
    const response = yield axios.patch<T>(path, body, {
      headers: { ...getDefaultHeaders(), ...headers },
    })

    const { headers: responseHeaders } = response
    replaceTokenIfReceived(responseHeaders)
    return response
  } catch (error: any) {
    return yield call(handleErrors, error)
  }
}
export function* deleteReq<T, B>(
  path: string,
  headers?: AxiosRequestHeaders,
  body?: B
): any {
  try {
    const response = yield axios.delete<T>(path, {
      headers: { ...getDefaultHeaders(), ...headers },
    })

    const { data, headers: responseHeaders } = response
    replaceTokenIfReceived(responseHeaders)
    return data
  } catch (error: any) {
    return yield call(handleErrors, error)
  }
}

const crudRequests = {
  getReq,
  postReq,
  putReq,
  patchReq,
  deleteReq,
}
export default crudRequests
