import React from 'react'
import { ContentBody } from 'components/pages/components/ContentBody'

interface iProps {
  children: JSX.Element | JSX.Element[]
  title: string
}

const ContentBodyWrapper = ({ children, title = '' }: iProps) => {
  return <ContentBody title={title}>{children}</ContentBody>
}

export default ContentBodyWrapper
