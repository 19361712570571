import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { iCustomField, iHubFilter } from 'store/types'
interface IProps {
  key?: string
  label: string
  unit: string | undefined
  filter: iHubFilter
  defaultValue: string
  onChange: (value: iCustomField) => void
}

const CustomInput = ({
  onChange,
  key,
  label,
  filter,
  unit,
  defaultValue,
}: IProps) => {
  const defaultMinValue = 0.00001
  const [inputValue, setInputValue] = useState<string>('')
  const [focusState, setFocusState] = useState(false)

  useEffect(() => {
    setInputValue(defaultValue)
  }, [defaultValue, setInputValue])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target) {
        const newValue = event.target.value
        setInputValue(newValue)

        if (onChange) {
          onChange({
            name: filter.name,
            displayName: label,
            value: Number(newValue),
            info: filter,
          })
        }
      }
    },
    [onChange, filter, label]
  )

  const measuringUnitGuard = useCallback(() => {
    return focusState || inputValue !== ''
  }, [focusState, inputValue])

  const minFilter = useCallback(
    (
      eventKey: string,
      inputValue: string,
      minValue: number | undefined = defaultMinValue
    ) => {
      if (
        eventKey === 'ArrowDown' &&
        (inputValue === '' || Number(inputValue) <= minValue)
      ) {
        return true
      }
      return false
    },
    []
  )

  const fiterOutKeys = useCallback((eventKey: string) => {
    const filterKeys = [
      'ArrowDown',
      'ArrowUp',
      'ArrowLeft',
      'ArrowRight',
      'Backspace',
    ]
    if (filterKeys.includes(eventKey)) {
      return ''
    }

    return eventKey
  }, [])

  return (
    <TextField
      key={key}
      id={label}
      className="w-[13.125rem]"
      label={label}
      defaultValue={inputValue}
      onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event)}
      type="number"
      InputProps={{
        inputProps: { min: defaultMinValue },
        startAdornment: measuringUnitGuard() ? (
          <InputAdornment position="start">{unit}</InputAdornment>
        ) : null,
      }}
      inputProps={{
        sx: { padding: '0.53125rem', paddingLeft: '2.34375rem' },
      }}
      onFocus={() => {
        setFocusState(true)
      }}
      onBlur={() => {
        setFocusState(false)
      }}
      onKeyDownCapture={(event) => {
        if (
          event.key === ' ' ||
          minFilter(event.key, inputValue) ||
          isNaN(Number(inputValue + fiterOutKeys(event.key)))
        ) {
          event.preventDefault()
          return
        }
      }}
    />
  )
}

export default CustomInput
