import React from 'react'
import TitleWrapper from 'components/custom/TitleWrapper'
import { eColor, eTypographyVariant } from 'components/types'
import { useTranslation } from 'react-i18next'
import { iCo2EmissionAccordionColumn } from 'store/types'
import DataWithLabel from './DataWithLabel'

interface iProps {
  columnTitle: string
  measuringUnit?: string
  keyPrefix: string
  data: iCo2EmissionAccordionColumn[]
  className?: string
}

const AccordionColumn = ({
  columnTitle,
  measuringUnit,
  keyPrefix,
  data,
  className,
}: iProps) => {
  const { t } = useTranslation()

  return (
    <div className={`${className}`}>
      <TitleWrapper
        variant={eTypographyVariant.h5}
        sx={{ marginBottom: '12px' }}
        color={eColor.info}
        ariaLabel={`${columnTitle} ${measuringUnit}`}>
        <>
          {columnTitle}{' '}
          <span className="text-[12px]">
            {measuringUnit && `[${measuringUnit}]`}
          </span>
        </>
      </TitleWrapper>
      {data.map((entry, index) => (
        <DataWithLabel
          label={t(`${keyPrefix}.${entry.key}`)}
          data={entry.value}
          key={`${columnTitle}-${index}`}
        />
      ))}
    </div>
  )
}

export default AccordionColumn
