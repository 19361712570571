import React, { FC } from 'react'
import { componentNames } from '../config/roles'
import { useTranslation } from 'react-i18next'
import { componentPermissions } from 'config/permissions'
import { RoutePaths } from 'routes/paths'
import Group35113Image from 'assets/images/Group-35113.svg'
import BackArrow from 'assets/icons/back-arrow.svg'
import CustomSvgStyled from 'components/custom/CustomSvgStyled'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import InfoPageContent from 'components/custom/InfoPageContent'
import { eButtonVariant } from 'components/types'

const Custom404: FC = () => {
  const { t } = useTranslation()

  return (
    <InfoPageContent
      title={t('404.description')}
      svgSrc={Group35113Image}
      button={
        <ButtonWrapper variant={eButtonVariant.outlined}>
          <CustomSvgStyled
            invertedColor={true}
            src={BackArrow}
            className="mr-2"
            size={{ height: 20, width: 20, unit: 'px' }}
          />
          {t('general.backToHomepage')}
        </ButtonWrapper>
      }
      linkTo="/"
    />
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.notFound,
    title: '404.title',
    element: <Custom404 />,
    roles: componentPermissions[componentNames.page404],
    requiresAuthentication: false,
  },
  name: 'Custom404',
}
export default exportObj
