import { Roles, componentNames } from 'config/roles'
import {
  eProductKey,
  iPricedProductListing,
  iProductOverview,
} from 'store/types/subscriptionOrder'
import {
  eCurrencyCodes,
  iOrganizationSubscription,
  mCurrencySymbols,
} from 'store/types'
import { componentPermissions } from 'config/permissions'

export const containsAnyCommercialRoles = (roles: Roles[]): boolean => {
  return Boolean(roles.filter((r) => r.includes('commercial')).length)
}

export const isThisADarkColor = (color: string): boolean => {
  // Variables for red, green, blue values
  let r, g, b, hsp

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    let c = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )

    r = c![1]
    g = c![2]
    b = c![3]
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    let c =
      color.length < 5
        ? +('0x' + color.slice(1).replace(/./g, '$&$&'))
        : +('0x' + color.slice(1))

    r = c >> 16
    g = (c >> 8) & 255
    b = c & 255
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  if (typeof r === 'number' && typeof g === 'number' && typeof b === 'number') {
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))
  }

  // Using the HSP value, determine whether the color is light or dark
  return hsp && hsp < 127.5 ? true : false
}

export const getCurrencySymbol = (code: eCurrencyCodes): string | undefined => {
  return mCurrencySymbols[code]
}

export const generateSubscriptionName = (id: number, startAt: Date) => {
  const startDate = new Date(startAt)
  return `SUS_${id}_${startDate.getFullYear()}${
    startDate.getMonth() + 1
  }${startDate.getDate()}`
}

export const yearlyToMonthlyPrice = (yearly: number) => (yearly * 1.15) / 12

export const getProductUnscaledPrice = (
  productKey: eProductKey,
  overview?: iProductOverview
) => {
  if (!overview) return 0

  const all: iPricedProductListing[] = [
    ...overview.addonDatabases,
    ...overview.databases,
    ...overview.features,
    ...overview.packages,
  ]

  const index = all.map((p) => p.productKey).indexOf(productKey)

  return index !== -1 ? all[index].price : 0
}
export const getPackageProductKeyFromSubscriptions = (
  subs?: iOrganizationSubscription[]
): eProductKey | undefined => {
  if (!subs || subs.length < 1) return undefined

  const uniqueArray: eProductKey[] = []
  subs.map(s => ({
    packageKey: s.packageKey, 
    isActive: s.active, 
    isCancelled: s.canceled
  })).forEach(k => {
    if (
      k.packageKey 
        && !uniqueArray.includes(k.packageKey)
        && k.isActive
        && !k.isCancelled
    ) {
      uniqueArray.push(k.packageKey)
    }
  })

  return uniqueArray.length > 0 
    ? uniqueArray[0]
    : undefined
}

export const getAddOnsDatabasesFromSubscriptions = (subs?: iOrganizationSubscription[]) => {
  const addOnsDB: string[] = []
  subs?.forEach((s) => {
    s.includedProductKeys?.forEach((d) => {
      if (!addOnsDB.find((aDB) => aDB === d)) addOnsDB.push(d)
    })
  })
  return addOnsDB
}

export const isBillingRestricted = (roles: Roles[]) =>  roles.filter((element: Roles) => componentPermissions[componentNames.billingRestricted].includes(element));
