import { FC, useEffect, useState } from 'react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import CustomStringInput from 'components/custom/CustomStringInput'
import { Form, Field } from 'react-final-form'
import { eButtonTypes, eButtonVariant, eColor } from 'components/types'
import { useDispatch, useSelector } from 'react-redux'
import { eLocale, iOrganizationData, userState } from 'store/types'
import {
  sagaGetOrganizationProfileDataAction,
  sagaUpdateOrganizationProfileDataAction,
} from 'saga/actions/user'
import { organizationProfileState } from 'store/slices/organizationProfile'
import { iLooseObject } from 'pages/DataHub/filters/types'
import useFieldsErrors from 'config/hooks/useFieldsErrors'
import CountrySelectAutoComplete from 'components/custom/CountrySelectAutoComplete'
import { useSearchParams } from 'react-router-dom'
import countries from 'i18n-iso-countries'
import { userReducerState } from 'store/slices/user'
import NewOrganizationSuccessModal from './NewOrganizationSuccessModal'

const OrganizationEditPage: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [editMode, setEditMode] = useState(false)
  const organizationData: any = useSelector(organizationProfileState)
  const { errorFormFields, clearGlobalErrors } = useFieldsErrors()
  const [customErrors, setCustomErrors] = useState<iLooseObject>({})

  // Support English & German languages.
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
  countries.registerLocale(require('i18n-iso-countries/langs/de.json'))
  const userInfo: userState = useSelector(userReducerState)
  const orgInfo: any = useSelector(organizationProfileState)
  const userLanguage = userInfo?.settings?.locale || eLocale.English
  //on first load
  useEffect(() => {
    return () => {
      clearGlobalErrors()
    }
  }, [clearGlobalErrors])
  useEffect(() => {
    //get org info
    if (!orgInfo?.id || !userInfo?.organization?.id)
      dispatch(sagaGetOrganizationProfileDataAction())

    const localView: string = searchParams.get('view') || ''
    setEditMode(localView === 'edit' ? true : false)
  }, [
    dispatch,
    orgInfo?.id,
    searchParams,
    setEditMode,
    userInfo?.organization?.id,
  ])

  useEffect(() => {
    setCustomErrors(errorFormFields)
  }, [errorFormFields])

  const onSubmit = useCallback(
    (editedFormData: iOrganizationData) => {
      dispatch(
        sagaUpdateOrganizationProfileDataAction(editedFormData, setSearchParams)
      )
    },
    [dispatch, setSearchParams]
  )
  const submitButton = useCallback(() => {
    setCustomErrors({})
  }, [])

  const cancelButton = useCallback(() => {
    setSearchParams({ view: 'info' })
    setCustomErrors({})
  }, [setSearchParams])

  const generateRequiredLabel = useCallback(
    (placeholder: string) => `${placeholder} *`,
    []
  )

  const generateOptionalLabel = useCallback(
    (placeholder: string) => `${placeholder} (optional)`,
    []
  )
  const customValidate = useCallback(
    (values: any) => {
      return customErrors
    },
    [customErrors]
  )
  const orderedOrganizationData = {
    name: organizationData.name,
    website: organizationData.website,
    address: organizationData.street,
    city: organizationData.city,
    postalCode: organizationData.postalCode,
    country: organizationData.country,
    vatId: organizationData.vatId,
    invoiceFirstName: organizationData.invoiceFirstName,
    invoiceLastName: organizationData.invoiceLastName,
    invoiceEmail: organizationData.invoiceEmail,
  }
  return (
    <>
      {!editMode ? (
        <div className="mt-2.5">
          {Object.keys(orderedOrganizationData)
            .filter((key) => ['id'].indexOf(key) === -1)
            .map((key) => {
              return (
                <div
                  key={'orderedOrganizationData-' + key}
                  className="sm:w-full lg:w-1/2"
                >
                  <div className="mb-1 font-bold pt-4">
                    {t('organization.register.' + key)}
                  </div>
                  <div className="mb-[1px] text-[color:var(--paletteGray)] min-h-[20px] border-b-[1px]">
                    {key === 'country'
                      ? countries.getName(organizationData[key], userLanguage)
                      : organizationData[key]}
                  </div>
                </div>
              )
            })}
          <div className="mt-2.5 pt-5">
            <ButtonWrapper
              type={eButtonTypes.button}
              color={eColor.primary}
              onClick={() => {
                setSearchParams({ view: 'edit' })
              }}
              aria-label={t('organization.edit.editInfo')}
            >
              {t('organization.edit.editInfo')}
            </ButtonWrapper>
          </div>
        </div>
      ) : (
        <Form
          initialValues={organizationData as iLooseObject}
          validate={customValidate}
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="mt-4 space-y-2">
                <Field name="name">
                  {({ input, meta }) => (
                    <CustomStringInput
                      defaultValue={organizationData.name}
                      name="name"
                      label={generateRequiredLabel(
                        t('organization.register.name')
                      )}
                      placeholder={'Organization01 GmbH'}
                      onChange={input.onChange}
                      onBlur={() => {
                        input.onBlur()
                      }}
                      error={{
                        active: meta.error,
                        text: meta.error,
                      }}
                    />
                  )}
                </Field>
                <Field name="website">
                  {({ input, meta }) => (
                    <CustomStringInput
                      defaultValue={organizationData.website}
                      name="website"
                      label={generateOptionalLabel(
                        t('organization.register.website')
                      )}
                      placeholder={'www.abc.com'}
                      onChange={input.onChange}
                      onBlur={() => {
                        input.onBlur()
                      }}
                      error={{
                        active: meta.error,
                        text: meta.error,
                      }}
                    />
                  )}
                </Field>
                <Field name="address">
                  {({ input, meta }) => (
                    <CustomStringInput
                      defaultValue={organizationData.address}
                      name="address"
                      label={generateRequiredLabel(
                        t('organization.register.street')
                      )}
                      placeholder={'Konrad-Zuse-Platz 8'}
                      onChange={input.onChange}
                      onBlur={() => {
                        input.onBlur()
                      }}
                      error={{
                        active: meta.error,
                        text: meta.error,
                      }}
                    />
                  )}
                </Field>
                <Field name="city">
                  {({ input, meta }) => (
                    <CustomStringInput
                      defaultValue={organizationData.city}
                      name="city"
                      label={generateRequiredLabel(
                        t('organization.register.city')
                      )}
                      placeholder={'München'}
                      onChange={input.onChange}
                      onBlur={() => {
                        input.onBlur()
                      }}
                      error={{
                        active: meta.error,
                        text: meta.error,
                      }}
                    />
                  )}
                </Field>
                <Field name="postalCode">
                  {({ input, meta }) => (
                    <CustomStringInput
                      defaultValue={organizationData.postalCode}
                      name="postalCode"
                      label={generateRequiredLabel(
                        t('organization.register.postalCode')
                      )}
                      placeholder={'425622'}
                      onChange={input.onChange}
                      onBlur={() => {
                        input.onBlur()
                      }}
                      error={{
                        active: meta.error,
                        text: meta.error,
                      }}
                    />
                  )}
                </Field>
                <Field name="country">
                  {({ input, meta }) => (
                    <CountrySelectAutoComplete
                      defaultValue={organizationData.country}
                      label={generateRequiredLabel(
                        t('organization.register.country')
                      )}
                      onChange={input.onChange}
                      onBlur={() => input.onBlur()}
                      error={{
                        active: meta.error,
                        text: meta.error,
                      }}
                    />
                  )}
                </Field>
                <Field name="vatId">
                  {({ input, meta }) => (
                    <CustomStringInput
                      defaultValue={organizationData.vatId}
                      name="vatId"
                      label={generateRequiredLabel(
                        t('organization.register.vatId')
                      )}
                      placeholder={'TP 180536'}
                      onChange={input.onChange}
                      onBlur={() => {
                        input.onBlur()
                      }}
                      error={{
                        active: meta.error,
                        text: meta.error,
                      }}
                    />
                  )}
                </Field>
                <Field name='invoiceFirstName'>
                  {({ input, meta }) => (
                    <CustomStringInput
                      defaultValue={organizationData.invoiceFirstName}
                      name='invoiceFirstName'
                      className='w-full'
                      label={generateOptionalLabel(
                        t('organization.register.invoiceFirstName')
                      )}
                      placeholder={t('general.example', {
                        example: 'Anne',
                      })}
                      onChange={input.onChange}
                      onBlur={() => {
                        input.onBlur()
                      }}
                      error={{
                        active: meta.error,
                        text: meta.error,
                      }}
                    />
                  )}
                </Field>
                <Field name='invoiceLastName'>
                  {({ input, meta }) => (
                    <CustomStringInput
                      defaultValue={organizationData.invoiceLastName}
                      name='invoiceLastName'
                      className='w-full'
                      label={generateOptionalLabel(
                        t('organization.register.invoiceLastName')
                      )}
                      placeholder={t('general.example', {
                        example: 'Murphy',
                      })}
                      onChange={input.onChange}
                      onBlur={() => {
                        input.onBlur()
                      }}
                      error={{
                        active: meta.error,
                        text: meta.error,
                      }}
                    />
                  )}
                </Field>
                <Field name="invoiceEmail">
                  {({ input, meta }) => (
                    <CustomStringInput
                      defaultValue={organizationData.invoiceEmail}
                      name="invoiceEmail"
                      label={generateOptionalLabel(
                        t('organization.register.invoiceEmail')
                      )}
                      placeholder={'anne.murpghy@mail.com'}
                      onChange={input.onChange}
                      onBlur={() => {
                        input.onBlur()
                      }}
                      error={{
                        active: meta.error,
                        text: meta.error,
                      }}
                    />
                  )}
                </Field>
              </div>

              <div className="flex space-x-2 pt-2 mt-2">
                <div onClick={() => form.reset}>
                  <ButtonWrapper
                    type={eButtonTypes.button}
                    color={eColor.primary}
                    variant={eButtonVariant.contained}
                    onClick={cancelButton}
                    disabled={submitting}
                    aria-label={t('general.cancel')}
                  >
                    {t('general.cancel')}
                  </ButtonWrapper>
                </div>
                <ButtonWrapper
                  onClick={submitButton}
                  type={eButtonTypes.submit}
                  color={eColor.primary}
                  aria-label={t('button.saveChanges')}
                >
                  {t('button.saveChanges')}
                </ButtonWrapper>
              </div>
            </form>
          )}
        />
      )}
      <NewOrganizationSuccessModal />
    </>
  )
}

export default OrganizationEditPage
