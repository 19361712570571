import React, { useCallback, useEffect, useState } from 'react'
import { Box, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PaperWrapper from './PaperWrapper'

interface iProps {
  children: JSX.Element | string
  footerSection?: JSX.Element | JSX.Element[]
  open?: boolean
  onClose?: () => void
  hasCloseButton?: boolean
  className?: string
  isLoading?: boolean,
  preventClose?: boolean,
}

const ModalWrapper = ({
  onClose,
  children,
  footerSection,
  open = false,
  hasCloseButton = false,
  className,
  isLoading = false,
  preventClose = false
}: iProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      if(!preventClose)
      setIsOpen(false)
      if (onClose) onClose()
    },
    [onClose, preventClose]
  )
  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const loadingStyle = `opacity-50 pointer-events-none`

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-describedby="modal-modal-content"
      >
        {/* TODO: implement responsivness */}
        {/*  */}
        {/* <Box className="absolute md:top-1/2 md:left-1/3 md:-translate-x-1/3 md:-translate-y-1/2 xl:top-1/2 Xl:left-1/2 xl:-translate-x-1/2 xl:-translate-y-1/2 shadow-xl md:w-3/4 rounded-2xl"> */}
        {/* <Box className="flex items-center justify-center h-screen sm:mx-8 md:mx-1/4 xl:mx-1/3 shadow-xl min-w-fit rounded-2xl"> */}
        <Box
          className={`
          absolute overflow-auto max-h-screen
          top-0 left-0 min-w-full min-h-full
          lg:top-1/2 lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 
          lg:min-w-[300px] lg:max-w-11/12 lg:min-h-0
          lg:shadow-xl lg:no-scrollbar 
        ${className}`}
        >
          <PaperWrapper
            className={`
            rounded-none max-w-screen p-4 pb-20 min-h-screen
            lg:min-h-0 lg:rounded-2xl lg:p-8
            lg:border-solid lg:border-2 lg:border-primary
          `}>
            <div 
              id="modal-modal-content" 
              className={isLoading ? loadingStyle : ''}
            >
              {hasCloseButton && (
                <CloseIcon
                  className={`
                    z-50
                    text-gray-700 hover:text-secondary
                    cursor-pointer fixed top-3 right-8 
                    lg:right-3`}
                  onClick={handleClose}
                />
              )}
              {children}
            </div>
            {footerSection && <div aria-hidden="true">{footerSection}</div>}
          </PaperWrapper>
        </Box>
      </Modal>
    </>
  )
}

export default ModalWrapper
