import { iLooseObject } from 'pages/DataHub/filters/types'

export interface SettingsFormData {
  username: string
  password: string
  passwordConfirm: string
}

export interface TabsContent {
  label: string
  href: string
  component: JSX.Element
}

export enum eRole {
  owner = 'owner',
  admin = 'admin',
}

export interface iOrganizationManageTeam extends iLooseObject {
  id?: string | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  roles?: string[] | null
  subscriptionId?: number | null
  addOns?: string | null
  status?: string | null
  actions?: string | null
  [key: string]: any
}

export interface iNewMember {
  firstName?: string
  lastName?: string
  email?: string
  roles?: string[]
}
