import React from 'react'
import { Box, FormControl, InputLabel, Select, SxProps } from '@mui/material'
import { eInputVariant, iFormInputError } from 'components/types'
interface IProps {
  className?: string
  label?: string
  inputClassName?: string
  sxSelect?: SxProps
  children?: React.ReactNode
  defaultValue?: string
  variant?: eInputVariant
  disabled?: boolean
  onChange: (value: string) => void
  error?: iFormInputError
}

const SelectWrapper = ({
  className = '',
  inputClassName = '',
  sxSelect,
  variant = eInputVariant.standard,
  children,
  label,
  defaultValue,
  disabled = false,
  onChange,
}: IProps) => {
  return (
    <Box className={`xs:w-full ${className}`}>
      <FormControl fullWidth>
        <InputLabel htmlFor="selectWrapper">{label}</InputLabel>
        <Select
          disabled={disabled}
          labelId="selectWrapper"
          value={defaultValue}
          variant={variant}
          label={label}
          onChange={(event) => onChange(event.target.value)}
          className={inputClassName}
          sx={sxSelect}>
          {children}
        </Select>
      </FormControl>
    </Box>
  )
}

export default SelectWrapper
