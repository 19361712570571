import { iDataHubResponseType } from './types'
import reqProvider from '../coreRequests'
import { DATA_API } from '../urlPaths'
import { iDataHubSearch } from 'store/types'
import { call } from 'redux-saga/effects'

export function* dataHubSearch(search: string): iDataHubSearch[] | any {
  const path = `${DATA_API}/${search}`
  const data: iDataHubResponseType[] | any = yield call(
    reqProvider.getReq,
    path
  )
  const returnData = data.results || []
  return returnData
}
