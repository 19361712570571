import React, { useEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'

import { Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IProps {
  title?: string
  id?: string
  className?: string
  children?: JSX.Element | JSX.Element[]
}

export const ContentBody = ({
  title = '',
  id = 'main_content',
  className = 'w-full',
  children,
}: IProps) => {
  /**
   * Do Viewport check
   */
  const [isMobile, setMobile] = useState<boolean>(true)
  const theme = useTheme()
  const {t} = useTranslation();

  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'))

  // TODO: Chip -  setMobile should be 100% CSS managed
  useEffect(() => {
    !isBelowMd && isMobile ? setMobile(false) : setMobile(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBelowMd])

  return (
    <div data-testid="contentBody-testid" id={id} className={className}>
      <Box className="p-10" role="region" aria-label={title}>
        <Typography
          variant={`h4`}
          color={`primary`}
          sx={{
            marginBottom: `20px`,
          }}
          role="heading"
          aria-label={title}>
          {t(title)}
        </Typography>
        {Array.isArray(children)
          ? React.Children.map(children, (child, i) => {
              return React.cloneElement(child, { key: i })
            })
          : children}
      </Box>
    </div>
  )
}

export const ContentBodySection = ({ title, children }: IProps) => {
  return (
    <div data-testid="cb-section-testid" className={`my-4 md:my-8`}>
      <div className={`pb-2`}>
        <Typography variant={`overline`} role="region" aria-label={title}>
          {title}
        </Typography>
      </div>
      {Array.isArray(children)
        ? React.Children.map(children, (child, i) => {
            return React.cloneElement(child, { key: i })
          })
        : children}
    </div>
  )
}
