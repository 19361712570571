import React from 'react'
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import { useTranslation } from 'react-i18next'

export const CompositesForm = (): JSX.Element => {
  const { t } = useTranslation()

  const countries = [
    t('country.deu'),
    t('country.usa'),
    t('country.bgr'),
    t('country.esp'),
    t('country.svk'),
  ]

  return (
    <>
      <FormControl className={`Data-Explorer-form`}>
        <Autocomplete
          id={`country-select`}
          sx={{
            width: `100%`,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('dataExplorer.countryOrigin')}
              variant={`standard`}
            />
          )}
          options={countries}
          autoHighlight
        />
        <TextField
          className={`Data-Explorer-text-field`}
          id={`search-value`}
          label={t('general.searchValueExample', { example: 'X5CrNi18' })}
          variant={`standard`}
          required
        />
        <FormControlLabel
          label={
            <Typography variant={`body2`}>
              {t('general.searchVariant', { variant: 'recycled' })}
            </Typography>
          }
          className={`Data-Explorer-option-label`}
          control={<Checkbox color={`secondary`} />}
        />
        <FormLabel id={`calculation-type-label`}>
          {t('dataExplorer.calculationType')}
        </FormLabel>
        <RadioGroup name={`calculation-process`} defaultValue={`typical`}>
          <FormControlLabel
            value={`typical`}
            control={<Radio />}
            label={t('dataExplorer.calculationTypeOptions.typical')}
          />
          <FormControlLabel
            value={`median`}
            control={<Radio />}
            label={t('dataExplorer.calculationTypeOptions.median')}
          />
          <FormControlLabel
            value={`min`}
            control={<Radio />}
            label={t('dataExplorer.calculationTypeOptions.minimum')}
          />
          <FormControlLabel
            value={`max`}
            control={<Radio />}
            label={t('dataExplorer.calculationTypeOptions.maximum')}
          />
        </RadioGroup>
        <div className={`Data-Explorer-button-container`}>
          <ButtonWrapper>{t('general.search')}</ButtonWrapper>
        </div>
      </FormControl>
    </>
  )
}
