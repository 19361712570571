import styled from '@emotion/styled'
import { TooltipProps, Tooltip, tooltipClasses } from '@mui/material'
import { eColors } from 'components/types'

export const TooltipWrapper = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: '#000000',
    backgroundColor: eColors.paletteGray,
    fontSize: 15,
    boxShadow: '0px 2px 4px 0px #00000040',

    fontWeight: 400,
    lineHeight: '23px',
  },
}))
