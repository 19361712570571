import ButtonWrapper from "components/custom/ButtonWrapper"
import { eButtonSize, eColor } from "components/types"
import { FC } from "react"
import { useTranslation } from "react-i18next"

interface iPackageOfferingActionProps {
  color?: eColor,
  label: string,
  disabled?: boolean,
  action: () => void
}

export const PackageOfferingAction: FC<iPackageOfferingActionProps> = ({
  color = eColor.primary,
  label,
  disabled = false,
  action
}) => {

  const { t } = useTranslation()

  return (
    <ButtonWrapper 
      color={color}
      onClick={action}
      size={eButtonSize.small}
      disabled={disabled}
      className={`my-2`}
    >
      {t(label)}
    </ButtonWrapper>
  )
}