import { createContext, useContext, useEffect } from 'react'

/**
 * This is the interface for the React Context used on the App
 * component. This object is passed down by the context Provider.
 */
export const HistoryContext = createContext({
  history: [] as URL[],
  setHistory: (() => {}) as React.Dispatch<React.SetStateAction<URL[]>>,
})

export const UrlContextManipulator = () => {
  const { history, setHistory } = useContext(HistoryContext)

  var effectCounter = 0
  useEffect(() => {
    if (effectCounter > 0) return
    effectCounter++

    /** For avoiding redundant entries */
    const isUrlAtMostRecentHistoryPosition = (
      url: URL,
      history: URL[]
    ): boolean => {
      if (history.length === 0) return false
      else if (JSON.stringify(history[0]) === JSON.stringify(url)) return true
      else return false
    }
    /** Set current URL only if not most recent */
    let currentURL = new URL(window.location.href)
    if (!isUrlAtMostRecentHistoryPosition(currentURL, history)) {

      history.unshift(currentURL)
      setHistory(history)
    }
  })

  return null
}
