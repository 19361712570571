import { call, put, select, takeLeading } from 'redux-saga/effects'
import apiRequests from 'config/services'
import { dataHubTypes } from 'saga/actions/dataHub'
import { PayloadAction } from '@reduxjs/toolkit'
import { iContextDataType } from './types'
import {
  selectedSearchCategoryState,
  setSearchParametersData,
} from 'store/slices/searchParametersSlice'
import { iSearchParameters_Data } from 'pages/DataHub/filters/types'

export function* getSearchParametersDataSaga(
  action: PayloadAction<iContextDataType>
) {
  try {
    const searchParamsSelectedCategory: string = yield select(
      selectedSearchCategoryState
    )
    const result: iSearchParameters_Data[] = yield call(
      apiRequests.getSearchParametersData,
      1,
      searchParamsSelectedCategory
    )

    // set the data in redux
    yield put(setSearchParametersData(result))
  } catch (e) {
        //catchind errors
  }
}

export default function* getSearchPArametersDataHook() {
  yield takeLeading(
    dataHubTypes.SAGA_GET_SEARCH_PARAMETERS_DATA,
    getSearchParametersDataSaga
  )
}
