import React, { MouseEvent, useCallback } from 'react'
import { Button, SxProps } from '@mui/material'
import {
  eButtonTypes,
  eButtonSize,
  eButtonVariant,
  eColor,
} from 'components/types'

interface IProps {
  onClick?: (event: MouseEvent) => void
  className?: string
  type?: eButtonTypes
  disabled?: boolean
  color?: eColor
  sxClass?: SxProps
  children: React.ReactNode
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  size?: eButtonSize
  variant?: eButtonVariant
  fullWidth?: boolean
}
const ButtonWrapper = ({
  className,
  type = eButtonTypes.button,
  children,
  disabled,
  color = eColor.primary,
  sxClass = {},
  startIcon,
  endIcon,
  size,
  variant = eButtonVariant.text,
  fullWidth = false,
  onClick = (e: any) => {},
}: IProps) => {
  const getButtonVariantColor = useCallback(() => {
    switch (color) {
      case eColor.secondary:
        return {
          backgroundColor: 'var(--paletteOrange)',
          '&:hover': {
            backgroundColor: 'var(--paletteLighterOrange)',
          },
        }
      default:
        break
    }
  }, [color])

  return (
    <Button
      variant={variant}
      size={size}
      disabled={disabled}
      endIcon={endIcon}
      startIcon={startIcon}
      className={className}
      type={type}
      sx={{ ...sxClass, ...getButtonVariantColor() }}
      onClick={(event: MouseEvent) => onClick(event)}
      fullWidth={fullWidth}
    >
      {children}
    </Button>
  )
}

export default ButtonWrapper
