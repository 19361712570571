import ContentBodyWrapper from 'components/custom/ContentBodyWrapper'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import { RoutePaths } from 'routes/paths'

const PrivacyPolicy: FC = () => {

  return (
    <ContentBodyWrapper title={''}>
    </ContentBodyWrapper>
  )
}
const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.privacyPolicy,
    title: 'footer.PrivacyPolicy',
    element: <PrivacyPolicy />,
    roles: componentPermissions[componentNames.privacyPolicy],
    requiresAuthentication: true,
  },
  name: 'TermsAndConditions',
}
export default exportObj
