import { FC, useEffect, useState } from 'react'
import {
  LinearProgress,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ExcelIcon } from 'assets/icons/ms-excel.svg'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDispatch } from 'react-redux'
import DownloadIcon from '@mui/icons-material/Download'
import {
  sagaDownloadProcessedWorkbook,
  sagaRemoveMassUploadInputFile,
} from 'saga/actions/massFileUpload'
import PaperWrapper from 'components/custom/PaperWrapper'
import TextWrapper from 'components/custom/TextWrapper'
import { Cancel } from '@mui/icons-material'
import { eTypographyVariant } from 'components/types'

interface FileQueueProps {
  files: File[]
  working: boolean
  cancelled: boolean
  done: boolean
  currentFileName?: string
  currentContextName?: string
  totalNumberOfRows: number
  numberOfRowsProcessed: number
  totalNumberOfRowsInCurrentFile: number
  numberOfRowsProcessedInCurrentFile: number
  completedFileNames: string[]
}

const FileQueue: FC<FileQueueProps & React.HTMLAttributes<HTMLDivElement>> = ({
  files,
  working,
  cancelled,
  done,
  currentFileName,
  currentContextName,
  totalNumberOfRows,
  numberOfRowsProcessed,
  totalNumberOfRowsInCurrentFile,
  numberOfRowsProcessedInCurrentFile,
  completedFileNames,
  ...props
}: FileQueueProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const styles = {
    idle: `min-h-fit p-4 lg:w-3/5 lg:transition lg:transition-width`,
    working: `p-4 min-h-fit lg:w-full lg:transition lg:transition-width`,
  }

  /** Calculate the progress percentage for the current file */
  const [progress, updateProgress] = useState<number>(0)
  const [itemProgress, updateItemProgress] = useState<number>(0)

  useEffect(() => {
    const percentage = Math.trunc(
      (100 / totalNumberOfRows) * numberOfRowsProcessed
    )
    updateProgress(isNaN(percentage) ? 0 : percentage)
  }, [numberOfRowsProcessed, totalNumberOfRows])

  useEffect(() => {
    const percentage = Math.trunc(
      (100 / totalNumberOfRowsInCurrentFile) *
        numberOfRowsProcessedInCurrentFile
    )
    updateItemProgress(isNaN(percentage) ? 0 : percentage)
  }, [numberOfRowsProcessedInCurrentFile, totalNumberOfRowsInCurrentFile])

  return (
    <>
      <PaperWrapper
        {...props}
        className={!working ? styles.idle : styles.working}>
        <TextWrapper variant={eTypographyVariant.overline}>
          {t('massUpload.addedTitle', { count: files.length })}
        </TextWrapper>
        <List>
          {files.length > 0 &&
            files.map((file, key) => {
              return (
                <ListItem key={key} className={`px-0`}>
                  <ListItemIcon className={`min-w-min mr-3`}>
                    <SvgIcon
                      component={ExcelIcon}
                      viewBox="0 0 48 48"
                      color={`primary`}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <TextWrapper variant={eTypographyVariant.caption}>
                      {file.name}
                    </TextWrapper>
                  </ListItemText>

                  {file.name === currentFileName && working ? (
                    <CircularProgress
                      color={`primary`}
                      variant={`determinate`}
                      value={
                        !completedFileNames.includes(file.name) &&
                        currentFileName === file.name
                          ? itemProgress
                          : 0
                      }
                      size={24}
                    />
                  ) : null}

                  {!working && !cancelled && !done ? (
                    <IconButton
                      color={`error`}
                      aria-label="Delete file"
                      onClick={() =>
                        dispatch(sagaRemoveMassUploadInputFile(file.name))
                      }>
                      <DeleteIcon />
                    </IconButton>
                  ) : null}

                  {completedFileNames.includes(file.name) && !cancelled ? (
                    <IconButton
                      color={`primary`}
                      aria-label="Download File"
                      onClick={() =>
                        dispatch(sagaDownloadProcessedWorkbook(file.name))
                      }>
                      <DownloadIcon />
                    </IconButton>
                  ) : null}

                  {cancelled ? <Cancel color={`warning`} /> : null}
                </ListItem>
              )
            })}
          {working && totalNumberOfRows > 0 ? (
            <div
              className={`mt-7 flex flex-row flex-nowrap w-full items-center gap-4`}>
              <div className={`w-full`}>
                <LinearProgress
                  variant={progress === 0 ? `indeterminate` : `determinate`}
                  value={progress <= 0 || cancelled ? 100 : progress}
                  color={!cancelled ? `primary` : `warning`}
                />
              </div>
              {!cancelled && (
                <TextWrapper
                  variant={eTypographyVariant.caption}
                  className={`flex-shrink-0`}>
                  {progress > 0 ? `${progress}%` : t('massUpload.loading')}
                </TextWrapper>
              )}
              {cancelled && (
                <TextWrapper
                  variant={eTypographyVariant.caption}
                  className={`flex-shrink-0`}>
                  {t('massUpload.cancelled')}
                </TextWrapper>
              )}
            </div>
          ) : null}
        </List>
      </PaperWrapper>
    </>
  )
}

export default FileQueue
