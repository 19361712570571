export enum eHardFilterNames {
  database = 'database',
  type = 'type',
}

export enum eDataHubNames {
  all = '.',
  energy = '.energy',
  consumables = '.consumables',
  documentation = '.documentation',
  logistics = '.logistics',
  materials = '.materials',
  production = '.production',
  electronics = '.electronics',
}

export const allDataHubCategories = [
  eDataHubNames.energy,
  eDataHubNames.consumables,
  eDataHubNames.documentation,
  eDataHubNames.logistics,
  eDataHubNames.materials,
  eDataHubNames.production,
  eDataHubNames.electronics,
]

export interface filterOption {
  id: string
  value: string | number
  label: string
}
