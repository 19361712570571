import { eHardFilterNames } from 'config/mock/data-hub/types'
import { call } from 'redux-saga/effects'
import reqProvider from '../coreRequests'
import { DATA_API } from '../urlPaths'
import { iDataHubFiltersType, iDataHubResponseType } from './types'

export function* getDataHubCategories(): string[] | any {
  //setting the base context path
  const requestPath = `${DATA_API}/context?contextName=.` // hardcoded parent context for getting categories for menus
  const data: iDataHubResponseType[] | any = yield call(
    reqProvider.getReq,
    requestPath
  )
  // get the filters from the object
  const filters = data.filters
  //extract only the database name from the list of resulted filters
  const categoriesIndex = filters.findIndex((item: iDataHubFiltersType) => {
    return item.name === eHardFilterNames.database
  })
  const returnData = data.filters[categoriesIndex].values || []
  return returnData
}
