export enum eCurrencyCodes {
  EUR = 'EUR',
  USD = 'USD',
  JPY = 'JPY',
  BGN = 'BGN',
  CZK = 'CZK',
  DKK = 'DKK',
  GBP = 'GBP',
  HUF = 'HUF',
  PLN = 'PLN',
  RON = 'RON',
  SEK = 'SEK',
  CHF = 'CHF',
  ISK = 'ISK',
  NOK = 'NOK',
  HRK = 'HRK',
  TRY = 'TRY',
  AUD = 'AUD',
  BRL = 'BRL',
  CAD = 'CAD',
  CNY = 'CNY',
  HKD = 'HKD',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  KRW = 'KRW',
  MXN = 'MXN',
  MYR = 'MYR',
  NZD = 'NZD',
  PHP = 'PHP',
  SGD = 'SGD',
  THB = 'THB',
  ZAR = 'ZAR',
}

export const mCurrencySymbols: {[K in eCurrencyCodes]?: string} = {
  'EUR': '€',
  'USD': '$'
}

export interface iCurrency {
  code: eCurrencyCodes
}

export interface currencyValuesState {
  currencyCode: eCurrencyCodes
  rate: number
}

export interface currencyState {
  rates: currencyValuesState[]
}
