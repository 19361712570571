import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes/paths'
import { sagaClearUserProfileAction } from 'saga/actions/user'

const Logout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(sagaClearUserProfileAction())
    navigate(RoutePaths.login)
  }, [dispatch, navigate])

  return <></>
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.logout,
    title: 'Logout',
    element: <Logout />,
    roles: componentPermissions[componentNames.pageLogout],
    requiresAuthentication: true,
  },
  name: 'Logout',
}
export default exportObj
