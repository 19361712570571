import { FC, useEffect } from 'react'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { RoutePaths } from 'routes/paths'
import Dropzone from './components/Dropzone'
import FileQueue from './components/FileQueue'
import { Alert, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ContentBodyWrapper from 'components/custom/ContentBodyWrapper'
import { useDispatch, useSelector } from 'react-redux'
import {
  massFileUploadReducerState,
  resetState,
} from 'store/slices/massFileUploadSlice'
import {
  sagaStartMassUploadFileProcessing,
  sagaCancelMassUploadFileProcessing,
  sagaResetMassFileUploadState,
} from 'saga/actions/massFileUpload'
import SummarizeIcon from '@mui/icons-material/Summarize'
import { useIsFirstRender } from 'usehooks-ts'
import { sagaGetDataHubCategoriesAction } from 'saga/actions/dataHub'
import { clearHubCategories } from 'store/slices/dataHubCategories'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import {
  eColor,
  eElementOnMouseOverEffect,
  eLinkTarget,
} from 'components/types'
import LinkWrapper from 'components/custom/LinkWrapper'

const MassUpload: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isFirstRender = useIsFirstRender()

  const {
    componentError,
    isFilesAvailable,
    isProcessingFiles,
    isProcessingFilesCancelled,
    isProcessingFilesDone,
    currentFileProcessed,
    currentContextProcessed,
    totalNumberOfRows,
    numberOfRowsProcessed,
    totalNumberOfRowsInCurrentFile,
    numberOfRowsProcessedInCurrentFile,
    uploadedFiles,
    processedInputFileNames,
  } = useSelector(massFileUploadReducerState)

  const styles = {
    container: {
      idle: `w-full flex flex-col gap-4 lg:relative lg:flex-row lg:flex-nowrap lg:justify-between`,
      working: `w-full flex flex-col gap-4 lg:flex-row lg:flex-wrap lg:justify-between`,
    },
    button: `w-full flex flex-row justify-end mt-4`,
    downloadLink: `text-gray-500 hover:text-orange-400`,
  }

  // first load: (clear category items)
  useEffect(() => {
    return () => {
      // clear categories global state
      dispatch(clearHubCategories())
    }
  }, [dispatch])

  /** Reset if revisiting the component */
  useEffect(() => {
    if (isFirstRender && isProcessingFilesDone) {
      dispatch(resetState())
    }
  })

  /* Reload Child menu items */
  useEffect(() => {
    dispatch(sagaGetDataHubCategoriesAction())
  }, [dispatch])

  /**
   * This function pre-filters strings coming from the store,
   * since the useTranslation() hook is not available on Sagas.
   *
   * Untranslated strings will therefore have to preceeded with
   * double accolades (~~), succeed by the translation json path.
   *
   * This function will then known, which string is untranslated
   * and will parse the string trough the useTranslation() hook.
   */
  const preFilterComponentError = (error: string): string => {
    if (error.startsWith('~~')) {
      return t(error.replace(/^~~/gi, ''))
    } else {
      return error
    }
  }

  return (
    <ContentBodyWrapper title={t('massUpload.title')}>
      <>
        {componentError && (
          <Alert severity={`error`} className={`mb-3`}>
            {preFilterComponentError(componentError)}
          </Alert>
        )}

        <div className={`max-w-fit mb-2`}>
          <a
            href="/templates/sustamize_mass_file_upload_template.xlsx"
            target={eLinkTarget.blank}>
            <div className={styles.downloadLink}>
              <SummarizeIcon className={`mr-1`} />
              <Typography variant={`caption`}>
                {t('massUpload.downloadTemplate')}
              </Typography>
            </div>
          </a>
        </div>
        <div
          className={styles.container[!isProcessingFiles ? 'idle' : 'working']}>
          <Dropzone visible={!isProcessingFiles ? true : false} />
          {uploadedFiles.length > 0 && (
            <FileQueue
              files={uploadedFiles}
              working={!isProcessingFiles ? false : true}
              cancelled={isProcessingFilesCancelled}
              done={isProcessingFilesDone}
              currentFileName={currentFileProcessed}
              currentContextName={currentContextProcessed}
              totalNumberOfRows={totalNumberOfRows}
              numberOfRowsProcessed={numberOfRowsProcessed}
              totalNumberOfRowsInCurrentFile={totalNumberOfRowsInCurrentFile}
              numberOfRowsProcessedInCurrentFile={
                numberOfRowsProcessedInCurrentFile
              }
              completedFileNames={processedInputFileNames}
            />
          )}
        </div>
        {!isProcessingFilesDone && (
          <div className={styles.button}>
            <ButtonWrapper
              onClick={() =>
                dispatch(
                  !isProcessingFiles
                    ? sagaStartMassUploadFileProcessing()
                    : sagaCancelMassUploadFileProcessing()
                )
              }
              color={!isProcessingFiles ? eColor.primary : eColor.error}
              disabled={!isFilesAvailable}>
              {t(
                !isProcessingFiles
                  ? 'massUpload.processButtonStart'
                  : 'massUpload.processButtonCancel'
              )}
            </ButtonWrapper>
          </div>
        )}
        {isProcessingFilesDone && (
          <div className={styles.button}>
            <LinkWrapper
              to={`/data-hub/tools/mass-upload`}
              underline={eElementOnMouseOverEffect.none}>
              <ButtonWrapper
                onClick={() => dispatch(sagaResetMassFileUploadState())}
                color={eColor.secondary}>
                {t(`massUpload.processButtonBack`)}
              </ButtonWrapper>
            </LinkWrapper>
          </div>
        )}
      </>
    </ContentBodyWrapper>
  )
}

const routeObj = {
  routeProps: {
    path: RoutePaths.massUpload,
    element: <MassUpload />,
    title: 'massUpload.title',
    roles: componentPermissions[componentNames.pageMassUpload],
    requiresAuthentication: true,
    redirectToUpgrade: true
  },
  name: 'MassUpload',
}

export default routeObj
