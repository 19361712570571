import { ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as config from 'config/services/requests'

import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { capitaliseString } from 'config/lib'
import { eMenuGroups, eMenusSortType, iMenusType } from 'store/types'
import { sortedMenuItemsGroups } from 'config/mock/menuItems'
import { clearAllFilters } from 'store/slices/selectedFieldsSlice'
import { RoutePaths } from 'routes/paths'
import { iLooseObject } from 'pages/DataHub/filters/types'
import { getUrlParamValue, urlToObject } from 'config/lib'
import { toggleDrawer } from 'store/slices/generalSettings'
interface iProps {
  menuItems: iMenusType[]
  className?: string
}

const ChildMenuItems = ({ menuItems, className }: iProps) => {
  const [parentData, setParentData] = useState<iLooseObject>({
    routePath: '/',
    label: 'pages.home',
    icon: 'HomeIcon',
  })
  const dispatch = useDispatch()
  const theme = useTheme()
  let navigate = useNavigate()
  const { t } = useTranslation()
  let locationParams = useLocation()
  const myUrlParams: iLooseObject = urlToObject(locationParams.search)

  useEffect(() => {
    menuItems.forEach((mainMenuItem) => {
      const childMenuPathMatch = (item: iMenusType) =>
        item.routePath.toString() === locationParams.pathname.toString()

      const childPages = mainMenuItem?.childPages || []
      const isCurrentParent = childPages.some(childMenuPathMatch)

      if (isCurrentParent) {
        const { routePath, label, icon } = mainMenuItem

        setParentData({
          routePath: routePath as string,
          label: label as string,
          icon: icon as string,
        })
      }
    })
  }, [locationParams, menuItems])

  const clickHandler = useCallback(
    (routePath: string, externalLink?: string) => {
      if (externalLink) {
        // Open the external link in a new tab
        window.open(externalLink, '_blank')
      } else {
        // Proceed with normal internal navigation
        dispatch(clearAllFilters())
        dispatch(toggleDrawer())
        navigate(routePath)
      }
    },
    [dispatch, navigate]
  )

  const sortChildPages = (childPages: iMenusType[]) => {
    childPages.sort((a, b) => {
      const sortGroupChildPageA = sortedMenuItemsGroups.filter(
        (group) => a.group === group.name
      )
      const sortGroupChildPageB = sortedMenuItemsGroups.filter(
        (group) => b.group === group.name
      )

      if (
        sortGroupChildPageA.length === 1 &&
        sortGroupChildPageB.length === 1
      ) {
        const childPageA = t(a.label)
        const childPageB = t(b.label)
        const sortA = sortGroupChildPageA[0].sort
        const sortB = sortGroupChildPageB[0].sort
        if (sortA === sortB) {
          const changeSortFlag: number = sortA === eMenusSortType.dsc ? -1 : 1
          if (childPageA < childPageB) {
            return -1 * changeSortFlag
          }

          if (childPageA > childPageB) {
            return 1 * changeSortFlag
          }
        }
      }
      return 0
    })

    return childPages
  }

  const parentIconKey: string = parentData.icon as string
  const ParentIcon = config.susIcons[parentIconKey]
  const BackIcon = config.susIcons[config.IconsTypes.Back]

  return (
    <div className={`flex flex-col ${className}`}>
      <div className="sm:flex sm:flex-inline flex-row pt-2 pl-4 lg:hidden flex sm:align-middle sm:w-full sm:mt-5">
        <Link
          to={RoutePaths.dashboard}
          className="flex align-middle text-base sm:w-full"
        >
          <BackIcon className="flex max-w-[26px] self-center max-h-[16px] dark:fill-white" />
          <ListItemIcon className="min-w-[33px] dark:fill-white">
            <ParentIcon className="flex max-w-[26px] self-center max-h-[27px]" />
          </ListItemIcon>{' '}
          {t(parentData.label)}
        </Link>
      </div>
      <div>
        {menuItems.map((obj: iMenusType, i: number) => {
          const childPages: iMenusType[] = obj.childPages ?? []
          let sortedChildPages = childPages.slice()

          const isCurrentParentMenu: boolean =
            childPages.findIndex(
              (item) => locationParams.pathname === item.routePath
            ) !== -1 ||
            (locationParams.pathname.indexOf('/entity/') !== -1 &&
              obj.routePath === RoutePaths.dataHub)

          if (isCurrentParentMenu) {
            sortedChildPages = sortChildPages(childPages.slice())
          }

          const groups = Array.from(
            new Set(sortedChildPages.map((item: iMenusType) => item.group))
          )

          const elementGroups = groups.map(
            (group?: eMenuGroups | undefined) => {
              return (
                <div
                  className={`leading-[0px]
              lg:mt-6 sm:mt-0 ${group ? 'leading-normal' : ''}`}
                  key={`mainGroup${group}`}
                >
                  <label className="font-bold pb-3 sm:ml-14 lg:ml-4">
                    {group ? t(group) : ''}
                  </label>
                  {sortedChildPages
                    .filter((child) => child.group === group)
                    .map((item, i) => {
                      let label = item.label
                      if (label.startsWith('.')) label = 'knowledgeHub' + label
                      const routePath = item.routePath
                      const externalLink = item.externalLink

                      const isBasePath = routePath === locationParams.pathname
                      const isDatahubBasePage =
                        locationParams.pathname === RoutePaths.dataHub
                      const isDatahubSearchParamsPage =
                        locationParams.pathname ===
                        RoutePaths.dataHubSearchParams
                      const contextNameValue = 'contextName'
                      const urlContextName = getUrlParamValue(
                        myUrlParams,
                        contextNameValue
                      )
                      const routeContextName = routePath.split(
                        `${contextNameValue}=`
                      )[1]
                      const isContexNameAvailable = urlContextName.length > 0

                      const contextNamesMatch =
                        urlContextName.indexOf(routeContextName) !== -1

                      const isContextName =
                        isContexNameAvailable && !isDatahubSearchParamsPage
                          ? false
                          : isBasePath

                      const isCategoryChildPage =
                        contextNamesMatch && isDatahubBasePage
                          ? true
                          : isContextName

                      const final = isContexNameAvailable
                        ? isCategoryChildPage
                        : isBasePath

                      const isActive: boolean = final
                      const MainMenuIcon = item.icon
                        ? config.susIcons[item.icon]
                        : () => null

                      return (
                        <ListItem
                          className="sm:border-l-0"
                          dense
                          key={'ListItem' + i}
                          selected={isActive}
                          onClick={() =>
                            clickHandler(
                              routePath.replace('..', '.'),
                              externalLink
                            )
                          }
                        >
                          <Link
                            to="#"
                            className="flex flex-row lg:max-w-[170px] sm:w-screen sm:ml-10 lg:ml-0"
                          >
                            <ListItemIcon className="min-w-[33px] dark:fill-white">
                              <MainMenuIcon className="flex max-w-[20px] self-center max-h-[24px]" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <div
                                  role="region"
                                  aria-label={t(
                                    label.substring(0, 1) === '.'
                                      ? capitaliseString(
                                          label.substring(1, label.length)
                                        )
                                      : label
                                  )}
                                >
                                  {t(
                                    label.substring(0, 1) === '.'
                                      ? capitaliseString(
                                          label.substring(1, label.length)
                                        )
                                      : label
                                  )}
                                </div>
                              }
                            />
                          </Link>
                        </ListItem>
                      )
                    })}
                </div>
              )
            }
          )
          return (
            <div
              key={`childPagesContainer${i}`}
              className={`flex flex-col items-start self-start h-[calc(100vh-_var(--topBarHeight))]
            dark:border-l-[color:var(--paletteDarkGrayDelimiter)]
            sm:dark:border-l-0
            ${theme.palette.mode === 'dark' ? 'dark' : ''} ${
                isCurrentParentMenu
                  ? 'show inline-flex lg:border-l-2 sm:border-l-0 lg:border-l-[color:var(--paletteLightGrayDelimiter)]'
                  : 'hidden'
              }`}
            >
              {elementGroups}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ChildMenuItems
