
export const bulletPointClass = "ml-8"
export const linkClass = "break-normal  text-green-500 underline"

export const makeLink = (text: string, url: string, target?: string) => {
   
  return `<a class="${linkClass}" href="${url}" target="${target || '_blank'}">${text}</a>`
}
  
export const makeBulttePointsList = (texts: string, className?: string) => {
  let lists = ''
  texts.split('|').forEach((t) => (lists += `<li>${t}</li>`))

  return `<ol class="${className ||  'list-disc'} ${bulletPointClass}">${lists} </ol>`
}
export const getImage = (path: string, belowText?: string) => {
  return `<img style="max-height:90vh;" class="text-center m-auto" src="${path}"alt='${path}'/>${belowText ? '<div class="text-primary text-center">'+belowText+'</div>' : ''}`
}