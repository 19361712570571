import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { RoutePaths } from 'routes/paths'
import { userReducerState } from 'store/slices/user'
import { eLocale, userState } from 'store/types'
import { InprintDocumentEnglish } from './components/InprintDocumentEnglish'
import { InprintDocumentGerman } from './components/InprintDocumentGerman'

const Imprint: FC = () => {
  const userInfo: userState = useSelector(userReducerState)

  switch (userInfo.settings.locale) {
    case eLocale.German:
      return <InprintDocumentGerman />
    default:
      return <InprintDocumentEnglish />
  }
}
const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.imprint,
    title: 'footer.Imprint',
    element: <Imprint />,
    roles: componentPermissions[componentNames.imprint],
    requiresAuthentication: true,
  },
  name: 'Imprint',
}
export default exportObj
