import { call, put, select, takeLeading } from 'redux-saga/effects'
import apiRequests from 'config/services'
import {
  sagaGetOrganizationTeamMembersAction,
  userTypes,
} from 'saga/actions/user'
import { setUserData, userReducerState } from 'store/slices/user'
import { getLocal } from 'config/lib'
import {
  eSnackbarLocation,
  iOrganizationData,
  userState,
} from 'store/types'
import { PayloadAction } from '@reduxjs/toolkit'
import { iLooseObject } from 'pages/DataHub/filters/types'
import {
  setToastErrors,
  toggleAddMemberModalVisibility,
} from 'store/slices/generalSettings'
import { eSeverity } from 'components/types'
import { organizationProfileState } from 'store/slices/organizationProfile'

export function* addOrganizationMemberSaga(
  action: PayloadAction<iLooseObject>
) {
  try {
    const addNewOrganizationMember: iLooseObject = {
      id: action.payload.id,
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      email: action.payload.email,
      roles: action.payload.roles.length > 0 ? [action.payload.roles] : [],
    }

    const response: iLooseObject = yield call(
      apiRequests.addNewOrganizationMember,
      addNewOrganizationMember
    )
    if (response) {
      yield put(toggleAddMemberModalVisibility())
      const organizationProfile: iOrganizationData = yield select(
        organizationProfileState
      )
      if (organizationProfile && organizationProfile.id) {
        yield put(
          sagaGetOrganizationTeamMembersAction(
            parseInt(organizationProfile?.id)
          )
        )
      }
      yield put(
        setToastErrors({
          messages: [
            {
              code: 'new-member-success',
              message: 'success.message',
            },
          ],
          isMessageActive: true,
          severity: eSeverity.success,
          messageType: eSnackbarLocation.default,
        })
      )
    }
  } catch (e) {
    //catchind errors
  }
}

export function* setUserLanguageProfileDataSaga(action: any) {
  try {
    let userInfo: userState = yield select(userReducerState)

    const locale = getLocal('i18nextLng') || 'en'
    const newUserInfo = JSON.parse(JSON.stringify(userInfo))
    newUserInfo.settings.locale = locale
    yield call(apiRequests.setUserLanguage, locale)
    yield put(setUserData(newUserInfo))
  } catch (e) {
    //catchind errors
  }
}

export default function* addOrganizationMemberHook() {
  yield takeLeading(userTypes.SAGA_ADD_MEMBER, addOrganizationMemberSaga)
}
