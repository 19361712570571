import { call, select } from 'redux-saga/effects'
import reqProvider from '../coreRequests'
import { BASE_API } from '../urlPaths'
import { organizationProfileState } from 'store/slices/organizationProfile'
import { iLooseObject } from 'pages/DataHub/filters/types'

export function* deleteTeamMember(memberId: number): any {
  const orgData: iLooseObject = yield select(organizationProfileState)
  const { id: orgId } = orgData
  const path = `${BASE_API}/organizations/${orgId}/users/${memberId}`
  const data: any = yield call(reqProvider.deleteReq, path)
  return data ? true : false
}

export function* activateDeactivateTeamMember(
  memberId: number,
  deactivate: boolean = false
) {
  const orgData: iLooseObject = yield select(organizationProfileState)
  const { id: orgId } = orgData
  const path = `${BASE_API}/organizations/${orgId}/users/${memberId}/${
    deactivate ? 'deactivate' : 'activate'
  }`
  const data: iLooseObject = yield call(
    reqProvider.getReq,
    path,
    undefined,
    false
  )
  return data
}
