export interface iHubCategoriesState {
  categories: string[]
}

export interface iHubResponseType {
  context: string
  filters: iHubFilter
}

export enum eComponentType {
  filters = 'filters',
  settings = 'settings',
}

export enum eBoundsSliderName {
  minValue = 'minValue',
  maxValue = 'maxValue',
}

interface iRangeValueSliderBound {
  name: string
  displayName: string
  value: number
}

export interface iHubFilter {
  name: string
  displayName: string
  type: string
  elementType?: string
  isContext?: boolean
  values?: string[]
  value?: string
  bounds?: iRangeValueSliderBound[]
  steps?: number
  unit?: string
  defaultValue?: string | boolean
  isMultiSelect?: boolean
  required?: boolean
}

export interface iDataHubSearch {
  id: string
  name: string
  category: string
  type: string
}

export interface iHubType {
  context: string
  filters: iHubFilter[]
  results: iDataHubSearch[]
  settings: iHubFilter[]
  settingRules: string[]
}

export interface iHubsSliceState {
  hub: iHubType
}
