
import { useTheme } from '@mui/material'
import { iElementSize } from 'components/types'
import React from 'react'
interface iProps {
  src: string
  title?: string
  size?: iElementSize
  className?: string
  invertedColor?: boolean
}

const CustomSvgStyled = ({
  src,
  title,
  size = { height: 24, width: 24, unit: 'px' },
  className = '',
  invertedColor = false,
}: iProps) => {
  const theme = useTheme()
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <img
      src={src}
      alt={title}
      title={title}
      width={`${size.width}${size.unit}`}
      height={`${size.height}${size.unit}`}
      className={`${className} ${!invertedColor} ${isDarkMode ?  'invert-[1]': ''}` }
    />
  )
}

export default CustomSvgStyled
