import React, { useEffect, useState } from 'react'

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { dataHubReducerState } from 'store/slices/dataHubSlice'
import { eComponentType, iHubType } from 'store/types'
import DataHubFilterWrapper from 'pages/DataHub/filters/components/DataHubFilterWrapper'
import { iHubFilter } from 'store/types'
import TextWrapper from 'components/custom/TextWrapper'

const FiltersSection = () => {
  const { t } = useTranslation()
  const hubData: iHubType | any = useSelector(dataHubReducerState).hub
  const [hubDataFiltersIsContextTrue, setHubDataFiltersIsContextTrue] =
    useState()
  const [hubDataFiltersIsContextFalse, setHubDataFiltersIsContextFalse] =
    useState()
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)

  useEffect(() => {
    setHubDataFiltersIsContextTrue(
      hubData.filters.filter((item: iHubFilter) => item.isContext)
    )
    setShowAdvancedFilters(false)
    if (hubData['context'].toString().length > 1) {
      setHubDataFiltersIsContextFalse(
        hubData.filters.filter((fltr: iHubFilter) => {
          if (!fltr.isContext) {
            setShowAdvancedFilters(true)
          }
          return !fltr.isContext
        })
      )
    }
  }, [hubData, hubData.filters])
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <Accordion
      className="mt-4"
      elevation={1}
      sx={{ ':first-of-type': { borderRadius: '1rem' } }}
      disableGutters={true}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={
          showAdvancedFilters && (
            <ExpandMoreIcon
              className="flex self-end my-3 text-paletteOrange cursor-pointer"
              onClick={() => setExpanded(!expanded)}
            />
          )
        }
        aria-controls="filterBy-content"
        id="filterBy-header"
        className="flex w-full justify-end items-end cursor-default"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className="flex flex-col w-full">
          <TextWrapper>{t('search.filterBy')}</TextWrapper>
          <div className="flex flex-wrap w-full gap-x-8 gap-y-4 ml-3 flex-row">
            <div className="flex flex-wrap flex-1 flex-row gap-x-8 gap-y-4">
              <DataHubFilterWrapper
                filters={hubDataFiltersIsContextTrue || []}
                showFiltersOnly={true}
                componentType={eComponentType.filters}
              />
            </div>
            {showAdvancedFilters && (
              // TODO: `max-[871px]:w-full` this implementation is too dependant on menu width (SMZ-695, SMZ-696)
              <div
                className="flex pr-5 svg justify-end items-end text-paletteOrange cursor-pointer max-[871px]:w-full"
                onClick={() => setExpanded(!expanded)}
              >
                {t('search.advancedFilters')}
              </div>
            )}
          </div>
        </div>
      </AccordionSummary>
      {showAdvancedFilters ? (
        <AccordionDetails>
          <hr className="mb-4" />
          <div className="flex flex-wrap gap-x-8 gap-y-4 ml-3">
            <DataHubFilterWrapper
              filters={hubDataFiltersIsContextFalse || []}
              componentType={eComponentType.filters}
            />
          </div>
        </AccordionDetails>
      ) : (
        <></>
      )}
    </Accordion>
  )
}

export default FiltersSection
