interface iProps {
  label: string
  value: string
  onClick?: () => void
}
export const CardRow = ({ label, value, onClick }: iProps) => {
  return (
    <div
      className="text-[16px]
        leading-8  
        tracking-[0.14px] 
        opacity-70"
    >
      <span className="font-semibold">{label}: </span>
      <span
        className={onClick ? 'text-blue-500 cursor-pointer' : ''}
        onClick={onClick}
      >
        {value}
      </span>
    </div>
  )
}
