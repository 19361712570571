import { getUserId } from '../lib'
import reqProvider from '../coreRequests'
import { AUTH_API, BASE_API } from '../urlPaths'
import { getLocal, setLocal } from 'config/lib'
import { eCurrencyCodes, iCurrency } from 'store/types'
import { call } from 'redux-saga/effects'

export function* getUserData(): any {
  const userId = getUserId()
  const path = `${BASE_API}/users/${userId}`
  const data: any = yield call(reqProvider.getReq, path)
  return data
}

export function* getUserProfileUrls(): any {
  const userId = getUserId()

  const path = `${AUTH_API}/users/${userId}/get-change-profile-urls?redirectUrl=${encodeURIComponent(
    window.location.origin
  )}/logout`

  const data: any = yield call(reqProvider.getReq, path)
  return data
}

export function getUserCurrency(): iCurrency {
  // TODO: get from API
  const localCode: string = getLocal('currency') || 'USD'
  const currencyCodeKey = localCode as unknown as eCurrencyCodes
  const code: eCurrencyCodes = eCurrencyCodes[currencyCodeKey]
  const currency: iCurrency = { code }
  return currency
}

export function* setUserCurrency(currencyCode: string): any {
  // TODO: set to API; set to Redux
  const userId = getUserId()
  const path: string = `${BASE_API}/users/${userId}`
  const headers: any = { 'Content-Type': 'application/merge-patch+json' }
  const body: any = {
    settings: {
      currency: currencyCode,
    },
  }
  yield call(reqProvider.patchReq, path, headers, body)

  setLocal('currency', currencyCode)
}

export function* setUserLanguage(language: string): any {
  const userId = getUserId()
  const path = `${BASE_API}/users/${userId}`
  const headers: any = { 'Content-Type': 'application/merge-patch+json' }
  const body: any = {
    settings: {
      locale: language,
    },
  }
  yield call(reqProvider.patchReq, path, headers, body)
}
