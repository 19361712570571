import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { eSeverity } from 'components/types'
import { iLooseObject } from 'pages/DataHub/filters/types'
import { RootState } from 'store'
import { eSnackbarLocation } from 'store/types'
import { iGeneralSettings, iUserProfileUrls } from 'store/types/generalSettings'

export const initialState: iGeneralSettings = {
  userProfileUrls: { changePasswordUrl: '', updateProfileUrl: '' },
  isDrawerOpen: false,
  toastErrorData: {
    messages: [],
    isMessageActive: true,
    severity: eSeverity.error,
    messageType: eSnackbarLocation.default,
  },
  fieldsErrorData: {
    messages: [],
  },
  organization: {
    isOrganizationCreationSuccessModalOpen: false,
    isOrganizationCreateModalOpen: false,
    isAddMemberModalOpen: false,
  },
  toastData: {
    message: { title: '', message: '' },
    severity: eSeverity.error,
    isMessageActive: false,
    messageType: eSnackbarLocation.default,
  },
}

//get current reducer state
export const userProfileUrlsState = (state: RootState) =>
  state.generalSettings.userProfileUrls
export const isDrawerOpenReducerState = (state: RootState) => state.isDrawerOpen
export const messageReducerState = (state: RootState) => state.messageState
export const generalSettingsState = (state: RootState) => state.generalSettings
export const generalSettingsOrganizationState = (state: RootState) =>
  state.generalSettings.organization

//reducer Object
export const generalSettingsReducer = createSlice({
  name: 'generalSettings',
  initialState,
  reducers: {
    setUserProfileUrls: (
      { userProfileUrls },
      { payload }: PayloadAction<iUserProfileUrls>
    ) => {
      userProfileUrls.changePasswordUrl = payload.changePasswordUrl
      userProfileUrls.updateProfileUrl = payload.updateProfileUrl
    },
    toggleDrawer: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen
    },
    setToastErrors: (state, { payload }: PayloadAction<iLooseObject>) => {
      state.toastErrorData.messages = payload.messages
      state.toastErrorData.severity = payload.severity
      state.toastErrorData.isMessageActive = payload.isMessageActive
      state.toastErrorData.messageType = payload.messageType
    },
    setFieldsErrors: (state, { payload }: PayloadAction<iLooseObject>) => {
      const { messages } = payload
      state.fieldsErrorData.messages = messages
    },
    clearFieldsErrors: (state) => {
      state.fieldsErrorData = initialState.fieldsErrorData
    },
    clearToastErrors: (state) => {
      state.toastErrorData = initialState.toastErrorData
    },
    removeToastItemByCode: (state, { payload }: PayloadAction<string>) => {
      const indexToRemove = state.toastErrorData.messages.findIndex(
        (item: iLooseObject) => {
          return item?.code === payload
        }
      )

      const newToastMessages: iLooseObject[] = JSON.parse(
        JSON.stringify(state.toastErrorData.messages)
      )
      //only remove if code is not found in list
      if (indexToRemove !== -1) {
        delete newToastMessages[indexToRemove]
        state.toastErrorData.messages = newToastMessages
      }
    },
    toggleOrganizationSuccessModalVisibility: (state) => {
      state.organization.isOrganizationCreationSuccessModalOpen =
        !state.organization.isOrganizationCreationSuccessModalOpen
    },
    toggleCreateOrganizationModalVisibility: (state) => {
      state.organization.isOrganizationCreateModalOpen =
        !state.organization.isOrganizationCreateModalOpen
    },
    toggleAddMemberModalVisibility: (state) => {
      state.organization.isAddMemberModalOpen =
        !state.organization.isAddMemberModalOpen
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setUserProfileUrls,
  toggleDrawer,
  setToastErrors,
  setFieldsErrors,
  clearFieldsErrors,
  clearToastErrors,
  removeToastItemByCode,
  toggleOrganizationSuccessModalVisibility,
  toggleCreateOrganizationModalVisibility,
  toggleAddMemberModalVisibility,
} = generalSettingsReducer.actions

export default generalSettingsReducer.reducer
