import React, { useEffect } from 'react'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { RoutePaths } from 'routes/paths'
import SearchPreferences from './components/SearchPreferences'
import { serializeUrlAttributes } from 'config/lib'
import { useDispatch, useSelector } from 'react-redux'
import {
  sagaGetDataHubAction,
  sagaGetDataHubCategoriesAction,
  sagaGetDataHubSearchAction,
  sagaSetSettingsDefaultFieldsData,
} from 'saga/actions/dataHub'
import { useSearchParams } from 'react-router-dom'
import { iLooseObject } from '../filters/types'
import { eComponentType, eSnackbarLocation } from 'store/types'
import { messageReducerState } from 'store/slices/generalSettings'
import { getSearchParametersInitialState } from 'store/slices/searchParametersSlice'
import InfoPreferences from './components/InfoPreferences'
import SavedParameters from './components/SavedParameters'
import SnackbarWrapper from 'components/custom/SnackbarWrapper'

const SearchParameters = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const messageState = useSelector(messageReducerState).toastErrorData

  const dispatch = useDispatch()
  useEffect(() => {
    //
    const initialContextName = searchParams.get('contextName')
    if (initialContextName === null) {
      //default context name for search params
      setSearchParams({
        contextName: `.${getSearchParametersInitialState().searchCategory}`,
      })
    }
  }, [searchParams, setSearchParams])
  //if params change in the url:
  useEffect(() => {
    const newObjectTest: iLooseObject = {}
    for (const entry of Array.from(searchParams)) {
      const [param, value] = entry
      newObjectTest[param] = value
    }

    const serializedUrl = `?${serializeUrlAttributes(newObjectTest)}`

    dispatch(sagaGetDataHubSearchAction(serializedUrl))
    // data hub data (results, FILTERS, context, prefferences) updates based on  are requested in
    dispatch(sagaGetDataHubAction(serializedUrl, eComponentType.settings))
    // when page loads for the first time, it should get the settings from dataHub and put them in the selected fields / filters reducer in the appropriate type  ONLY for default available values
    dispatch(sagaSetSettingsDefaultFieldsData())
    // add the child menus into view
    dispatch(sagaGetDataHubCategoriesAction())
  }, [dispatch, searchParams])

  return (
    <div className="flex flex-col gap-y-2 w-full p-8">
      <InfoPreferences />
      <SearchPreferences />
      {messageState.isMessageActive &&
      messageState.messageType === eSnackbarLocation.embededSearchParams ? (
        <SnackbarWrapper
          className="w-full lg:w-full"
          message={{ title: 'x', message: 'x' }}
        />
      ) : null}
      <SavedParameters />
    </div>
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.dataHubSearchParams,
    element: <SearchParameters />,
    title: 'dataHub.optionsPreferences',
    roles: componentPermissions[componentNames.pageSearchParameters],
    requiresAuthentication: true,
  },
  name: 'Search Parameters',
}

export default exportObj
