import { iSingleEntityParams } from 'config/services/requests/dataHub/types'
import { iUpdateStatusSearchParameter } from 'saga/sagas/dataHub/types'
import { eComponentType } from 'store/types'

export const dataHubTypes = {
  SAGA_GET_DATA_HUB: 'SAGA_GET_DATA_HUB',
  SAGA_GET_DATA_HUB_CATEGORIES: 'SAGA_GET_DATA_HUB_CATEGORIES',
  SAGA_GET_DATA_HUB_SEARCH: 'SAGA_GET_DATA_HUB_SEARCH',
  SAGA_GET_SINGLE_ENTITY_DATA: 'SAGA_GET_SINGLE_ENTITY_DATA',
  SAGA_SET_SETTINGS_DEFAULT_FIELDS_DATA:
    'SAGA_SET_SETTINGS_DEFAULT_FIELDS_DATA',
  SAGA_GET_SEARCH_PARAMETERS_DATA: 'SAGA_GET_SEARCH_PARAMETERS_DATA',
  SAGA_GET_COUNTRY_LIST: 'SAGA_GET_COUNTRY_LIST',
  SAGA_DELETE_SEARCH_PARAMETER: 'SAGA_DELETE_SEARCH_PARAMETER',
  SAGA_UPDATE_SEARCH_PARAMETER_STATUS: 'SAGA_UPDATE_SEARCH_PARAMETER_STATUS',
}

export function sagaGetDataHubAction(
  queryPath: string,
  componentType?: eComponentType
) {
  return {
    type: dataHubTypes.SAGA_GET_DATA_HUB,
    payload: { queryPath, componentType },
  }
}

export function sagaGetDataHubCategoriesAction() {
  return {
    type: dataHubTypes.SAGA_GET_DATA_HUB_CATEGORIES,
  }
}

export function sagaGetDataHubSearchAction(searchTerms?: string) {
  return {
    type: dataHubTypes.SAGA_GET_DATA_HUB_SEARCH,
    payload: searchTerms,
  }
}

export function sagaGetSingleEntityDataAction(params?: iSingleEntityParams) {
  return {
    type: dataHubTypes.SAGA_GET_SINGLE_ENTITY_DATA,
    payload: params,
  }
}
export function sagaSetSettingsDefaultFieldsData(params?: string) {
  return {
    type: dataHubTypes.SAGA_SET_SETTINGS_DEFAULT_FIELDS_DATA,
    payload: params,
  }
}
export function sagaGetSearchParametersData() {
  return {
    type: dataHubTypes.SAGA_GET_SEARCH_PARAMETERS_DATA,
  }
}
export function sagaDeleteSearchParametersDataAction(searchSettingId: string) {
  return {
    type: dataHubTypes.SAGA_DELETE_SEARCH_PARAMETER,
    payload: searchSettingId,
  }
}
//update (PATCH) search parameters state
export function sagaUpdateSearchParameterStatusDataAction(
  statusData: iUpdateStatusSearchParameter
) {
  return {
    type: dataHubTypes.SAGA_UPDATE_SEARCH_PARAMETER_STATUS,
    payload: statusData,
  }
}
