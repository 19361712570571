import { AxiosRequestHeaders } from 'axios'
import { getToken } from 'config/lib'
import jwt_decode from 'jwt-decode'
import { ITermsAccepted, JwtData } from '.'

export const getDefaultHeaders = (): AxiosRequestHeaders => {
  const token = getToken()

  return {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  }
}

export const getUserId = (): string => {
  const token: string = getToken()
  const decoded = jwt_decode<JwtData>(token)
  const userId = decoded.sub
  return userId
}

export const isTermsAccepted = (): boolean => {
  const token: string = getToken()
  const decoded = jwt_decode<JwtData>(token)
  const termsAccepted = decoded.terms_accepted
  return termsAccepted === ITermsAccepted.ON
}
