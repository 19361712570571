import React from 'react'
import { ContentBody } from 'components/pages/components/ContentBody'

import PaperWrapper from 'components/custom/PaperWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
import TextWrapper from 'components/custom/TextWrapper'
import LinkWrapper from 'components/custom/LinkWrapper'
import { eLinkTarget, eTypographyVariant } from 'components/types'

export const InprintDocumentEnglish = () => {
  const year = new Date().getFullYear()
  const headingClassName = 'pt-4 pb-2 text-paletteOrange uppercase'

  return (
    <ContentBody title="sustamize Imprint">
      <PaperWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          Company Information
        </TitleWrapper>
        <TextWrapper>sustamize GmbH</TextWrapper>
        <TextWrapper>Konrad-Zuse-Platz 8</TextWrapper>
        <TextWrapper>81829 Munich</TextWrapper>
        <TextWrapper>E-Mail: hallo@sustamize.com</TextWrapper>
        <TextWrapper>Website: www.sustamize.com</TextWrapper>
        <TextWrapper>Phone: +49 (0) 89 954573702</TextWrapper>
        <TextWrapper className="mt-6">
          represented by the managing director Michael Kochs
        </TextWrapper>
        <TextWrapper className="mb-6">
          Value added tax identification number / USt-ID: DE354262347
        </TextWrapper>
        <TextWrapper>Handelsregister: HRB 277048</TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          Additional disclosure:
        </TitleWrapper>
        <TextWrapper>
          Online dispute resolution in accordance with Art. 14 (1) ODR
          Regulation: The European Commission provides a platform for online
          dispute resolution (OS), which you can access at{' '}
          <LinkWrapper
            to="http://ec.europa.eu/consumers/odr/finden.Widerspruchsbelehrung"
            target={eLinkTarget.blank}
          >
            http://ec.europa.eu/consumers/odr/finden.Widerspruchsbelehrung
          </LinkWrapper>{' '}
          in accordance with § 355 BGB.
        </TextWrapper>
        <TextWrapper>
          You have the right to revoke this contract/donation within fourteen
          days without giving any reason. The revocation period is fourteen days
          from the day on which you or a third party named by you, who is not
          the carrier, have taken or has taken possession of the goods. In order
          to exercise your right of revocation, you must inform us (sustamize
          GmbH i.G, Michael Kochs, Konrad-Zuse-Platz 8, 81829 München,
          hallo@sustamize.com) by means of a clear declaration (e.g. a letter or
          e-mail sent by post) of your decision to revoke this contract. If you
          make use of this option, we will immediately send you (e.g. by e-mail)
          a confirmation of receipt of such revocation. To comply with the
          revocation period, it is sufficient that you send the notification of
          the exercise of the right of revocation before the expiry of the
          revocation period.
        </TextWrapper>
        <TextWrapper>
          All images, graphics, screenshots: © {year} sustamize GmbH i.G.
        </TextWrapper>
      </PaperWrapper>
    </ContentBody>
  )
}
