import { Roles } from 'config/roles'
import { iLooseObject } from 'pages/DataHub/filters/types'

export enum eLocale {
  English = 'en',
  German = 'de',
}

export interface userState {
  id: string
  username: string
  email: string
  firstName: string
  lastName: string
  roles: Roles[]
  settings: {
    currency: string
    locale: eLocale
  }
  organization: iLooseObject
}
