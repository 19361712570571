import { FC } from "react"
import { RoutePaths } from "routes/paths"
import { componentPermissions } from "config/permissions"
import { componentNames } from "config/roles"
import ContentBodyWrapper from "components/custom/ContentBodyWrapper"
import { useTranslation } from "react-i18next"
import PaperWrapper from "components/custom/PaperWrapper"
import { userReducerState } from "store/slices/user"
import { useDispatch, useSelector } from "react-redux"
import { containsAnyCommercialRoles, isBillingRestricted } from "./helpers"
import { Pricing } from "./Pricing"
import { useEffectOnce } from "usehooks-ts"
import { setUserMenuItems } from "store/slices/menuItems"
import SubscriptionsTab from "./Subscriptions/components/subsciptions-tab"

const Billing: FC = () => {

  const { t } = useTranslation()
  const { roles } = useSelector(userReducerState)
  const dispatch = useDispatch();

  useEffectOnce(() => {
    dispatch(setUserMenuItems())
  })

  return !containsAnyCommercialRoles(roles) || true ? (
    <>
      <Pricing />
      {!!isBillingRestricted(roles)?.length ? (
        <ContentBodyWrapper title={t(`pages.billing`)}>
          <PaperWrapper>
            <SubscriptionsTab />
          </PaperWrapper>
        </ContentBodyWrapper>
      ) : (
        <></>
      )}
    </>
  ) : (
    <ContentBodyWrapper title={t(`pages.billing`)}>
    </ContentBodyWrapper>
  )
}

const routeObj = {
  routeProps: {
    path: RoutePaths.billing,
    element: <Billing />,
    title: 'pages.billing',
    roles: componentPermissions[componentNames.billing],
    restrictedRoles: componentPermissions[componentNames.billingRestricted],
    requiresAuthentication: true,
  },
  name: 'Billing',
}

export default routeObj