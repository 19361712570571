import React, { useRef } from 'react'
import { ContentBody } from 'components/pages/components/ContentBody'

import PaperWrapper from 'components/custom/PaperWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
import TextWrapper from 'components/custom/TextWrapper'
import LinkWrapper from 'components/custom/LinkWrapper'
import { eElementOnMouseOverEffect, eTypographyVariant } from 'components/types'

export const CalculationVariantsDocument = () => {
  const tableOfContentHover = eElementOnMouseOverEffect.hover
  const headingClassName = 'pt-4 pb-2 text-paletteOrange uppercase'
  const orderedListDiscClassName = 'list-disc list-inside m-4'
  const documentVersioningTextWrapperClassName = 'mt-14'

  const refPurposeScopeAndUsers = useRef(null)
  const refCalculationVariantsScenarios = useRef(null)
  const refCommon = useRef(null)
  const refMean = useRef(null)
  const refMedian = useRef(null)
  const refMinimal = useRef(null)
  const refMaximal = useRef(null)
  const refRecycling = useRef(null)

  return (
    <ContentBody title="Calculation Variants">
      <PaperWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          Content
        </TitleWrapper>
        <ul>
          <li>
            <LinkWrapper
              hash="PurposeScopeAndUsers"
              customRef={refPurposeScopeAndUsers}
              underline={tableOfContentHover}
            >
              Purpose, Scope and Users
            </LinkWrapper>
          </li>
          <li>
            <LinkWrapper
              hash="CalculationVariantsScenarios"
              customRef={refCalculationVariantsScenarios}
              underline={tableOfContentHover}
            >
              Calculation Variants Scenarios
            </LinkWrapper>
          </li>
          <li>
            <ul className="ml-4">
              <li>
                <LinkWrapper
                  hash="Common"
                  customRef={refCommon}
                  underline={tableOfContentHover}
                >
                  1.1 Common
                </LinkWrapper>
              </li>
              <li>
                <LinkWrapper
                  hash="Mean"
                  customRef={refMean}
                  underline={tableOfContentHover}
                >
                  1.2 Mean
                </LinkWrapper>
              </li>
              <li>
                <LinkWrapper
                  hash="Median"
                  customRef={refMedian}
                  underline={tableOfContentHover}
                >
                  1.3 Median
                </LinkWrapper>
              </li>
              <li>
                <LinkWrapper
                  hash="Minimal"
                  customRef={refMinimal}
                  underline={tableOfContentHover}
                >
                  1.4 Minimal
                </LinkWrapper>
              </li>
              <li>
                <LinkWrapper
                  hash="Maximal"
                  customRef={refMaximal}
                  underline={tableOfContentHover}
                >
                  1.5 Maximal
                </LinkWrapper>
              </li>
              <li>
                <LinkWrapper
                  hash="Recycling"
                  customRef={refRecycling}
                  underline={tableOfContentHover}
                >
                  1.6 Recycling
                </LinkWrapper>
              </li>
            </ul>
          </li>
        </ul>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
          id="PurposeScopeAndUsers"
          ref={refPurposeScopeAndUsers}
        >
          Purpose, Scope and Users
        </TitleWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
          id="CalculationVariantsScenarios"
          ref={refCalculationVariantsScenarios}
        >
          Calculation Variants Scenarios
        </TitleWrapper>
        <TextWrapper>
          sustamize&apos;s calculation methodology is based on to realize the
          extensive and broad data coverage in specific fields such as the
          material databases. Based on the results of the data research and data
          clustering, sustamize provides its users with a range of results
          depending on the different processes used.
        </TextWrapper>
        <ol className={orderedListDiscClassName}>
          <li>Common</li>
          <li>Mean</li>
          <li>Median</li>
          <li>Minimal</li>
          <li>Maximal</li>
        </ol>
        <TextWrapper>
          Following these criteria, the material database user will be able to
          assess different scenarios. These criteria are further explained in
          the sections below. In case the need for data on production processes
          itself arises, sustamize will provide it from the database for
          reference processes, which is not part of the material database DB03.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h6}
          className={headingClassName}
          id="Common"
          ref={refCommon}
        >
          1.1 Common
        </TitleWrapper>
        <TextWrapper>
          The common value was introduced as a way of reducing difficulty in
          choosing a value within the array of possible values. This reference
          dataset represents, as the name suggest, the most common process used
          for the production of a given material in that industry worldwide. A
          differentiation according to local most commonly used process cannot
          yet be provided. These processes are evaluated by our experts with
          years of industry knowledge. Important references apart from only
          technological factors in the choice of values are related to the
          quality of a given study and the age of a study. As an example a very
          new study might reflect a very modern production type that has not
          made it into general production and thus be disqualified as use for a
          common value. An older study, possibly with lower overall quality,
          might still be deemed more accurate in such a case. Otherwise if the
          two studies were to be of equal quality and technological significance
          the newer one would be used. On the other hand if they were roughly
          produced at the same time, the one with the higher quality would be
          used.
        </TextWrapper>
        <TextWrapper>
          The base calculation of these CO2e references refers to the country of
          production. However, sustamize also uses this as grounds for the
          simulation of country impact (Energy mix of the specific country).
        </TextWrapper>
        <TextWrapper>
          Recommendation: The “common” data of the reference dataset should be
          the selection of choice as this data reflects the status quo in most
          of the processes and applications. This means that this data should
          cover the needs of typical product footprint analyses.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h6}
          className={headingClassName}
          id="Mean"
          ref={refMean}
        >
          1.2 Mean
        </TitleWrapper>
        <TextWrapper>
          An alternative type of data is the “mean” process selection. In this
          case not the most common industrial used processes like for
          refinement, alloying and metal or polymer processing are used but the
          median of all processes of each category in the database is used for
          the calculation.
        </TextWrapper>
        <TextWrapper>
          Recommendation: The use of “mean” data is only recommended if it is
          assumed that materials that are used in the production processes are
          not produced via common technology and it is not optimized production
          technology, nor a very inefficient production technology, in which
          case one of the following datasets should be used. This can be
          understood as a less specific “common” value. It reflects a higher
          uncertainty as to the used processes in the production.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h6}
          className={headingClassName}
          id="Median"
          ref={refMedian}
        >
          1.3 Median
        </TitleWrapper>
        <TextWrapper>
          A similar type of data to the “mean” is the “median” process
          selection. In the same way as with the mean not the most common
          industrial used processes like for refinement, alloying and metal or
          polymer processing are used but the median process of each category in
          the database is used for the calculation.
        </TextWrapper>
        <TextWrapper>
          Recommendation: The use of “median” data can be used similar to the
          mean value and is usually preferrable as it is not biased towards
          outliers that might influence the result in one way or another.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h6}
          className={headingClassName}
          id="Minimal"
          ref={refMinimal}
        >
          1.4 Minimal
        </TitleWrapper>
        <TextWrapper>
          For use in optimization scenarios, two other data ranges are offered.
          The first one is the “minimal” or “min” value. This value is based on
          using the most efficient processes found in the global research. It
          represents the lowest possible value of a CO2e Footprint of a material
          and can be achieved by optimizing processes alone.
        </TextWrapper>
        <TextWrapper>
          Recommendation: For optimization scenarios, this value can be used to
          compare a current footprint with one that includes a high degree of
          optimization. The minimal value can also be useful for planning
          large-scale changes in supply chains, i.e. building up a new material
          supply with state of the art technology.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h6}
          className={headingClassName}
          id="Maximal"
          ref={refMaximal}
        >
          1.5 Maximal
        </TitleWrapper>
        <TextWrapper>
          Analogous to the minimal value, the “maximal” or “max” data type
          signifies the opposite. It represents the scenario of the least
          efficient processes used according to global research. It actually
          means the highest value a material should reach using inefficient
          processes. Understandably, in some aspect there is no limit to
          inefficiencies and how high the value for a material could rise.
          Nevertheless, “max” is a good representation of a still functional but
          inefficient production process.
        </TextWrapper>
        <TextWrapper>
          Recommendation: This data set can be used to simulate an inefficient
          production in worst case scenario analysis or as a comparison with
          other scenarios. It can also be useful if a specific location is known
          to use a highly inefficient or old technology for production as an
          approximation.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h6}
          className={headingClassName}
          id="Recycling"
          ref={refRecycling}
        >
          1.6 Recycling
        </TitleWrapper>
        <TextWrapper>
          In addition to the values for primary materials, sustamize provides
          values for recycling (secondary) material. These are provided in the
          same categories “common”, “mean”, “median”, “min” and “max” as with
          the primary materials and the same logic applies as described above.
        </TextWrapper>
        <TextWrapper>
          Any recycling material&apos;s value calculation is made under the
          assumption that 100 % of the material is recycling material, just as
          the base calculation for primary material assumes that 100 % of the
          material is newly won. If a material mix is needed to represent a
          certain recycling quota, both values need to be used and combined
          according to their respective percentage. For example, a material made
          from 40% secondary material, that has a footprint of 1.5 kg of CO2e
          per kg material, and 60 % of primary material, with a footprint of 2.5
          kg CO2e per kg material, would have a final footprint of 2.1 kg CO2e
          per kg of material. Calculation: 0.4 x 1.5 kg + 0.6 x 2.5 kg = 2.1 kg
          CO2e per kg.
        </TextWrapper>
        <TextWrapper className={documentVersioningTextWrapperClassName}>
          Version 1.1 Date: 23-01-2023 Authors: Jonas Scheyrer, Andreas Rennet
        </TextWrapper>
      </PaperWrapper>
    </ContentBody>
  )
}
