import { eSeverity } from 'components/types'
import { iRoleMissingResponseError } from 'pages/DataHub/filters/types'
import { put } from 'redux-saga/effects'
import { setFieldsErrors, setToastErrors } from 'store/slices/generalSettings'
import { eSnackbarLocation, userState } from 'store/types'
import { authCheck } from './coreRequests'
import { RoutePaths } from 'routes/paths'
import { Roles } from 'config/roles'

export function* handleErrors(
  error: any,
  willRedirectToUpgradePage: boolean = true,
  suppressErrorMessage: boolean = false,
  userInfo: userState | undefined = undefined
): iRoleMissingResponseError | any {
  const errors =
    (error &&
      error?.response &&
      error?.response?.data &&
      error?.response?.data?.violations &&
      error?.response?.data?.violations) ||
    []

  const status = error?.response?.status
  let severity = eSeverity.error
  let isMessageActive = true
  let messageType = eSnackbarLocation.default
  const { response } = error
  const { data } = response

  switch (status) {
    case 400:
      const { errorCode } = response.data
      yield put(
        setToastErrors({
          messages: [{ code: errorCode }],
          severity: eSeverity.warning,
          isMessageActive: isMessageActive,
          messageType: messageType,
        })
      )
      break
    case 0:
    case 401:
      authCheck()
      return false
    case 403:
      suppressErrorMessage = true

      if (willRedirectToUpgradePage) {
        if(!!userInfo?.roles.find(r=> r=== Roles.ROLE_ORG_USER)){
             window.location.replace(RoutePaths.memberAccessForbidden)
        }else{
          window.location.replace(RoutePaths.upgrade)
        }
     
      }
      const missingRoleData: iRoleMissingResponseError = {
        requiredPermission: data.requiredPermission,
        status: status,
      }

      return missingRoleData
    case 404:
      suppressErrorMessage = true
      document.location.replace(RoutePaths.notFound)
      return false

    case 422:
      if (errors[0].propertyPath === 'isActive') {
        //error for search results in toast
        yield !suppressErrorMessage &&
          put(
            setToastErrors({
              messages: errors,
              severity: eSeverity.warning,
              isMessageActive: isMessageActive,
              messageType: messageType,
            })
          )
        return false
      }
      // error for field errors
      yield put(
        setFieldsErrors({
          messages: errors,
        })
      )
      return false
    case 500:
      suppressErrorMessage = true
      document.location.replace(RoutePaths.serverError)
      return false
    default:
      yield !suppressErrorMessage &&
        put(
          setToastErrors({
            messages: errors,
            severity: severity,
            isMessageActive: isMessageActive,
            messageType: messageType,
          })
        )
      return false
  }
}
