import { put, takeLeading } from "redux-saga/effects";
import { massFileUploadActionTypes } from "saga/actions/massFileUpload";
import { setComponentError } from "store/slices/massFileUploadSlice";

function* setComponentErrorSaga(payload: any) {
  yield put(setComponentError(payload.payload));
}

export function* setMassFileUploadComponentErrorSagaHook() {
  yield takeLeading(
    massFileUploadActionTypes.SAGA_SET_COMPONENT_ERROR,
    setComponentErrorSaga
  );
}