import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { eTypographyVariant } from 'components/types'

const PaymentMethods = () => {
  const { t } = useTranslation()

  return (
    <>
      <Typography
        variant={eTypographyVariant.h5}
        data-testid={`payment-methods-title-testID`}
      >
        {t('profile.paymentMethods.title')}
      </Typography>
      <div>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero ea
        eligendi dolorum, nobis vitae tempore aut error eius, illum asperiores
        in debitis ab, optio assumenda quos! Repudiandae quos voluptate
        dignissimos!
      </div>
    </>
  )
}

export default PaymentMethods
