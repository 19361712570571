import { call, put, takeLeading } from 'redux-saga/effects'
import apiRequests from 'config/services'
import { currencyActionType } from 'saga/actions/currency'
import { setCurrencyData } from 'store/slices/currency'
import { currencyValuesState } from 'store/types'

export function* getLatestExchangeRatesSaga() {
  try {
    let result: currencyValuesState[] = yield call(
      apiRequests.getLatestExchangeRates
    )
    yield put(setCurrencyData(result))
  } catch (e) {
        //catchind errors
  }
}

export default function* getCurrencyDataSagaHook() {
  yield takeLeading(
    currencyActionType.SAGA_GET_LATEST_EXCGHANGE_RATES,
    getLatestExchangeRatesSaga
  )
}
