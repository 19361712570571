import { ePackageSelection } from "components/types";
import { eLocale } from "store/types"
import { eProductKey, iPricedProductListing, iProductOverview } from "store/types/subscriptionOrder";

export const toScaledPrice = (
  price?: number, 
  locale = eLocale.English, 
  priceScale = 2
): string => {

  if (price === undefined) return ''

  const decimalPrice = price / Math.pow(10, priceScale)

  return `€` + new Intl.NumberFormat(
    locale, 
    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  ).format(decimalPrice);
}

export const yearlyToMonthlyPrice = (yearly: number) => (yearly * 1.15) / 12;

export const getProductUnscaledPrice = (productKey: eProductKey, overview?: iProductOverview) => {

  if (!overview) return 0

  const all: iPricedProductListing[] = [
    ...overview.addonDatabases,
    ...overview.databases,
    ...overview.features,
    ...overview.packages
  ]

  const index = all
    .map(p => p.productKey)
    .indexOf(productKey);

  return index !== -1
    ? all[index].price
    : 0
}
export const packageSelectionToPackageKey = (s: ePackageSelection): 
  |eProductKey.SUST_STANDARD_PACKAGE
  |eProductKey.SUST_PRO_PACKAGE
  |undefined => {
  switch (s) {
    case ePackageSelection.STANDARD: return eProductKey.SUST_STANDARD_PACKAGE;
    case ePackageSelection.PRO: return eProductKey.SUST_PRO_PACKAGE;
    default: return undefined
  }
}

export const packageKeyToPackageSelection = (s: 
  |eProductKey.SUST_STANDARD_PACKAGE
  |eProductKey.SUST_PRO_PACKAGE
  |undefined): ePackageSelection => {

    switch (s) {
      case eProductKey.SUST_STANDARD_PACKAGE:
        return ePackageSelection.STANDARD
      case eProductKey.SUST_PRO_PACKAGE:
        return ePackageSelection.PRO
      default:
        return ePackageSelection.FLEX
    }
}
