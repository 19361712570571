import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { submitSubscriptionConfiguration } from "config/services/requests/subscription/submitSubscriptionConfiguration";
import { call, put, takeLeading } from "redux-saga/effects";
import { subscriptionOrderActionTypes } from "saga/actions/subscriptionOrder";
import { setSubscriptionSubmitStatus, toggleLoadingSubmitSubscription } from "store/slices/subscriptionOrderSlice";
import { iSubscriptionOrder } from "store/types/subscriptionOrder";

function* submitSubscriptionConfigurationSaga(payload: PayloadAction<iSubscriptionOrder>) {
  yield put(toggleLoadingSubmitSubscription())
  const response: AxiosResponse<unknown> = yield call(submitSubscriptionConfiguration, payload.payload)
  yield put(setSubscriptionSubmitStatus({status: response.status, response: response.data}))
  yield put(toggleLoadingSubmitSubscription())
}

export default function* submitSubscriptionOrderConfigurationSagaHook() {
  yield takeLeading(
    subscriptionOrderActionTypes.SAGA_SUBMIT_SUBSCRIPTION_CONFIGURATION,
    submitSubscriptionConfigurationSaga
  )
}