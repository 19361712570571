import { CardContent, Chip, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  iCo2ValuesChipLabel,
  iCo2Value,
  eCo2Details,
  iCo2Emission,
} from 'store/types'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { urlToObject, getScopeLabel } from 'config/lib'
import { CardRow } from './CardRow'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { iLooseObject } from 'pages/DataHub/filters/types'
import { RoutePaths } from 'routes/paths'

interface iProps {
  cO2Emission: iCo2Emission
  cO2Values: iCo2Value
  emissionFactorUnitSymbol: string
}

const SingleEntityCard = ({
  cO2Emission,
  cO2Values,
  emissionFactorUnitSymbol,
}: iProps) => {
  const { t } = useTranslation()
  const [, setSearchParams] = useSearchParams()
  const location = useLocation()
  const urlPath = location.search
  const singleEntityParams: iLooseObject = urlToObject(urlPath)
  const isObjectNotEmpty = (obj: Object) => Object.keys(obj).length > 0
  //const processDataLength = isObjectNotEmpty(cO2Values?.calculationProcessModel)
  const contextName: string = singleEntityParams['contextName'][0]
  const categoryName = contextName.split('.')[1]
  const typeName = contextName.substring(contextName.lastIndexOf('.') + 1)
  // const co2Source = cO2Values?.co2Source

  const co2Scope = cO2Values?.scope
  const isCo2Scope = co2Scope !== null && co2Scope !== undefined

  // const isMaterialContext =
  //   categoryName === 'materials' && typeName !== 'metals'

  const showChipCondition =
    ['energy', 'production', 'electronics'].indexOf(categoryName) === -1 &&
    ['transportation'].indexOf(typeName) === -1

  const isMetalType = typeName === 'metals'

  const navigate = useNavigate()
  const [chipsIdToDisplay, setChipsIdToDisplay] = useState<number[]>([])
  const [displayChipFlag] = useState(
    !cO2Values.isRecycled || cO2Values.isRecycled
  )
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const chipLabel: iCo2ValuesChipLabel[] = useMemo(
    () => [
      {
        label: t('singleEntity.card.primary'),
        color: '#F1AD5B',
      },
      {
        label: t('singleEntity.card.recycling'),
        color: '#A5EC6B',
      },
    ],
    [t]
  )

  const cardProcessRow = useCallback(() => {
    return (
      <CardRow
        label={t('singleEntity.card.process')}
        value={t('general.seeMore')}
        onClick={() => {
          singleEntityParams['id'] = cO2Values['id'] || null
          singleEntityParams['type'] = eCo2Details.process
          delete singleEntityParams['search']
          setSearchParams(singleEntityParams)
        }}
      />
    )
  }, [cO2Values, setSearchParams, singleEntityParams, t])

  // const cardSourceRow = useCallback(() => {
  //   if (co2Source || isMetalType) {
  //     return (
  //       <CardRow
  //         label={t('singleEntity.card.externalSources')}
  //         value={t('general.seeMore')}
  //         onClick={() => {
  //           singleEntityParams['id'] = cO2Values['id'] || null
  //           singleEntityParams['type'] = eCo2Details.sources
  //           delete singleEntityParams['search']
  //           setSearchParams(singleEntityParams)
  //         }}
  //       />
  //     )
  //   }
  // }, [
  //   cO2Values,
  //   co2Source,
  //   isMetalType,
  //   setSearchParams,
  //   singleEntityParams,
  //   t,
  // ])

  const chipGenerator = useCallback(() => {
    return chipsIdToDisplay.map((chipId, i) => (
      <Chip
        key={`card-chip-${i}`}
        className="w-auto ml-2"
        sx={{ backgroundColor: chipLabel[chipId].color }}
        label={chipLabel[chipId].label}
      />
    ))
  }, [chipLabel, chipsIdToDisplay])

  const navigateToKnowledgeHub = useCallback(
    (knowledgeHubLocation: string) => {
      switch (knowledgeHubLocation) {
        case 'energy':
          navigate(RoutePaths.knowledgeHubEnergy)
          break
        case 'materials':
          navigate(RoutePaths.knowledgeHubMaterials)
          break
        case 'logistics':
          navigate(RoutePaths.knowledgeHubLogistics)
          break
        case 'production':
          navigate(RoutePaths.knowledgeHubProduction)
          break
        case 'electronics':
          navigate(RoutePaths.knowledgeHubElectronics)
          break
        default:
          break
      }
    },
    [navigate]
  )
  const formatDate = (timestamp: string) => {
    const date = new Date(timestamp)
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
    })
  }

  useEffect(() => {
    setChipsIdToDisplay([])
    if (showChipCondition) {
      if (!cO2Values.isRecycled) {
        setChipsIdToDisplay([0])
      }
      if (cO2Values.isRecycled) {
        setChipsIdToDisplay([1])
      }
    }
  }, [cO2Values, categoryName, showChipCondition, typeName])

  const formatEnergyComposition = (
    composition: Partial<
      Record<keyof typeof cO2Values.productionProcessEnergyComposition, number>
    >,
    unit: string
  ) => {
    return Object.entries(composition)
      .filter(([_, value]) => value !== undefined && value) // Filter out undefined or zero values
      .map(
        ([key, value]) =>
          `${capitalizeFirstLetter(key)}: ${value}` + emissionFactorUnitSymbol
      ) // Format each key-value pair
  }

  return (
    <Paper
      className="w-[24.75rem] flex flex-col border-2 border-solid"
      elevation={2}
    >
      <CardContent className="px-4 py-1">
        <div>
          <h6 className="tracking-[0.14px] font-bold text-[22px] leading-[38px] m-0">
            {cO2Values.referenceValue} {emissionFactorUnitSymbol}
          </h6>
          {cO2Values?.co2OriginComposition &&
            Object.keys(cO2Values.co2OriginComposition).length > 0 && (
              <small className="tracking-[0.14px] text-[14px] leading-[18px]">
                {formatEnergyComposition(
                  cO2Values.co2OriginComposition,
                  emissionFactorUnitSymbol
                ).map((line, index) => {
                  const [label, valueWithUnit] = line.split(':')
                  const value = parseFloat(valueWithUnit) // Extract numerical value from the string

                  return (
                    <div key={index} className="mb-1">
                      {' '}
                      {/* Reduced margin-bottom */}
                      <div className="flex justify-between mb-1">
                        <span className="text-gray-700 font-semibold">
                          {label}:
                        </span>
                        <span className="text-gray-700 font-semibold">
                          {valueWithUnit}
                        </span>
                      </div>
                      <div className="relative w-full bg-gray-200 rounded-full h-2 overflow-hidden">
                        {' '}
                        {/* Reduced height */}
                        <div
                          className="h-full rounded-full shadow-md"
                          style={{
                            width: `${
                              (value / cO2Values.referenceValue) * 100
                            }%`,
                            backgroundColor: '#4fa500',
                          }} // Custom color and width
                        ></div>
                      </div>
                    </div>
                  )
                })}
              </small>
            )}
        </div>
        <hr className="my-4 border-gray-300" />
        <div className="flex flex-col">
          {
            <CardRow
              label={t('singleEntity.card.referenceYear')}
              value={`${cO2Emission.year}`}
            />
          }
          <CardRow
            label={t('singleEntity.card.validity')}
            value={`${cO2Values.validity}`}
          />
          <CardRow
            label={t('singleEntity.card.addedOn')}
            value={cO2Values.addedOn.split('T')[0]}
          />
          <CardRow
            label={t('singleEntity.card.lastUpdated')}
            value={cO2Values.lastUpdatedOn.split('T')[0]}
          />
          {/* only show source if exists*/}
          {/*co2Source ? cardSourceRow() : isMetalType ? cardSourceRow() : null*/}
          {
            /* {isMaterialContext
            ? cardProcessRow()
            : isMetalType
            ? cardProcessRow()
            : null} */
            cardProcessRow()
          }
          <div className="row flex justify-end mb-2 -mr-1 mt-2">
            {isCo2Scope ? (
              <Chip
                className="w-auto ml-2"
                sx={{ backgroundColor: '#ADD8E6' }} // Choose a suitable color for the scope chip
                label={getScopeLabel(co2Scope)} // Display the scope label as chip
              />
            ) : singleEntityParams['contextName'].includes(
                '.materials.metals'
              ) ? (
              <Chip
                className="w-auto ml-2"
                sx={{ backgroundColor: '#ADD8E6' }} // Choose a suitable color for the scope chip
                label={'Cradle-to-gate'} // Display the scope label as chip
              />
            ) : null}
            {displayChipFlag ? chipGenerator() : ''}
            {/* Chips for Min, Max, Common */}
            {cO2Values.type === 'min' && (
              <Chip
                className="w-auto ml-2"
                sx={{
                  backgroundColor: '#F5F5DC',
                }}
                label={t('singleEntity.min')}
              />
            )}
            {cO2Values.type === 'max' && (
              <Chip
                className="w-auto ml-2"
                sx={{
                  backgroundColor: '#FFB6C1',
                }}
                label={t('singleEntity.max')}
              />
            )}
            {cO2Values.type === 'common' && (
              <Chip
                className="w-auto ml-2"
                sx={{
                  backgroundColor: '#FFD700',
                }}
                label={t('singleEntity.card.common')}
              />
            )}
          </div>
        </div>
      </CardContent>
    </Paper>
  )
}

export default SingleEntityCard
