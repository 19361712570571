import { downloadProcessedFile, injectApiDataIntoWorkbook } from "config/services/MassUpload/download";
import { select, takeLeading } from "redux-saga/effects";
import { massFileUploadActionTypes } from "saga/actions/massFileUpload";
import { massFileUploadReducerState } from "store/slices/massFileUploadSlice";
import { iMassFileUploadState } from "store/types/massFileUpload";

function* downloadProcessedWorkbookSaga(payload: any) {
  /** Retrieve state */
  let state: iMassFileUploadState = yield select(massFileUploadReducerState);
  let fileName = payload.payload;
  let workbooks = state.processedWorkbooks;
  let contexts = state.fileOutputCollection;

  /** Inject  information retrieved from the search API */
  let processedWorkbook = injectApiDataIntoWorkbook(fileName, workbooks, contexts);

  /** Download the actual file */
  downloadProcessedFile(fileName, processedWorkbook);
}

export default function* downloadProcessedWorkbookSagaHook() {
  yield takeLeading(
    massFileUploadActionTypes.SAGA_DOWNLOAD_WORKBOOK,
    downloadProcessedWorkbookSaga
  );
}