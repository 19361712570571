import React from 'react'
import { Paper, SxProps } from '@mui/material'

interface iProps {
  children: string | React.ReactNode | JSX.Element | JSX.Element[]
  className?: string
  sx?: SxProps
  elevation?: number
}

const PaperWrapper = ({
  children,
  className = 'p-4 min-w-fit',
  sx,
  elevation = 2,
}: iProps) => {
  return (
    <Paper elevation={elevation} className={className} sx={sx}>
      {children}
    </Paper>
  )
}

export default PaperWrapper
