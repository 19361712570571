import { ContentBody } from 'components/pages/components/ContentBody'
import { componentPermissions } from 'config/permissions'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RoutePaths } from 'routes/paths'
import { componentNames } from '../../config/roles'

const Scope12Calculator: FC = () => {
  const { t } = useTranslation()

  return <ContentBody title={t('scope12Calculator.title')}></ContentBody>
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.scope12Calculator,
    element: <Scope12Calculator />,
    title: 'scope12Calculator.title',
    roles: componentPermissions[componentNames.pageToolsScope12Calculator],
    requiresAuthentication: true,
  },
  name: 'Scope12Calculator',
}
export default exportObj
