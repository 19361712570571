import { call, put, select, takeLeading } from 'redux-saga/effects'
import apiRequests from 'config/services'
import { userTypes } from 'saga/actions/user'
import { setUserData, userReducerState } from 'store/slices/user'
import { getLocal } from 'config/lib'
import { userState } from 'store/types'

export function* setUserCurrencyProfileDataSaga(action: any) {
  try {
    let userInfo: userState = yield select(userReducerState)

    const selectedCurrency: string = action.payload
    const newUserInfo = JSON.parse(JSON.stringify(userInfo))
    newUserInfo.settings.currency = selectedCurrency

    yield call(apiRequests.setUserCurrency, selectedCurrency)
    yield put(setUserData(newUserInfo))
  } catch (e) {
        //catchind errors
  }
}

export function* setUserLanguageProfileDataSaga(action: any) {
  try {
    let userInfo: userState = yield select(userReducerState)

    const locale = getLocal('i18nextLng') || 'en'
    const newUserInfo = JSON.parse(JSON.stringify(userInfo))
    newUserInfo.settings.locale = locale
    yield call(apiRequests.setUserLanguage, locale)
    yield put(setUserData(newUserInfo))
  } catch (e) {
        //catchind errors
  }
}

export default function* setUserDataSagaHook() {
  yield takeLeading(
    userTypes.SAGA_SET_USER_CURRENCY_PROFILE_DATA,
    setUserCurrencyProfileDataSaga
  )
  yield takeLeading(
    userTypes.SAGA_SET_USER_LANGUAGE_PROFILE_DATA,
    setUserLanguageProfileDataSaga
  )
}
