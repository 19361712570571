import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mocks } from 'config/mock'
import { RootState } from 'store'
import { iMenuItemsState, iMenusType } from 'store/types/menuItems'

//get current reducer state
export const menuItemsReducerState = (state: RootState) => state.menuItems

export const initialState: iMenuItemsState = {
  menu: mocks.menuItems,
}

export const menuItemsReducer = createSlice({
  name: 'menuItemsReducer',
  initialState,
  reducers: {
    clearMenuItems: (state) => {
      //, action: PayloadAction<iMenusType[]>
      //   const allHubs: iMenusType[] = action.payload
      state.menu = []
    },
    setUserMenuItems: (state) => {
      state.menu = mocks.userMenuItems
    },
    setDefaultMenuItems: (state) => {
      state.menu = mocks.menuItems
    },
    addDataHubCategories(state, action: PayloadAction<iMenusType[]>) {
      const newStateMenu: iMenusType[] = JSON.parse(JSON.stringify(state.menu))
      const dynamicMenu: iMenusType[] = action.payload
      const dataHubMenuIndex = newStateMenu.findIndex(
        (item) => item.label === 'dataHub.title'
      )
      newStateMenu[dataHubMenuIndex].childPages = dynamicMenu

      state.menu = newStateMenu
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  clearMenuItems,
  addDataHubCategories,
  setUserMenuItems,
  setDefaultMenuItems,
} = menuItemsReducer.actions

export default menuItemsReducer.reducer
