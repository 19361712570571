import './FullHero.css'

interface IProps {
  imgSrc?: string
  opacity?: 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100
  bgColor?: 'green' | 'orange'
  children?: JSX.Element | JSX.Element[]
}

const FullHero = ({ imgSrc, opacity, bgColor, children }: IProps) => {
  const setBackgroundColor = (
    bgColor: string | undefined,
    opacity: number | undefined
  ): string => {
    let green = '27, 102, 0'
    let orange = '250, 149, 29'

    let color = !bgColor ? 'green' : bgColor === 'orange' ? orange : green

    let _opacity = !opacity ? 1 : (100 - opacity) / 100

    return `rgba(${color}, ${_opacity})`
  }

  return (
    <div
      className={`Full-Hero-main`}
      style={{
        backgroundImage: `url(${imgSrc})`,
      }}>
      <div className={`Full-Hero-children`}>{children}</div>

      <div
        className={`Full-Hero-opacity-filter`}
        style={{
          background: setBackgroundColor(bgColor, opacity),
        }}
      />
    </div>
  )
}

export default FullHero
