import { getAvailableProducts } from "config/services/requests/subscription/getAvailableProducts";
import { call, put, takeLeading } from "redux-saga/effects";
import { subscriptionOrderActionTypes } from "saga/actions/subscriptionOrder";
import { setProductOverview } from "store/slices/subscriptionOrderSlice";
import { iProductOverview } from "store/types/subscriptionOrder";

function* getAvailableProductsSaga() {
  const products: iProductOverview = yield call(getAvailableProducts)
  yield put(setProductOverview(products))
}

export default function* getAvailableProductsSagaHook() {
  yield takeLeading(
    subscriptionOrderActionTypes.SAGA_GET_AVAILABLE_PRODUCTS,
    getAvailableProductsSaga
  )
}