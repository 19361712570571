import React, { useCallback, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import CancelIcon from '@mui/icons-material/Cancel'

import './SingleEntityModal.css'
import SearchBar from 'components/custom/SearchBar'
import { useTranslation } from 'react-i18next'
import { Breadcrumbs, Typography } from '@mui/material'
import SingleEntityDetails from './SingleEntityDetails'
import { useDispatch, useSelector } from 'react-redux'
import { setVisibility } from 'store/slices/entityCO2Data'
import {
  eCo2Details,
  iCo2Emission,
  iCo2Value,
  iCo2SingleEntity,
} from 'store/types'
import { susIcons } from 'config/services/requests'
import { inArray, getUrlParamValue, urlToObject } from 'config/lib'
import SingleEntityCard from './SingleEntityCard'
import { useLocation, useSearchParams } from 'react-router-dom'
import { singleEntityState } from 'store/slices/singleEntity'
import { iLooseObject } from 'pages/DataHub/filters/types'
import ButtonWrapper from 'components/custom/ButtonWrapper'
import { eButtonVariant } from 'components/types'

interface iProps {
  baseCo2Values: iCo2Value[]
  recyclingCo2Values: iCo2Value[]
  emission: iCo2Emission
}

export default function SingleEntityModal({
  baseCo2Values,
  recyclingCo2Values,
  emission,
}: iProps) {
  function assignTypesToValues(values: iCo2Value[]): iCo2Value[] {
    if (!values || values.length === 0) return []

    // Find min and max reference values
    let minValue = values[0].referenceValue
    let maxValue = values[0].referenceValue

    values.forEach((value) => {
      if (value.referenceValue < minValue) {
        minValue = value.referenceValue
      }
      if (value.referenceValue > maxValue) {
        maxValue = value.referenceValue
      }
    })

    // Assign types by creating new objects
    return values.map((value) => {
      let type = '' // Default type

      if (value.isCommon) {
        type = 'common'
      } else if (value.referenceValue === minValue) {
        type = 'min'
      } else if (value.referenceValue === maxValue) {
        type = 'max'
      }

      return {
        ...value, // Spread the existing properties
        type, // Add or override the `type` property
      }
    })
  }
  // Example usage with baseCo2Values and recyclingCo2Values
  baseCo2Values = assignTypesToValues(emission?.co2Base?.co2ValueEntries)
  recyclingCo2Values = assignTypesToValues(
    emission?.co2Recycling?.co2ValueEntries
  )
  const cO2Values = baseCo2Values.concat(recyclingCo2Values || [])
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const urlPath = location.search
  const singleEntityParams: iLooseObject = urlToObject(urlPath)
  const [, setSearchParams] = useSearchParams()
  const [notFound, setNotFound] = useState(false)
  const [cardValues, setCardValues] = useState<iCo2Value[]>([])
  const singleEntity = useSelector(singleEntityState)?.singleEntity?.data
  const [searchInputValue, setSearchInputValue] = useState('')
  const formatDate = (timestamp: string) => {
    const date = new Date(timestamp)
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
    })
  }
  const validFrom = emission.co2Base.co2ValueEntries[0].validity
  const validTo = emission.co2Base.co2ValueEntries[0].validity

  const onSearch = useCallback(
    (searchTerm: string) => {
      const tempCardsValues = cO2Values.filter(
        (item: iCo2Value, index: number) => {
          return inArray(
            [
              item.addedOn,
              item.referenceValue.toString(),
              validFrom.toString(),
              validTo.toString(),
            ],
            searchTerm.split(' ')
          )
        }
      )

      setNotFound(tempCardsValues.length === 0)
      setCardValues(tempCardsValues)
      //set url param with search terms:
      singleEntityParams['search'] = searchTerm.split(' ').join(',')
      setSearchParams(singleEntityParams)
    },
    [cO2Values, validFrom, validTo, setSearchParams, singleEntityParams]
  )

  const id = getUrlParamValue(singleEntityParams, 'id') || ''
  const co2DataisVisible: boolean = !id

  // set data in the page from URL values
  useEffect(() => {
    const urlParamsMatch =
      singleEntityParams['country'] === emission.country &&
      singleEntityParams['validityFrom'] === validFrom &&
      singleEntityParams['validityTo'] !== validTo

    if (urlParamsMatch) {
      setCardValues(cO2Values)
    }
  }, [
    cO2Values,
    emission.country,
    validFrom,
    validTo,
    setCardValues,
    singleEntityParams,
  ])

  useEffect(() => {
    const emissionCountry = emission.country
    const emissionValidityFrom = validFrom
    const emissionValidityTo = validTo
    const searchTerms = getUrlParamValue(singleEntityParams, 'search') as string

    if (
      getUrlParamValue(singleEntityParams, 'country') === emissionCountry &&
      getUrlParamValue(singleEntityParams, 'validityFrom') ===
        emissionValidityFrom &&
      getUrlParamValue(singleEntityParams, 'validityTo') ===
        emissionValidityTo &&
      getUrlParamValue(singleEntityParams, 'search') !== searchInputValue
    ) {
      if (searchTerms) onSearch(searchTerms)
      setOpen(true)
    }
  }, [
    emission.country,
    validFrom,
    validTo,
    onSearch,
    searchInputValue,
    singleEntityParams,
  ])
  const DataPointIcon = susIcons.DataPoints

  const handleClickOpen = useCallback(() => {
    singleEntityParams['country'] = emission.country
    singleEntityParams['validityFrom'] = validFrom
    singleEntityParams['validityTo'] = validTo
    setSearchParams(singleEntityParams)
    setOpen(true)
    setCardValues(cO2Values)
  }, [
    cO2Values,
    emission.country,
    validFrom,
    validTo,
    setSearchParams,
    singleEntityParams,
  ])

  const handleClose = useCallback(() => {
    delete singleEntityParams['country']
    delete singleEntityParams['validityFrom']
    delete singleEntityParams['validityTo']
    delete singleEntityParams['search']
    delete singleEntityParams['type']
    setSearchParams(singleEntityParams)
    setOpen(false)
    setNotFound(false)
    dispatch(setVisibility(false))
  }, [dispatch, setSearchParams, singleEntityParams])

  const onCO2Click = useCallback(() => {
    onSearch('')

    delete singleEntityParams['id']
    delete singleEntityParams['type']
    delete singleEntityParams['search']
    setSearchParams(singleEntityParams)
  }, [onSearch, setSearchParams, singleEntityParams])

  const valueType = getUrlParamValue(singleEntityParams, 'type')

  const getModalTitle = useCallback(() => {
    switch (valueType) {
      case eCo2Details.process:
        return t('singleEntity.card.process')
      case eCo2Details.sources:
        return t('singleEntity.card.externalSources')
      default:
        return t('singleEntity.CO2Values.titleModal')
    }
  }, [t, valueType])

  const getSearchBar = useCallback(() => {
    return (
      <SearchBar
        placeholderProp={t('general.search')}
        onSearch={(value) => {
          onSearch(value || '')
          setSearchInputValue(value || '')
        }}
        customValue={getUrlParamValue(singleEntityParams, 'search') || ''}
        label={t('general.searchTopicInput', {
          topic: t('singleEntity.title'),
        })}
      />
    )
  }, [onSearch, singleEntityParams, t])

  return (
    <React.Fragment>
      <div>
        <ButtonWrapper
          className="flex self-center"
          variant={eButtonVariant.outlined}
          onClick={handleClickOpen}
        >
          {t('singleEntity.showCO2Values')}
        </ButtonWrapper>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={false}
        open={open}
        className="singleEntity-container"
        onClose={handleClose}
      >
        <DialogActions>
          <CancelIcon className="cursor-pointer" onClick={handleClose} />
        </DialogActions>
        <DialogContent className="pt-0">
          <DialogContentText component={'div'}>
            <div className="breadcrumb-container">
              <h2>{getModalTitle()}</h2>
              <Breadcrumbs
                separator=">"
                aria-label="breadcrumb"
                className="breadcrumb pl-2"
                sx={{ marginBottom: '2.1875rem' }}
              >
                <span className="cursor-pointer" onClick={() => handleClose()}>
                  {singleEntity?.information?.identification?.title}
                </span>

                <span className="cursor-pointer" onClick={() => onCO2Click()}>
                  {t('singleEntity.CO2Values.titleModal')}
                </span>

                {valueType && (
                  <span className="cursor-pointer">
                    {valueType === eCo2Details.sources &&
                      t('singleEntity.card.externalSources')}
                    {valueType === eCo2Details.process &&
                      t('singleEntity.card.process')}
                  </span>
                )}
              </Breadcrumbs>
              <div className="ml-4">
                {co2DataisVisible ? getSearchBar() : null}
              </div>
            </div>
            {[eCo2Details.sources, eCo2Details.process].indexOf(
              getUrlParamValue(singleEntityParams, 'type')
            ) !== -1 ? (
              <SingleEntityDetails cO2Values={cO2Values} />
            ) : notFound ? (
              <div className="notFound-layout">
                <Typography variant="h4">{t('search.noResults')}</Typography>
                <DataPointIcon />
              </div>
            ) : (
              <div className="flex flex-wrap justify-start gap-x-20 gap-y-3.5 pt-7 mx-4 mb-4">
                {cardValues.map((cardValue, index: number) => (
                  <SingleEntityCard
                    cO2Emission={emission}
                    cO2Values={cardValue}
                    emissionFactorUnitSymbol={emission.emissionFactorUnitSymbol}
                    key={index}
                  />
                ))}
              </div>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
