import React from 'react'
import { ContentBody } from 'components/pages/components/ContentBody'

import PaperWrapper from 'components/custom/PaperWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
import TextWrapper from 'components/custom/TextWrapper'
import LinkWrapper from 'components/custom/LinkWrapper'
import { eLinkTarget, eTypographyVariant } from 'components/types'

export const InprintDocumentGerman = () => {
  const year = new Date().getFullYear()
  const headingClassName = 'pt-4 pb-2 text-paletteOrange uppercase'

  return (
    <ContentBody title="sustamize Impressum">
      <PaperWrapper>
        <TitleWrapper variant={eTypographyVariant.h5} className={headingClassName}>
          Unternehmen Information
        </TitleWrapper>
        <TextWrapper>sustamize GmbH</TextWrapper>
        <TextWrapper>Konrad-Zuse-Platz 8</TextWrapper>
        <TextWrapper>81829 München</TextWrapper>
        <TextWrapper>E-Mail: hallo@sustamize.com</TextWrapper>
        <TextWrapper>Webseite: www.sustamize.com</TextWrapper>
        <TextWrapper>Telefonnummer: +49 (0) 89 954573702</TextWrapper>
        <TextWrapper className="mt-6">
          vertreten durch den Geschäftsführer Michael Kochs
        </TextWrapper>
        <TextWrapper className="mb-6">
          Umsatzsteueridentifikationsnummer: "beantragt"
        </TextWrapper>
        <TitleWrapper variant={eTypographyVariant.h5} className={headingClassName}>
          Zusätzliche Informationen
        </TitleWrapper>
        <TextWrapper>
          Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO: Die Europäische
          Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
          bereit, die Sie unter
          <LinkWrapper
            to="http://ec.europa.eu/consumers/odr/finden.Widerspruchsbelehrung"
            target={eLinkTarget.blank}
          >
            http://ec.europa.eu/consumers/odr/finden.Widerspruchsbelehrung
          </LinkWrapper>{' '}
          nach § 355 BGBSie haben das Recht, binnen vierzehn Tagen ohne Angabe
          von Gründen diesen Vertrag/Ihre Spende zu widerrufen. Die
          Widerrufsfrist beträgt vierzehn Tage ab dem Tag an dem Sie oder ein
          von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren
          in Besitz genommen haben bzw. hat. Um Ihr Widerrufsrecht auszuüben,
          müssen Sie uns (sustamize GmbH i.G., Michael Kochs, Konrad-Zuse-Platz
          8, 81829 München, hallo@sustamize.com) mittels einer eindeutigen
          Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über
          Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Machen
          Sie von dieser Möglichkeit Gebrauch, so werden wir Ihnen unverzüglich
          (z.B. per E-Mail) eine Bestätigung über den Eingang eines solchen
          Widerrufs übermitteln. Zur Wahrung der Widerrufsfrist reicht es aus,
          dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor
          Ablauf der Widerrufsfrist absenden.
        </TextWrapper>
        <TextWrapper>
          Alle Bilder, Grafiken, Screenshots: © {year} sustamize GmbH i.G.
        </TextWrapper>
      </PaperWrapper>
    </ContentBody>
  )
}
