import { iSubscriptionOrder } from "store/types/subscriptionOrder";
import { BASE_API } from "../urlPaths";
import { call } from "redux-saga/effects";
import reqProvider from '../coreRequests';
import { AxiosResponse } from "axios";

export function* submitSubscriptionConfiguration(config: iSubscriptionOrder) {
  const path = `${BASE_API}/subscription/submit`
  const response: AxiosResponse<unknown> = yield call(reqProvider.postReq, path, undefined, config)
  return response
}