import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { iHubCategoriesState } from 'store/types'
// for parametered slices
// import type { PayloadAction } from '@reduxjs/toolkit'

export const initialState: iHubCategoriesState = {
  categories: [],
}

//get current reducer state
export const dataHubCategoriesReducerState = (state: RootState) =>
  state.dataHubCategories

//reducer Object
export const dataHubCategoriesReducer = createSlice({
  name: 'dataHubCategoriesReducer',
  initialState,
  reducers: {
    setHubCategories: (state, action: PayloadAction<string[]>) => {
      const allHubcategories: string[] = action.payload
      state.categories = allHubcategories
    },
    clearHubCategories: (state) => {
      state.categories = []
    },
  },
})

// Action creators are generated for each case reducer function
export const { setHubCategories,clearHubCategories } = dataHubCategoriesReducer.actions

export default dataHubCategoriesReducer.reducer
