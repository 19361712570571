import React from 'react'
import { ContentBody } from 'components/pages/components/ContentBody'
import PaperWrapper from 'components/custom/PaperWrapper'
import TitleWrapper from 'components/custom/TitleWrapper'
import TextWrapper from 'components/custom/TextWrapper'
import { eTypographyVariant } from 'components/types'
import { Link } from 'react-router-dom'
import { assets } from 'config/services/requests'

export const DataAsServiceAggrementGerman = () => {
  const headingClassName = 'pt-4 pb-2 text-paletteOrange uppercase '
  const lowerAlphaListClassName = 'list-[none] list-inside ml-4'

  return (
    <ContentBody title="SUSTAMIZER – DATA-AS-A-SERVICE AGREEMENT (DaaSA)">
      <PaperWrapper>
        <Link
          to={assets.dataAsAServiceAgreementPdf}
          rel="noreferrer"
          target='_blank'
          className={`${headingClassName} flex justify-end text-right underline`}
        >
        Herunterladen
        </Link>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          PRÄAMBEL
        </TitleWrapper>
        <TextWrapper>
          <strong>
            Dieses Data-as-a-Service Agreement (im Folgenden: „DaaSA“) gilt für
            alle Vertragsverhältnisse, in denen ein Lizenznehmer als Endnutzer
            durch Vertrag mit dem Lizenzgeber eine Lizenz zur Nutzung der
            sustamizeDaten und -Datenbanken erwirbt.
          </strong>
        </TextWrapper>
        <TextWrapper>
          <strong>
            Der Zugang zu den gesetzlich geschützten sustamize-Datenbanken auf
            sustamizer.com oder über eine von sustamize bereitgestellte
            Schnittstelle (API) ist nur bei uneingeschränkter Akzeptanz
            nachfolgender Vertragsbedingungen erlaubt.
          </strong>
        </TextWrapper>
        <TextWrapper>
          <strong>
            Dieses DaaSA ist in deutscher und englischer Sprache ausgefertigt.
            Für die rechtlichen Wirkungen zwischen den Parteien ist allein die
            deutsche Fassung maßgeblich.
          </strong>
        </TextWrapper>
        <TextWrapper>
          MIT ERSTMALIGER NUTZUNG DER SUSTAMIZE-DATENBANKEN AUF SUSTAMIZER.COM
          ERKENNT DER LIZENZNEHMER DIE GÜLTIGKEIT UND VERBINDLICHKEIT DIESES
          DATA-AS-A-SERVICE-AGREEMENT VORBEHALTLOS AN.
        </TextWrapper>
        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 1 Begriffsbestimmungen
        </TitleWrapper>
        <TextWrapper>
          Die in diesem DaaSA verwendeten Begriffe haben die folgenden
          Bedeutungen:
        </TextWrapper>
        <TextWrapper>
          <strong>Arbeitsergebnis</strong> ist der vom Lizenzgeber erarbeitete
          konkrete CO2-Fußabdruck eines Produkts und/oder Unternehmens (z. B. 10
          kg CO2e / kg eines Produkts).
        </TextWrapper>
        <TextWrapper>
          <strong>Lizenzgeber</strong> ist die sustamize GmbH, eine Gesellschaft
          deutschen Rechts mit Sitz in München, eingetragen unter HRB 277048.
        </TextWrapper>
        <TextWrapper>
          <strong>Lizenznehmer</strong> ist ein Unternehmer iSv. § 14 BGB, der
          die sustamize-Datenbanken als Endnutzer zum Zwecke von
          Nachhaltigkeitsbewertungen nutzt.
        </TextWrapper>

        <TextWrapper>
          <strong>Named-User-Lizenz</strong> beschreibt die Lizenzierung der
          vertragsgegenständlichen sustamize-Daten und Datenbanken über den
          Sustamizer auf Basis registrierter, namentlich erfasster natürlicher
          Personen.
        </TextWrapper>
        <TextWrapper>
          <strong>Sustamizer</strong> ist ein webbasiertes Softwaretool des
          Lizenzgebers, durch das der Lizenznehmer innerhalb des jeweils durch
          Vertrag festgelegten Umfangs Zugang zu den sustamize-Daten und
          sustamize-Datenbanken erhält.
        </TextWrapper>
        <TextWrapper>
          <strong>sustamize-Datenbanken</strong> sind rechtlich geschützte
          Datenbanken des Lizenzgebers mit für die Erstellung eines
          CO2-Fußabdruckes von Produkten und Unternehmen und sonstigen in
          Zusammenhang mit CO2-Bilanz, Dekarbonisierung, LCA (Life Cycle
          Assessment) relevanten sustamize-Daten und Berechnungslogiken.
        </TextWrapper>
        <TextWrapper>
          <strong>sustamize-Daten</strong> sind die einzelnen Daten und
          Datensätze der sustamize-Datenbanken, einschließlich von Rohdaten,
          Berechnungslogiken, Arbeitsergebnissen und sonstigen Informationen der
          Lizenzgeberin. Bei den vertragsgegenständlichen sustamize-Daten
          handelt es sich um nicht-personenbezogene Daten zu Umwelt-Emissionen,
          insbesondere Treibhausgasen (gemessen in CO2-Äquivalenten) und
          Ressourcenverbräuchen (gemessen in Materialintensitäten), die im Laufe
          der Erzeugung, Herstellung, Nutzung, Verschrottung, der
          Weiterverwertung oder des Recyclings von Stoffen und/oder
          Dienstleistungen entstehen.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 2 Geltungsbereich DaaSA, Vertragsgegen-stand
        </TitleWrapper>
        <TextWrapper>
          (1) Der Lizenzgeber stellt zu den Bedingungen dieses DaaSA dem
          Lizenznehmer einen Online Lesezugriff auf seine sustamize-Datenbanken
          zur Verfügung und räumt hierbei dem Lizenznehmer entgeltliche und
          zeitlich befristete Datenlizenzen an den sustamize-Daten ein („
          <strong>Data-as-a-Service</strong>“).
        </TextWrapper>
        <TextWrapper>
          (2) Der Umfang der zur Verfügung gestellten sustamize-Daten ergibt
          sich aus dem Einzelvertrag/-bestellung, einem Rahmenvertrag oder
          sonstigen den Vertragsschluss begleitenden Unterlagen, (im Folgenden
          jeweils: „vertragsgegenständlich“) mit dem Lizenznehmer. Soweit dort
          zwischen den Parten ausdrücklich vereinbart, erbringt der Lizenzgeber
          an den Lizenznehmer auch Schulungs- und Beratungsleistungen und/oder
          sonstige Zusatzleistungen jeweils gegen gesondertes Entgelt.
        </TextWrapper>
        <TextWrapper>
          (3) Es gelten ergänzend die Allgemeinen Geschäftsbedingungen von
          sustamize in der jeweils bei Vertragsschluss gültigen Version
          (abrufbar unter: https://www.sustamize.com/terms-conditions), soweit
          dieses DaaSA keine davon abweichende Regelung enthält.
        </TextWrapper>
        <TextWrapper>
          (4) Allgemeine Geschäftsbedingungen des Lizenznehmers finden keine
          Anwendung. Dies gilt auch dann, wenn solchen Regelungen nicht
          ausdrücklich widersprochen wird.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 3 Leistungsbeschreibung, Service Level
        </TitleWrapper>
        <TextWrapper>
          (1) Die Berechnungslogiken für die Emissionsfaktoren in den
          sustamize-Datenbanken wurden nach den Anforderungen gemäß dem Standard
          „GHG Protocol – Product Life Cycle Accounting & Reporting Standard“
          (Ausgabe September 2011) entwickelt und nach den Vorgaben der ISO
          14064-3:2019-04 validiert (Konformitätsurkunde vom 23.01.2023 der GUT
          Certifizierungsgesellschaft für Managementsysteme mbH). Zur
          Qualitätssicherung werden interne QM-Maßnahmen durchgeführt.
        </TextWrapper>
        <TextWrapper>
          (2) Der Lizenzgeber hält die sustamize-Datenbanken nach bestem Bemühen
          auf dem aktuellen Wissenstand. Aufgrund des stetigen Wandels von
          Technik, Entwicklung und regulatorischem Umfeld kann der Lizenzgeber
          jedoch nicht die Aktualität, Fehlerfreiheit und Genauigkeit der
          sustamize-Daten zusagen.
        </TextWrapper>
        <TextWrapper>
          Dem Lizenzgeber obliegen darüber hinausgehend keine vertraglichen
          Pflichten zur Aktualisierung oder Verbesserung sowie keine Pflicht zur
          Verfügungstellung von neuen Daten (z. B. Updates, Upgrades) innerhalb
          bereits bestehender Datenlizenzen.
        </TextWrapper>
        <TextWrapper>
          (3) Der Lizenzgeber gewährleistet, dass die Erhebung der
          sustamize-Daten als auch die den Arbeitsergebnissen zugrundeliegende
          Berechnungslogik im Wesentlichen den Vorgaben des GHG Protocol
          entsprechen. Im Fall von Aktualisierungen des GHG Protocols
          verpflichtet sich der Lizenzgeber, diese innerhalb angemessener Zeit
          umzusetzen. Darüber hinausgehend übernimmt der Lizenzgeber keine
          Gewährleistung für die Aktualität, Fehlerfreiheit und Genauigkeit der
          sustamize-Daten.
        </TextWrapper>
        <TextWrapper>
          (4) Der Lizenzgeber übernimmt keinerlei Zusagen, Garantien oder
          Versprechen im Zusammenhang mit dem kommerziellen, rechtlichen oder
          sonstigen Verwendungszweck betreffend die vertragsgegenständlichen
          sustamize-DatenDie Lizenzgeber keinerlei Gewähr für etwaige Aussagen
          oder Maßnahmen kommerzieller oder rechtlicher Art, welche der
          Lizenznehmer aus der Nutzung der sustamize-Daten ableiten. Die
          Lizenzgeberin übernimmt insbesondere keine Gewähr dafür, dass die
          Verwendung der sustamize-Daten etwaigen gesetzlichen, behördlichen
          oder sonstigen Anforderungen im Hinblick auf die Deklarierung von
          Umwelt-Emissionen genügt (z. B. im Rahmen der Berichterstattung zu
          Corporate Social Responsibility).
        </TextWrapper>
        <TextWrapper>
          (5) Zur Verständlichkeit hat der Lizenznehmer über die Anwendung des
          Sustamizers „Wissenszentrum“ Zugang zu Informationen (z. B. über den
          Aufbau und das Zustandekommen der sustamize-Datenbanken). Das
          Wissenszentrum dient ausschließlich der Nutzerinformation zu den
          möglichen Anwendungen des Sustamizers und beinhaltet keine
          Gewährleistung, Garantie oder sonstige Zusage zur Datenqualität
          und/oder Funktionsumfang des Sustamizers.
        </TextWrapper>
        <TextWrapper>
          (6) Der Lizenzgeber stellt die sustamize-Daten über die Software
          Sustamizer verfügbar zu Arbeitstagen (Montag bis Freitag ohne
          bundesweite Feiertage) in der Zeit 9:00 bis 17:00 Uhr (MEZ) mit einem
          Service-Level von 95 % im Jahresmittel. Weitere Regelungen zum
          Service-Level-Agreement (SLA) sind unter § 9 vereinbart.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 4 Einräumung von Datenlizenzen, Nutzungsbeschränkungen
        </TitleWrapper>
        <TextWrapper>
          (1) Die sustamize-Datenbanken des Lizenzgebers sind rechtlich
          geschützt, insbesondere gemäß § 4 UrhG (Datenbankwerk) und §§ 87a ff.
          UrhG (Schutz des Datenbankherstellers). Der Lizenznehmer erkennt an,
          dass der Lizenzgeber erhebliche Investitionen und schöpferischen
          Aufwand für die Herstellung der sustamize-Datenbanken und der
          einzelnen sustamize-Daten aufgewendet hat und dass diese das Eigentum
          des Lizenzgebers sind.
        </TextWrapper>
        <TextWrapper>
          (2) Die in der Softwareanwendung Sustamizer enthaltenden
          sustamize-Datenbanken einschließlich der sustamize-Daten sind von
          erheblichem wirtschaftlichem Wert und unterliegen strengen
          Geheimhaltungsmaßnahmen. Die sustamize-Datenbanken einschließlich der
          sustamize-Daten und der eingesetzten Software sind vertrauliche
          Informationen und daher auch als Geschäftsgeheimnisse iSd. GeschGehG
          geschützt.
        </TextWrapper>
        <TextWrapper>
          (3) Der Lizenzgeber räumt dem Lizenznehmer jeweils durch
          Einzelbestellung oder Rahmenvertrag das Recht ein, die jeweils
          vertragsgegenständlichen sustamize-Daten sowie die
          sustamize-Datenbanken über den Zugang des Sustamizers im Rahmen eines
          Online-Lesezugriffs zum Zwecke von:
        </TextWrapper>
        <TextWrapper>
          Durchführung von Nachhaltigkeitsbewertungen (CO2-Fußabdrücke von
          Produkten, Materialien, Dienstleistungen, Unternehmen, Lieferanten;
          Ökobilanz)
        </TextWrapper>
        <TextWrapper>
          (im Folgenden: „<strong>Vertragszweck</strong>“) zu nutzen, dies unter
          folgenden Lizenzbedingungen:
        </TextWrapper>
        <TextWrapper>
          Das Recht zur kommerziellen Nutzung der sustamize-Daten sowie der
          sustamize-Datenbanken umfasst das nicht ausschließliche (einfache),
          zeitlich beschränkte, widerrufliche Recht,
        </TextWrapper>
        <div>
          <ol className={lowerAlphaListClassName}>
            <li>
              (a) die sustamize-Daten sowie die sustamize-Datenbanken im Rahmen
              eines Online-Lesezugriffs über den Sustamizer und dessen
              Software-Anwendungen innerhalb des durch Einzel-/Rahmenvertrag
              erworbenen Umfangs an Named-User-Lizenzen zu nutzen,
            </li>
            <li>
              (b) die Arbeitsergebnisse im Rahmen des Vertragszwecks frei zu
              verwenden, insbesondere zu speichern, zu vervielfältigen und
              weiterzugeben.
            </li>
          </ol>
        </div>
        <TextWrapper>
          (4) Weitergehende Rechte als unter vorstehendem Absatz 3 ausdrücklich
          eingeräumt, stehen dem Lizenznehmer nicht zu. Der Lizenznehmer ist
          insbesondere, aber nicht abschließend, nicht dazu berechtigt:
        </TextWrapper>
        <div>
          <ol className={lowerAlphaListClassName}>
            <li>
              (a) den Sustamizer, die sustamize-Datenbanken und die
              sustamize-Daten über den Umfang der eingeräumten Named-User-Lizenz
              hinaus zu nutzen (z. B. durch unzulässige Mehrfach-Nutzung eines
              Accounts);
            </li>
            <li>
              (b) die vertragsgegenständlichen sustamize-Daten oder die
              sustamize-Datenbanken zu veröffentlichen, zu verbreiten oder
              anderweitig gegenüber Dritten offen zu legen in einem Umfang, der
              unwesentliche Mengen überschreitet; unberührt bleiben zwingende
              urheberrechtliche Schrankenbestimmungen.
            </li>
            <li>
              (c) die sustamize-Daten oder die sustamize-Datenbanken zu
              verkaufen, zu vermieten, zu verleasen, zu verleihen, zu verteilen,
              als Vermittler oder Anbieter zu agieren, oder Dritten jegliche Art
              von Lizenzrechten in Bezug auf die sustamize-Daten zu gewähren
              oder irgendwelche Teile davon;
            </li>
            <li>
              (d) Rechte an den vertragsgegenständlichen sustamize-Daten oder
              den sustamize-Datenbanken oder Teilen davon abzutreten oder
              anderweitig zu übertragen auf Dritte (z. B. als Sub- oder
              Unterlizenz);
            </li>
            <li>
              (e) Reverse Assembling, Reverse Compiling, Back Calculation,
              Decompiling, Disassembling, Hacking oder anderes Reverse
              Engineering durchzuführen, zu veranlassen, zu erlauben oder zu
              autorisieren oder das Extrahieren von sustamize-Daten; unberührt
              bleiben zwingende urheberrechtliche Schrankenbestimmungen;
            </li>
            <li>
              (f) die sustamize-Daten dynamisch zu veröffentlichen oder
              anderweitig offenzulegen durch oder integriert in ein Softwaretool
              (z. B. Anwendung) für den internen Gebrauch oder zur externen
              Nutzung durch Dritte;
            </li>
            <li>
              (g) durch irgendeine sonstige Handlung die Nutzungsbeschränkungen
              dieses DaaSA zu umgehen, oder dies zu versuchen.
            </li>
          </ol>
        </div>
        <TextWrapper>
          (5) Dem Lizenznehmer ist es weiterhin strikt untersagt, die
          sustamize-Daten des Lizenzgebers zur Erstellung einer Datenbank oder
          eines sonstigen Werks zu verwenden, die mit den sustamize-Datenbanken
          vergleichbar sind oder sonst in Wettbewerb stehen (z. B. durch
          unzulässigen Einsatz von Screen-Scraping-Techniken oder mittels
          sonstiger vertragswidriger Datenextraktionen).
        </TextWrapper>
        <TextWrapper>
          (6) Die indirekte Nutzung (z. B. Auslösen von Verarbeitungsaktivitäten
          durch Softwarekomponenten, die von Dritten stammen mittels
          Schnittstelle) des Sustamizer, der sustamize-Datenbanken und -Daten
          ist untersagt. Dies betrifft auch die Verwendung der sustamize-Daten
          und Datenbanken im Rahmen des Trainings von Anwendungen künstlicher
          Intelligenz. Der Lizenzgeber behält sich die Nutzung der
          sustamize-Daten zum Text und Data Mining vor (§ 44b Abs. 3 UrhG).
        </TextWrapper>
        <TextWrapper>
          (7) Der Lizenznehmer ist berechtigt, soweit nicht anderweitig
          vereinbart, insbesondere zum Zwecke der Eigenwerbung den Titel/Namen
          der vertragsgegenständlichen sustamize-Datenbanken sowie
          Namen/Kennzeichen/Logos/Abbildungen des Lizenzgebers zu benutzen.
        </TextWrapper>
        <TextWrapper>
          (8) Der Lizenzgeber behält sich sämtliche Rechte an Know-How und
          Geschäftsgeheimnissen, sämtliche Urheberrechte, gewerbliche
          Schutzrechte (z. B. Marken, Patente), Namens- und sonstige Rechte aus
          geistigem Eigentum ausdrücklich vor. Rechte werden dem Lizenznehmer
          ausschließlich in dem Umfang dieses DaaSA eingeräumt, soweit nicht
          durch Einzel- oder Rahmenvertrag zwischen den Parteien anderweitig
          vereinbart.
        </TextWrapper>
        <TextWrapper>
          (9) Die Rechteeinräumungen an den Lizenznehmer werden gemäß § 158 Abs.
          1 BGB erst wirksam, wenn der Lizenznehmer die jeweils vertraglich
          geschuldeten Lizenzgebühren (sh. § 10 DaaSA) vollständig und
          vorbehaltlos gezahlt hat. Der Lizenzgeber kann eine Benutzung der
          vertragsgegenständlichen sustamize-Daten sowie sustomize-Datenbanken
          auch schon vor diesem Zeitpunkt vorläufig erlauben, eine Einräumung
          von Nutzungsrechten findet durch eine solche vorläufige Erlaubnis
          jedoch nicht statt.
        </TextWrapper>
        <TextWrapper>
          (10) Im Hinblick auf etwaig von dieser Nutzungsrechtseinräumung nicht
          erfasste Nutzungsarten ist der Lizenzgeber dem Lizenznehmer
          grundsätzlich gegen zusätzliche Vergütung zur Einräumung
          weitergehender Rechte in dem jeweiligen Einzel-/Rahmenvertrag bereit,
          dies unter dem Vorbehalt der Vereinbarkeit mit den geschäftlichen
          Interessen des Lizenzgebers.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 5 Geistiges Eigentum, Rechtspositionen an Daten
        </TitleWrapper>
        <TextWrapper>
          (1) Soweit durch die Erhebung, Zusammenstellung, Speicherung,
          Aufbereitung oder sonstigen Verarbeitung von sustamize-Daten durch den
          Lizenzgeber urheberrechtliche oder sonstige Rechtspositionen
          entstehen, stehen diese Rechte ausschließlich dem Lizenzgeber zu. Dies
          gilt insbesondere, aber nicht ausschließlich, für die Rechte als
          Datenbankhersteller nach dem Urheberrechtsgesetz.
        </TextWrapper>
        <TextWrapper>
          (2) Der faktisch-technische Zugriff des Lizenznehmers auf die Daten
          des Lizenzgebers eröffnet dem Lizenznehmer keine einem Eigentümer
          ähnlichen oder vergleichbaren Stellung und/oder keine ausschließlichen
          Nutzungsrechte im Hinblick auf die sustamize-Daten.
        </TextWrapper>
        <TextWrapper>
          (3) Soweit der Lizenznehmer die sustamize-Daten entgegen den
          Bestimmungen von § 4 (Einräumung von Datenlizenzen,
          Nutzungsbeschränkungen) vertragswidrig bearbeitet und dadurch neue
          oder veränderte Datenbanken oder sonstige Werke schafft, erhält der
          Lizenzgeber an diesen Datenbanken und/oder sonstigen Werken eine
          kostenlose, zeitlich unbeschränkte und ausschließliche Rücklizenz mit
          dem Recht zur eigenen exklusiven Verwertung durch den Lizenzgeber.
          Etwaige Ansprüche des Lizenzgebers auf Herausgabe von Daten und/oder
          vertragswidrig erzielten Arbeitsergebnissen durch den Lizenznehmer und
          sonstige vertragliche und/oder gesetzliche Ansprüche des Lizenzgebers
          bleiben hiervon unberührt.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 6 Datensicherheit, Datenschutz
        </TitleWrapper>
        <TextWrapper>
          (1) Die Parteien verpflichten sich, alle erforderlichen und üblichen
          Maßnahmen zur Gewährleistung der Datensicherheit zu treffen. Die
          Parteien stellen durch technische, organisatorische und sonstige nach
          Stand der Technik erforderlichen Maßnahmen sicher, dass die Daten der
          jeweils anderen Partei jederzeit vor dem unzulässigem Zugriff Dritter
          geschützt sind.
        </TextWrapper>
        <TextWrapper>
          (2) Die Parteien beachten die einschlägigen gesetzlichen Vorschriften
          (DSGVO u. a.), soweit anwendbar.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 7 Leistungen des Lizenzgebers
        </TitleWrapper>
        <TextWrapper>
          (1) Der Lizenzgeber stellt dem Lizenznehmer die
          vertragsgegenständlichen sustamize-Daten sowie die
          sustamize-Datenbanken durch das webbasierte Softwaretool „sustamizer“
          im Rahmen eines Online-Lesezugriffs zur Verfügung. Dem Lizenznehmer
          wird ermöglicht, die auf den Servern eines vom Lizenzgeber
          beauftragten Dienstleisters gespeicherte und ablaufende Software über
          eine Internetverbindung während der Laufzeit dieses DaaSA im Rahmen
          des Vertragszwecks (§ 4 Abs. 3) zu nutzen.
        </TextWrapper>
        <TextWrapper>
          (2) Der Lizenzgeber stellt dem Lizenznehmer die den Zugriff auf den
          sustamizer am Routerausgang des Rechenzentrums, in dem der Server mit
          der Software steht, in das allgemeine Internet ("
          <strong>Übergabepunkt</strong>"), zur Nutzung bereit. Der Lizenzgeber
          schuldet jedoch nicht die Herstellung und Aufrechterhaltung der
          Datenverbindung zwischen den IT-Systemen des Lizenznehmers und dem
          beschriebenen Übergabepunkt. Der Lizenznehmer trägt die alleinige
          Verantwortung für die Funktionsfähigkeit seines Internet-Zugangs
          einschließlich der Übertragungswege sowie seines eigenen
          Computernetzwerks. Soweit zwischen den Parteien gesondert vereinbart,
          kann die Bereitstellung der Daten auch über eine vom Lizenzgeber
          bereitgestellte Schnittstelle erfolgen; das Vorstehende gilt in diesem
          Fall entsprechend.
        </TextWrapper>
        <TextWrapper>
          (3) Der Lizenzgeber hat bei Verwendung der sustamize-Daten durch den
          Lizenznehmer gegenüber Dritten (z. B. Werbung, Marketing) – soweit
          diese Verwendung dem Lizenznehmer vertraglich gestattet ist – Anspruch
          auf Nennung seines Firmennamens als Quelle (z. B. „Quelle:
          sustamize-Datenbanken“). Soweit durch den Lizenzgeber Kennzeichnungen
          oder Rechtevorbehalte erfolgen, insbesondere Urheberrechtsvermerke,
          Marken, Seriennummern oder Ähnliches, dürfen diese ohne Zustimmung des
          Lizenzgebers nicht entfernt, verändert oder unkenntlich gemacht
          werden.
        </TextWrapper>
        <TextWrapper>
          (4) Der Lizenzgeber ist berechtigt, das Leistungsangebot des
          Sustamizers (z. B. Struktur der Datenbank, Inhalte und
          Benutzeroberfläche) zu ändern, wenn und soweit hierdurch die Erfüllung
          des Vertragszwecks des Lizenznehmers (sh. § 4 Abs. 3) nicht erheblich
          sowie das Äquivalenzverhältnis des Vertrages nicht beeinträchtigt
          wird. Die Änderungen müssen im Hinblick auf die Aktualität des
          Leistungsangebots, der Bedienbarkeit, der Verfügbarkeit oder der
          angebotenen Inhalte für den Lizenzgeber erforderlich sein, um die
          Funktionalität oder die Konkurrenzfähigkeit des Datenbankangebots zu
          gewährleisten. Der Lizenznehmer wird über entsprechende Änderungen von
          der Lizenzgeberin rechtzeitig benachrichtigt.
        </TextWrapper>
        <TextWrapper>
          (5) Der Lizenznehmer ist zur Weiterführung einer vom Lizenzgeber
          eingeräumten Named-User-Lizenz durch Austausch eines Mitarbeiters
          berechtigt, wenn der/die betreffende namentlich benannte
          Mitarbeiter(in) an der Lizenzausübung aus organisatorischen Gründen,
          durch Ausscheiden aus dem Unternehmen, Elternzeit oder Tod gehindert
          ist.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 8 Pflichten des Lizenznehmers
        </TitleWrapper>
        <TextWrapper>
          (1) Der Lizenznehmer sorgt durch Bereithaltung der erforderlichen
          technischen Infrastruktur dafür, dass die überlassenen Daten über
          seinen Webbrowser ab dem Übergabepunkt (§ 7(2)) übertragen werden
          können. Der Lizenznehmer trägt die alleinige Verantwortung für die
          Funktionsfähigkeit seines Internet-Zugangs einschließlich der
          Übertragungswege sowie seines Computernetzwerks.
        </TextWrapper>
        <TextWrapper>
          (2) Die Nutzung der sustamize-Daten setzt eine Registrierung voraus,
          soweit nicht anders vereinbart. Der Lizenznehmer ist verpflichtet, die
          bei der Anmeldung abgefragten Daten richtig und vollständig
          mitzuteilen.
        </TextWrapper>
        <TextWrapper>
          (3) Der Lizenznehmer ist verpflichtet, den unbefugten Zugriff Dritter
          auf die geschützten Bereiche des Sustamizers durch geeignete
          Vorkehrungen zu verhindern. Zu diesem Zwecke wird der Lizenznehmer,
          soweit erforderlich, seine Mitarbeiter auf die Einhaltung des
          Urheberrechts hinweisen. Der Lizenznehmer führt regelmäßige
          Datensicherungen seiner eigenen Daten durch und setzt auf seinem
          Computernetzwerk ein Virenschutzprogramm in jeweils aktueller Version
          ein.
        </TextWrapper>
        <TextWrapper>
          (4) Der Lizenznehmer ist nicht berechtigt, die von dem Lizenzgeber
          verwendeten Computerprogramme des Sustamizers (z. B.
          Datenbanksoftware, Kalkulationsprogramme, Berechnungslogiken) zu
          verändern oder in irgendeiner Weise sonst zu manipulieren oder zu
          beeinflussen (z. B. durch Reverse Assembling, Reverse Compiling, Back
          Calculation, Decompiling, Disassembling, Hacking oder andere Reverse
          Engineering). Die Datenbankinhalte unterliegen strengen
          Geheimhaltungsmaßnahmen nach dem GeschGehG.
        </TextWrapper>
        <TextWrapper>
          (5) Bei Bestehen eines Rahmenvertrags informiert der Lizenznehmer den
          Lizenzgeber regelmäßig, mindestens quartalsweise, unaufgefordert über
          den Bestand an in Anspruch genommenen Named-User-Lizenzen und stellt
          dem Lizenzgeber auf Anforderung weitere Informationen (z. B.
          Auftragsbestätigungen) zur Verfügung; Dokumente können bei Bestehen
          von Geschäftsgeheimnissen geschwärzt werden.
        </TextWrapper>
        <TextWrapper>
          (6) Im Falle einer Übernutzung ist der Lizenznehmer verpflichtet, die
          Übernutzung unverzüglich dem Lizenzgeber mitzuteilen. Der Lizenzgeber
          hat gegenüber dem Lizenznehmer im Umfange der Übernutzung Anspruch auf
          Lizenzgebühren, wobei der Lizenzgeber wirtschaftlich so zu stellen
          ist, als wäre die Übernutzung vertragsgemäß entsprechend den
          Regelungen von § 10, § 11 vergütet. Der Lizenznehmer hat Auskunft zu
          erteilen über das Nutzungsvolumen der Übernutzung. Absatz 8 findet
          Anwendung. Etwaige weitergehende vertragliche und gesetzliche
          Ansprüche des Lizenzgebers bleiben unberührt.
        </TextWrapper>
        <TextWrapper>
          (7) Der Lizenznehmer wird den Lizenzgeber bei etwaig erforderlichen
          Maßnahmen gegen eine Verletzung von Rechten des Lizenzgebers durch
          Dritte (z. B. Verletzung von Urheberrechten, Datenbankrechten,
          Geschäftsgeheimnissen) angemessen unterstützen, insbesondere durch
          Erteilung von Information und Dokumentation zur Beweissicherung, wobei
          Geschäftsgeheimnisse des Lizenznehmers angemessen zu berücksichtigen
          sind.
        </TextWrapper>
        <TextWrapper>
          (8) Soweit Dritte gegenüber dem Lizenzgeber Ansprüche wegen
          Rechtsverletzungen geltend machen, die auf pflichtwidrige Handlungen
          und/oder Unterlassungen des Lizenznehmers zurückzuführen sind, stellt
          der Lizenznehmer den Lizenzgeber vollumfänglich von Ansprüchen Dritter
          frei. Der Freistellungsanspruch umfasst auch die Kosten der
          notwendigen außergerichtlichen und gerichtlichen Kosten inkl. der
          Kosten für etwaig erforderliche Sachverständigengutachten. Die
          Freistellung findet keine Anwendung, wenn der Lizenznehmer die
          Rechtsverletzung nicht zu vertreten hat.
        </TextWrapper>
        <TextWrapper>
          (9) Der Lizenznehmer wird alle auf die Nutzung der auf die
          sustamize-Daten anwendbaren gesetzlichen Vorschriften einhalten. Er
          wird alle erforderlichen Maßnahmen ergreifen, um Korruption sowie
          Verstöße gegen die Regeln des lauteren Wettbewerbs zu vermeiden. Die
          sustamize-Daten unterliegen auch strafrechtlichem Schutz; auf die
          Vorschriften des Strafgesetzbuches zum Schutz von Daten wird
          hingewiesen, insbesondere § 202a StGB (Ausspähen von Daten), § 202b
          StGB (Abfangen von Daten), § 303a StGB (Datenveränderung).
        </TextWrapper>
        <TextWrapper>
          (10) Der Lizenznehmer ist, soweit zwischen den Parteien nicht
          ausdrücklich anderweitig geregelt, nicht dazu berechtigt, die
          (Lizenz-)Rechte und Pflichten dieses DaaSA auf Dritte zu übertragen,
          Ansprüche aus diesem Vertrag abzutreten, zu verpfänden oder sonst mit
          dem Recht eines Dritten zu belasten. Dritte im Sinne dieser
          Bestimmungen sind auch verbundene Unternehmen des Lizenznehmers iSv. §
          15 AktG. Die Regelung unter § 7 Abs. 5 bleibt unberührt.
        </TextWrapper>
        <TextWrapper>
          (11) Der Lizenznehmer ist verpflichtet, mit den sustamize-Daten
          während der Vertragsdauer keine anderen Produkte und/oder
          Dienstleistungen mittel- oder unmittelbar herzustellen und zu
          vertreiben, die im Wettbewerb zu den sustamize-Datenbanken stehen.
          Wettbewerb in diesem Sinne ist insbesondere die Erstellung von
          Datenbanken mit den sustamize-Daten zur Durchführung von
          Nachhaltigkeitsbewertungen (CO2-Fußabdrücke von Produkten,
          Materialien, Dienstleistungen, Unternehmen, Lieferanten; Ökobilanz).
        </TextWrapper>
        <TextWrapper>
          (12) Gegen Forderungen des Lizenzgebers ist eine Aufrechnung und/oder
          Zurückbehaltung mit Gegenforderungen des Lizenznehmers nicht zulässig,
          es sei denn diese sind unbestritten oder rechtskräftig festgestellt.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 9 Service Level Agreement (SLA)
        </TitleWrapper>
        <TextWrapper>
          Die Leistungsbeschreibung (§ 3 DaaSA) zur zeitlichen und technischen
          Verfügbarkeit der sustamize-Daten wird wie folgt konkretisiert:
        </TextWrapper>
        <TextWrapper>
          (1) Die <strong>zeitliche Verfügbarkeit</strong> gemäß § 3(5) gibt den
          prozentualen Anteil des Zeitraums an, für den der Sustamizer am
          Übergabepunkt (sh. § 7(2)) zur Verfügung steht. Außerhalb dieses
          Zeitraums ist der Lizenzgeber nicht verpflichtet, die Software zur
          Nutzung bereit zu stellen. Bei der Berechnung der tatsächlichen
          Verfügbarkeiten gelten dem Lizenzgeber nicht zurechenbare
          Ausfallzeiten als verfügbare Zeiten. Diese unschädlichen Ausfallzeiten
          sind:
        </TextWrapper>
        <div>
          <ol className={lowerAlphaListClassName}>
            <li>
              (a) mit dem Lizenznehmer abgestimmte Wartungs- oder sonstige
              Leistungen, durch die ein Zugriff auf den Sustamizer nicht möglich
              ist;
            </li>
            <li>
              (b) unvorhergesehen erforderlich werdende Wartungsarbeiten, wenn
              diese Arbeiten nicht durch eine Verletzung der Pflichten des
              Lizenzgebers zum Erbringen der Services verursacht wurden (höhere
              Gewalt, insbesondere nicht vorhersehbare Hard-/Softwareausfälle,
              Streiks, Krieg, Pandemien, Naturereignisse etc.);
            </li>
            <li>
              (c) Ausfallzeiten aufgrund von Viren- oder Hackerangriffen, soweit
              der Lizenzgeber die vereinbarten, mangels Vereinbarung die
              üblichen Schutzmaßnahmen getroffen hat;
            </li>
            <li>
              (d) Ausfallzeiten aufgrund von Vorgaben des Lizenznehmers,
              aufgrund von Nichtverfügbarkeiten der Ausstattung des
              Lizenznehmers oder aufgrund anderer durch den Lizenznehmer
              verursachte Unterbrechungen (z. B. unterbleibende
              Mitwirkungsleistungen des Lizenznehmers);
            </li>
            <li>
              (e) Ausfallzeitverlängerungen, die aufgrund einer Blockierung des
              Konsolen- bzw. Remote-Zugangs durch den Lizenznehmer verursacht
              wurden;
            </li>
            <li>
              (f) Ausfallzeiten für das Einspielen von dringend notwendigen
              Security Patches;
            </li>
            <li>
              (g) Ausfallzeiten aufgrund von Software-Fehlern in Anwendungen des
              Lizenznehmers oder aufgrund von durch dessen Anwendungen oder
              -daten ausgelösten Fehlern in der System- und systemnahen
              Software;
            </li>
            <li>
              (h) Ausfallzeiten, die durch Dritte (nicht dem Lizenzgeber
              zurechenbare Personen) verursacht werden.
            </li>
          </ol>
        </div>
        <TextWrapper>
          (2) Die technische Verfügbarkeit bezieht sich auf die Verfügbarkeit
          der dem Lizenznehmer zur Nutzung angebotenen Software am Übergabepunkt
          § 7(2)). Beeinträchtigungen im Bereich der Datenübertragung von diesem
          Übergabepunkt zum Lizenznehmer und/oder im Bereich der IT-Anlage des
          Lizenznehmers selbst bleiben außer Betracht. Besondere Bestimmungen im
          Hinblick auf den Übergabepunkt des sustamizer oder - bei explizit
          vereinbarter Bereitstellung über eine Schnittstelle - die technischen
          Merkmale der vom Lizenzgeber bereitgestellten Schnittstelle, ergeben
          sich aus <strong>Anlage 1.</strong>
        </TextWrapper>
        <TextWrapper>
          (3) Der Lizenznehmer übernimmt es als Obliegenheit, Beeinträchtigungen
          der Verfügbarkeit (im Folgenden: „Störungen“) dem Lizenzgeber
          unverzüglich zu melden. Meldungen sind per E-mail an folgende Adresse
          zu richten: <strong>support@sustamize.com</strong>. Eine Bearbeitung
          eingehender Anfragen erfolgt zu den regulären Geschäftszeiten des
          Lizenzgebers (Mo-Fr (außer nationale Feiertage); 9-17 Uhr deutscher
          Zeit).
        </TextWrapper>
        <TextWrapper>
          (4) Störungen, die den Regelungen dieses SLA unterliegen, werden wie
          folgt klassifiziert:
        </TextWrapper>
        <div>
          <ol className={lowerAlphaListClassName}>
            <li>
              (a) <strong>Priorität 1:</strong> Störungen mit hoher Auswirkung
              oder großer Dringlichkeit, z. B. Störungen, bei denen der
              Lizenznehmer nicht in der Lage ist, den Sustamizer oder dessen
              grundlegende Funktionen zu nutzen. Störungen der Priorität 1
              können auch im Falle von Sicherheitslücken mit hohem Schweregrad
              vorliegen.
            </li>
            <li><br></br>
              Reaktionszeit = [24 Stunden]
            </li><br></br>
            
            <li>
              (b) <strong>Priorität 2:</strong> Störungen mit mittlerer
              Auswirkung oder mittlerer Dringlichkeit, z. B. wenn der
              Lizenznehmer trotz der Störung den Sustamizer teilweise nutzen
              kann, die wesentlichen Funktionen nutzbar sind, die Störung jedoch
              erhebliche Nutzungseinschränkungen verursacht und zusätzliche
              Arbeit und/oder Kosten erfordert. Störungen der Priorität 2 können
              auch bei Schwachstellen der Informationssicherheit mit mittlerem
              Schweregrad auftreten.{' '}
            </li>
            <li><br></br>
              Reaktionszeit = [72 Stunden]
            </li><br></br>
            
            <li>
              (c) <strong>Priorität 3:</strong> Störungen mit geringer
              Auswirkung oder geringer Dringlichkeit, z. B. wenn der
              Lizenznehmer trotz der Störung in der Lage ist, den Sustamizer zu
              nutzen, aber einige Funktionen nicht nutzbar sind, was zu geringen
              Nutzungseinschränkungen führt. Störungen der Priorität 3 können
              auch bei Schwachstellen in der Informationssicherheit mit einem
              geringen Schweregrad auftreten.
            </li>
            <li><br></br>
              Reaktionszeit = [96 Stunden]
            </li><br></br>
          </ol>
        </div>
        <TextWrapper>
          (5) Der Lizenzgeber wird sich bemühen, bei Eingang einer
          ordnungsgemäßen Störungsmeldung des Lizenznehmers spätestens innerhalb
          der Reaktionszeiten gemäß Absatz 4 mit der Analyse und möglichst schon
          mit der Beseitigung der Störung zu beginnen. Die Arbeiten zur
          Störungsbeseitigung erfolgen im Rahmen der Möglichkeiten des
          Lizenzgebers unter Beachtung seiner vertraglichen Pflichten. Ein
          Anspruch auf die Beseitigung der Störung innerhalb einer bestimmten
          Zeit folgt aus der Vereinbarung der Reaktionszeiten nicht. Ein
          Anspruch auf Wiederherstellung der Verfügbarkeit der Software besteht
          nicht, soweit die vereinbarte Verfügbarkeit gewährleistet ist.
        </TextWrapper>
        <TextWrapper>
          (6) Eine vom Lizenzgeber zu behebende Störung liegt nicht vor bei
          Beeinträchtigungen der Datenübertragung außerhalb des vom Lizenzgeber
          betriebenen Datennetzes, z. B. durch Leitungsausfall oder -störung bei
          anderen Providern oder Telekommunikationsanbietern, oder einer
          vertragswidrigen Inanspruchnahme der bereitgestellten
          Systemkapazitäten, z. B. durch eine überhöhte Zahl der Zugriffe durch
          den Lizenznehmer.
        </TextWrapper>
        <TextWrapper>
          (7) Meldet der Lizenznehmer dem Lizenzgeber eine Störung und stellt
          sich nach einer Prüfung heraus, dass die Störung nicht innerhalb des
          Datennetzes des Lizenzgebers aufgetreten ist oder aus sonstigen
          Gründen nicht von dem Lizenzgeber zu vertreten ist, kann der
          Lizenzgeber dem Lizenznehmer die zur Störungserkennung erbrachten
          Leistungen zu den für solche Leistungen geltenden Stundensätzen des
          Lizenzgebers in Rechnung stellen, es sei denn, der Lizenznehmer hätte
          auch bei Anstrengung der erforderlichen Sorgfalt nicht erkennen
          können, dass die Störung nicht innerhalb des Datennetzes des
          Lizenzgebers aufgetreten ist.
        </TextWrapper>
        <TextWrapper>
          (8) In folgenden Fällen ist der Lizenznehmer von der Entrichtung der
          Lizenzgebühren anteilig, d. h. pro rata temporis, befreit:
        </TextWrapper>
        <div>
          <ol className={lowerAlphaListClassName}>
            <li>
              (a) Für die Zeit, für die eine Störung der Priorität I vorliegt,
              ist der Lizenznehmer von der Entrichtung des vereinbarten Entgelts
              vollständig befreit.
            </li>
            <li>
              (b) Für die Zeit, für die eine Störung der Priorität II oder III
              vorliegt, ist der Lizenznehmer von der Entrichtung des Entgeltes
              in Höhe von 60 % befreit.
            </li>
          </ol>
        </div>
        <TextWrapper>
          (9) Im Übrigen finden die Regelungen von § 16Anwendung.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 10 Lizenzgebühren
        </TitleWrapper>
        <TextWrapper>
          (1) Die von dem Lizenznehmer zu zahlenden Lizenzgebühren richten sich
          nach der zum Zeitpunkt der Bestellung mit Einzelvertrag oder
          Rahmenvertrag gültigen Preisliste oder nach einer Vereinbarung in
          Textform zwischen Lizenzgeber und Lizenznehmer.
        </TextWrapper>
        <TextWrapper>
          (2) Die Vorschriften von §§ 313, 314 BGB bleiben unberührt.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 11 Zahlungsbedingungen
        </TitleWrapper>
        <TextWrapper>
          (1) Alle Zahlungen an den Lizenzgeber erfolgen in EURO und an die von
          dem Lizenzgeber in Textform bestimmte Bankverbindung.
        </TextWrapper>
        <TextWrapper>
          (2) Sofern die Parteien keine abweichende Vereinbarung treffen, sind
          sämtliche Zahlungen jeweils nach Abschluss eines Einzelvertrages oder,
          innerhalb eines Rahmenvertrags, mit Abruf einer Einzellizenz zu
          leisten.
        </TextWrapper>
        <TextWrapper>
          (3) Rechnungen des Lizenzgebers sind 15 Kalendertage nach
          Rechnungszugang zur Zahlung ohne Abzug fällig.
        </TextWrapper>
        <TextWrapper>
          (4) Alle Vergütungen verstehen sich zuzüglich der gesetzlichen
          Mehrwertsteuer, sofern der Lizenzgeber im Zahlungszeitpunkt der
          Umsatzsteuerpflicht unterliegt oder auf sie optiert hat. Entsteht die
          Umsatzsteuerpflicht oder die Option auf sie nachträglich, so kann die
          Mehrwertsteuer unter Vorlage der Mehrwertsteuerpflicht-Bescheinigung
          des zuständigen Finanzamtes gegen Rechnungsstellung nachgefordert
          werden.
        </TextWrapper>
        <TextWrapper>
          (5) Sofern der Lizenznehmer aufgrund anwendbarer Gesetze verpflichtet
          ist einen Teil des Nettopreises als Quellensteuer oder vergleichbare
          Abgabe an eine zuständige Finanzbehörde zu bezahlen, hat er dem
          Lizenznehmer zusätzlich zum übrig bleibenden Nettobetrag denjenigen
          Betrag zu bezahlen, der dem an die Finanzbehörde abzuführenden Betrag
          entspricht, so dass der Lizenzgeber in jedem Fall den vereinbarten
          Nettopreis erhält.
        </TextWrapper>
        <TextWrapper>
          Befindet sich der Lizenznehmer mit einer Zahlung trotz Mahnung in
          Verzug, kann der Lizenzgeber den weiteren Zugang zu dem Sustamizer
          sperren.
        </TextWrapper>
        <TextWrapper>
          (6) Der Lizenzgeber ist berechtigt, bei wiederholtem Zahlungsverzug
          des Lizenznehmers für die weitere Leistungserbringung Vorauszahlungen
          zu verlangen, deren Höhe von dem Lizenzgeber nach billigem Ermessen (§
          315 BGB) bestimmt werden kann.
        </TextWrapper>
        <TextWrapper>
          (7) Die gesetzlichen Vorschriften (z. B. §§ 320, 273 BGB) bleiben
          unberührt.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 12 Lizenzdauer
        </TitleWrapper>
        <TextWrapper>
          (1) Soweit nicht zwischen den Parteien schriftlich oder in Textform
          anderweitig vereinbart, sind die von dem Lizenzgeber innerhalb eines
          Einzel- oder Rahmenvertrags gewährten Datenlizenzen zeitlich befristet
          auf ein Jahr. Die Datenlizenzen verlängern sich automatisch um jeweils
          ein weiteres Jahr, sofern nicht eine Partei 30 Tage zuvor einer
          weiteren Verlängerung in Textformkündigt.
        </TextWrapper>
        <TextWrapper>
          (2) Dem Lizenzgeber steht ein Sonderkündigungsrecht zur
          außerordentlichen Kündigung einer Lizenz zu, wenn:
        </TextWrapper>
        <div>
          <ol className={lowerAlphaListClassName}>
            <li>
              (a) der Lizenznehmer mit einer Zahlung trotz Mahnung und
              Fristsetzung über einen Zeitraum von mehr als 2 Monaten in Verzug
              ist;
            </li>
            <li>
              (b) beim Lizenznehmer eine wesentliche Vermögensverschlechterung
              eintritt, die erwarten lässt, dass Rechnungen an den Lizenznehmer
              nicht mehr beglichen werden;
            </li>
            <li>
              (c) der Lizenznehmer Geistiges Eigentum des Lizenzgebers verletzt
              (z. B. vertragswidrige Nutzung oder Verarbeitung der
              sustamize-Daten);
            </li>
            <li>
              (d) der Lizenznehmer sonst eine Pflicht schuldhaft verletzt und
              diese Verletzung nicht innerhalb einer von dem Lizenzgeber
              gesetzten angemessenen Frist abstellt.
            </li>
          </ol>
        </div>
        <TextWrapper>
          (3) Mit Wirksamwerden der Kündigung gemäß Absatz 2 enden die dem
          Lizenznehmer unter diesem DaaSA eingeräumten Rechte. Etwaige Ansprüche
          des Lizenznehmers auf Rückzahlung von Lizenzgebühren oder sonstige
          Ansprüche des Lizenznehmers jedweder Art sind ausgeschlossen.
        </TextWrapper>
        <TextWrapper>
          (4) Im Falle der Vereinbarung eines Rahmenvertrags bleibt die
          Lizenzdauer im Falle der Ausübung eines vereinbarten ordentlichen
          Kündigungsrechtes grundsätzlich unberührt; die Parteien treffen hierzu
          und zu den Löschungspflichten des Lizenznehmers eine gesonderte
          Regelung in dem Rahmenvertrag.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 13 Löschungs- und Herausgabepflichten
        </TitleWrapper>
        <TextWrapper>
          (1) Der Lizenznehmer ist verpflichtet, nach Beendigung der Lizenzdauer
          etwaige in seiner Verfügungsgewalt stehenden sustamize-Daten und
          Datenbanksoftware der sustamize-Datenbanken endgültig und dauerhaft zu
          löschen, soweit dem nicht zwingende gesetzliche Vorschriften oder
          zwingende informationssicherheitstechnische Erfordernisse (z. B.
          Backup) entgegenstehen. Die Löschung und deren Dokumentation hat nach
          den anerkannten Regeln der Technik und unter Beachtung der jeweils
          maximalen Schutzstandards zu erfolgen. Auf Aufforderung durch die
          Lizenzgeberin hat der Lizenznehmer sämtliche Löschungsmaßnahmen und
          deren Vertragsgemäßheit an Eides Statt zu versichern.
        </TextWrapper>
        <TextWrapper>
          (2) Von der Löschungspflicht ausgenommen sind Arbeitsergebnisse.
        </TextWrapper>
        <TextWrapper>
          (3) Informationen, Materialien, Unterlagen, Dokumente, Hard-/Software
          und sonstige Inhalte des Lizenzgebers, die dem Lizenznehmer in
          verkörperter Form vorliegen, einschließlich aller Kopien der
          vertragsgegenständlichen sustamize-Daten auf Datenträgern oder
          Hardware-Geräten, sind nach Ende der Lizenzdauer an den Lizenzgeber
          zurückzugeben oder auf dessen Verlangen hin oder bei Nichtannahme zu
          vernichten. Die Regelung von Absatz 1 findet entsprechende Anwendung.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 14 Haftung für Rechte Dritter
        </TitleWrapper>
        <TextWrapper>
          (1) Für den Fall, dass Dritte gegen den Lizenznehmer Ansprüche wegen
          der Verletzung von urheberrechtlich geschützten Datenbankwerken durch
          die sustamize-Daten erheben und dies von dem Lizenzgeber zu vertreten
          ist, stellt der Lizenzgeber den Lizenznehmer von diesen Ansprüchen
          frei, soweit sie berechtigt sind.
        </TextWrapper>
        <TextWrapper>
          (2) Der Lizenznehmer ist verpflichtet, den Lizenzgeber unverzüglich
          darüber zu informieren, wenn ein Dritter im Zusammenhang mit den
          sustamize-Daten Ansprüche gegenüber dem Lizenznehmer stellt.
          Unterlässt der Lizenznehmer eine rechtzeitige Meldung und entstehen
          hieraus Schäden oder sonstige Nachteile (z. B. außergerichtliche oder
          gerichtliche Kosten), so haftet der Lizenzgeber hierfür nicht.
        </TextWrapper>
        <TextWrapper>
          (3) Soweit berechtigte Ansprüche Dritter gemäß Absatz 1 bestehen, kann
          der Lizenzgeber gegenüber dem Lizenznehmer nach seiner Wahl die
          Nacherfüllung dadurch vornehmen, dass der Lizenzgeber
        </TextWrapper>
        <div>
          <ol className={lowerAlphaListClassName}>
            <li>
              (a) von dem über das verletzte Datenbankrecht Verfügungsbefugten
              zugunsten des Lizenznehmers ein für dessen Vertragszweck (vgl. §
              4(3)) ausreichendes Nutzungsrecht erwirkt, oder
            </li>
            <li>
              (b) die gemäß Absatz 1 gegenständlichen sustamize-Daten mit für
              den Lizenznehmer akzeptablen Auswirkungen auf den Verwendungszweck
              ändert oder austauscht, oder
            </li>
            <li>
              (c) einen neuen Versionsstand der gemäß Absatz 1 gegenständlichen
              sustamize-Daten liefert, bei dessen vertragsgemäßer Nutzung keine
              Datenbankrechte Dritter verletzt werden.
            </li>
          </ol>
        </div>
        <TextWrapper>
          (4) Der Lizenznehmer wird den Lizenzgeber bei der Abwehr von
          Ansprüchen Dritter, soweit zumutbar und für die Verteidigung und
          Vergleichsgespräche förderlich, auf Kosten des Lizenzgebers
          unterstützen (z. B. durch Information, Dokumentation,
          Beweissicherung).
        </TextWrapper>
        <TextWrapper>
          (5) Der Lizenzgeber haftet nicht für eine Verletzung von Rechten
          Dritter, soweit die Rechtsverletzung auf einer pflichtwidrigen
          Handlung oder Unterlassung des Lizenznehmers beruht.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 15 Sonstige Haftung
        </TitleWrapper>
        <TextWrapper>
          (1) Der Lizenzgeber haftet für Vorsatz und grobe Fahrlässigkeit nach
          den gesetzlichen Vorschriften.
        </TextWrapper>
        <TextWrapper>
          (2) Für leichte Fahrlässigkeit haftet der Lizenzgeber nur bei
          Verletzung einer wesentlichen Vertragspflicht (Kardinalpflicht) sowie
          bei Schäden aus der Verletzung des Lebens, des Körpers oder der
          Gesundheit.
        </TextWrapper>
        <TextWrapper>
          (3) Soweit nicht ein Fall von Absatz 1 vorliegt, gilt bei der
          Verletzung einer wesentlichen Vertragspflicht (Kardinalpflicht) gemäß
          Absatz 2 folgendes: Wesentlich sind solche Vertragspflichten, deren
          Beachtung notwendige Voraussetzung für die Durchführung des Vertrags
          ist, oder deren Verletzung die Zweckerreichung des Vertrags gefährdet
          und der Lizenznehmer berechtigterweise auf die Einhaltung der
          betroffenen Pflicht vertrauen durfte. Der Lizenzgeber haftet dabei nur
          für vorhersehbare Schäden, mit deren Entstehung typischerweise
          gerechnet werden muss, in dem unter nachfolgendem Absatz 4 bestimmten
          Umfang.
        </TextWrapper>
        <TextWrapper>
          (4) Die Parteien sind sich einig, dass der vorhersehbare
          vertragstypische Schaden einen Betrag in Höhe von 50 % des jährlichen
          Netto-Umsatzes, den der Lizenzgeber beim Lizenznehmer erzielt, nicht
          übersteigt.
        </TextWrapper>
        <TextWrapper>
          (5) Im Übrigen ist die Haftung des Lizenzgebers ausgeschlossen,
          einschließlich der verschuldensunabhängigen Schadensersatzhaftung für
          Mängel, die bereits bei Vertragsschluss vorhanden waren.
        </TextWrapper>
        <TextWrapper>
          (6) Die vorstehenden Regelungen gelten auch zugunsten der
          Erfüllungsgehilfen des Lizenzgebers.
        </TextWrapper>
        <TextWrapper>
          (7) Nacherfüllungsansprüche verjähren in 12 Monaten ab Ablieferung.
          Hiervon ausgenommen sind Schadensersatzansprüche sowie Mängelansprüche
          bei arglistigem Verschweigen eines Mangels.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 16 Geheimnisschutz, Vertraulichkeit
        </TitleWrapper>
        <TextWrapper>
          (1) Jede Partei wird Informationen und Unterlagen, die sie von der
          anderen Partei im Zusammenhang mit der Durchführung dieses DaaSA
          erhält, vertraulich behandeln. Innerhalb des eigenen Unternehmens
          dürfen Informationen und Unterlagen der anderen Partei nur dem
          Personal zur Kenntnis gebracht oder zugänglich gemacht werden, welches
          die Informationen und Unterlagen zur Erfüllung dieses DaaSA und/oder
          eines auf dessen Grundlage abgeschlossenen Einzel-/Rahmenvertrags
          benötigt und selbst vertraglich zur Vertraulichkeit verpflichtet
          wurde. Jede Partei wird Informationen oder Unterlagen der anderen
          Vertragspartei ohne deren vorherige schriftliche Zustimmung nicht an
          Dritte (einschließlich verbundener Unternehmen iSv. § 15 AktG)
          weitergeben. Liegt eine solche Zustimmung vor, ist der Dritte vor
          Weitergabe schriftlich zur Vertraulichkeit entsprechend der
          Bestimmungen dieses § 17 zu verpflichten. Die Geheimhaltungspflicht
          besteht auch nach Lizenz- und Vertragsende so lange fort, wie diese
          Informationen und Unterlagen geheim sind.
        </TextWrapper>
        <TextWrapper>
          (2) Ausgenommen von der Verpflichtung zur Vertraulichkeit gem.
          vorstehend Absatz 1 sind Informationen oder Unterlagen, die allgemein
          bekannt sind.
        </TextWrapper>
        <TextWrapper>
          (3) Der Lizenznehmer hat die sustamize-Daten ausschließlich für den
          Vertragszweck gemäß § 4(3) zu verwenden. Der Lizenznehmer ist
          verpflichtet, die sustamize-Daten und sämtliche ihm zur Verfügung
          gestellten Zugangsschlüssel, -codes etc. durch geeignete Maßnahmen vor
          dem Zugriff unberechtigter Dritter zu schützen und geheim zu halten.
          Alle körperlichen und unkörperlichen Kopien der sustamize-Daten sind
          an einem geschützten Ort aufzubewahren.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 17 Höhere Gewalt
        </TitleWrapper>
        <TextWrapper>
          Keiner der Vertragspartner ist verantwortlich für Schäden und
          Verzögerungen, die auf Ursachen basieren, die nicht in der Kontrolle
          des jeweiligen Vertragspartners liegen. Solche Ursachen sind
          insbesondere Streik, Aussperrungen, Arbeitskämpfe, Naturkatastrophen,
          Pandemien, Krieg, militärische Auseinandersetzungen, innere Unruhen,
          Sabotageakte, gesetzliche oder behördliche Anordnungen.
        </TextWrapper>

        <TitleWrapper
          variant={eTypographyVariant.h5}
          className={headingClassName}
        >
          § 18 Schlussbestimmungen
        </TitleWrapper>
        <TextWrapper>
          (1) Weichen die Vereinbarungen der Parteien in einem Einzel- oder
          Rahmenvertrag von diesem DaaSA ab, so gilt im Zweifel die Vereinbarung
          der Parteien im Einzel- oder Rahmenvertrag.
        </TextWrapper>
        <TextWrapper>
          (2) Sollten einzelne Bestimmungen dieses DaaSA unwirksam sein oder
          werden, so wird dadurch die Wirksamkeit der übrigen Bestimmungen nicht
          berührt. Statt der unwirksamen Bestimmung gilt dasjenige, was die
          Parteien nach dem ursprünglich angestrebten Zweck unter
          wirtschaftlicher Betrachtungsweise redlicherweise vereinbart hätten.
          Das Gleiche gilt im Falle des Vorliegens einer Vertragslücke.
        </TextWrapper>
        <TextWrapper>
          (3) Dieses DaaSA und das Vertragsverhältnis zwischen den Parteien
          unterliegt dem Recht der Bundesrepublik Deutschland unter Ausschluss
          des UN-Kaufrechts.
        </TextWrapper>
        <TextWrapper>
          (4) Gerichtsstand im Verkehr mit Kaufleuten ist der Sitz des
          Lizenzgebers (München). Der Lizenzgeber ist jedoch nach seiner Wahl
          berechtigt, auch am Sitz des Lizenznehmers zu klagen.
        </TextWrapper>
      </PaperWrapper>
    </ContentBody>
  )
}
