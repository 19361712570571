import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import React from 'react'
import { filterOption } from './types'

interface IProps {
  label: string
  value: filterOption
  options: filterOption[]
}
const SelectFilter = ({ label, value, options }: IProps) => {
  const [age, setAge] = React.useState('')

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string)
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={age}
        label={label}
        onChange={handleChange}
      >
        {options.map((option: filterOption) => {
          const key = `${label}-${value}`
          return (
            <MenuItem key={key} value={option.value}>
              {option.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default SelectFilter
