import { DATA_API } from '../urlPaths'
import reqProvider from '../coreRequests'
import { iSingleEntity, userState } from 'store/types'
import { iRoleMissingResponseError } from 'pages/DataHub/filters/types'
import { iSingleEntityParams } from './types'
import { call, select } from 'redux-saga/effects'
import { userReducerState } from 'store/slices/user'

export function* getSingleEntityRequest(
  queryPath: iSingleEntityParams,
  redirect: boolean = true
): iSingleEntity | iRoleMissingResponseError | any {
  // get the single entity ID
  const entityId = queryPath.uuid
  const userInfo: userState = yield select(userReducerState)

  //get parameters as string
  const params: string = Object.keys(queryPath)
    .map((item: any, index: number) => {
      const verifyNullUndefinedOrFalse =
        (queryPath[item] !== null && queryPath[item] !== undefined) ||
        queryPath[item] === false
      const isKeyIdParam = item === 'uuid'
      const isFirstParam = index === 0
      const prefixCharacter =
        !isFirstParam && verifyNullUndefinedOrFalse ? '&' : ''

      const currentParam = verifyNullUndefinedOrFalse
        ? `${item}=${queryPath[item]}`
        : ''

      return !isKeyIdParam ? `${prefixCharacter}${currentParam}` : ''
    })
    .join('')

  const path = `${DATA_API}/entity/${entityId}?${params}`
  const data: iSingleEntity | iRoleMissingResponseError | any = yield call(
    reqProvider.getReq,
    path,
    undefined,
    redirect,
    userInfo
  )
  return data
}
