import {
  iLooseObject,
  iSaveSearchParameters,
} from 'pages/DataHub/filters/types'
import { iNewMember } from 'pages/user/types'
import { iOrganizationData } from 'store/types'

export const userTypes = {
  SAGA_FETCH_USER_PROFILE_DATA: 'SAGA_FETCH_USER_PROFILE_DATA',
  SAGA_CLEAR_USER_PROFILE_DATA: 'SAGA_CLEAR_USER_PROFILE_DATA',
  SAGA_SET_USER_CURRENCY_PROFILE_DATA: 'SAGA_SET_USER_CURRENCY_PROFILE_DATA',
  SAGA_SET_USER_LANGUAGE_PROFILE_DATA: 'SAGA_SET_USER_LANGUAGE_PROFILE_DATA',
  SAGA_DELETE_USER: 'SAGA_DELETE_USER',
  SAGA_GET_ORGANIZATION_PROFILE_DATA: 'SAGA_GET_ORGANIZATION_PROFILE_DATA',
  SAGA_SET_ORGANIZATION_PROFILE_DATA: 'SAGA_SET_ORGANIZATION_PROFILE_DATA',
  SAGA_UPDATE_ORGANIZATION_PROFILE_DATA:
    'SAGA_UPDATE_ORGANIZATION_PROFILE_DATA',
  SAGA_GET_ORGANIZATION_TEAM_MEMBERS_DATA:
    'SAGA_GET_ORGANIZATION_TEAM_MEMBERS_DATA',
  SAGA_SAVE_SEARCH_PARAMETERS: 'SAGA_SAVE_SEARCH_PARAMETERS',
  SAGA_GET_SEARCH_PARAMETERS: 'SAGA_GET_SEARCH_PARAMETERS',
  SAGA_GET_USER_PROFILE_URLS: 'SAGA_GET_USER_PROFILE_URLS',
  SAGA_ADD_MEMBER: 'SAGA_ADD_MEMBER',
  SAGA_UPDATE_MEMBER: 'SAGA_UPDATE_MEMBER',
  SAGA_DELETE_TEAM_MEMBER: 'SAGA_DELETE_TEAM_MEMBER',
  SAGA_ACTIVATE_TEAM_MEMBER: 'SAGA_ACTIVATE_TEAM_MEMBER',
  SAGA_DEACTIVATE_TEAM_MEMBER: 'SAGA_DEACTIVATE_TEAM_MEMBER',
}

export function sagaUserProfileRequestAction() {
  return {
    type: userTypes.SAGA_FETCH_USER_PROFILE_DATA,
  }
}

export function sagaUserProfileUrlsAction() {
  return { type: userTypes.SAGA_GET_USER_PROFILE_URLS }
}

export function sagaSetUserCurrencyProfileAction(newCurrency: string) {
  return {
    type: userTypes.SAGA_SET_USER_CURRENCY_PROFILE_DATA,
    payload: newCurrency,
  }
}
export function sagaSetUserLanguageProfileAction(newLanguage: string) {
  return {
    type: userTypes.SAGA_SET_USER_LANGUAGE_PROFILE_DATA,
    payload: newLanguage,
  }
}
export function sagaClearUserProfileAction(justLogout: boolean = true) {
  return {
    type: userTypes.SAGA_CLEAR_USER_PROFILE_DATA,
    payload: justLogout,
  }
}
export function sagaDeleteUserAction(navigate: any) {
  return {
    type: userTypes.SAGA_DELETE_USER,
    payload: navigate,
  }
}
export function sagaGetOrganizationProfileDataAction() {
  return {
    type: userTypes.SAGA_GET_ORGANIZATION_PROFILE_DATA,
  }
}

export function sagaSetOrganizationProfileDataAction(
  organizationData: iOrganizationData
) {
  return {
    type: userTypes.SAGA_SET_ORGANIZATION_PROFILE_DATA,
    payload: organizationData,
  }
}
export function sagaUpdateOrganizationProfileDataAction(
  organizationData: iOrganizationData,
  setSearchParams: any
) {
  return {
    type: userTypes.SAGA_UPDATE_ORGANIZATION_PROFILE_DATA,
    payload: {
      organizationData,
      setSearchParams,
    },
  }
}
export function sagaGetOrganizationTeamMembersAction(organizationId: number) {
  return {
    type: userTypes.SAGA_GET_ORGANIZATION_TEAM_MEMBERS_DATA,
    payload: { organizationId },
  }
}

export function sagaSaveSearchParametersAction(
  searchParameters: iSaveSearchParameters,
  contextParam: string | undefined,
  setParams: any | undefined //SetUrlSearchParams
) {
  return {
    type: userTypes.SAGA_SAVE_SEARCH_PARAMETERS,
    payload: { searchParameters, contextParam, setParams },
  }
}
export function sagagetUserSearchParametersAction(page: number = 1) {
  return {
    type: userTypes.SAGA_GET_SEARCH_PARAMETERS,
    payload: page,
  }
}
export function sagaAddMemberAction(memberData: iNewMember) {
  return {
    type: userTypes.SAGA_ADD_MEMBER,
    payload: memberData,
  }
}
export function sagaUpdateMemberAction(payload: iLooseObject) {
  return {
    type: userTypes.SAGA_UPDATE_MEMBER,
    payload: payload,
  }
}
export function sagaDeleteTeamMemberAction(memberId: number) {
  return {
    type: userTypes.SAGA_DELETE_TEAM_MEMBER,
    payload: memberId,
  }
}
export function sagaActivateTeamMemberAction(memberId: number) {
  return {
    type: userTypes.SAGA_ACTIVATE_TEAM_MEMBER,
    payload: memberId,
  }
}
export function sagaDeactivateTeamMemberAction(memberId: number) {
  return {
    type: userTypes.SAGA_DEACTIVATE_TEAM_MEMBER,
    payload: memberId,
  }
}
