import { Paper } from '@mui/material'
import LinkWrapper from 'components/custom/LinkWrapper'
import { ContentBody } from 'components/pages/components/ContentBody'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RoutePaths } from 'routes/paths'
import UpgradeImage  from 'assets/images/upgrade_image.png'

const Upgrade: FC = () => {
  const { t } = useTranslation()

  return (
    <ContentBody title={t('upgrade.title')}>
      <Paper elevation={2}>
        <div className="p-4 flex flex-col text-center justify-center">
          <img src={UpgradeImage} alt="upgrade" className="w-1/2 self-center h-full mb-2" />
          <p className="p-4 text-center text-lg">
            {t('upgrade.description_prefix')}
            <LinkWrapper to={RoutePaths.billing}>
              {t('upgrade.sales_anchor')}
            </LinkWrapper>
            {t('upgrade.description_suffix')}
          </p>
        </div>
      </Paper>
    </ContentBody>
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: RoutePaths.upgrade,
    // index: true,
    title: 'upgrade.title',
    element: <Upgrade />,
    roles: componentPermissions[componentNames.upgrade],
    requiresAuthentication: true,
  },
  name: 'Upgrade',
}
export default exportObj
