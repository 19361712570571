//Import pages
import apiManagement from '../pages/management/api-management'
import home from 'pages/Home'
import custom403 from 'pages/403'
import member403 from 'pages/403Member'
import custom404 from 'pages/404'
import custom500 from 'pages/500'
import login from 'pages/Login'
import subscriptionManagement from 'pages/management/subscription-management'
import organizationManagement from 'pages/management/organization-management'
import support from 'pages/Help/Support'
import knowledgeCenter from 'pages/KnowledgeCenter'
import databaseOverview from 'pages/KnowledgeCenter/DatabaseOverview'
import calculationVariants from 'pages/KnowledgeCenter/CalculationVariants'
import dataQualityAssurance from 'pages/KnowledgeCenter/DataQualityAssurance'
import electronics from 'pages/KnowledgeCenter/ElectronicsDocument'
import energy from 'pages/KnowledgeCenter/EnergyMixDocument'
import logistics from 'pages/KnowledgeCenter/LogisticsDocument'
import materials from 'pages/KnowledgeCenter/MaterialsDocument'
import production from 'pages/KnowledgeCenter/ProductionDocument'
import dataCenter from 'pages/tools/data-center'
import SingleEntity from 'pages/SingleEntity'
import procurementCenter from 'pages/tools/procurement-center'
import scope12Calculator from 'pages/tools/scope-12-calculator'
import simulationCenter from 'pages/tools/simulation-center'
import organizationProfile from 'pages/user/organization'
import upgrade from 'pages/Upgrade'
import preferences from 'pages/DataHub/preferences'
import dataHub from 'pages/DataHub/filters'
import cbam from 'pages/DataHub/AdditionalResources/CbamData'
import comingSoon from 'pages/ComingSoon/coming-soon'
import massUpload from 'pages/MassUpload'
import userSettings from 'pages/user/userSettings'
import logout from 'pages/Logout'
import termsAndConditions from 'pages/Legal/TermsAndConditions'
import privacyPolicy from 'pages/Legal/PrivacyPolicy'
import imprint from 'pages/Legal/Imprint'
import billing from 'pages/Billing'
import dataAsServiceAggrement from 'pages/Legal/DataAsServiceAggrement'
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  home,
  custom403,
  member403,
  custom404,
  custom500,
  logout,
  login,
  apiManagement,
  subscriptionManagement,
  organizationManagement,
  support,
  knowledgeCenter,
  databaseOverview,
  calculationVariants,
  dataQualityAssurance,
  electronics,
  energy,
  logistics,
  materials,
  production,
  dataCenter,
  SingleEntity,
  procurementCenter,
  scope12Calculator,
  simulationCenter,
  userSettings,
  organizationProfile,
  upgrade,
  dataHub,
  cbam,
  preferences,
  comingSoon,
  massUpload,
  termsAndConditions,
  privacyPolicy,
  imprint,
  billing,
  dataAsServiceAggrement,
]
