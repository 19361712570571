import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Roles } from 'config/roles'
import { RootState } from 'store'
import { eLocale, userState } from 'store/types'

// for parametered slices
// import type { PayloadAction } from '@reduxjs/toolkit'

export const initialState: userState = {
  id: '',
  username: '',
  email: '',
  firstName: '',
  lastName: '',
  roles: [Roles.ROLE_USER_ADMIN],
  settings: {
    currency: '',
    locale: eLocale.English,
  },
  organization: {},
}

//get current reducer state
export const userReducerState = (state: RootState) => state.user

//reducer Object
export const userReducer = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<userState>) => {
      // extracting the individual data fields from the payload
      const {
        id,
        username,
        email,
        firstName,
        lastName,
        roles,
        settings,
        organization,
      } = action.payload
      if (organization && organization['id']) {
        const orgId = organization['id']
        state.organization.id = orgId
      }
      //updating the state with the payload variable data
      state.id = id
      state.username = username
      state.email = email
      state.firstName = firstName
      state.lastName = lastName
      state.roles = roles
      state.settings = settings
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUserData } = userReducer.actions

export default userReducer.reducer
