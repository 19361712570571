import * as React from 'react'
import { componentPermissions } from 'config/permissions'
import { componentNames } from 'config/roles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RoutePaths } from 'routes/paths'

import { ReactComponent as ComingSoonLogo } from 'assets/images/coming-soon.svg'
import PaperWrapper from 'components/custom/PaperWrapper'
import ContentBodyWrapper from 'components/custom/ContentBodyWrapper'
import LinkWrapper from 'components/custom/LinkWrapper'
import { eElementOnMouseOverEffect } from 'components/types'

const ComingSoon: FC = () => {
  const { t } = useTranslation()

  return (
    <ContentBodyWrapper title={t('comingSoon.title')}>
      <PaperWrapper>
        <div className={`p-4 flex flex-col text-center justify-center`}>
          <ComingSoonLogo className="w-[18%] self-center h-full mb-2" />
          <p className="p-4 text-center text-lg">
            {t('comingSoon.description_prefix')}
            <LinkWrapper
              underline={eElementOnMouseOverEffect.always}
              to={`https://share-eu1.hsforms.com/14M56owquRuCDe8Atb9JfoAfaf1j`}
            >
              {t('comingSoon.description_anchor')}
            </LinkWrapper>
            {t('comingSoon.description_suffix')}
          </p>
        </div>
      </PaperWrapper>
    </ContentBodyWrapper>
  )
}

const exportObj = {
  routeProps: {
    // This gets passed straight to react-router
    path: [RoutePaths.comingSoon, RoutePaths.comingSoonId],
    title: 'comingSoon.title',
    element: <ComingSoon />,
    roles: componentPermissions[componentNames.comingSoon],
    requiresAuthentication: true,
  },
  name: 'ComingSoon',
}
export default exportObj
