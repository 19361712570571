import { call, put, select, takeLeading } from 'redux-saga/effects'
import apiRequests from 'config/services'
import { sagaClearUserProfileAction, userTypes } from 'saga/actions/user'
import { userReducerState } from 'store/slices/user'
import { userState } from 'store/types'

export function* deleteUserSaga(action: any) {
  try {
    const user: userState = yield select(userReducerState)
    yield call(apiRequests.deleteUser, user.id)

    yield put(sagaClearUserProfileAction(false))
    action.payload.navigate('login')
  } catch (e) {
        //catchind errors
  }
}

export default function* deleteUserSagaHook() {
  yield takeLeading(userTypes.SAGA_DELETE_USER, deleteUserSaga)
}
