import { call, put, takeLatest } from 'redux-saga/effects'
import apiRequests from 'config/services'
import {
  dataHubTypes,
  sagaGetDataHubCategoriesAction,
  sagaSetSettingsDefaultFieldsData,
} from 'saga/actions/dataHub'
import { setHub } from 'store/slices/dataHubSlice'
import { eComponentType, iHubType } from 'store/types'
import { PayloadAction } from '@reduxjs/toolkit'
import { iContextDataType } from './types'
import { booleanify } from 'config/lib'
import {
  getSearchParametersInitialState,
  setSearchCategory,
} from 'store/slices/searchParametersSlice'
// import { selectedSearchCategoryState } from 'store/slices/searchParametersSlice'

export function* getDataHubSaga(action: PayloadAction<iContextDataType>) {
  const { queryPath, componentType } = action.payload
  try {
    let result: iHubType = yield call(apiRequests.getDataHubData, queryPath)
    const { settings, filters, settingRules, context } = result

    if (filters[1]) {
      settings.unshift(filters[0], filters[1])
    } else if (filters[0]) {
      settings.unshift(filters[0])
    }
    settings.forEach((item) => {
      const isTypeBoolean = item.type === 'boolean'
      const defaultValueTemp: string = item.defaultValue?.toString() || ''

      item.defaultValue = isTypeBoolean
        ? booleanify(defaultValueTemp)
        : defaultValueTemp
    })
    if (result) {
      settingRules.push('isActive')
      const validityStartIndex = settingRules.indexOf('validityStart')
      settingRules.splice(validityStartIndex, 1)
      const validityEndIndex = settingRules.indexOf('validityEnd')
      settingRules[validityEndIndex] = 'period'

      yield put(setHub(result))
      if (componentType === eComponentType.settings) {
        const searchParamsCategory =
          context === '.'
            ? getSearchParametersInitialState().searchCategory
            : context.split('.')[1]
        yield put(sagaGetDataHubCategoriesAction())
        yield put(setSearchCategory(searchParamsCategory))
        yield put(sagaSetSettingsDefaultFieldsData())
      }
    }
  } catch (e) {
        //catchind errors
  }
}

export default function* getDataHubSagaHook() {
  yield takeLatest(dataHubTypes.SAGA_GET_DATA_HUB, getDataHubSaga)
}
