import React, { useCallback, useEffect, useState, MouseEvent } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import { eButtonVariant, iMenuWrapperValues } from 'components/types'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useTranslation } from 'react-i18next'
import { Roles } from 'config/roles'
import { iLooseObject } from 'pages/DataHub/filters/types'

interface IProps {
  btnText?: string
  variant?: eButtonVariant
  menuClass?: string
  btnClass?: string
  menuValues: iMenuWrapperValues[]
  defaultValue?: string
  justText?: boolean
  onClick?: (value: string, rawValue?: string) => void
  changeValueOnClick?: boolean
  showIcon?: boolean
  fullWidth?: boolean
}

const ButtonMenuWrapper = ({
  btnClass = 'h-7 bg-transparent text-black px-2',
  variant = eButtonVariant.contained,
  defaultValue = '',
  btnText,
  menuValues,
  justText,
  onClick,
  changeValueOnClick = true,
  showIcon = true,
  fullWidth = false,
}: IProps) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [value, setValue] = useState<string | null>(
    btnText || defaultValue || ''
  )
  const [selectedValue, setSelectedValue] = useState<string | null>('')

  const handleClose = useCallback(
    (event: MouseEvent, localItem?: iLooseObject) => {
      if (defaultValue.length !== 0 && changeValueOnClick) {
        setValue(event.currentTarget.textContent)
        setSelectedValue(event.currentTarget.textContent)
      }
      if (onClick)
        onClick(event.currentTarget.textContent as string, localItem?.value)
      setAnchorEl(null)
    },
    [changeValueOnClick, defaultValue.length, onClick]
  )
  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  useEffect(() => {
    if (btnText && btnText?.length > 0) setValue(btnText)
    else if (selectedValue && selectedValue?.length > 0) {
      setValue(selectedValue)
    } else setValue(defaultValue)
  }, [btnText, defaultValue, selectedValue, value])

  return justText ? (
    <>{t(Roles.ROLE_ORG_OWNER)}</>
  ) : (
    <>
      <Button
        aria-controls={open ? 'customized-menu' : undefined}
        aria-haspopup="true"
        className={btnClass}
        sx={{
          '&:hover': {
            boxShadow: `0 3px 5px 1px rgba(27, 102, 0, .2)`,
          },
          '&.Mui-focusVisible': {
            boxShadow: `0 3px 5px 1px rgba(27, 102, 0, .2)`,
          },
          '&.Mui-active': {
            boxShadow: `0 3px 5px 1px rgba(27, 102, 0, .2)`,
          },
        }}
        aria-expanded={open ? 'true' : undefined}
        variant={variant}
        onClick={handleClick}
        endIcon={showIcon ? <KeyboardArrowDownIcon className="-ml-1" /> : <></>}
        fullWidth={fullWidth}
      >
        {value}
      </Button>
      {!!menuValues.length && (
        <Menu
          id="customized-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={(event: any) => handleClose(event)}
        >
          {menuValues.map((item: iMenuWrapperValues, index) => (
            <MenuItem
              key={item.value + index}
              value={item.value}
              onClick={(event: MouseEvent) => handleClose(event, item)}
            >
              {t(item.label)}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}

export default ButtonMenuWrapper
