import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { iDataHubSearch, iHubsSliceState, iHubType } from 'store/types'
// for parametered slices
// import type { PayloadAction } from '@reduxjs/toolkit'

export const initialState: iHubsSliceState = {
  hub: {
    context: '',
    filters: [],
    results: [],
    settings: [],
    settingRules: [],
  },
}

//get current reducer state
export const dataHubReducerState = (state: RootState) => state.dataHub

//reducer Object
export const currentDataHubReducer = createSlice({
  name: 'dataHubReducer',
  initialState,
  reducers: {
    setHub: (state, action: PayloadAction<iHubType>) => {
      const newHub: iHubType = action.payload

      state.hub = { ...state.hub, ...newHub }
    },
    setSearchResults: (state, action: PayloadAction<iDataHubSearch[]>) => {
      const searchResults: iDataHubSearch[] = action.payload
      const newHubState: iHubType = {
        ...state.hub,
        results: searchResults,
      }
      state.hub = state.hub = newHubState
    },
    // setSearchPreferences:(state, action: PayloadAction<iDataHubSearch[]>) => {

    // }
  },
})

// Action creators are generated for each case reducer function
export const { setHub, setSearchResults } = currentDataHubReducer.actions

export default currentDataHubReducer.reducer
