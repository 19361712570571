export type Order = 'asc' | 'desc'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const sortBoolean = (a: any, b: any, fieldName: string) => {
  return a[fieldName] === b[fieldName] ? 0 : a[fieldName] ? -1 : 1
}
const sortString = (a: any, b: any, fieldName: string) => {
  return a[fieldName] > b[fieldName] ? 1 : b[fieldName] > a[fieldName] ? -1 : 0
}

const sortByFieldName = (
  objs: any[],
  fieldName: string,
  isBoolean?: boolean
) => {
  const arrayForSort = objs && objs.length ? [...objs] : []
  return arrayForSort.sort((a, b) =>
    isBoolean ? sortBoolean(a, b, fieldName) : sortString(a, b, fieldName)
  )
}
export { descendingComparator, getComparator, stableSort, sortByFieldName }
