import React, { useEffect, useState } from 'react'
import { eElementOnMouseOverEffect, eLinkTarget } from 'components/types'
import { Link } from 'react-router-dom'

interface iProps {
  children: JSX.Element | string
  to?: string
  title?: string
  customRef?: React.MutableRefObject<HTMLElement> | any
  hash?: string
  target?: eLinkTarget
  color?: string
  underline?: eElementOnMouseOverEffect
  className?: string
}

const LinkWrapper = ({
  children,
  to,
  title,
  customRef,
  hash,
  target = eLinkTarget.self,
  color = 'text-green-500',
  underline = eElementOnMouseOverEffect.always,
  className,
}: iProps) => {
  const [targetBlank, setTargetBlank] = useState(false)

  useEffect(() => {
    if (to?.startsWith('http')) {
      setTargetBlank(true)
    }
  }, [to])

  const contentBreakClassName = () => {
    if (children.toString().startsWith('http')) {
      return 'break-all'
    }

    return 'break-normal'
  }

  const styleHoverLink = () => {
    switch (underline) {
      case eElementOnMouseOverEffect.hover:
        return 'hover:underline'
      case eElementOnMouseOverEffect.none:
        return 'no-underline'
      default:
        return 'underline'
    }
  }

  if (to?.startsWith('mailto')) {
    return (
      <a
        href={`${to || ''}${hash ? `#${hash}` : ''}`}
        target={target}
        className={`${className || ''} ${color} ${styleHoverLink()}`}>
        {children}
      </a>
    )
  }

  return (
    <Link
      to={`${to ? to : ''}${hash ? `#${hash}` : ''}` || ''}
      title={title}
      target={targetBlank ? '_blank' : '_self'}
      ref={(el) => targetBlank && 'noreferrer'}
      rel={targetBlank ? 'noreferrer' : ''}
      color={color}
      className={`${contentBreakClassName()} ${
        className || ''
      } ${color} ${styleHoverLink()}`}
      onClick={() => {
        if (customRef) {
          customRef.scrollIntoView({ behavior: 'smooth' })
        }
      }}>
      {children}
    </Link>
  )
}

export default LinkWrapper
