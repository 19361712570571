import React, { useCallback, useState } from 'react'
import { Autocomplete, Paper, TextField } from '@mui/material'
import './DataExplorer.css'
import { useTranslation } from 'react-i18next'
import { MetalsForm } from './MetalsForm'
import { PolymersForm } from './PolymersForm'
import { CompositesForm } from './CompositesForm'

const DataExplorer = () => {
  const { t } = useTranslation()
  const [selectedForm, setSelectedForm] = useState(null)

  const handleSelection = useCallback((e: any, newValue: any | null) => {
    if (newValue !== null) {
      setSelectedForm(newValue?.component || null)
    }
  }, [])

  const dataOptions = [
    { label: t('dataExplorer.metals'), component: <MetalsForm /> },
    { label: t('dataExplorer.polymers'), component: <PolymersForm /> },
    { label: t('dataExplorer.composites'), component: <CompositesForm /> },
    { label: t('dataExplorer.countriesEnergyMix'), component: null },
    { label: t('dataExplorer.machinesEquipements'), component: null },
    { label: t('dataExplorer.transportLogistics'), component: null },
    { label: t('dataExplorer.packaging'), component: null },
    { label: t('dataExplorer.rawMaterials'), component: null },
    { label: t('dataExplorer.gtin'), component: null },
    { label: t('dataExplorer.iso14048'), component: null },
  ]

  return (
    <>
      <Paper className={`Data-Explorer-main`}>
        <div className={`Data-Explorer-dropdown-container`}>
          <Autocomplete
            id={`data-option-select`}
            sx={{
              width: `100%`,
            }}
            onChange={(e, newValue: any | null) => handleSelection(e, newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('general.searchData')}
                variant={`standard`}
              />
            )}
            options={dataOptions}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            autoHighlight
          />
        </div>
        <div className={`Data-Explorer-form-container`}>{selectedForm}</div>
      </Paper>
    </>
  )
}

export default DataExplorer
