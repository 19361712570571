

import { IAccordionData } from '../components/DynamicAccordion'
import { getImage, makeBulttePointsList } from './helpers'
import FlowOfTheTransportReferenceModelling from 'assets/images/KnowledgeHub/LogisticsDocument/FlowOfTheTransportReferenceModelling.png'


export const LogisticsDocumentData: IAccordionData[] = [
  {
    title: 'LOGISTICS',
    content: `The database on logistics contains an extensive transportation database and a related packaging database.`,
    isOpen: true,

    children: [
      {
        title: 'TRANSPORT',
        content: `The transport database contains data points for all types of private, public and commercial transport. In the transport field, the calculation result is the amount of CO2 emitted per kilometer and the number of tons transported. For example, the database gives insight on the emissions of a light truck of 3.5 tons loaded at 75% capacity emitting 337.5 g of CO2e per ton of transported product and per driven km. Meaning that for 5 tons of product transported in a light 3.5 ton-truck over a distance of 400km, emissions will amount to 675.0 kg of CO2e (calculation: 5*400*337.5/1000=675.0). sustamize also uses studies to create datasets that enable a calculation based on a product's usable volume rather than its weight, since the inhibiting factor in transport may be volume rather than weight.
      <br /><br />
      <b><u>Definition of Process Chain Options</u></b>
      <br />
      The data used for the calculation comes directly from official sources, such as studies by universities or government institutions, and is used in the database, but is also based on the fuel consumption of this mode of transport. The amount of fuel consumed is derived from official publications or tests carried out by institutions. Fuel consumption is always linked to the transport unit concerned. For trucks, for example, it is weight, which translates into fuel consumption per ton and kilometer, whereas for cars, the figure is kilometer only. By combining the specific fuel consumption and the emissions linked to the type of fuel, we obtain the emissions linked to the type of transport.
      <br />
      ${getImage(FlowOfTheTransportReferenceModelling, "Flow of the Transport Reference Modelling")}
      `,
        isOpen: true,
      },
      {
        title: 'PACKAGING',
        content: `The database contains CO2e emissions related to various packaging materials and products that are used both for intermediate products, transportation as well as final products. Examples include foils, foams, pallets, cardboard, or load carriers. The data is either adapted from studies on packaging or a result of our own calculations made with the support of our material database.
        <br /><br />
        <u><b>Definition of Process Chain Options</u></b>
        <br />
        Compared to the material database, the packing database has not yet been modelled according to the bottom-up method. Therefore, no country adjustments are possible.
        <br /><br />
        <u><b>Calculation Process for Packaging</u></b>
        <br />
        There is currently no calculation process associated with the data in the packaging database. This means that all CO2e values displayed are not modeled, but are derived directly from various sources which are described and explained in the aforementioned "Data Sources" part of the “Data Quality” section of this document.
      <br />
      `,
        isOpen: false,
      },
      
    ],
  },
]
